import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {filter, map} from "../../../_snowpack/pkg/lodash.js";
import {PRE_KINDERGARTEN_LABEL} from "../../constants/index.js";
import {useCurrentSchoolYearByRegionId} from "../index.js";
import {formatDateByPattern, toOrdinalSuffix, utcDateFromString} from "../../utils/index.js";
import {UNASSIGNED} from "../../constants/school-of-enrollment.constant.js";
import {AnnouncementFilterProgramYearType} from "../../enums/announcement-filter-program-year-type.enum.js";
import {datePatterns} from "../../enums/date-patterns.enum.js";
import {StudentsGradeLevel} from "../../enums/grade-levels.enum.js";
import {Separator} from "../../enums/separator.enum.js";
import {MthTitle} from "../../enums/title.enum.js";
export const useCurrentGradeAndProgramByRegionId = (regionId, setGrades) => {
  const {
    loading: schoolYearLoading,
    data: schoolYearData,
    error: schoolYearError
  } = useCurrentSchoolYearByRegionId(regionId);
  const [availableGrades, setAvailableGrades] = useState([]);
  const [programYearList, setProgramYearList] = useState([]);
  const [schoolPartnerList, setSchoolPartnerList] = useState([]);
  const [testPreference, setTestPreference] = useState(false);
  const [schoolYearId, setSchoolYearId] = useState(0);
  useEffect(() => {
    if (programYearList && schoolPartnerList && availableGrades) {
      if (setGrades)
        setGrades([StudentsGradeLevel.ALL, ...map(availableGrades, (el) => el.value)]);
    }
  }, [availableGrades]);
  useEffect(() => {
    if (!schoolYearLoading && schoolYearData) {
      const availGrades = schoolYearData?.grades?.split(Separator.COMMA).map((item) => {
        if (item === StudentsGradeLevel.KINDERGARTEN)
          return {
            label: StudentsGradeLevel.KINDERGARTEN,
            value: StudentsGradeLevel.KINDERGARTEN
          };
        else if (item === StudentsGradeLevel.PRE_KINDERGARTEN)
          return {
            label: PRE_KINDERGARTEN_LABEL,
            value: PRE_KINDERGARTEN_LABEL
          };
        else
          return {
            label: `${toOrdinalSuffix(Number(item))} ${MthTitle.GRADE}`,
            value: item.toString()
          };
      });
      const availSchoolPartners = map(filter(schoolYearData?.schoolEnrollmentYear, (el) => el.active === true && el.schoolPartner.region_id === regionId), (el) => ({
        label: el.schoolPartner.abbreviation,
        value: el.school_of_enrollment_id
      }));
      setSchoolPartnerList([...availSchoolPartners, {label: UNASSIGNED, value: UNASSIGNED}]);
      setAvailableGrades(availGrades);
      const schoolYear_date_begin = utcDateFromString(schoolYearData?.date_begin);
      const schoolYear_date_end = utcDateFromString(schoolYearData?.date_end);
      const dateBeginYYYY = formatDateByPattern(schoolYear_date_begin, datePatterns.FULL_YEAR);
      const dateEndYY = formatDateByPattern(schoolYear_date_end, datePatterns.SHORT_YEAR);
      const _programYearList = [
        {
          label: `${dateBeginYYYY}-${dateEndYY}`,
          value: AnnouncementFilterProgramYearType.SCHOOL_YEAR
        }
      ];
      if (schoolYearData?.midyear_application) {
        _programYearList.push({
          label: `${dateBeginYYYY}-${dateEndYY} ${MthTitle.MID_YEAR}`,
          value: AnnouncementFilterProgramYearType.MID_YEAR
        });
      }
      setProgramYearList(_programYearList);
      if (schoolYearData.testing_preference) {
        setTestPreference(true);
      } else {
        setTestPreference(false);
      }
      setSchoolYearId(schoolYearData?.school_year_id);
    }
  }, [schoolYearLoading, schoolYearData]);
  return {
    loading: schoolYearLoading,
    error: schoolYearError,
    gradeList: availableGrades,
    programYearList,
    schoolPartnerList,
    testPreference,
    schoolYearId
  };
};
