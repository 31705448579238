import React, {useEffect, useState, useContext, useMemo} from "../../../../_snowpack/pkg/react.js";
import {useQuery, gql} from "../../../../_snowpack/pkg/@apollo/client.js";
import CloseIcon from "../../../../_snowpack/pkg/@mui/icons-material/Close.js";
import {Grid, Modal} from "../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../_snowpack/pkg/@mui/system.js";
import {useFlag} from "../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {isNil} from "../../../../_snowpack/pkg/lodash.js";
import {FormProvider, useForm} from "../../../../_snowpack/pkg/react-hook-form.js";
import {EPIC_401_STORY_2373, EMPTY_STRING} from "../../../../core/constants/index.js";
import {MthColor, QUESTION_TYPE, QuestionTypeSlug, datePatterns} from "../../../../core/enums/index.js";
import {useSubmitEnrollmentPacket} from "../../../../core/hooks/useSubmitEnrollmentPacket/useSubmitEnrollmentPacket.js";
import {UserContext} from "../../../../providers/UserContext/UserProvider.js";
import {phoneFormat, formatDateByPatternUtc, getStudentGradeLevelBySY, formatDateForInput} from "../../../../core/utils/index.js";
import {getSettingsQuery, getEnrollmentPacketQuery, getSchoolYearSettingForPacketSubmit} from "../services.js";
import {EnrollmentPacketInfo} from "./EnrollmentPacketInfo/index.js";
import {EnrollmentPacketVaccineView} from "./EnrollmentPacketVaccineView/index.js";
import {PacketConfirmModals} from "./modals/ConfirmModals.js";
import {EnrollmentPacketDocument} from "./PacketDocuments.js";
import {EnrollmentPacketNotes} from "./PacketNotes.js";
import {PacketSaveButtons} from "./PacketSaveButtons.js";
import {EnrollmentPacketDropDownButton} from "./PacketStatusDropDown.js";
import {EnrollmentJobsInfo} from "./PacketStudentInfo.js";
import {studentContext, PacketModalQuestionsContext} from "./providers.js";
import {useStyles} from "./styles.js";
export const getPacketQuestionsGql = gql`
  query getPacketEnrollmentQuestions($input: EnrollmentQuestionsInput) {
    getPacketEnrollmentQuestions(input: $input) {
      id
      tab_name
      is_active
      region_id
      groups {
        id
        group_name
        tab_id
        order
        questions {
          id
          question
          group_id
          order
          description
          options
          additional_question
          required
          type
          slug
          default_question
          display_admin
          validation
        }
      }
    }
  }
`;
export const ProfilePacketModal = ({handleModem, packet_id, refetch}) => {
  const classes = useStyles;
  const {me} = useContext(UserContext);
  const epic401story2373 = useFlag(EPIC_401_STORY_2373);
  const settingsQuery = useQuery(getSettingsQuery, {
    variables: {
      regionId: Number(me?.selectedRegionId)
    },
    fetchPolicy: "network-only"
  });
  const enableImmunization = settingsQuery.data?.immunizationEnabled ?? false;
  const {loading: packetLoading, data: packetData} = useQuery(getEnrollmentPacketQuery, {
    variables: {packetID: packet_id},
    fetchPolicy: "network-only"
  });
  const {packet, school_year_id} = useMemo(() => ({
    packet: packetData?.packet,
    school_year_id: Number(packetData?.packet?.school_year_id)
  }), [packetData?.packet]);
  const {data: packetSchoolYear} = useQuery(getSchoolYearSettingForPacketSubmit, {
    variables: {
      schoolYearId: packet?.school_year_id
    },
    skip: !packet?.school_year_id,
    fetchPolicy: "network-only"
  });
  const scheduleEnabled = packetSchoolYear?.schoolyear?.schedule;
  const gradeLevel = getStudentGradeLevelBySY(packet?.student, school_year_id);
  const {data} = useQuery(getPacketQuestionsGql, {
    variables: {
      input: {
        region_id: Number(me?.selectedRegionId),
        school_year_id: epic401story2373 ? packet?.school_year_id ?? packet?.student?.current_school_year_status.school_year_id : packet?.student?.applications?.at(-1)?.school_year?.school_year_id,
        mid_year: packet?.reenroll ? false : packet?.student?.applications?.at(-1)?.school_year?.school_year_id === packet?.student?.current_school_year_status.school_year_id ? packet?.student?.applications?.at(-1)?.midyear_application : false
      }
    },
    skip: isNil(packet) || isNil(me?.selectedRegionId),
    fetchPolicy: "network-only"
  });
  const [questionsData, setQuestionsData] = useState();
  useEffect(() => {
    if (data?.getPacketEnrollmentQuestions.length > 0) {
      const jsonTabData = data?.getPacketEnrollmentQuestions.map((t) => {
        if (t.groups.length > 0) {
          const jsonGroups = t.groups.map((g) => {
            if (g.questions.length > 0) {
              const jsonQuestions = g.questions.map((q) => {
                return {
                  ...q,
                  options: JSON.parse(q.options) || []
                };
              }).sort((a, b) => a.order - b.order);
              return {...g, questions: jsonQuestions};
            }
            return g;
          }).sort((a, b) => a.order - b.order);
          return {...t, groups: jsonGroups};
        }
        return t;
      });
      setQuestionsData(jsonTabData);
    } else {
      setQuestionsData([]);
    }
  }, [data]);
  const [dynamicValues, setDynamicValues] = useState();
  useEffect(() => {
    const temp = {
      immunizations: [],
      parent: {...packet?.student?.parent?.person},
      notes: packet?.admin_notes || "",
      status: packet?.status || "",
      preSaveStatus: packet?.status || "",
      packetStatuses: [],
      showSaveWarnModal: false,
      missingInfoAlert: false,
      showMissingInfoModal: false,
      showAgeIssueModal: false,
      showValidationErrors: false,
      age_issue: false,
      saveAlert: "",
      medicalExempt: packet?.medical_exemption === 1,
      exemptionDate: packet?.exemption_form_date ? formatDateByPatternUtc(new Date(packet?.exemption_form_date), datePatterns.ZMONTH_ZDAY_4DYEAR) : EMPTY_STRING,
      enableExemptionDate: false,
      signature_file_id: packet?.signature_file_id || 0,
      missing_files: packet?.missing_files || [],
      school_year_id
    };
    if (questionsData && (questionsData?.length || 0) > 0 && packetLoading == false && packet) {
      questionsData.map((tab) => {
        tab?.groups?.map((group) => {
          group?.questions?.map((q) => {
            if (q.default_question) {
              if (q.slug.includes("packet_")) {
                const fieldName = q.slug.split("packet_")[1];
                temp[q.slug] = packet[fieldName];
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  temp[q.slug] = formatDateForInput(packet[fieldName]);
                }
              } else if (q.slug.includes("student_")) {
                const fieldName = q.slug.split("student_")[1];
                temp[q.slug] = packet.student.person[fieldName];
                temp[QuestionTypeSlug.STUDENT_GRADE_LEVEL] = gradeLevel;
                temp[QuestionTypeSlug.STUDENT_EMAILCONFIRM] = packet.student.person.email;
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  temp[q.slug] = formatDateForInput(packet.student.person[fieldName]);
                }
              } else if (q.slug.includes("address_")) {
                const fieldName = q.slug.split("address_")[1];
                temp[q.slug] = packet.student.parent.person.address[fieldName];
              } else if (q.slug.includes("parent_")) {
                const fieldName = q.slug.split("parent_")[1];
                temp[q.slug] = packet.student.parent.person[fieldName];
                temp[QuestionTypeSlug.PARENT_PHONE_NUMBER] = phoneFormat(packet.student.parent.phone.number);
                temp[QuestionTypeSlug.PARENT_RECIEVE_TEXT] = packet.student.parent.phone.recieve_text;
                temp[QuestionTypeSlug.PARENT_EMAILCONFIRM] = packet.student.parent.person.email;
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  temp[q.slug] = formatDateForInput(packet.student.parent.person[fieldName]);
                }
              } else if (q.slug.includes("meta_")) {
                const fieldName = q.slug;
                const metaJSON = JSON.parse(packet.meta);
                temp[q.slug] = metaJSON && metaJSON[fieldName] || "";
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  temp[q.slug] = formatDateForInput(metaJSON && metaJSON[fieldName] || null);
                }
              }
            } else {
              const fieldName = q.slug;
              const metaJSON = JSON.parse(packet.meta);
              temp[q.slug] = metaJSON && metaJSON[fieldName] || "";
              if (q.type === QUESTION_TYPE.CALENDAR) {
                temp[q.slug] = formatDateForInput(metaJSON && metaJSON[fieldName] || null);
              }
            }
          });
        });
      });
    }
    setDynamicValues(temp);
  }, [packet, packetLoading, questionsData]);
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: dynamicValues
  });
  useEffect(() => {
    methods.reset(dynamicValues);
  }, [dynamicValues, methods]);
  const {onSubmit, loading: submitLoading} = useSubmitEnrollmentPacket(packet, refetch, methods, questionsData, scheduleEnabled);
  if (isNil(packet) || isNil(school_year_id))
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  return /* @__PURE__ */ React.createElement(FormProvider, {
    ...methods
  }, /* @__PURE__ */ React.createElement("form", {
    onSubmit: methods.handleSubmit(onSubmit)
  }, /* @__PURE__ */ React.createElement(Modal, {
    open: true,
    onClose: () => handleModem(),
    "aria-labelledby": "modal-modal-title",
    "aria-describedby": "modal-modal-description"
  }, /* @__PURE__ */ React.createElement(studentContext.Provider, {
    value: packet.student
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.modalCard
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", justifyContent: "space-between"}
  }, /* @__PURE__ */ React.createElement(EnrollmentPacketDropDownButton, null), /* @__PURE__ */ React.createElement(CloseIcon, {
    onClick: () => handleModem(),
    style: classes.close
  })), /* @__PURE__ */ React.createElement(PacketModalQuestionsContext.Provider, {
    value: questionsData
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.content
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    sx: {padding: "10px 0px"}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 6,
    sm: 6,
    xs: 12
  }, /* @__PURE__ */ React.createElement(EnrollmentJobsInfo, {
    packet,
    handleModem,
    refetch
  }), /* @__PURE__ */ React.createElement(EnrollmentPacketDocument, {
    packetData: packet
  }), /* @__PURE__ */ React.createElement(EnrollmentPacketNotes, null), /* @__PURE__ */ React.createElement(PacketSaveButtons, {
    submitForm: methods.handleSubmit(onSubmit),
    loading: submitLoading
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 5,
    sm: 5,
    xs: 5
  }, enableImmunization && /* @__PURE__ */ React.createElement(EnrollmentPacketVaccineView, {
    packet
  }))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 12,
    sm: 12,
    xs: 12,
    sx: {padding: "20px 0px"}
  }, /* @__PURE__ */ React.createElement("hr", {
    style: {borderTop: `solid 1px ${MthColor.SYSTEM_11}`, width: "97%", borderBottom: "0"}
  })), /* @__PURE__ */ React.createElement(EnrollmentPacketInfo, null))), /* @__PURE__ */ React.createElement(PacketConfirmModals, {
    packet,
    refetch,
    submitForm: methods.handleSubmit(onSubmit)
  }))))));
};
