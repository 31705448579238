import React, {useState, useEffect, useContext, useCallback} from "../../../../../_snowpack/pkg/react.js";
import {gql, useQuery} from "../../../../../_snowpack/pkg/@apollo/client.js";
import {
  Box,
  Checkbox,
  outlinedInputClasses,
  Radio,
  TextField,
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel
} from "../../../../../_snowpack/pkg/@mui/material.js";
import {useFlag} from "../../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {Controller, useFormContext} from "../../../../../_snowpack/pkg/react-hook-form.js";
import {v4 as uuidv4} from "../../../../../_snowpack/pkg/uuid.js";
import {PhoneInput} from "../../../../../components/PhoneInput/PhoneInput.js";
import {ValidationType} from "../../../../../components/QuestionItem/QuestionItemProps.js";
import {EPIC_4318_STORY_4481, EPIC_3733_STORY_4680} from "../../../../../core/constants/index.js";
import {DisplayHTML} from "../../../../../core/components/shared/DisplayHTML/DisplayHTML.js";
import {DropDown} from "../../../../../core/components/shared/DropDown/DropDown.js";
import {Paragraph} from "../../../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor, MthTitle, QUESTION_TYPE, QuestionTypeSlug, TagType} from "../../../../../core/enums/index.js";
import {UserContext} from "../../../../../providers/UserContext/UserProvider.js";
import {gradesToDropDownItems} from "../../../../../core/utils/index.js";
export const getActiveSchoolYearsByRegionId = gql`
  query GetActiveSchoolYears($regionId: ID!) {
    getSchoolYearsByRegionId(region_id: $regionId) {
      grades
      school_year_id
    }
  }
`;
export const PacketQuestionItem = ({item}) => {
  const [questionItems, setQuestionItems] = useState([/* @__PURE__ */ React.createElement(Grid, {
    key: uuidv4()
  })]);
  const {getValues} = useFormContext();
  const values = getValues();
  useEffect(() => {
    if (item) {
      let childsEnable = false;
      setQuestionItems(item.map((i) => {
        if (values[`${i.additional_question}`]) {
          const parentIsAction = item.find((ii) => ii.slug == i.additional_question).options.filter((o) => o.action == 2).find((po) => Array.isArray(values[`${i.additional_question}`]) ? values[`${i.additional_question}`].find((fv) => fv.label == po.label) : po.label == values[`${i.additional_question}`] || po.value == values[`${i.additional_question}`]);
          if (parentIsAction && !childsEnable) {
            return {...i, isEnable: true};
          } else {
            childsEnable = true;
            return {...i, isEnable: false};
          }
        } else {
          return {...i, isEnable: false};
        }
      }));
    } else {
      setQuestionItems([/* @__PURE__ */ React.createElement(Grid, {
        key: uuidv4()
      })]);
    }
  }, [item]);
  const handleAdditionalAction = (slug, value) => {
    let index = 1e3;
    const updateQuestionItems = questionItems.map((q) => {
      if (q.additional_question === slug) {
        index = q.order;
        return {...q, isEnable: value};
      } else {
        if (value) {
          return q;
        } else {
          if (q.order > index) {
            return {...q, isEnable: false};
          } else {
            return q;
          }
        }
      }
    });
    setQuestionItems(updateQuestionItems);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, questionItems.map((q, index) => {
    if (q.additional_question && q.isEnable || !q.additional_question) {
      if (q.type === QUESTION_TYPE.INFORMATION) {
        return /* @__PURE__ */ React.createElement(Grid, {
          key: index,
          item: true,
          xs: 6
        }, /* @__PURE__ */ React.createElement(Box, {
          display: "flex",
          alignItems: "center"
        }, /* @__PURE__ */ React.createElement(Paragraph, {
          size: "large"
        }, /* @__PURE__ */ React.createElement(DisplayHTML, {
          text: q.question,
          tagType: TagType.PARAGRAPH
        }))));
      } else {
        return /* @__PURE__ */ React.createElement(Grid, {
          key: index,
          item: true,
          xs: questionItems.length > 1 ? 12 : 6
        }, q.type !== QUESTION_TYPE.AGREEMENT && /* @__PURE__ */ React.createElement(Box, {
          display: "flex",
          alignItems: "center",
          width: questionItems.length > 1 ? "50%" : "100%"
        }, /* @__PURE__ */ React.createElement(Subtitle, {
          fontWeight: "500"
        }, q.question)), /* @__PURE__ */ React.createElement(Box, {
          alignItems: "center",
          width: questionItems.length > 1 ? "49%" : "100%"
        }, /* @__PURE__ */ React.createElement(Item, {
          question: q,
          setAdditionalQuestion: (slug, value) => handleAdditionalAction(slug, value)
        })));
      }
    } else {
      return void 0;
    }
  }));
};
function Item({
  question: q,
  setAdditionalQuestion
}) {
  const epic4318story4481 = useFlag(EPIC_4318_STORY_4481);
  const epic3733Story4680 = useFlag(EPIC_3733_STORY_4680);
  const {control, watch, setValue, getValues} = useFormContext();
  const [school_year_id] = watch(["school_year_id"]);
  const [otherValue, setOtherValue] = useState("");
  const [gradesDropDownItems, setGradesDropDownItems] = useState([]);
  const {me} = useContext(UserContext);
  const [grades, setGrades] = useState([]);
  const {loading: schoolLoading, data: schoolYearData} = useQuery(getActiveSchoolYearsByRegionId, {
    variables: {
      regionId: me?.selectedRegionId
    },
    fetchPolicy: "network-only"
  });
  const values = getValues();
  const [fieldData, setFieldData] = useState(values[`${q.slug}`]);
  const handlePhoneChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "").slice(0, 10);
    setFieldData(inputValue);
    setValue(q.slug, inputValue);
  };
  useEffect(() => {
    if (values) {
      setFieldData(values[`${q.slug}`]);
      if (q.type === QUESTION_TYPE.CHECKBOX) {
        const otherTemp = multiSelected("Other");
        if (otherTemp) {
          setOtherValue(values[`${q.slug}`].find((other) => other.label == "Other").value);
        }
      }
    }
  }, [q]);
  useEffect(() => {
    if (q.type !== QUESTION_TYPE.TEXTFIELD && q.type !== QUESTION_TYPE.CALENDAR && fieldData != void 0) {
      setValue(q.slug, fieldData);
    }
  }, [fieldData, q]);
  useEffect(() => {
    if (!schoolLoading && schoolYearData?.getSchoolYearsByRegionId) {
      schoolYearData.getSchoolYearsByRegionId.forEach((element) => {
        if (school_year_id == element.school_year_id) {
          setGrades(element.grades?.split(","));
        }
      });
    }
  }, [me, schoolLoading, schoolYearData, school_year_id]);
  const [dropDownItemsData, setDropDownItemsData] = useState([]);
  useEffect(() => {
    if (q.slug === "student_grade_level") {
      setDropDownItemsData(gradesDropDownItems || []);
    } else {
      setDropDownItemsData(q.options || []);
    }
  }, [q, gradesDropDownItems]);
  useEffect(() => {
    setGradesDropDownItems(gradesToDropDownItems(grades, epic4318story4481));
  }, [grades]);
  function handleChangeOther(value) {
    const otherTemp = multiSelected("Other");
    if (otherTemp) {
      const updateOther = fieldData.map((f) => f.label === "Other" ? {label: "Other", value} : f);
      setFieldData(updateOther);
    }
    setOtherValue(value);
  }
  const multiSelected = useCallback((value) => {
    let res = false;
    if (q.type === QUESTION_TYPE.CHECKBOX) {
      res = Array.isArray(fieldData) && fieldData?.find((f) => f.label == value) ? true : false;
    } else {
      res = fieldData?.indexOf(value) >= 0;
    }
    return res;
  }, [fieldData, q]);
  const onHandleChange = useCallback((value) => {
    if (q?.options?.find((f) => f.value === value || f.label === value)?.action == 2) {
      if (q.type === QUESTION_TYPE.CHECKBOX) {
        if (Array.isArray(fieldData) && fieldData?.find((f) => f.label === value)) {
          setAdditionalQuestion(q.slug, false);
        } else {
          setAdditionalQuestion(q.slug, true);
        }
      } else {
        setAdditionalQuestion(q.slug, true);
      }
    } else {
      if (q.type === QUESTION_TYPE.CHECKBOX) {
        if (Array.isArray(fieldData) && fieldData?.find((f) => q.options?.find((qq) => qq.action == 2 && f.label == qq.label))) {
          setAdditionalQuestion(q.slug, true);
        } else {
          setAdditionalQuestion(q.slug, false);
        }
      } else {
        setAdditionalQuestion(q.slug, false);
      }
    }
    if (q.type === QUESTION_TYPE.AGREEMENT) {
      if (Array.isArray(fieldData) && fieldData.indexOf(value) >= 0) {
        setFieldData(fieldData.filter((f) => f !== value));
      } else {
        setFieldData(fieldData ? [...fieldData, value] : [value]);
      }
    } else if (q.type === QUESTION_TYPE.CHECKBOX) {
      if (Array.isArray(fieldData) && fieldData?.find((f) => f.label === value)) {
        setFieldData(fieldData?.filter((f) => f.label !== value));
      } else {
        if (value === "Other") {
          setFieldData(fieldData ? [...fieldData, {label: value, value: otherValue}] : [{label: value, value: otherValue}]);
        } else {
          const temp = fieldData ? [...fieldData, {label: value, value}] : [{label: value, value}];
          setFieldData(temp);
        }
      }
    } else {
      setFieldData(value);
    }
  }, [fieldData, q]);
  function onChangeDropDown(value) {
    const selected = dropDownItemsData.find((f) => f.value === value);
    onHandleChange(selected?.value);
  }
  if (q.type === QUESTION_TYPE.DROPDOWN) {
    return /* @__PURE__ */ React.createElement(Controller, {
      name: q.slug,
      control,
      render: ({field}) => /* @__PURE__ */ React.createElement(DropDown, {
        sx: {
          minWidth: "100%",
          [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: MthColor.SYSTEM_07
          }
        },
        defaultValue: field.value,
        labelTop: true,
        dropDownItems: dropDownItemsData,
        setParentValue: (v) => onChangeDropDown(v),
        size: "small"
      })
    });
  } else if (q.validation === ValidationType.PHONE || q.validation === ValidationType.PHONEOPTIN) {
    return /* @__PURE__ */ React.createElement(Controller, {
      name: q.slug,
      control,
      render: ({field}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(TextField, {
        ...field,
        size: "small",
        variant: "outlined",
        fullWidth: true,
        sx: {
          maxWidth: "100%",
          [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: MthColor.SYSTEM_07
          }
        },
        InputLabelProps: {
          style: {color: MthColor.SYSTEM_05}
        },
        InputProps: {
          inputComponent: PhoneInput,
          onChange: handlePhoneChange
        }
      }), epic3733Story4680 && q.validation === ValidationType.PHONEOPTIN && /* @__PURE__ */ React.createElement(FormControlLabel, {
        control: /* @__PURE__ */ React.createElement(Checkbox, {
          checked: Boolean(values[QuestionTypeSlug.PARENT_RECIEVE_TEXT]),
          disabled: true
        }),
        label: MthTitle.TEXT_MESSAGE
      }))
    });
  } else if (q.type === QUESTION_TYPE.TEXTFIELD) {
    return /* @__PURE__ */ React.createElement(Controller, {
      name: q.slug,
      control,
      render: ({field}) => /* @__PURE__ */ React.createElement(TextField, {
        ...field,
        size: "small",
        variant: "outlined",
        fullWidth: true,
        sx: {
          maxWidth: "100%",
          [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: MthColor.SYSTEM_07
          }
        },
        InputLabelProps: {
          style: {color: MthColor.SYSTEM_05}
        }
      })
    });
  } else if (q.type === QUESTION_TYPE.CHECKBOX) {
    return /* @__PURE__ */ React.createElement(Controller, {
      name: q.slug,
      control,
      render: () => /* @__PURE__ */ React.createElement(FormControl, {
        required: true,
        component: "fieldset",
        variant: "standard",
        sx: {width: "100%"}
      }, /* @__PURE__ */ React.createElement(FormGroup, {
        style: {display: "flex", flexDirection: "row", flexWrap: "wrap"}
      }, /* @__PURE__ */ React.createElement(Grid, {
        container: true
      }, q.options.map((o, index) => /* @__PURE__ */ React.createElement(Grid, {
        item: true,
        xs: q.options.length > 3 ? 6 : 12,
        key: index
      }, /* @__PURE__ */ React.createElement(FormControlLabel, {
        control: /* @__PURE__ */ React.createElement(Checkbox, {
          checked: multiSelected(o.label),
          onClick: () => onHandleChange(o.label)
        }),
        label: o.label
      }), o.label === "Other" && /* @__PURE__ */ React.createElement(TextField, {
        size: "small",
        sx: {
          maxWidth: "50%",
          [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: MthColor.SYSTEM_07
          }
        },
        InputLabelProps: {
          style: {color: MthColor.SYSTEM_05}
        },
        variant: "outlined",
        fullWidth: true,
        value: otherValue,
        onChange: (e) => handleChangeOther(e.target.value)
      }))))))
    });
  } else if (q.type === QUESTION_TYPE.AGREEMENT) {
    return /* @__PURE__ */ React.createElement(Controller, {
      name: q.slug,
      control,
      render: () => /* @__PURE__ */ React.createElement(FormControl, {
        required: true,
        name: "acknowledge",
        component: "fieldset",
        variant: "standard"
      }, /* @__PURE__ */ React.createElement(FormGroup, {
        style: {width: "50%"}
      }, /* @__PURE__ */ React.createElement(FormControlLabel, {
        control: /* @__PURE__ */ React.createElement(Checkbox, {
          checked: multiSelected(q.slug),
          onClick: () => onHandleChange(q.slug)
        }),
        label: /* @__PURE__ */ React.createElement(Paragraph, {
          size: "medium"
        }, /* @__PURE__ */ React.createElement(DisplayHTML, {
          text: q.question,
          tagType: TagType.PARAGRAPH
        }))
      })))
    });
  } else if (q.type === QUESTION_TYPE.MULTIPLECHOICES) {
    return /* @__PURE__ */ React.createElement(Controller, {
      name: q.slug,
      control,
      render: ({field}) => /* @__PURE__ */ React.createElement(FormControl, {
        required: true,
        component: "fieldset",
        variant: "standard",
        sx: {width: "100%"}
      }, /* @__PURE__ */ React.createElement(FormGroup, {
        style: {display: "flex", flexDirection: "row", flexWrap: "wrap"}
      }, /* @__PURE__ */ React.createElement(Grid, {
        container: true
      }, q.options.map((o, index) => /* @__PURE__ */ React.createElement(Grid, {
        item: true,
        xs: q.options.length > 3 ? 6 : 12,
        key: index
      }, /* @__PURE__ */ React.createElement(FormControlLabel, {
        control: /* @__PURE__ */ React.createElement(Radio, {
          checked: field.value == (q.slug == "meta_special_education" ? o.value : o.label),
          onClick: () => onHandleChange(q.slug == "meta_special_education" ? o.value : o.label)
        }),
        label: o.label
      }))))))
    });
  } else if (q.type === QUESTION_TYPE.CALENDAR) {
    return /* @__PURE__ */ React.createElement(Controller, {
      name: q.slug,
      control,
      render: ({field}) => {
        return /* @__PURE__ */ React.createElement(TextField, {
          ...field,
          value: field.value,
          type: "date",
          placeholder: "Date of Birth",
          size: "small",
          variant: "outlined",
          fullWidth: true,
          sx: {
            minWidth: "100%",
            [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: MthColor.SYSTEM_07
            }
          },
          InputLabelProps: {
            style: {color: MthColor.SYSTEM_05}
          }
        });
      }
    });
  }
  return null;
}
