import {MthColor} from "../core/enums/index.js";
const mthButtonCommonStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center"
};
export const mthButtonSizeClasses = {
  xs: {
    ...mthButtonCommonStyles,
    height: "29px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "6px 16px"
  },
  small: {
    ...mthButtonCommonStyles,
    height: "36px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "11px 16px"
  },
  medium: {
    ...mthButtonCommonStyles,
    height: "48px",
    fontSize: "12px",
    fontWeight: "700",
    padding: "11px 16px"
  }
};
export const mthButtonColorClasses = {
  primary: {
    background: MthColor.BUTTON_LINEAR_GRADIENT,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.PRIMARY_MEDIUM_MOUSEOVER,
      color: MthColor.WHITE
    },
    "&:disabled": {
      opacity: 0.5,
      color: MthColor.LIGHTGRAY
    }
  },
  dark: {
    background: MthColor.BUTTON_LINEAR_GRADIENT_DARK,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.SYSTEM_01,
      color: MthColor.WHITE
    },
    "&:disabled": {
      opacity: 0.5,
      color: MthColor.WHITE
    }
  },
  gray: {
    background: MthColor.LIGHTGRAY,
    color: MthColor.SYSTEM_01,
    "&:hover": {
      background: MthColor.LIGHTGRAY,
      color: MthColor.SYSTEM_01
    }
  },
  darkGray: {
    background: MthColor.SYSTEM_08,
    color: MthColor.SYSTEM_01,
    "&:hover": {
      background: MthColor.SYSTEM_08,
      color: MthColor.SYSTEM_01
    }
  },
  orange: {
    background: MthColor.ORANGE_GRADIENT,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.MTHORANGE,
      color: MthColor.WHITE
    }
  },
  mthGreen: {
    background: MthColor.MTHGREEN_GRADIENT,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.MTHGREEN,
      color: MthColor.WHITE
    }
  },
  red: {
    background: MthColor.RED_GRADIENT,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.RED,
      color: MthColor.WHITE
    },
    "&:disabled": {
      opacity: 0.5,
      color: MthColor.WHITE
    }
  },
  green: {
    background: MthColor.GREEN_GRADIENT,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.GREEN,
      color: MthColor.WHITE
    },
    "&:disabled": {
      opacity: 0.5,
      color: MthColor.WHITE
    }
  },
  black: {
    background: MthColor.SYSTEM_01,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.SYSTEM_01,
      color: MthColor.WHITE
    }
  },
  yellow: {
    background: MthColor.YELLOW_GRADIENT,
    color: MthColor.WHITE,
    "&:hover": {
      background: MthColor.YELLOW,
      color: MthColor.WHITE
    }
  }
};
export const mthButtonClasses = {
  primary: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.primary,
    borderRadius: "8px",
    minWidth: "140px"
  },
  roundPrimary: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.primary,
    borderRadius: "40px"
  },
  smallPrimary: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.primary,
    borderRadius: "8px"
  },
  xsPrimary: {
    ...mthButtonSizeClasses.xs,
    ...mthButtonColorClasses.primary,
    borderRadius: "8px",
    minWidth: "92px"
  },
  roundSmallPrimary: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.primary,
    borderRadius: "40px",
    minWidth: "100px"
  },
  roundXsPrimary: {
    ...mthButtonSizeClasses.xs,
    ...mthButtonColorClasses.primary,
    borderRadius: "40px",
    minWidth: "92px"
  },
  dark: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.dark,
    borderRadius: "8px",
    minWidth: "140px"
  },
  roundDark: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.dark,
    borderRadius: "40px"
  },
  smallDark: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.dark,
    borderRadius: "8px"
  },
  roundSmallDark: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.dark,
    borderRadius: "40px"
  },
  roundXsDark: {
    ...mthButtonSizeClasses.xs,
    ...mthButtonColorClasses.dark,
    borderRadius: "40px",
    minWidth: "92px"
  },
  black: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.black,
    borderRadius: "8px",
    minWidth: "140px"
  },
  gray: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.gray,
    borderRadius: "8px",
    minWidth: "140px"
  },
  roundGray: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.gray,
    borderRadius: "40px"
  },
  smallGray: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.gray
  },
  roundSmallGray: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.gray,
    borderRadius: "40px"
  },
  roundXsGray: {
    ...mthButtonSizeClasses.xs,
    ...mthButtonColorClasses.gray,
    borderRadius: "40px",
    minWidth: "92px"
  },
  darkGray: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.darkGray,
    borderRadius: "8px",
    minWidth: "140px"
  },
  roundDarkGray: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.darkGray,
    borderRadius: "20px"
  },
  requiredErrorButton: {
    border: 1,
    borderColor: MthColor.RED
  },
  orange: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.orange,
    borderRadius: "8px",
    minWidth: "140px"
  },
  roundOrange: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.orange,
    borderRadius: "40px"
  },
  smallOrange: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.orange
  },
  roundSmallOrange: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.orange,
    borderRadius: "40px"
  },
  mthGreen: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.mthGreen,
    borderRadius: "8px",
    minWidth: "120px"
  },
  roundMthGreen: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.mthGreen,
    borderRadius: "40px"
  },
  smallMthGreen: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.mthGreen
  },
  roundSmallMthGreen: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.mthGreen,
    borderRadius: "40px"
  },
  red: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.red,
    borderRadius: "8px",
    minWidth: "140px"
  },
  smallRed: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.red,
    borderRadius: "8px"
  },
  xsRed: {
    ...mthButtonSizeClasses.xs,
    ...mthButtonColorClasses.red,
    borderRadius: "8px",
    minWidth: "92px"
  },
  roundXsRed: {
    ...mthButtonSizeClasses.xs,
    ...mthButtonColorClasses.red,
    borderRadius: "40px",
    minWidth: "92px"
  },
  green: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.green,
    borderRadius: "8px",
    minWidth: "140px"
  },
  roundXsGreen: {
    ...mthButtonSizeClasses.xs,
    ...mthButtonColorClasses.green,
    borderRadius: "40px",
    minWidth: "92px"
  },
  roundSmallBlack: {
    ...mthButtonSizeClasses.small,
    ...mthButtonColorClasses.black,
    borderRadius: "40px",
    minWidth: "92px"
  },
  yellow: {
    ...mthButtonSizeClasses.medium,
    ...mthButtonColorClasses.yellow,
    borderRadius: "8px",
    minWidth: "140px"
  },
  uploadFile: {
    padding: "8px 16px",
    height: "unset"
  }
};
