export var RoleLevel;
(function(RoleLevel2) {
  RoleLevel2[RoleLevel2["SUPER_ADMIN"] = 1] = "SUPER_ADMIN";
  RoleLevel2[RoleLevel2["ADMIN"] = 2] = "ADMIN";
  RoleLevel2[RoleLevel2["TEACHER_ASSISTANT"] = 3] = "TEACHER_ASSISTANT";
  RoleLevel2[RoleLevel2["SCHOOL_PARTNER"] = 12] = "SCHOOL_PARTNER";
  RoleLevel2[RoleLevel2["STUDENT"] = 13] = "STUDENT";
  RoleLevel2[RoleLevel2["OBSERVER"] = 14] = "OBSERVER";
  RoleLevel2[RoleLevel2["PARENT"] = 15] = "PARENT";
  RoleLevel2[RoleLevel2["TEACHER"] = 16] = "TEACHER";
})(RoleLevel || (RoleLevel = {}));
export var RoleLabels;
(function(RoleLabels2) {
  RoleLabels2["PARENT"] = "Parent";
  RoleLabels2["STUDENT"] = "Student";
  RoleLabels2["OBSERVER"] = "Observer";
})(RoleLabels || (RoleLabels = {}));
