import {c as createCommonjsModule} from "./_commonjsHelpers-53bde9c7.js";
import {r as react} from "./index-c6c556cc.js";
var reactJsxRuntime_production_min = createCommonjsModule(function(module, exports) {
  var g = 60103;
  exports.Fragment = 60107;
  if (typeof Symbol === "function" && Symbol.for) {
    var h = Symbol.for;
    g = h("react.element");
    exports.Fragment = h("react.fragment");
  }
  var m = react.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.ReactCurrentOwner, n = Object.prototype.hasOwnProperty, p = {key: true, ref: true, __self: true, __source: true};
  function q(c, a, k) {
    var b, d = {}, e = null, l = null;
    k !== void 0 && (e = "" + k);
    a.key !== void 0 && (e = "" + a.key);
    a.ref !== void 0 && (l = a.ref);
    for (b in a)
      n.call(a, b) && !p.hasOwnProperty(b) && (d[b] = a[b]);
    if (c && c.defaultProps)
      for (b in a = c.defaultProps, a)
        d[b] === void 0 && (d[b] = a[b]);
    return {$$typeof: g, type: c, key: e, ref: l, props: d, _owner: m.current};
  }
  exports.jsx = q;
  exports.jsxs = q;
});
var jsxRuntime = createCommonjsModule(function(module) {
  {
    module.exports = reactJsxRuntime_production_min;
  }
});
export {jsxRuntime as j};
