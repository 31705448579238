import React, {useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import {useLazyQuery, useQuery} from "../../../../../_snowpack/pkg/@apollo/client.js";
import CloseIcon from "../../../../../_snowpack/pkg/@mui/icons-material/Close.js";
import {Button, Grid, Modal, TextField} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../../_snowpack/pkg/@mui/system.js";
import {WarningModal} from "../../../../../components/WarningModal/Warning.js";
import {RECEIVE_EMAIL_GIVING_LINK_TO_CREATE_PASSWORD} from "../../../../../core/constants/index.js";
import {DropDown} from "../../../../../core/components/shared/DropDown/DropDown.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor, RoleLevel} from "../../../../../core/enums/index.js";
import {checkEmailQuery} from "../../../../../graphql/queries/email-template.js";
import {getAllRoles} from "../../../../../graphql/queries/role.js";
import {StudentsModal} from "./StudentsModal.js";
import {useStyles} from "./styles.js";
export const NewUserModal = ({
  handleModem,
  visible = false,
  students = [],
  parent_id,
  ParentEmailValue,
  regionId,
  schoolYearId,
  currentRegion
}) => {
  const classes = useStyles;
  const [apolloError, setApolloError] = useState({
    title: "",
    severity: "",
    flag: false
  });
  const [email, setEmail] = useState("");
  const [parentEmail] = useState(ParentEmailValue);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [payloadData, setPayloadData] = useState();
  const {loading: loadingRoles, data: rolesData} = useQuery(getAllRoles);
  const [rolesOption, setRolesOption] = useState([]);
  const [showEmailError, setShowEmailError] = useState(false);
  const regionOption = [
    {
      label: currentRegion?.regionDetail?.name,
      value: currentRegion?.id || 0
    }
  ];
  const handleSubmit = () => {
    if (!firstName) {
      setApolloError({
        title: "First name is required",
        severity: "Warning",
        flag: true
      });
      return;
    } else if (!email) {
      setApolloError({
        title: "Email address is required",
        severity: "Warning",
        flag: true
      });
      return;
    } else if (!parentEmail) {
      setApolloError({
        title: "Parent Email address is required.",
        severity: "Warning",
        flag: true
      });
      return;
    }
    setPayloadData({
      email,
      first_name: firstName,
      last_name: lastName,
      parent_id,
      region: currentRegion?.region_id || 0,
      regions: [currentRegion?.region_id || 0]
    });
    setShowStudentModal(true);
  };
  const handleCloseStudentModal = (status) => {
    setShowStudentModal(false);
    if (status)
      handleModem();
  };
  useEffect(() => {
    if (!loadingRoles && rolesData !== void 0) {
      const updatedRoles = rolesData?.roles?.find((role) => role.level === RoleLevel.OBSERVER);
      setRolesOption([
        {
          label: updatedRoles?.name,
          value: updatedRoles?.id
        }
      ]);
    }
  }, [loadingRoles]);
  const [checkEmail, {loading: emailLoading, data: emailData}] = useLazyQuery(checkEmailQuery, {
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!emailLoading && emailData !== void 0) {
      if (emailData.emailTaken === true) {
        setShowEmailError(true);
      } else {
        setShowEmailError(false);
      }
    }
  }, [emailLoading, emailData]);
  return /* @__PURE__ */ React.createElement(Modal, {
    open: visible,
    onClose: () => handleModem(),
    "aria-labelledby": "Create User",
    "aria-describedby": "Create New User"
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.modalCard
  }, apolloError.flag && /* @__PURE__ */ React.createElement(WarningModal, {
    handleModem: () => setApolloError({title: "", severity: "", flag: false}),
    title: apolloError.severity,
    subtitle: apolloError.title,
    btntitle: "Close",
    handleSubmit: () => setApolloError({title: "", severity: "", flag: false})
  }), showStudentModal && /* @__PURE__ */ React.createElement(StudentsModal, {
    visible: true,
    handleModem: handleCloseStudentModal,
    students,
    data: payloadData,
    regionId,
    schoolYearId
  }), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.header
  }, /* @__PURE__ */ React.createElement(Subtitle, null, RECEIVE_EMAIL_GIVING_LINK_TO_CREATE_PASSWORD), /* @__PURE__ */ React.createElement(CloseIcon, {
    style: classes.close,
    onClick: handleModem
  })), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    rowSpacing: 2
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: "large"
  }, "Email"), /* @__PURE__ */ React.createElement(TextField, {
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: email,
    onChange: (e) => setEmail(e.target.value),
    onKeyUp: () => {
      checkEmail({
        variables: {
          email
        }
      });
    }
  }), showEmailError && /* @__PURE__ */ React.createElement(Subtitle, {
    size: "medium",
    color: MthColor.RED
  }, "This email is already being used.")), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: "large"
  }, "First Name"), /* @__PURE__ */ React.createElement(TextField, {
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: firstName,
    onChange: (e) => setFirstName(e.target.value)
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: "large"
  }, "Last Name"), /* @__PURE__ */ React.createElement(TextField, {
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: lastName,
    onChange: (e) => setLastName(e.target.value)
  })), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    item: true,
    xs: 9
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(DropDown, {
    disabled: true,
    dropDownItems: rolesOption,
    placeholder: "User Type",
    size: "small",
    sx: {width: "50%"},
    defaultValue: rolesOption[0]?.value,
    setParentValue: () => {
    }
  }))), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    item: true,
    xs: 9
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(DropDown, {
    disabled: true,
    dropDownItems: regionOption,
    setParentValue: () => {
    },
    defaultValue: currentRegion?.id || 0,
    size: "small",
    sx: {width: "50%"}
  }))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: "large"
  }, "Parent Account Email"), /* @__PURE__ */ React.createElement(TextField, {
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: parentEmail,
    disabled: true
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "end",
      height: "100%",
      width: "100%"
    }
  }, /* @__PURE__ */ React.createElement(Button, {
    onClick: handleSubmit,
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      color: "white",
      width: "92px",
      borderRadius: "8px",
      textTransform: "none",
      fontWeight: 700,
      height: "24px"
    }
  }, "Add"))))));
};
