import React, {useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {useLazyQuery, useMutation, useQuery} from "../../../../_snowpack/pkg/@apollo/client.js";
import {Box, Modal, TextField, Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import {isNil} from "../../../../_snowpack/pkg/lodash.js";
import {MthNumberInput} from "../../../../components/MthNumberInput/index.js";
import {EMPTY_STRING} from "../../../../core/constants/index.js";
import {DropDown} from "../../../../core/components/shared/DropDown/DropDown.js";
import {
  CourseType,
  MthTitle,
  ReduceFunds,
  ReimbursementFormType,
  ReimbursementRequestStatus,
  ReimbursementType,
  ReimbursementValues,
  ScheduleStatus
} from "../../../../core/enums/index.js";
import {saveReimbursementRequestMutation} from "../../../../graphql/mutation/reimbursement-request.js";
import {getPeriodsForReimbursements} from "../../../../graphql/queries/schedule-period.js";
import {arrayToString} from "../../../../core/utils/index.js";
import {SaveCancelComponent} from "../../Curriculum/CourseCatalog/Components/SaveCancelComponent/index.js";
import {getActivePendingStudentsBySchoolYear} from "./services.js";
import {useStyles} from "./styles.js";
const DOLLAR = "$";
export const DirectDeductionModal = ({
  showModal,
  selectedYearId,
  selectedYear,
  anyStudentIdFromParent,
  refetch,
  isEditMode = true,
  reimbursementToEdit
}) => {
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [amount, setAmount] = useState();
  const [typeList, setTypeList] = useState([]);
  const [periodsItems, setPeriodsItems] = useState([]);
  const [typeSelected, setTypeSelected] = useState();
  const [periodSelected, setPeriodSelected] = useState();
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [error, setError] = useState({student: false, amount: false, type: false, period: false});
  const [saveRequest] = useMutation(saveReimbursementRequestMutation);
  const {loading, data} = useQuery(getActivePendingStudentsBySchoolYear, {
    variables: {
      schoolYearId: selectedYearId,
      studentId: anyStudentIdFromParent
    },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    const auxTypes = [];
    if (selectedYear.require_software)
      auxTypes.push({value: ReimbursementFormType.REQUIRED_SOFTWARE, label: MthTitle.REQUIRED_SOFTWARE});
    if (selectedYear.reimbursements === ReduceFunds.SUPPLEMENTAL || selectedYear.direct_orders === ReduceFunds.SUPPLEMENTAL) {
      auxTypes.push({
        label: MthTitle.SUPPLEMENTAL_LEARNING_FUNDS,
        value: ReimbursementFormType.SUPPLEMENTAL
      });
    } else if (selectedYear.reimbursements === ReduceFunds.TECHNOLOGY || selectedYear.direct_orders === ReduceFunds.TECHNOLOGY) {
      auxTypes.push({
        label: MthTitle.TECHNOLOGY_ALLOWANCE,
        value: ReimbursementFormType.TECHNOLOGY
      });
      if (selectedYear.ScheduleBuilder?.third_party_provider)
        auxTypes.push({
          label: MthTitle.THIRD_PARTY_PROVIDER,
          value: ReimbursementFormType.THIRD_PARTY_PROVIDER
        });
      if (selectedYear.ScheduleBuilder?.custom_built)
        auxTypes.push({
          label: MthTitle.CUSTOM_BUILT,
          value: ReimbursementFormType.CUSTOM_BUILT
        });
    }
    setTypeList([...auxTypes]);
  }, [selectedYear]);
  useEffect(() => {
    if (!loading) {
      setStudentList(data.getActivePendingStudentsBySchoolYear.map((item) => ({
        label: item.student,
        value: item.student_id
      })));
    }
  }, [loading]);
  const [getStudentPeriods, {loading: periodsLoading, data: periodsData}] = useLazyQuery(getPeriodsForReimbursements, {
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (selectedStudent && selectedStudent !== 0 && selectedYearId) {
      getStudentPeriods({
        variables: {
          schoolYearId: selectedYearId,
          studentId: selectedStudent
        }
      });
    }
  }, [selectedStudent, selectedYearId]);
  useEffect(() => {
    if (!periodsLoading && periodsData?.getPeriodsForReimbursements) {
      const schedulePeriods = periodsData?.getPeriodsForReimbursements;
      if (schedulePeriods?.length && schedulePeriods?.at(0)?.Schedule?.status === ScheduleStatus.ACCEPTED) {
        let mergedPeriods = [];
        if (selectedYear?.ReimbursementSetting?.is_merged_periods && selectedYear.ReimbursementSetting.merged_periods) {
          mergedPeriods = selectedYear.ReimbursementSetting.merged_periods.includes(",") ? selectedYear.ReimbursementSetting.merged_periods?.split(",") : [selectedYear.ReimbursementSetting.merged_periods];
        }
        const newPeriods = [];
        const newTitles = [];
        const newPeriodIds = [];
        const tempCustomPeriods = [];
        const tempPartyPeriods = [];
        const tempRequiredSoftwarePeriods = [];
        schedulePeriods?.map((schedulePeriod) => {
          const isSoftwareReimbursement = schedulePeriod?.Title?.software_reimbursement || schedulePeriod?.Course?.software_reimbursement;
          const isCustomBuiltCourse = CourseType.CUSTOM_BUILT === schedulePeriod.course_type;
          const isThirdPartyProvider = CourseType.THIRD_PARTY_PROVIDER === schedulePeriod.course_type;
          const periodLabel = `${MthTitle.PERIOD} ${schedulePeriod?.Period?.period} - ${schedulePeriod?.Title?.name}`;
          const periodValue = schedulePeriod?.schedule_period_id;
          if (typeSelected === ReimbursementFormType.REQUIRED_SOFTWARE) {
            if (isSoftwareReimbursement) {
              tempRequiredSoftwarePeriods.push({label: periodLabel, value: periodValue});
            }
          } else {
            if (schedulePeriod?.Period?.period && mergedPeriods.includes(`${schedulePeriod?.Period?.period}`) && isCustomBuiltCourse) {
              newPeriods.push(schedulePeriod?.Period?.period);
              newTitles.push(`${schedulePeriod?.Title?.name}`);
              newPeriodIds.push(schedulePeriod?.schedule_period_id);
            } else if (isCustomBuiltCourse) {
              tempCustomPeriods.push({label: periodLabel, value: periodValue});
            } else if (isThirdPartyProvider) {
              tempPartyPeriods.push({label: periodLabel, value: periodValue});
            }
          }
        });
        if (newPeriodIds.length > 0) {
          tempCustomPeriods.push({
            label: `${MthTitle.PERIOD} ${arrayToString(newPeriods)} - ${arrayToString(newTitles)}`,
            value: newPeriodIds.join(",")
          });
        }
        if (typeSelected === ReimbursementFormType.REQUIRED_SOFTWARE) {
          setPeriodsItems(tempRequiredSoftwarePeriods);
        } else {
          setPeriodsItems(typeSelected === ReimbursementFormType.CUSTOM_BUILT ? tempCustomPeriods : tempPartyPeriods);
        }
      }
    }
  }, [periodsLoading, periodsData, typeSelected]);
  const handleCancel = () => {
    showModal(false);
  };
  const handleChangeStudent = (value) => {
    setSelectedStudent(Number(value));
  };
  useEffect(() => {
    if (isEditMode && reimbursementToEdit) {
      setSelectedStudent(reimbursementToEdit.student_id);
      setAmount(Number(reimbursementToEdit.amount.replace(DOLLAR, EMPTY_STRING)));
      setPeriodSelected(reimbursementToEdit.periods);
      setAdditionalInformation(reimbursementToEdit.additional_information ?? "");
      setTypeSelected(ReimbursementValues[reimbursementToEdit.category]);
      getStudentPeriods({
        variables: {
          schoolYearId: selectedYearId,
          studentId: reimbursementToEdit.student_id
        }
      });
    }
  }, [isEditMode, reimbursementToEdit]);
  const handleSave = async () => {
    let auxPeriodSelected = "";
    const auxError = {student: false, type: false, amount: false, period: false};
    if (!selectedStudent)
      auxError.student = true;
    if (!typeSelected)
      auxError.type = true;
    if (!amount || amount === 0)
      auxError.amount = true;
    if (typeSelected !== ReduceFunds.SUPPLEMENTAL && typeSelected !== ReduceFunds.TECHNOLOGY) {
      if (!periodSelected || periodsItems.filter((item) => !isNil(item.value) && item.value.toString() === periodSelected).length === 0)
        auxError.period = true;
      else
        auxPeriodSelected = periodSelected;
    } else
      setPeriodSelected("");
    setError({...auxError});
    if (auxError.amount || auxError.period || auxError.student || auxError.type)
      return;
    const response = await saveRequest({
      variables: {
        requestInput: {
          reimbursement_request_id: isEditMode ? reimbursementToEdit?.reimbursement_request_id : null,
          SchoolYearId: selectedYearId,
          StudentId: selectedStudent,
          form_type: typeSelected,
          reimbursement_type: ReimbursementType.DIRECT_DEDUCTION,
          periods: auxPeriodSelected,
          status: isEditMode ? ReimbursementRequestStatus.REPROCESSED : ReimbursementRequestStatus.PROCESSED,
          total_amount: amount,
          additional_information: additionalInformation
        }
      }
    });
    if (response.data.createOrUpdateReimbursementRequest.reimbursement_request_id) {
      refetch();
      showModal(false);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Modal, {
    open: true
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: useStyles.customModal
  }, /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "column"
  }, /* @__PURE__ */ React.createElement(DropDown, {
    dropDownItems: studentList,
    labelTop: true,
    defaultValue: isEditMode && reimbursementToEdit ? reimbursementToEdit?.student_id : EMPTY_STRING,
    disabled: isEditMode,
    placeholder: MthTitle.STUDENT,
    setParentValue: handleChangeStudent,
    size: "medium",
    error: {
      error: error.student,
      errorMsg: MthTitle.REQUIRED
    }
  }), /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mb: 2,
    mt: 2
  }, /* @__PURE__ */ React.createElement(MthNumberInput, {
    numberType: "price",
    label: MthTitle.AMOUNT,
    placeholder: "",
    fullWidth: true,
    InputLabelProps: {shrink: true},
    className: "MthFormField",
    value: isEditMode && reimbursementToEdit ? Number(reimbursementToEdit?.amount.replace("$", "")) : Number(Number(amount || 0)?.toFixed(2)),
    onChangeValue: (value) => {
      setAmount(value || 0);
    },
    sx: {width: "212px"},
    helperText: error.amount ? MthTitle.REQUIRED : "",
    error: error.amount
  }), /* @__PURE__ */ React.createElement(DropDown, {
    dropDownItems: typeList,
    labelTop: true,
    placeholder: MthTitle.TYPE,
    defaultValue: isEditMode && reimbursementToEdit ? ReimbursementValues[reimbursementToEdit.category] : void 0,
    setParentValue: (value) => {
      setTypeSelected(value.toString());
    },
    size: "medium",
    sx: {width: "212px"},
    error: {
      error: error.type,
      errorMsg: MthTitle.REQUIRED
    }
  })), /* @__PURE__ */ React.createElement(Box, null, selectedYear.schedule === true && typeSelected !== ReduceFunds.SUPPLEMENTAL && typeSelected !== ReduceFunds.TECHNOLOGY && /* @__PURE__ */ React.createElement(Box, {
    mb: 1.5
  }, /* @__PURE__ */ React.createElement(DropDown, {
    dropDownItems: periodsItems,
    labelTop: true,
    placeholder: MthTitle.PERIOD,
    defaultValue: isEditMode && reimbursementToEdit ? reimbursementToEdit?.periods : void 0,
    setParentValue: (value) => {
      setPeriodSelected(value.toString());
    },
    size: "medium",
    sx: {width: "100%"},
    error: {
      error: error.period,
      errorMsg: MthTitle.REQUIRED
    }
  })), /* @__PURE__ */ React.createElement(Typography, {
    sx: {fontSize: 18, fontWeight: 700, mb: 1.5}
  }, MthTitle.ADDITIONAL_INFORMATION), /* @__PURE__ */ React.createElement(TextField, {
    multiline: true,
    fullWidth: true,
    className: "MthFormField",
    rows: 14,
    sx: {maxHeight: "360px", mb: 4.5},
    value: additionalInformation,
    onChange: (e) => setAdditionalInformation(e.target.value)
  }))), /* @__PURE__ */ React.createElement(SaveCancelComponent, {
    isSubmitted: false,
    handleCancel,
    handleSave
  }))));
};
