import {r as react, R as React} from "./index-c6c556cc.js";
function sheetForTag(tag) {
  if (tag.sheet) {
    return tag.sheet;
  }
  for (var i2 = 0; i2 < document.styleSheets.length; i2++) {
    if (document.styleSheets[i2].ownerNode === tag) {
      return document.styleSheets[i2];
    }
  }
}
function createStyleElement(options) {
  var tag = document.createElement("style");
  tag.setAttribute("data-emotion", options.key);
  if (options.nonce !== void 0) {
    tag.setAttribute("nonce", options.nonce);
  }
  tag.appendChild(document.createTextNode(""));
  tag.setAttribute("data-s", "");
  return tag;
}
var StyleSheet = /* @__PURE__ */ function() {
  function StyleSheet2(options) {
    var _this = this;
    this._insertTag = function(tag) {
      var before;
      if (_this.tags.length === 0) {
        if (_this.insertionPoint) {
          before = _this.insertionPoint.nextSibling;
        } else if (_this.prepend) {
          before = _this.container.firstChild;
        } else {
          before = _this.before;
        }
      } else {
        before = _this.tags[_this.tags.length - 1].nextSibling;
      }
      _this.container.insertBefore(tag, before);
      _this.tags.push(tag);
    };
    this.isSpeedy = options.speedy === void 0 ? true : options.speedy;
    this.tags = [];
    this.ctr = 0;
    this.nonce = options.nonce;
    this.key = options.key;
    this.container = options.container;
    this.prepend = options.prepend;
    this.insertionPoint = options.insertionPoint;
    this.before = null;
  }
  var _proto = StyleSheet2.prototype;
  _proto.hydrate = function hydrate(nodes) {
    nodes.forEach(this._insertTag);
  };
  _proto.insert = function insert(rule) {
    if (this.ctr % (this.isSpeedy ? 65e3 : 1) === 0) {
      this._insertTag(createStyleElement(this));
    }
    var tag = this.tags[this.tags.length - 1];
    if (this.isSpeedy) {
      var sheet = sheetForTag(tag);
      try {
        sheet.insertRule(rule, sheet.cssRules.length);
      } catch (e2) {
      }
    } else {
      tag.appendChild(document.createTextNode(rule));
    }
    this.ctr++;
  };
  _proto.flush = function flush() {
    this.tags.forEach(function(tag) {
      return tag.parentNode && tag.parentNode.removeChild(tag);
    });
    this.tags = [];
    this.ctr = 0;
  };
  return StyleSheet2;
}();
var e = "-ms-";
var r = "-moz-";
var a = "-webkit-";
var n = "comm";
var c = "rule";
var s = "decl";
var i = "@import";
var h = "@keyframes";
var $ = "@layer";
var g = Math.abs;
var k = String.fromCharCode;
var m = Object.assign;
function x(e2, r2) {
  return O(e2, 0) ^ 45 ? (((r2 << 2 ^ O(e2, 0)) << 2 ^ O(e2, 1)) << 2 ^ O(e2, 2)) << 2 ^ O(e2, 3) : 0;
}
function y(e2) {
  return e2.trim();
}
function j(e2, r2) {
  return (e2 = r2.exec(e2)) ? e2[0] : e2;
}
function z(e2, r2, a2) {
  return e2.replace(r2, a2);
}
function C(e2, r2) {
  return e2.indexOf(r2);
}
function O(e2, r2) {
  return e2.charCodeAt(r2) | 0;
}
function A(e2, r2, a2) {
  return e2.slice(r2, a2);
}
function M(e2) {
  return e2.length;
}
function S(e2) {
  return e2.length;
}
function q(e2, r2) {
  return r2.push(e2), e2;
}
function B(e2, r2) {
  return e2.map(r2).join("");
}
var D = 1;
var E = 1;
var F = 0;
var G = 0;
var H = 0;
var I = "";
function J(e2, r2, a2, n2, c2, s2, t) {
  return {value: e2, root: r2, parent: a2, type: n2, props: c2, children: s2, line: D, column: E, length: t, return: ""};
}
function K(e2, r2) {
  return m(J("", null, null, "", null, null, 0), e2, {length: -e2.length}, r2);
}
function L() {
  return H;
}
function N() {
  H = G > 0 ? O(I, --G) : 0;
  if (E--, H === 10)
    E = 1, D--;
  return H;
}
function P() {
  H = G < F ? O(I, G++) : 0;
  if (E++, H === 10)
    E = 1, D++;
  return H;
}
function Q() {
  return O(I, G);
}
function R() {
  return G;
}
function T(e2, r2) {
  return A(I, e2, r2);
}
function U(e2) {
  switch (e2) {
    case 0:
    case 9:
    case 10:
    case 13:
    case 32:
      return 5;
    case 33:
    case 43:
    case 44:
    case 47:
    case 62:
    case 64:
    case 126:
    case 59:
    case 123:
    case 125:
      return 4;
    case 58:
      return 3;
    case 34:
    case 39:
    case 40:
    case 91:
      return 2;
    case 41:
    case 93:
      return 1;
  }
  return 0;
}
function V(e2) {
  return D = E = 1, F = M(I = e2), G = 0, [];
}
function W(e2) {
  return I = "", e2;
}
function X(e2) {
  return y(T(G - 1, re(e2 === 91 ? e2 + 2 : e2 === 40 ? e2 + 1 : e2)));
}
function Z(e2) {
  while (H = Q())
    if (H < 33)
      P();
    else
      break;
  return U(e2) > 2 || U(H) > 3 ? "" : " ";
}
function ee(e2, r2) {
  while (--r2 && P())
    if (H < 48 || H > 102 || H > 57 && H < 65 || H > 70 && H < 97)
      break;
  return T(e2, R() + (r2 < 6 && Q() == 32 && P() == 32));
}
function re(e2) {
  while (P())
    switch (H) {
      case e2:
        return G;
      case 34:
      case 39:
        if (e2 !== 34 && e2 !== 39)
          re(H);
        break;
      case 40:
        if (e2 === 41)
          re(e2);
        break;
      case 92:
        P();
        break;
    }
  return G;
}
function ae(e2, r2) {
  while (P())
    if (e2 + H === 47 + 10)
      break;
    else if (e2 + H === 42 + 42 && Q() === 47)
      break;
  return "/*" + T(r2, G - 1) + "*" + k(e2 === 47 ? e2 : P());
}
function ne(e2) {
  while (!U(Q()))
    P();
  return T(e2, G);
}
function ce(e2) {
  return W(se("", null, null, null, [""], e2 = V(e2), 0, [0], e2));
}
function se(e2, r2, a2, n2, c2, s2, t, u, i2) {
  var f = 0;
  var o = 0;
  var l = t;
  var v = 0;
  var p = 0;
  var h2 = 0;
  var b = 1;
  var w = 1;
  var d = 1;
  var $2 = 0;
  var g2 = "";
  var m2 = c2;
  var x2 = s2;
  var y2 = n2;
  var j2 = g2;
  while (w)
    switch (h2 = $2, $2 = P()) {
      case 40:
        if (h2 != 108 && O(j2, l - 1) == 58) {
          if (C(j2 += z(X($2), "&", "&\f"), "&\f") != -1)
            d = -1;
          break;
        }
      case 34:
      case 39:
      case 91:
        j2 += X($2);
        break;
      case 9:
      case 10:
      case 13:
      case 32:
        j2 += Z(h2);
        break;
      case 92:
        j2 += ee(R() - 1, 7);
        continue;
      case 47:
        switch (Q()) {
          case 42:
          case 47:
            q(ue(ae(P(), R()), r2, a2), i2);
            break;
          default:
            j2 += "/";
        }
        break;
      case 123 * b:
        u[f++] = M(j2) * d;
      case 125 * b:
      case 59:
      case 0:
        switch ($2) {
          case 0:
          case 125:
            w = 0;
          case 59 + o:
            if (d == -1)
              j2 = z(j2, /\f/g, "");
            if (p > 0 && M(j2) - l)
              q(p > 32 ? ie(j2 + ";", n2, a2, l - 1) : ie(z(j2, " ", "") + ";", n2, a2, l - 2), i2);
            break;
          case 59:
            j2 += ";";
          default:
            q(y2 = te(j2, r2, a2, f, o, c2, u, g2, m2 = [], x2 = [], l), s2);
            if ($2 === 123)
              if (o === 0)
                se(j2, r2, y2, y2, m2, s2, l, u, x2);
              else
                switch (v === 99 && O(j2, 3) === 110 ? 100 : v) {
                  case 100:
                  case 108:
                  case 109:
                  case 115:
                    se(e2, y2, y2, n2 && q(te(e2, y2, y2, 0, 0, c2, u, g2, c2, m2 = [], l), x2), c2, x2, l, u, n2 ? m2 : x2);
                    break;
                  default:
                    se(j2, y2, y2, y2, [""], x2, 0, u, x2);
                }
        }
        f = o = p = 0, b = d = 1, g2 = j2 = "", l = t;
        break;
      case 58:
        l = 1 + M(j2), p = h2;
      default:
        if (b < 1) {
          if ($2 == 123)
            --b;
          else if ($2 == 125 && b++ == 0 && N() == 125)
            continue;
        }
        switch (j2 += k($2), $2 * b) {
          case 38:
            d = o > 0 ? 1 : (j2 += "\f", -1);
            break;
          case 44:
            u[f++] = (M(j2) - 1) * d, d = 1;
            break;
          case 64:
            if (Q() === 45)
              j2 += X(P());
            v = Q(), o = l = M(g2 = j2 += ne(R())), $2++;
            break;
          case 45:
            if (h2 === 45 && M(j2) == 2)
              b = 0;
        }
    }
  return s2;
}
function te(e2, r2, a2, n2, s2, t, u, i2, f, o, l) {
  var v = s2 - 1;
  var p = s2 === 0 ? t : [""];
  var h2 = S(p);
  for (var b = 0, w = 0, d = 0; b < n2; ++b)
    for (var $2 = 0, k2 = A(e2, v + 1, v = g(w = u[b])), m2 = e2; $2 < h2; ++$2)
      if (m2 = y(w > 0 ? p[$2] + " " + k2 : z(k2, /&\f/g, p[$2])))
        f[d++] = m2;
  return J(e2, r2, a2, s2 === 0 ? c : i2, f, o, l);
}
function ue(e2, r2, a2) {
  return J(e2, r2, a2, n, k(L()), A(e2, 2, -2), 0);
}
function ie(e2, r2, a2, n2) {
  return J(e2, r2, a2, s, A(e2, 0, n2), A(e2, n2 + 1, -1), n2);
}
function oe(e2, r2) {
  var a2 = "";
  var n2 = S(e2);
  for (var c2 = 0; c2 < n2; c2++)
    a2 += r2(e2[c2], c2, e2, r2) || "";
  return a2;
}
function le(e2, r2, a2, t) {
  switch (e2.type) {
    case $:
      if (e2.children.length)
        break;
    case i:
    case s:
      return e2.return = e2.return || e2.value;
    case n:
      return "";
    case h:
      return e2.return = e2.value + "{" + oe(e2.children, t) + "}";
    case c:
      e2.value = e2.props.join(",");
  }
  return M(a2 = oe(e2.children, t)) ? e2.return = e2.value + "{" + a2 + "}" : "";
}
function ve(e2) {
  var r2 = S(e2);
  return function(a2, n2, c2, s2) {
    var t = "";
    for (var u = 0; u < r2; u++)
      t += e2[u](a2, n2, c2, s2) || "";
    return t;
  };
}
function pe(e2) {
  return function(r2) {
    if (!r2.root) {
      if (r2 = r2.return)
        e2(r2);
    }
  };
}
var identifierWithPointTracking = function identifierWithPointTracking2(begin, points, index) {
  var previous = 0;
  var character = 0;
  while (true) {
    previous = character;
    character = Q();
    if (previous === 38 && character === 12) {
      points[index] = 1;
    }
    if (U(character)) {
      break;
    }
    P();
  }
  return T(begin, G);
};
var toRules = function toRules2(parsed, points) {
  var index = -1;
  var character = 44;
  do {
    switch (U(character)) {
      case 0:
        if (character === 38 && Q() === 12) {
          points[index] = 1;
        }
        parsed[index] += identifierWithPointTracking(G - 1, points, index);
        break;
      case 2:
        parsed[index] += X(character);
        break;
      case 4:
        if (character === 44) {
          parsed[++index] = Q() === 58 ? "&\f" : "";
          points[index] = parsed[index].length;
          break;
        }
      default:
        parsed[index] += k(character);
    }
  } while (character = P());
  return parsed;
};
var getRules = function getRules2(value, points) {
  return W(toRules(V(value), points));
};
var fixedElements = /* @__PURE__ */ new WeakMap();
var compat = function compat2(element) {
  if (element.type !== "rule" || !element.parent || element.length < 1) {
    return;
  }
  var value = element.value, parent = element.parent;
  var isImplicitRule = element.column === parent.column && element.line === parent.line;
  while (parent.type !== "rule") {
    parent = parent.parent;
    if (!parent)
      return;
  }
  if (element.props.length === 1 && value.charCodeAt(0) !== 58 && !fixedElements.get(parent)) {
    return;
  }
  if (isImplicitRule) {
    return;
  }
  fixedElements.set(element, true);
  var points = [];
  var rules = getRules(value, points);
  var parentRules = parent.props;
  for (var i2 = 0, k2 = 0; i2 < rules.length; i2++) {
    for (var j2 = 0; j2 < parentRules.length; j2++, k2++) {
      element.props[k2] = points[i2] ? rules[i2].replace(/&\f/g, parentRules[j2]) : parentRules[j2] + " " + rules[i2];
    }
  }
};
var removeLabel = function removeLabel2(element) {
  if (element.type === "decl") {
    var value = element.value;
    if (value.charCodeAt(0) === 108 && value.charCodeAt(2) === 98) {
      element["return"] = "";
      element.value = "";
    }
  }
};
function prefix(value, length) {
  switch (x(value, length)) {
    case 5103:
      return a + "print-" + value + value;
    case 5737:
    case 4201:
    case 3177:
    case 3433:
    case 1641:
    case 4457:
    case 2921:
    case 5572:
    case 6356:
    case 5844:
    case 3191:
    case 6645:
    case 3005:
    case 6391:
    case 5879:
    case 5623:
    case 6135:
    case 4599:
    case 4855:
    case 4215:
    case 6389:
    case 5109:
    case 5365:
    case 5621:
    case 3829:
      return a + value + value;
    case 5349:
    case 4246:
    case 4810:
    case 6968:
    case 2756:
      return a + value + r + value + e + value + value;
    case 6828:
    case 4268:
      return a + value + e + value + value;
    case 6165:
      return a + value + e + "flex-" + value + value;
    case 5187:
      return a + value + z(value, /(\w+).+(:[^]+)/, a + "box-$1$2" + e + "flex-$1$2") + value;
    case 5443:
      return a + value + e + "flex-item-" + z(value, /flex-|-self/, "") + value;
    case 4675:
      return a + value + e + "flex-line-pack" + z(value, /align-content|flex-|-self/, "") + value;
    case 5548:
      return a + value + e + z(value, "shrink", "negative") + value;
    case 5292:
      return a + value + e + z(value, "basis", "preferred-size") + value;
    case 6060:
      return a + "box-" + z(value, "-grow", "") + a + value + e + z(value, "grow", "positive") + value;
    case 4554:
      return a + z(value, /([^-])(transform)/g, "$1" + a + "$2") + value;
    case 6187:
      return z(z(z(value, /(zoom-|grab)/, a + "$1"), /(image-set)/, a + "$1"), value, "") + value;
    case 5495:
    case 3959:
      return z(value, /(image-set\([^]*)/, a + "$1$`$1");
    case 4968:
      return z(z(value, /(.+:)(flex-)?(.*)/, a + "box-pack:$3" + e + "flex-pack:$3"), /s.+-b[^;]+/, "justify") + a + value + value;
    case 4095:
    case 3583:
    case 4068:
    case 2532:
      return z(value, /(.+)-inline(.+)/, a + "$1$2") + value;
    case 8116:
    case 7059:
    case 5753:
    case 5535:
    case 5445:
    case 5701:
    case 4933:
    case 4677:
    case 5533:
    case 5789:
    case 5021:
    case 4765:
      if (M(value) - 1 - length > 6)
        switch (O(value, length + 1)) {
          case 109:
            if (O(value, length + 4) !== 45)
              break;
          case 102:
            return z(value, /(.+:)(.+)-([^]+)/, "$1" + a + "$2-$3$1" + r + (O(value, length + 3) == 108 ? "$3" : "$2-$3")) + value;
          case 115:
            return ~C(value, "stretch") ? prefix(z(value, "stretch", "fill-available"), length) + value : value;
        }
      break;
    case 4949:
      if (O(value, length + 1) !== 115)
        break;
    case 6444:
      switch (O(value, M(value) - 3 - (~C(value, "!important") && 10))) {
        case 107:
          return z(value, ":", ":" + a) + value;
        case 101:
          return z(value, /(.+:)([^;!]+)(;|!.+)?/, "$1" + a + (O(value, 14) === 45 ? "inline-" : "") + "box$3$1" + a + "$2$3$1" + e + "$2box$3") + value;
      }
      break;
    case 5936:
      switch (O(value, length + 11)) {
        case 114:
          return a + value + e + z(value, /[svh]\w+-[tblr]{2}/, "tb") + value;
        case 108:
          return a + value + e + z(value, /[svh]\w+-[tblr]{2}/, "tb-rl") + value;
        case 45:
          return a + value + e + z(value, /[svh]\w+-[tblr]{2}/, "lr") + value;
      }
      return a + value + e + value + value;
  }
  return value;
}
var prefixer = function prefixer2(element, index, children, callback) {
  if (element.length > -1) {
    if (!element["return"])
      switch (element.type) {
        case s:
          element["return"] = prefix(element.value, element.length);
          break;
        case h:
          return oe([K(element, {
            value: z(element.value, "@", "@" + a)
          })], callback);
        case c:
          if (element.length)
            return B(element.props, function(value) {
              switch (j(value, /(::plac\w+|:read-\w+)/)) {
                case ":read-only":
                case ":read-write":
                  return oe([K(element, {
                    props: [z(value, /:(read-\w+)/, ":" + r + "$1")]
                  })], callback);
                case "::placeholder":
                  return oe([K(element, {
                    props: [z(value, /:(plac\w+)/, ":" + a + "input-$1")]
                  }), K(element, {
                    props: [z(value, /:(plac\w+)/, ":" + r + "$1")]
                  }), K(element, {
                    props: [z(value, /:(plac\w+)/, e + "input-$1")]
                  })], callback);
              }
              return "";
            });
      }
  }
};
var defaultStylisPlugins = [prefixer];
var createCache = function createCache2(options) {
  var key = options.key;
  if (key === "css") {
    var ssrStyles = document.querySelectorAll("style[data-emotion]:not([data-s])");
    Array.prototype.forEach.call(ssrStyles, function(node) {
      var dataEmotionAttribute = node.getAttribute("data-emotion");
      if (dataEmotionAttribute.indexOf(" ") === -1) {
        return;
      }
      document.head.appendChild(node);
      node.setAttribute("data-s", "");
    });
  }
  var stylisPlugins = options.stylisPlugins || defaultStylisPlugins;
  var inserted = {};
  var container;
  var nodesToHydrate = [];
  {
    container = options.container || document.head;
    Array.prototype.forEach.call(document.querySelectorAll('style[data-emotion^="' + key + ' "]'), function(node) {
      var attrib = node.getAttribute("data-emotion").split(" ");
      for (var i2 = 1; i2 < attrib.length; i2++) {
        inserted[attrib[i2]] = true;
      }
      nodesToHydrate.push(node);
    });
  }
  var _insert;
  var omnipresentPlugins = [compat, removeLabel];
  {
    var currentSheet;
    var finalizingPlugins = [le, pe(function(rule) {
      currentSheet.insert(rule);
    })];
    var serializer = ve(omnipresentPlugins.concat(stylisPlugins, finalizingPlugins));
    var stylis = function stylis2(styles) {
      return oe(ce(styles), serializer);
    };
    _insert = function insert(selector, serialized, sheet, shouldCache) {
      currentSheet = sheet;
      stylis(selector ? selector + "{" + serialized.styles + "}" : serialized.styles);
      if (shouldCache) {
        cache.inserted[serialized.name] = true;
      }
    };
  }
  var cache = {
    key,
    sheet: new StyleSheet({
      key,
      container,
      nonce: options.nonce,
      speedy: options.speedy,
      prepend: options.prepend,
      insertionPoint: options.insertionPoint
    }),
    nonce: options.nonce,
    inserted,
    registered: {},
    insert: _insert
  };
  cache.sheet.hydrate(nodesToHydrate);
  return cache;
};
var EmotionCacheContext = /* @__PURE__ */ react.createContext(typeof HTMLElement !== "undefined" ? /* @__PURE__ */ createCache({
  key: "css"
}) : null);
EmotionCacheContext.Provider;
var withEmotionCache = function withEmotionCache2(func) {
  return /* @__PURE__ */ react.forwardRef(function(props, ref) {
    var cache = react.useContext(EmotionCacheContext);
    return func(props, cache, ref);
  });
};
var ThemeContext = /* @__PURE__ */ react.createContext({});
React["useInsertionEffect"] ? React["useInsertionEffect"] : function useInsertionEffect(create) {
  create();
};
export {ThemeContext as T, withEmotionCache as w};
