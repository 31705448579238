import * as __SNOWPACK_ENV__ from "../../../../../_snowpack/env.js";
import React, {useState, useRef} from "../../../../../_snowpack/pkg/react.js";
import {useMutation} from "../../../../../_snowpack/pkg/@apollo/client.js";
import AccountBoxOutlinedIcon from "../../../../../_snowpack/pkg/@mui/icons-material/AccountBoxOutlined.js";
import ChevronLeftIcon from "../../../../../_snowpack/pkg/@mui/icons-material/ChevronLeft.js";
import ChevronRightIcon from "../../../../../_snowpack/pkg/@mui/icons-material/ChevronRight.js";
import {FormControlLabel, Checkbox, Avatar, Grid, Tooltip} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../../_snowpack/pkg/@mui/system.js";
import {useFlag} from "../../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import dayjs from "../../../../../_snowpack/pkg/dayjs.js";
import {isNil} from "../../../../../_snowpack/pkg/lodash.js";
import {useHistory} from "../../../../../_snowpack/pkg/react-router-dom.js";
import Slider from "../../../../../_snowpack/pkg/react-slick.js";
import {Metadata} from "../../../../../components/Metadata/Metadata.js";
import {EPIC_4601_BUG_4983} from "../../../../../core/constants/index.js";
import {EMPTY_STRING, WITHDRAWN_VALIDATION_AGE} from "../../../../../core/constants/index.js";
import {Paragraph} from "../../../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor, MthRoute, RoleLabels, StudentStatus, StudentStatusLabel, MthTitle} from "../../../../../core/enums/index.js";
import {becomeUserMutation} from "../../../../../graphql/mutation/user.js";
import GradeComponent from "./GradeComponent.js";
import {getStringInitialLetter} from "../../../../../core/utils/index.js";
export const Students = ({
  students,
  selectedStudent,
  handleChangeStudent,
  me,
  currentSchoolYear,
  nextSchoolYear,
  userRole
}) => {
  const history = useHistory();
  const sliderRef = useRef(null);
  const [showAll, setShowAll] = useState(false);
  const status = {
    0: StudentStatusLabel.PENDING,
    1: StudentStatusLabel.ACTIVE,
    2: StudentStatusLabel.WITHDRAWN,
    3: StudentStatusLabel.GRADUATED,
    4: EMPTY_STRING,
    10: StudentStatusLabel.COMPLETED
  };
  const epic4601bug4983 = useFlag(EPIC_4601_BUG_4983);
  function SampleNextArrow() {
    return /* @__PURE__ */ React.createElement("div", {
      style: {
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        right: "-160px",
        top: "31%",
        width: "150px",
        height: "30px",
        position: "absolute",
        alignItems: "center"
      }
    }, /* @__PURE__ */ React.createElement(ChevronRightIcon, {
      style: {display: "block", color: "black", background: "#FAFAFA", cursor: "pointer"},
      onClick: () => sliderRef.current.slickNext()
    }));
  }
  function SamplePrevArrow(props) {
    const {className} = props;
    return /* @__PURE__ */ React.createElement(ChevronLeftIcon, {
      className,
      style: {display: "block", color: "black", background: "#FAFAFA"},
      onClick: () => sliderRef.current.slickPrev()
    });
  }
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: /* @__PURE__ */ React.createElement(SampleNextArrow, null),
    prevArrow: /* @__PURE__ */ React.createElement(SamplePrevArrow, null)
  };
  const [becomeUserAction] = useMutation(becomeUserMutation);
  const becomeUser = (id) => {
    becomeUserAction({
      variables: {
        userId: Number(id)
      }
    }).then((resp) => {
      localStorage.setItem("masquerade", resp.data.masqueradeUser.jwt);
      localStorage.setItem("previousPage", location.href.replace(__SNOWPACK_ENV__.SNOWPACK_PUBLIC_WEB_URL, ""));
    }).then(() => {
      history.push(MthRoute.DASHBOARD);
      location.reload();
    });
  };
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "column"
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "24px"
    }
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    size: "large",
    fontWeight: "700",
    sx: {marginRight: "36px"}
  }, MthTitle.STUDENTS), /* @__PURE__ */ React.createElement(FormControlLabel, {
    control: /* @__PURE__ */ React.createElement(Checkbox, {
      value: showAll,
      onChange: (_e, checked) => setShowAll(checked)
    }),
    label: /* @__PURE__ */ React.createElement(Paragraph, {
      fontWeight: "700",
      size: "medium"
    }, MthTitle.SHOW_GRADUATED_COMPLETED)
  })), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "24px"
    }
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 10
  }, /* @__PURE__ */ React.createElement(Slider, {
    ...settings,
    ref: sliderRef
  }, students.filter((item) => {
    const status2 = Number(item.status?.[0]?.status) || void 0;
    let isValidAge = !item?.person?.date_of_birth;
    if (!isValidAge) {
      const itemDateOfBirth = new Date(item?.person?.date_of_birth);
      const currentDate = new Date();
      const validationAge = new Date();
      validationAge.setFullYear(currentDate.getFullYear() - WITHDRAWN_VALIDATION_AGE);
      isValidAge = itemDateOfBirth > validationAge;
    }
    return [
      void 0,
      StudentStatus.DELETED,
      StudentStatus.PENDING,
      StudentStatus.ACTIVE,
      StudentStatus.APPLIED,
      StudentStatus.REAPPLIED,
      StudentStatus.ACCEPTED,
      StudentStatus.INACTIVE
    ].includes(status2) || status2 === StudentStatus.WITHDRAWN && isValidAge;
  }).sort((a, b) => {
    const hasNoStatusA = a.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id) === void 0;
    const hasNoStatusB = b.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id) === void 0;
    if (hasNoStatusA && !hasNoStatusB)
      return 1;
    if (!hasNoStatusA && hasNoStatusB)
      return -1;
    const isWithdrawnA = a.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id)?.status === StudentStatus.WITHDRAWN;
    const isWithdrawnB = b.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id)?.status === StudentStatus.WITHDRAWN;
    if (isWithdrawnA && !isWithdrawnB)
      return 1;
    if (!isWithdrawnA && isWithdrawnB)
      return -1;
    const firstNameA = String(a?.person.first_name?.toLowerCase());
    const firstNameB = String(b?.person.first_name?.toLowerCase());
    if (firstNameA < firstNameB)
      return -1;
    if (firstNameA > firstNameB)
      return 1;
    return a.student_id - b.student_id;
  }).map((item, idx) => /* @__PURE__ */ React.createElement(Box, {
    sx: {cursor: "pointer"},
    onClick: () => handleChangeStudent(item),
    key: idx
  }, /* @__PURE__ */ React.createElement(Metadata, {
    title: /* @__PURE__ */ React.createElement(Subtitle, {
      fontWeight: "600",
      color: selectedStudent === Number(item.student_id) && userRole === RoleLabels.STUDENT ? MthColor.MTHBLUE : MthColor.SYSTEM_01
    }, item.person.first_name),
    subtitle: /* @__PURE__ */ React.createElement(Box, {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center"
    }, /* @__PURE__ */ React.createElement(Paragraph, {
      fontWeight: "600",
      color: selectedStudent === Number(item.student_id) && userRole === RoleLabels.STUDENT ? MthColor.MTHBLUE : MthColor.SYSTEM_11,
      sx: {fontSize: "16px"}
    }, /* @__PURE__ */ React.createElement(GradeComponent, {
      item,
      currentYear: currentSchoolYear,
      nextYear: nextSchoolYear,
      isWithdrawn: item.status?.find((item2) => item2?.school_year_id === currentSchoolYear?.school_year_id)?.status === StudentStatus.WITHDRAWN
    })), selectedStudent === Number(item.student_id) && userRole === RoleLabels.STUDENT && Boolean(me.masquerade) && !isNil(item?.person?.user_id) && !isNaN(Number(item.person.user_id)) && /* @__PURE__ */ React.createElement(Tooltip, {
      title: "Masquerade"
    }, /* @__PURE__ */ React.createElement(AccountBoxOutlinedIcon, {
      sx: {color: MthColor.MTHBLUE, height: 15, width: 15},
      onClick: () => becomeUser(Number(item.person.user_id))
    }))),
    image: /* @__PURE__ */ React.createElement(Avatar, {
      alt: item.person.preferred_first_name ?? item.person.first_name,
      src: EMPTY_STRING,
      variant: "rounded",
      style: {marginRight: 8}
    }, getStringInitialLetter(item.person.preferred_first_name ?? item.person.first_name))
  }))), students.filter((item) => {
    const studentLatestStatus = item.status[0]?.status || null;
    const studentDateOfBirth = item.person.date_of_birth;
    if (epic4601bug4983) {
      return showAll && (studentLatestStatus === StudentStatus.GRADUATED || studentLatestStatus === StudentStatus.COMPLETED || studentLatestStatus === StudentStatus.WITHDRAWN && studentDateOfBirth && dayjs(studentDateOfBirth) < dayjs().subtract(19, "years"));
    } else {
      return showAll && (item.status.length && Number(item.status[0].status) > 1 && item.status.length && Number(item.status[0].status) === 3 || item.status.length && Number(item.status[0].status) === 2 && item.person.date_of_birth && dayjs(item.person.date_of_birth) < dayjs().subtract(19, "years"));
    }
  }).map((item, idx) => /* @__PURE__ */ React.createElement(Box, {
    sx: {cursor: "pointer"},
    onClick: () => handleChangeStudent(item),
    key: idx
  }, /* @__PURE__ */ React.createElement(Metadata, {
    title: /* @__PURE__ */ React.createElement(Subtitle, {
      fontWeight: "700",
      color: selectedStudent === Number(item.student_id) ? MthColor.MTHBLUE : MthColor.GRAY
    }, item.person.first_name),
    subtitle: /* @__PURE__ */ React.createElement(Paragraph, {
      color: MthColor.GRAY,
      size: "large"
    }, status[item.status[0].status]),
    image: /* @__PURE__ */ React.createElement(Avatar, {
      alt: item.person.preferred_first_name ?? item.person.first_name,
      src: EMPTY_STRING,
      variant: "rounded",
      style: {marginRight: 8}
    }, getStringInitialLetter(item.person.preferred_first_name ?? item.person.first_name))
  }))))))));
};
