import {r as react} from "../../common/index-c6c556cc.js";
import {u as useTheme$1} from "../../common/useTheme-b68d9fcc.js";
import {j as jsxRuntime} from "../../common/jsx-runtime-c4f7c71f.js";
import {_ as _extends} from "../../common/extends-1379b0c5.js";
import {a as useTheme, T as ThemeContext} from "../../common/useThemeWithoutDefault-a6f20aad.js";
import {T as ThemeContext$1} from "../../common/emotion-element-cbed451f.browser.esm-6f9225f1.js";
import "../../common/_commonjsHelpers-53bde9c7.js";
import "../../common/createTheme-723fa293.js";
import "../../common/objectWithoutPropertiesLoose-fe69c3b9.js";
const hasSymbol = typeof Symbol === "function" && Symbol.for;
var nested = hasSymbol ? Symbol.for("mui.nested") : "__THEME_NESTED__";
function mergeOuterLocalTheme(outerTheme, localTheme) {
  if (typeof localTheme === "function") {
    const mergedTheme = localTheme(outerTheme);
    return mergedTheme;
  }
  return _extends({}, outerTheme, localTheme);
}
function ThemeProvider$1(props) {
  const {
    children,
    theme: localTheme
  } = props;
  const outerTheme = useTheme();
  const theme = react.useMemo(() => {
    const output = outerTheme === null ? localTheme : mergeOuterLocalTheme(outerTheme, localTheme);
    if (output != null) {
      output[nested] = outerTheme !== null;
    }
    return output;
  }, [localTheme, outerTheme]);
  return /* @__PURE__ */ jsxRuntime.jsx(ThemeContext.Provider, {
    value: theme,
    children
  });
}
function InnerThemeProvider(props) {
  const theme = useTheme$1();
  return /* @__PURE__ */ jsxRuntime.jsx(ThemeContext$1.Provider, {
    value: typeof theme === "object" ? theme : {},
    children: props.children
  });
}
function ThemeProvider(props) {
  const {
    children,
    theme: localTheme
  } = props;
  return /* @__PURE__ */ jsxRuntime.jsx(ThemeProvider$1, {
    theme: localTheme,
    children: /* @__PURE__ */ jsxRuntime.jsx(InnerThemeProvider, {
      children
    })
  });
}
export {ThemeProvider as default};
