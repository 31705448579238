import {gql} from "../../_snowpack/pkg/@apollo/client.js";
export const getActiveSchoolYearsByRegionId = gql`
  query GetActiveSchoolYears($regionId: ID!) {
    getActiveSchoolYears(region_id: $regionId) {
      date_begin
      date_end
      date_reg_close
      date_reg_open
      grades
      special_ed
      special_ed_options
      birth_date_cut
      school_year_id
      midyear_application
      midyear_application_open
      midyear_application_close
      MainyearApplicatable
      enrollment_packet
      MidyearApplicatable
      full_application_open
      full_application_close
    }
  }
`;
export const getSchoolYearsByRegionId = gql`
  query GetSchoolYearsByRegionId($regionId: ID!) {
    getSchoolYearsByRegionId(region_id: $regionId) {
      date_begin
      date_end
      date_reg_close
      date_reg_open
      grades
      special_ed
      special_ed_options
      birth_date_cut
      school_year_id
      midyear_application
      midyear_application_open
      midyear_application_close
      midyear_schedule_open
      midyear_schedule_close
      IsCurrentYear
      intent_to_re_enroll_close
      intent_to_re_enroll_open
    }
  }
`;
export const getSchoolYearQuery = gql`
  query SchoolYears {
    schoolYears {
      school_year_id
      date_begin
      date_end
    }
  }
`;
export const GetCurrentSchoolYearByRegionId = gql`
  query Schoolyear_getcurrent($regionId: Int!) {
    schoolyear_getcurrent(region_id: $regionId) {
      schedule
      learning_logs_first_second_semesters
    }
  }
`;
export const getReimbursementSchoolYearsByRegionId = gql`
  query ReimbursementRequestSchoolYears($regionId: Int!) {
    reimbursementRequestSchoolYears(regionId: $regionId) {
      school_year_id
      date_begin
      date_end
    }
  }
`;
export const getActiveStudentHomeroomSchoolYearsQuery = gql`
  query ActiveHomeroomSchoolYears($studentId: Int!) {
    activeHomeroomSchoolYears(studentId: $studentId) {
      HomeroomSettings {
        SchoolYearId
        days_to_submit_early
        diploma
        gender
        grades_by_subject
        grading_scale_percentage
        id
        max_of_excused_learning_logs_allowed
        notify_when_graded
        notify_when_resubmit_required
        passing_average
        special_education
        update_required_schedule_to_submit
        zero_count
      }
      IsCurrentYear
      date_begin
      date_end
      school_year_id
    }
  }
`;
export const getSchoolYearSettingsQuery = gql`
  query Schoolyear($schoolYearId: ID!) {
    schoolyear(school_year_id: $schoolYearId) {
      schedule
      first_semester_ends
      second_semester_starts
      learning_logs_eoy_deadline
      learning_logs_first_second_semesters
      schedule_builder_open
      schedule_builder_close
      midyear_schedule_open
      midyear_schedule_close
    }
  }
`;
