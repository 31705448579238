import {useContext, useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {
  KINDERGARTEN_LABEL,
  KINDERGARTEN_NUM_VALUE,
  KINDER_ABBREVIATION,
  PRE_KINDERGARTEN_LABEL,
  PRE_KINDERGARTEN_NUM_VALUE,
  PRE_KINDER_ABBREVIATION
} from "../../constants/index.js";
import {dateSettings} from "../../enums/index.js";
import {UserContext} from "../../../providers/UserContext/UserProvider.js";
import {getSchoolYearsByRegionId} from "../../../screens/Admin/SiteManagement/services.js";
import {formatSchoolYear, sortGrades, toOrdinalSuffix} from "../../utils/index.js";
export const useProgramYearListBySchoolYearId = (school_year_id) => {
  const {me} = useContext(UserContext);
  const [programYearList, setProgramYearList] = useState([]);
  const [programYears, setProgramYears] = useState([]);
  const [specialEd, setSpecialEd] = useState([]);
  const [availableGrades, setAvailableGrades] = useState([]);
  const [numericGrades, setNumericGrades] = useState([]);
  const [specialEdOptions, setSpecialEdOptions] = useState([]);
  const {loading, data, error} = useQuery(getSchoolYearsByRegionId, {
    variables: {
      regionId: me?.selectedRegionId
    },
    skip: !me?.selectedRegionId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data?.region?.SchoolYears && school_year_id) {
      const {SchoolYears} = data?.region;
      SchoolYears?.map((schoolYear) => {
        if (schoolYear?.school_year_id === Number(school_year_id)) {
          if (schoolYear?.grades) {
            const sortedGrades = sortGrades(schoolYear?.grades);
            const availGrades = sortedGrades?.split(",").map((item) => {
              if (item.includes(KINDER_ABBREVIATION) && !item.includes(PRE_KINDER_ABBREVIATION)) {
                return {
                  label: KINDERGARTEN_LABEL,
                  value: KINDERGARTEN_LABEL
                };
              } else if (item.includes(PRE_KINDER_ABBREVIATION)) {
                return {
                  label: PRE_KINDERGARTEN_LABEL,
                  value: PRE_KINDERGARTEN_LABEL
                };
              } else {
                return {
                  label: `${toOrdinalSuffix(Number(item))} Grade`,
                  value: item.toString()
                };
              }
            });
            setAvailableGrades(availGrades);
            const numericGrades2 = sortedGrades?.split(",").map((item) => {
              if (item.includes(KINDER_ABBREVIATION) && !item.includes(PRE_KINDER_ABBREVIATION)) {
                return {
                  label: KINDERGARTEN_LABEL,
                  value: KINDERGARTEN_NUM_VALUE
                };
              } else if (item.includes(PRE_KINDER_ABBREVIATION)) {
                return {
                  label: PRE_KINDERGARTEN_LABEL,
                  value: PRE_KINDERGARTEN_NUM_VALUE
                };
              } else {
                return {
                  label: `${toOrdinalSuffix(Number(item))} Grade`,
                  value: Number(item)
                };
              }
            });
            setNumericGrades(numericGrades2);
          } else {
            setAvailableGrades([]);
          }
          if (schoolYear?.special_ed) {
            const specialEds = schoolYear?.special_ed_options?.split(",").map((item, index) => {
              return {
                label: item,
                value: `${index}`
              };
            });
            setSpecialEd(specialEds?.map((item) => item.value));
            setSpecialEdOptions(specialEds || []);
          }
          if (schoolYear?.midyear_application) {
            setProgramYearList([
              {
                label: `${formatSchoolYear(schoolYear?.date_begin, schoolYear?.date_end, dateSettings.UTC)}`,
                value: "schoolYear"
              },
              {
                label: `${formatSchoolYear(schoolYear?.midyear_application_open, schoolYear?.midyear_application_close, dateSettings.UTC)} Mid-year`,
                value: "midYear"
              }
            ]);
            setProgramYears(["schoolYear", "midYear"]);
          } else {
            setProgramYearList([
              {
                label: `${formatSchoolYear(schoolYear?.date_begin, schoolYear?.date_end, dateSettings.UTC)}`,
                value: "schoolYear"
              }
            ]);
            setProgramYears(["schoolYear"]);
          }
        }
      });
    }
  }, [loading, data, school_year_id]);
  return {
    loading,
    programYearList,
    programYears,
    setProgramYears,
    selectedSpecialEd: specialEd,
    setSelectedSpecialEd: setSpecialEd,
    gradeList: availableGrades,
    numericGradeList: numericGrades,
    specialEdList: specialEdOptions,
    error
  };
};
