export var StudentLearningLogStatistics;
(function(StudentLearningLogStatistics2) {
  StudentLearningLogStatistics2["GRADED_AVERAGE"] = "avgGrade";
  StudentLearningLogStatistics2["GRADED_ZERO_COUNT"] = "gradedZeroCount";
  StudentLearningLogStatistics2["RESUBMIT_REQUIRED_COUNT"] = "resubmitRequiredLearningLogs";
  StudentLearningLogStatistics2["AVG_GRADE_FIRST"] = "avgGradeFirst";
  StudentLearningLogStatistics2["AVG_GRADE_SECOND"] = "avgGradeSecond";
  StudentLearningLogStatistics2["EXCUSED_LEARNING_LOGS"] = "excusedLearningLogs";
  StudentLearningLogStatistics2["SEMESTERS_ENABLED"] = "semestersEnabled";
  StudentLearningLogStatistics2["FIRST_GRADED"] = "firstGraded";
  StudentLearningLogStatistics2["SECOND_GRADED"] = "secondGraded";
})(StudentLearningLogStatistics || (StudentLearningLogStatistics = {}));
