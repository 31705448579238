export var ColumnName;
(function(ColumnName2) {
  ColumnName2["STUDENT_LEGAL"] = "studentLegalName";
})(ColumnName || (ColumnName = {}));
export var RequestName;
(function(RequestName2) {
  RequestName2["STUDENT_LEGAL"] = "Student Legal";
  RequestName2["GRADE"] = "Grade";
  RequestName2["SOE"] = "SoE";
  RequestName2["ASSESSMENT"] = "Assessment";
  RequestName2["OPT_IN"] = "Opt-in";
  RequestName2["OPT_OUT"] = "Opt-out";
  RequestName2["DATE"] = "Date";
  RequestName2["PARENT"] = "Parent";
  RequestName2["PHONE"] = "Phone";
  RequestName2["EMAIL"] = "Email";
  RequestName2["UNIQUE_ID"] = "Student Unique Identifier";
})(RequestName || (RequestName = {}));
export var ExtraTitle;
(function(ExtraTitle2) {
  ExtraTitle2["STUDENT_LEGAL_FIRST_NAME"] = "Student Legal First Name";
  ExtraTitle2["STUDENT_LEGAL_LAST_NAME"] = "Student Legal Last Name";
})(ExtraTitle || (ExtraTitle = {}));
export var ReportTitle;
(function(ReportTitle2) {
  ReportTitle2["STUDENT_LEGAL"] = "Student Legal";
  ReportTitle2["GRADE"] = "Grade Level";
  ReportTitle2["SOE"] = "SoE";
  ReportTitle2["ASSESSMENT"] = "Assessment Title";
  ReportTitle2["OPT_IN"] = "Opt-in selection";
  ReportTitle2["OPT_OUT"] = "Opt-out selection";
  ReportTitle2["DATE"] = "Date";
  ReportTitle2["PARENT"] = "Parent";
  ReportTitle2["PHONE"] = "Phone";
  ReportTitle2["EMAIL"] = "Parent Email";
  ReportTitle2["UNIQUE_ID"] = "Student Unique Identifier";
})(ReportTitle || (ReportTitle = {}));
export var ReportButtons;
(function(ReportButtons2) {
  ReportButtons2["SEND_OPTOUT_TO_RECORD"] = "Send Opt-Out to Records";
})(ReportButtons || (ReportButtons = {}));
