import React, {useContext, useEffect, useMemo, useState} from "../../../../_snowpack/pkg/react.js";
import {useMutation, useQuery} from "../../../../_snowpack/pkg/@apollo/client.js";
import AddIcon from "../../../../_snowpack/pkg/@mui/icons-material/Add.js";
import CloseIcon from "../../../../_snowpack/pkg/@mui/icons-material/Close.js";
import {Box, Button, Modal, Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import {CustomModal} from "../../../../components/CustomModal/CustomModals.js";
import {Pagination} from "../../../../components/Pagination/Pagination.js";
import {EMPTY_STRING, lastComma, paginationOptions} from "../../../../core/constants/index.js";
import {DropDown} from "../../../../core/components/shared/DropDown/DropDown.js";
import {Search} from "../../../../core/components/shared/Search/index.js";
import {
  GRADE_LEVEL_LABEL,
  MthColor,
  MthTitle,
  Order,
  ReimbursementKeyValues,
  ReimbursementRequestStatus,
  ReimbursementRequestTypeAbr,
  datePatterns
} from "../../../../core/enums/index.js";
import {deleteReimbursementRequestsMutation} from "../../../../graphql/mutation/reimbursement-request.js";
import {useSchoolYearsByRegionId} from "../../../../core/hooks/index.js";
import {UserContext} from "../../../../providers/UserContext/UserProvider.js";
import {formatDateByPattern, getTimezone, utcToTimezoned} from "../../../../core/utils/index.js";
import {DirectDeductionModal} from "../DirectDeduction/index.js";
import {ReimbursementTable} from "./ReimbursementTable.js";
import {getFamilyReimbursements} from "./services.js";
import {useStyles} from "./styles.js";
import {REIMBURSEMENT_TABLE_HEAD} from "./types.js";
const defaultPaginationOption = paginationOptions[0];
const dollar = "$";
export const ReimbursementModal = ({
  closeModal,
  studentId = 0,
  schoolYearIdSelected = void 0,
  isFromParent = false,
  personId
}) => {
  const {me} = useContext(UserContext);
  const [searchField, setSearchField] = useState("");
  const [paginationLimit, setPaginationLimit] = useState(defaultPaginationOption);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState(`${REIMBURSEMENT_TABLE_HEAD.SUBMITTED.toLowerCase()}|${Order.ASC}`);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reimbursementToDelete, setReimbursementToDelete] = useState(-1);
  const [reimbursementToEdit, setReimbursementToEdit] = useState();
  const [showDiretDeductionModal, setShowDirectDeductionModal] = useState(false);
  const totalSum = selectedItems.reduce((acc, curr) => acc + Number(curr.amount.replace(dollar, "")), 0).toFixed(2);
  const [deleteReimbursementRequests] = useMutation(deleteReimbursementRequestsMutation);
  const {
    dropdownItems: schoolYearDropdownItems,
    selectedYearId,
    setSelectedYearId,
    selectedYear
  } = useSchoolYearsByRegionId(me?.selectedRegionId, schoolYearIdSelected);
  const {
    loading: loadingFamilyReimbursement,
    data: familyReimbursementData,
    refetch: refetchReimbursementData
  } = useQuery(getFamilyReimbursements, {
    variables: {
      schoolYearId: selectedYearId,
      search: searchField,
      skip,
      sort,
      studentId,
      take: paginationLimit,
      isFromParent,
      personId
    },
    skip: studentId !== void 0 ? false : true,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loadingFamilyReimbursement && familyReimbursementData) {
      const {
        getFamilyReimbursements: {results, total}
      } = familyReimbursementData;
      if (total >= 0) {
        const data = results.map((item) => {
          let paid_ordered = "";
          if (item.rb_type === ReimbursementRequestTypeAbr.REIMBURSEMENT && item.status === ReimbursementRequestStatus.PAID) {
            paid_ordered = item.paid;
          }
          if ((item.rb_type === ReimbursementRequestTypeAbr.DIRECT_DEDUCTION || item.rb_type === ReimbursementRequestTypeAbr.DIRECT_ORDER) && item.status === ReimbursementRequestStatus.ORDERED) {
            paid_ordered = item.ordered;
          }
          return {
            reimbursement_request_id: item.reimbursement_request_id,
            student_id: item.student_id,
            amount: `${dollar}${item.amount}`,
            period: item.period?.replace(lastComma, " &$1"),
            rb_type: item.rb_type,
            status: item.status,
            student: item.student,
            grade: isNaN(Number(item.grade)) ? GRADE_LEVEL_LABEL.K : item.grade,
            category: ReimbursementKeyValues[item.category],
            paid_ordered: paid_ordered ? formatDateByPattern(utcToTimezoned(new Date(paid_ordered), getTimezone(me)), datePatterns.ZMONTH_ZDAY_4DYEAR) : EMPTY_STRING,
            submitted: item.submitted ? formatDateByPattern(utcToTimezoned(new Date(item.submitted), getTimezone(me)), datePatterns.ZMONTH_ZDAY_4DYEAR) : EMPTY_STRING,
            approved_ordered_by: item.approved_ordered_by,
            additional_information: item.additional_information,
            periods: item.periods
          };
        });
        setTotalData(total);
        setTableData([...data]);
      }
    }
  }, [loadingFamilyReimbursement, familyReimbursementData]);
  const anyStudentId = useMemo(() => {
    if (!loadingFamilyReimbursement && familyReimbursementData) {
      const {
        getFamilyReimbursements: {studentId: studentId2}
      } = familyReimbursementData;
      return studentId2;
    }
    return 0;
  }, [loadingFamilyReimbursement, familyReimbursementData]);
  useEffect(() => {
    handlePageChange(1);
  }, [searchField]);
  useEffect(() => {
    setSelectedItems([]);
  }, [selectedYearId]);
  const handleChangePageLimit = (value) => {
    handlePageChange(1);
    setPaginationLimit(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSkip(paginationLimit ? paginationLimit * (page - 1) : defaultPaginationOption);
  };
  const handleSort = (property, order) => {
    setSort(`${property}|${order}`);
  };
  const handleSelection = (items, isAll) => {
    const result = items.filter((item) => item.isSelected).map((item) => item.rawData);
    if (isAll)
      setSelectedItems([...result]);
  };
  const handleSelectItem = (item, isUnselect) => {
    if (isUnselect) {
      setSelectedItems(selectedItems.filter((f_item) => f_item.reimbursement_request_id !== item.rawData.reimbursement_request_id));
    } else {
      setSelectedItems([...selectedItems, item.rawData]);
    }
  };
  const handleClickDelete = (reimbursement_id) => {
    setReimbursementToDelete(reimbursement_id);
    setShowDeleteModal(true);
  };
  const handleDelete = async () => {
    if (reimbursementToDelete === -1)
      return;
    await deleteReimbursementRequests({
      variables: {
        reimbursementRequestsActionInput: {
          reimbursementRequestIds: reimbursementToDelete
        }
      }
    });
    await refetchReimbursementData();
    setSelectedItems(selectedItems.filter((item) => item.reimbursement_request_id !== reimbursementToDelete));
  };
  const editDirectDeduction = (item) => {
    setReimbursementToEdit(item);
    setShowDirectDeductionModal(true);
  };
  const handleShowModal = (value) => {
    setReimbursementToEdit(void 0);
    setShowDirectDeductionModal(value);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Modal, {
    open: true,
    onClose: () => closeModal()
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: useStyles.customReportModal
  }, /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end"
  }, /* @__PURE__ */ React.createElement(CloseIcon, {
    style: useStyles.closeBtn,
    onClick: () => closeModal()
  })), /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mt: 1.5
  }, /* @__PURE__ */ React.createElement(Typography, {
    sx: {fontSize: "20px", fontWeight: 700}
  }, MthTitle.FAMILY_REIMBURSEMENT_TITLE), /* @__PURE__ */ React.createElement(DropDown, {
    dropDownItems: schoolYearDropdownItems,
    placeholder: MthTitle.SELECT_YEAR,
    defaultValue: selectedYearId,
    borderNone: true,
    setParentValue: (val) => {
      setSelectedYearId(Number(val));
    },
    sx: {mr: 3, "& .MuiFormControl-root .MuiInputBase-root": {fontSize: 18}}
  })), /* @__PURE__ */ React.createElement(Box, {
    sx: {mt: 3, mb: 2.75},
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Search, {
    setSearchField,
    sx: {width: 280, fontSize: 12, fontWeight: 600, height: 40}
  }), /* @__PURE__ */ React.createElement(Button, {
    sx: {bg: MthColor.BG_MAIN, ml: 2.5, fontSize: 16, fontWeight: 600},
    startIcon: /* @__PURE__ */ React.createElement(AddIcon, null),
    onClick: () => setShowDirectDeductionModal(true)
  }, MthTitle.DIRECT_DEDUCTION)), /* @__PURE__ */ React.createElement(Pagination, {
    testId: "pagination",
    setParentLimit: handleChangePageLimit,
    handlePageChange,
    defaultValue: paginationLimit || defaultPaginationOption,
    numPages: Math.ceil(totalData / paginationLimit) || 1,
    currentPage
  })), /* @__PURE__ */ React.createElement(Box, {
    maxHeight: 450,
    overflow: "auto"
  }, /* @__PURE__ */ React.createElement(ReimbursementTable, {
    data: tableData,
    selectedItems,
    handleSort,
    handleSelection,
    reimbursementToDelete: handleClickDelete,
    handleSelectItem,
    showPeriod: false,
    editDirectDeduction
  })), /* @__PURE__ */ React.createElement(Typography, {
    fontSize: 20,
    fontWeight: 700,
    mt: 4.75
  }, MthTitle.SUM, "\u2003", `${dollar}${totalSum}`))), showDeleteModal && /* @__PURE__ */ React.createElement(CustomModal, {
    title: MthTitle.DELETE,
    description: MthTitle.DELETE_REQUEST,
    cancelStr: MthTitle.CANCEL,
    confirmStr: MthTitle.DELETE,
    backgroundColor: MthColor.WHITE,
    onClose: () => {
      setShowDeleteModal(false);
    },
    onConfirm: () => {
      handleDelete();
      setShowDeleteModal(false);
    }
  }), showDiretDeductionModal && /* @__PURE__ */ React.createElement(DirectDeductionModal, {
    showModal: handleShowModal,
    selectedYear,
    selectedYearId,
    anyStudentIdFromParent: anyStudentId,
    refetch: refetchReimbursementData,
    isEditMode: reimbursementToEdit !== void 0,
    reimbursementToEdit
  }));
};
