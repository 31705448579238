import {MthColor} from "../../../../../../core/enums/index.js";
export const saveCancelClasses = {
  align: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  cancelBtn: {
    width: "160px",
    height: "36px",
    borderRadius: "40px",
    background: MthColor.LIGHTGRAY,
    color: MthColor.SYSTEM_01
  },
  saveBtn: {
    width: "160px",
    height: "36px",
    borderRadius: "40px",
    background: MthColor.BUTTON_LINEAR_GRADIENT_DARK,
    color: MthColor.WHITE,
    marginLeft: 5
  }
};
