import React, {useContext, useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../../../_snowpack/pkg/@apollo/client.js";
import {DeleteForeverOutlined} from "../../../../../_snowpack/pkg/@mui/icons-material.js";
import AddIcon from "../../../../../_snowpack/pkg/@mui/icons-material/Add.js";
import CloseIcon from "../../../../../_snowpack/pkg/@mui/icons-material/Close.js";
import {Button, Card, IconButton, Modal, Tooltip, Typography} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../../_snowpack/pkg/@mui/system.js";
import {MthTable} from "../../../../../components/MthTable/index.js";
import {Pagination} from "../../../../../components/Pagination/Pagination.js";
import {paginationOptions} from "../../../../../core/constants/index.js";
import {DropDown} from "../../../../../core/components/shared/DropDown/DropDown.js";
import {Search} from "../../../../../core/components/shared/Search/index.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor, MthTitle, Order, datePatterns} from "../../../../../core/enums/index.js";
import {getResourceRequestsQuery} from "../../../../../graphql/queries/resource-request.js";
import {useSchoolYearsByRegionId} from "../../../../../core/hooks/index.js";
import {UserContext} from "../../../../../providers/UserContext/UserProvider.js";
import {formatDateByPatternUtc, gradeShortText, resourceRequestCost, resourceRequestStatus} from "../../../../../core/utils/index.js";
import {useStyles} from "./styles.js";
const defaultPaginationOption = paginationOptions[0];
export const HomeroomResourceModal = ({
  showHomeromResourceModal,
  toggleHomeroomResourceModal,
  schoolYearIdSelected,
  studentIds
}) => {
  const styles = useStyles;
  const {me} = useContext(UserContext);
  const [searchField, setSearchField] = useState("");
  const [paginationLimit, setPaginationLimit] = useState(defaultPaginationOption);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const {
    dropdownItems: schoolYearDropdownItems,
    selectedYearId,
    setSelectedYearId
  } = useSchoolYearsByRegionId(me?.selectedRegionId, schoolYearIdSelected);
  const handleChangePageLimit = (value) => {
    handlePageChange(1);
    setPaginationLimit(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSkip(paginationLimit ? paginationLimit * (page - 1) : defaultPaginationOption);
  };
  const gradeLevelText = (gradeLevels) => {
    const gradeLevelYear = gradeLevels?.find((gradeLevel) => Number(gradeLevel?.school_year_id) === Number(selectedYearId));
    return gradeShortText(gradeLevelYear?.grade_level ?? gradeLevels?.[gradeLevels?.length - 1]?.grade_level);
  };
  const fields = [
    {
      key: "created_at",
      label: "Submitted",
      sortable: true,
      width: "110px",
      formatter: (item) => {
        return formatDateByPatternUtc(item.rawData.created_at, datePatterns.ZMONTH_ZDAY_4DYEAR);
      }
    },
    {
      key: "student",
      label: "Student",
      sortable: true,
      formatter: (item) => {
        return `${item.rawData.Student?.person?.last_name}, ${item.rawData.Student?.person?.first_name}`;
      }
    },
    {
      key: "grade",
      label: "Grade",
      sortable: true,
      formatter: (item) => {
        return gradeLevelText(item.rawData.Student?.grade_levels);
      }
    },
    {
      key: "vendor",
      label: "Vendor",
      sortable: true,
      formatter: (item) => {
        return item.rawData.Resource?.title;
      }
    },
    {
      key: "status",
      label: "Status",
      sortable: true,
      formatter: (item) => {
        return resourceRequestStatus(item.rawData.status);
      }
    },
    {
      key: "cost",
      label: "Cost",
      sortable: true,
      formatter: (item) => {
        return resourceRequestCost(item.rawData);
      }
    },
    {
      key: "level",
      label: "Level",
      sortable: true,
      formatter: (item) => {
        return item.rawData.ResourceLevel?.name;
      }
    },
    {
      key: "action",
      formatter: () => {
        return /* @__PURE__ */ React.createElement(Box, {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end"
        }, /* @__PURE__ */ React.createElement(Tooltip, {
          title: MthTitle.DELETE
        }, /* @__PURE__ */ React.createElement(IconButton, null, /* @__PURE__ */ React.createElement(DeleteForeverOutlined, null))));
      }
    }
  ];
  const {loading, data} = useQuery(getResourceRequestsQuery, {
    variables: {
      schoolYearId: Number(selectedYearId),
      skip,
      take: paginationLimit,
      region: Number(me?.selectedRegionId),
      search: searchField,
      studentIds
    },
    skip: !selectedYearId,
    fetchPolicy: "network-only"
  });
  const createData = (resourceRequest) => {
    return {
      key: `schedule-request-${resourceRequest.resource_id}-${resourceRequest.student_id}`,
      columns: {},
      selectable: true,
      isSelected: false,
      rawData: resourceRequest
    };
  };
  useEffect(() => {
    if (!loading && data?.resourceRequests) {
      const {resourceRequests} = data;
      const {results, total} = resourceRequests;
      setTotalCnt(total);
      setTableData((results || []).map((item) => {
        return createData(item);
      }));
    }
  }, [loading, data]);
  return /* @__PURE__ */ React.createElement(Modal, {
    open: showHomeromResourceModal,
    onClose: toggleHomeroomResourceModal
  }, /* @__PURE__ */ React.createElement(Card, {
    sx: {...styles.homeroomResourceModal}
  }, /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: styles.cancelIcon,
    onClick: toggleHomeroomResourceModal
  }, /* @__PURE__ */ React.createElement(CloseIcon, {
    fontSize: "small"
  }))), /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: 1.5
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    size: "large",
    fontWeight: "bold"
  }, MthTitle.HOMEROOM_RESOURCES), /* @__PURE__ */ React.createElement(DropDown, {
    dropDownItems: schoolYearDropdownItems,
    placeholder: MthTitle.SELECT_YEAR,
    defaultValue: selectedYearId,
    borderNone: true,
    setParentValue: (val) => {
      setSelectedYearId(Number(val));
    },
    sx: {mr: 3, "& .MuiFormControl-root .MuiInputBase-root": {fontSize: 18}}
  })), /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    sx: {mt: 3, mb: 2.75}
  }, /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "row"
  }, /* @__PURE__ */ React.createElement(Search, {
    setSearchField,
    sx: {width: 280, fontSize: 12, fontWeight: 600, height: 40}
  }), /* @__PURE__ */ React.createElement(Button, {
    onClick: () => {
    },
    disableElevation: true,
    variant: "contained",
    sx: {
      background: MthColor.BG_MAIN,
      color: MthColor.BLACK,
      width: "200px",
      height: "44px",
      alignItems: "center",
      ml: 2.5
    },
    startIcon: /* @__PURE__ */ React.createElement(AddIcon, null)
  }, MthTitle.HOMEROOM_RESOURCE)), /* @__PURE__ */ React.createElement(Pagination, {
    testId: "pagination",
    setParentLimit: handleChangePageLimit,
    handlePageChange,
    defaultValue: paginationLimit || 25,
    numPages: Math.ceil(totalCnt / paginationLimit) || 1,
    currentPage,
    fontWeight: 600
  })), /* @__PURE__ */ React.createElement(Box, {
    maxHeight: 450,
    overflow: "auto"
  }, /* @__PURE__ */ React.createElement(MthTable, {
    items: tableData,
    fields,
    size: "small",
    labelSize: 12,
    selectable: true,
    oddBg: false,
    isTableCellBorder: false,
    defaultOrder: Order.ASC
  })), /* @__PURE__ */ React.createElement(Typography, {
    fontSize: 20,
    fontWeight: 700,
    mt: 4.75
  }, MthTitle.SUM, "\u2003", `$${data?.resourceRequests?.totalPrice.toFixed(2)}`)));
};
