import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {EMPTY_STRING, HYPHEN} from "../../constants/index.js";
import {datePatterns, FileKind, Separator, StudentsFolder} from "../../enums/index.js";
import {utcToTimezoned, formatDateByPattern} from "../index.js";
import {formatMonthDays, formatTime} from "../date/date.util.js";
const LOWER_S = "s";
const APOSTROPHE = "'";
export const toOrdinalSuffix = (number) => {
  const digits = [number % 10, number % 100], ordinals = ["st", "nd", "rd", "th"], oPattern = [1, 2, 3, 4], tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1]) ? number + ordinals[digits[0] - 1] : number + ordinals[3];
};
export const extractAllNumFromStr = (str) => {
  const nums = str.match(/\d+/g);
  if (nums)
    return nums.map(Number);
  else
    return [];
};
export const replaceInsertsToValue = (studentInfo, value, timezone, link) => {
  if (studentInfo) {
    const {applications, person, parent} = studentInfo;
    const school_year = applications?.at(-1)?.school_year || {};
    const timezonedYearBegin = utcToTimezoned(new Date(studentInfo?.current_school_year_status?.date_begin ?? school_year.date_begin), timezone);
    const yearbegin = formatDateByPattern(timezonedYearBegin, datePatterns.FULL_YEAR);
    const timezonedYearEnd = utcToTimezoned(new Date(studentInfo?.current_school_year_status?.date_end ?? school_year.date_end), timezone);
    const yearend = formatDateByPattern(timezonedYearEnd, datePatterns.FULL_YEAR);
    const midYear = applications?.at(-1)?.school_year_id === studentInfo?.current_school_year_status?.school_year_id ? applications?.at(-1)?.midyear_application : false;
    const yearText = midYear ? `${yearbegin}-${yearend.substring(2, 4)} Mid-year` : `${yearbegin}-${yearend.substring(2, 4)}`;
    return value.toString().replace(/\[STUDENT\]/g, person.first_name).replace(/\[PARENT\]/g, parent.person.first_name).replace(/\[YEAR\]/g, yearText).replace(/\[LINK\]/g, link ?? "");
  } else
    return value;
};
export const ordinalSuffixOf = (num) => {
  const i = +num;
  if (!i)
    return num;
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11)
    return i + "st";
  if (j == 2 && k != 12)
    return i + "nd";
  if (j == 3 && k != 13)
    return i + "rd";
  return i + "th";
};
export const extractContent = (s) => {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText || "";
};
export const getOptions = (options, optionsToRemove, isOptionRemove) => {
  if (isOptionRemove) {
    return options.filter((option) => !optionsToRemove.includes(option));
  }
  return options;
};
export const renderDate = (selectedEvent) => {
  const startTime = formatTime(selectedEvent?.startDate);
  const startDate = formatMonthDays(selectedEvent?.startDate);
  const endDate = formatMonthDays(selectedEvent?.endDate);
  if (!selectedEvent?.allDay) {
    if (startDate === endDate)
      return `${startTime}, ${startDate}`;
    else
      return `${startTime}, ${startDate} - ${endDate}`;
  } else {
    if (startDate === endDate)
      return `${endDate}`;
    else
      return `${startDate} - ${endDate}`;
  }
};
export const phoneFormat = (phone) => {
  if (!phone) {
    return "";
  }
  phone = phone.replaceAll("-", "");
  return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
};
export const arrayToString = (value) => {
  if (value?.length) {
    if (value.length === 1)
      return value.toString();
    const first = value.slice(0, -1);
    const last = value.slice(-1);
    const last_comma = first.length > 1 ? "," : "";
    return `${first.join(", ")}${last_comma} and ${last}`;
  }
  return "";
};
export const removeFileExtension = (fileName) => {
  if (!fileName || fileName.trim() === EMPTY_STRING) {
    return EMPTY_STRING;
  }
  const lastIndex = fileName.lastIndexOf(".");
  if (lastIndex === -1) {
    return fileName;
  }
  return fileName.substring(0, lastIndex);
};
export const concatStrings = (...strings) => {
  return strings.filter((str) => str !== void 0 && str !== null && str.trim() !== "").map((str) => str?.trim()).join(" ");
};
export const getfolderName = (fileData, flagName) => {
  const lastName = `${fileData?.lastName || EMPTY_STRING}`;
  const firstName = `${fileData?.firstName || EMPTY_STRING}`;
  const uniqueId = `${fileData?.uniqueId || EMPTY_STRING}`;
  if (lastName && firstName && uniqueId) {
    const commaSeparator = `${fileData?.lastName}` ? Separator.COMMA : `${EMPTY_STRING}`;
    const studentName = `${lastName}${commaSeparator}${firstName}`;
    const schoolYear = fileData?.schoolYear ? `${StudentsFolder.SCHOOLYEAR_AB}${HYPHEN}${fileData?.schoolYear}` : `${EMPTY_STRING}`;
    const fileKind = `${FileKind.STUDENT_PACKET}`;
    const folderName = flagName ? concatStrings(studentName, uniqueId, fileKind, schoolYear) : concatStrings(lastName, commaSeparator, firstName);
    return folderName;
  } else {
    throw new Error("Unable to construct folder name due to missing data.");
  }
};
export const renderCommaString = (str) => {
  const list = str?.split(",") || [];
  return list.map((x, i) => list.length > 1 && i === list.length - 1 ? `& ${x}` : x).join(", ");
};
export const possessive = (noun) => {
  if (noun) {
    const endsWithS = noun.toLowerCase().endsWith(LOWER_S);
    if (endsWithS) {
      return `${noun}${APOSTROPHE}`;
    } else {
      return `${noun}${APOSTROPHE}${LOWER_S}`;
    }
  }
  return EMPTY_STRING;
};
export const booleanToYesOrNo = (response) => {
  if (isNil(response))
    return EMPTY_STRING;
  return response ? "Yes" : "No";
};
export const parseArray = (arrayStr) => {
  if (isNil(arrayStr))
    return [];
  try {
    return JSON.parse(arrayStr);
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const removeSpaceLowerCaseString = (str) => {
  if (str) {
    const lowerCaseStr = str.toLocaleLowerCase();
    const strWithoutSpaces = lowerCaseStr.replace(/\s/g, "");
    return strWithoutSpaces;
  }
  return EMPTY_STRING;
};
