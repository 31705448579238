import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {htmlTag} from "../../constants/index.js";
import {ReportQuestionType, ReportQuestionTypeLabel} from "../../enums/index.js";
import {getAllQuestions} from "../../../screens/Admin/Reports/SchoolPartner/services.js";
import {getCustomReportAndColumnsQuery} from "../../../screens/Admin/Reports/SchoolPartnerReport/services.js";
export const useQuestionsCustomReport = (regionId, schoolYearId, customReportId = -1) => {
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const {loading, data: allQuestions} = useQuery(getAllQuestions, {
    variables: {
      regionId,
      schoolYearId
    },
    skip: regionId ? false : true,
    fetchPolicy: "network-only"
  });
  const {data: customReport, loading: isLoadingCustomReport} = useQuery(getCustomReportAndColumnsQuery, {
    variables: {
      reportId: customReportId
    },
    skip: !allQuestions || customReportId === -1,
    fetchPolicy: "network-only"
  });
  const mapFn = (question, id, slug) => {
    return {
      question: question.replace(htmlTag, ""),
      id: Number(id),
      slug,
      defaultValue: ""
    };
  };
  useEffect(() => {
    if (!loading && allQuestions && (customReportId === -1 || !isLoadingCustomReport && customReport)) {
      const auxDataColumns = [];
      const auxSelectedQuestions = [];
      const defaultSelectedQuestions = customReport?.getCustomReportById?.customReportColumns?.filter((c) => c.type === ReportQuestionType.DEFAULT) || [];
      const applicationSelectedQuestions = customReport?.getCustomReportById?.customReportColumns?.filter((c) => c.type === ReportQuestionType.APPLICATION) || [];
      const enrollmentSelectedQuestions = customReport?.getCustomReportById?.customReportColumns?.filter((c) => c.type === ReportQuestionType.ENROLLMENT) || [];
      const programSelectedQuestions = customReport?.getCustomReportById?.customReportColumns?.filter((c) => c.type === ReportQuestionType.PROGRAM_DETAILS) || [];
      const customSelectedQuestions = customReport?.getCustomReportById?.customReportColumns?.filter((c) => c.type === ReportQuestionType.CUSTOM) || [];
      const {getAllDefaultQuestions: allDefaultQuestions} = allQuestions;
      if (allDefaultQuestions) {
        const auxDefaultQuestions = allDefaultQuestions.map(({column_title: question, id, slug}) => mapFn(question, id, slug));
        auxDataColumns.push({
          title: ReportQuestionTypeLabel.DEFAULT,
          type: ReportQuestionType.DEFAULT,
          selected: defaultSelectedQuestions.map((c) => c.defaultQuestion?.id?.toString() || ""),
          show: true,
          questions: auxDefaultQuestions,
          options: getOptions(auxDefaultQuestions)
        });
        defaultSelectedQuestions.forEach((c) => {
          const question = auxDefaultQuestions.find((q) => q.id === c.defaultQuestion?.id);
          if (question) {
            auxSelectedQuestions.push({...question, order: c.order, type: c.type});
          }
        });
      }
      const {getApplicationQuestionsByRegionAndSchoolYear: allApplicationQuestions} = allQuestions;
      if (allApplicationQuestions) {
        const auxApplicationQuestions = allApplicationQuestions.map(({question, id, slug}) => mapFn(question, id, slug));
        auxDataColumns.push({
          title: ReportQuestionTypeLabel.APPLICATION,
          type: ReportQuestionType.APPLICATION,
          selected: applicationSelectedQuestions.map((c) => c.applicationQuestion?.id?.toString() || ""),
          show: true,
          questions: auxApplicationQuestions,
          options: getOptions(auxApplicationQuestions)
        });
        applicationSelectedQuestions.forEach((c) => {
          const question = auxApplicationQuestions.find((q) => q.id === c.applicationQuestion?.id);
          if (question) {
            auxSelectedQuestions.push({...question, order: c.order, type: c.type});
          }
        });
      }
      const {getEnrollmentQuestionsByRegionAndSchoolYear: allEnrollmentQuestions} = allQuestions;
      if (allEnrollmentQuestions) {
        const auxEnrollmentQuestions = allEnrollmentQuestions.map(({question, id, slug}) => mapFn(question, id, slug));
        auxDataColumns.push({
          title: ReportQuestionTypeLabel.ENROLLMENT,
          type: ReportQuestionType.ENROLLMENT,
          selected: enrollmentSelectedQuestions.map((c) => c.enrollmentQuestion?.id?.toString() || ""),
          show: true,
          questions: auxEnrollmentQuestions,
          options: getOptions(auxEnrollmentQuestions)
        });
        enrollmentSelectedQuestions.forEach((c) => {
          const question = auxEnrollmentQuestions.find((q) => q.id === c.enrollmentQuestion?.id);
          if (question) {
            auxSelectedQuestions.push({...question, order: c.order, type: c.type});
          }
        });
      }
      const {getAllProgramDetailColumns: allProgramDetailColumns} = allQuestions;
      if (allProgramDetailColumns) {
        const auxProgramDetailsColumn = allProgramDetailColumns.map(({column_title: question, id, slug}) => mapFn(question, id, slug));
        auxDataColumns.push({
          title: ReportQuestionTypeLabel.PROGRAM_DETAILS,
          type: ReportQuestionType.PROGRAM_DETAILS,
          selected: programSelectedQuestions.map((c) => c.programDetailColumn?.id?.toString() || ""),
          show: true,
          questions: auxProgramDetailsColumn,
          options: getOptions(auxProgramDetailsColumn)
        });
        programSelectedQuestions.forEach((c) => {
          const question = auxProgramDetailsColumn.find((q) => q.id === c.programDetailColumn?.id);
          if (question) {
            auxSelectedQuestions.push({...question, order: c.order, type: c.type});
          }
        });
      }
      customSelectedQuestions.forEach((c) => {
        auxSelectedQuestions.push({
          id: c.id || 0,
          question: c.column_title,
          slug: "",
          order: c.order,
          type: c.type,
          defaultValue: c.default_value
        });
      });
      auxSelectedQuestions.sort((a, b) => (a.order || 0) - (b.order || 0));
      setDataColumns(auxDataColumns);
      setSelectedQuestions(auxSelectedQuestions);
    }
  }, [customReport, allQuestions, loading, isLoadingCustomReport]);
  const getOptions = (arrayQuestions) => {
    return arrayQuestions.map((item) => ({
      label: item.question,
      value: item.id.toString()
    }));
  };
  return {
    loading: loading && isLoadingCustomReport,
    allReportColumns: dataColumns,
    setReportColumns: setDataColumns,
    selectedQuestions,
    customReport: customReport?.getCustomReportById
  };
};
