export var AssessmentOptOutOptions;
(function(AssessmentOptOutOptions2) {
  AssessmentOptOutOptions2["AAPPL_ASSESSMENT_OF_PERFORMANCE_TOWARD_PROFICIENCY_IN_LANGUAGES_ELEMENTARY"] = "AAPPL Assessment of Performance Toward Proficiency in Languages (Elementary)";
  AssessmentOptOutOptions2["ACADIENCE_MATH_ELEMENTARY"] = "Acadience Math (Elementary)";
  AssessmentOptOutOptions2["ACADIENCE_READING_ELEMENTARY"] = "Acadience Reading (Elementary)";
  AssessmentOptOutOptions2["DLM_ENGLISH_LANGUAGE_ARTS_ELA_ELEMENTARY"] = "DLM English Language Arts (ELA) (Elementary)";
  AssessmentOptOutOptions2["DLM_MATHEMATICS_ELEMENTARY"] = "DLM Mathematics (Elementary)";
  AssessmentOptOutOptions2["DLM_SCIENCE_ELEMENTARY"] = "DLM Science (Elementary)";
  AssessmentOptOutOptions2["EARLY_LITERACY_ALTERNATE_ASSESSMENT_ELEMENTARY"] = "Early Literacy Alternate Assessment (Elementary)";
  AssessmentOptOutOptions2["EARLY_MATHEMATICS_ALTERNATE_ASSESSMENT_ELEMENTARY"] = "Early Mathematics Alternate Assessment (Elementary)";
  AssessmentOptOutOptions2["KINDERGARTEN_ENTRY_AND_EXIT_PROFILE_KEEP_ELEMENTARY"] = "Kindergarten Entry and Exit Profile (KEEP) (Elementary)";
  AssessmentOptOutOptions2["RISE_BENCHMARK_MODULES_ENGLISH_LANGUAGE_ARTS_ELA_ELEMENTARY"] = "RISE Benchmark Modules English Language Arts (ELA) (Elementary)";
  AssessmentOptOutOptions2["RISE_BENCHMARK_MODULES_MATHEMATICS_ELEMENTARY"] = "RISE Benchmark Modules Mathematics (Elementary)";
  AssessmentOptOutOptions2["RISE_BENCHMARK_MODULES_SCIENCE_ELEMENTARY"] = "RISE Benchmark Modules Science (Elementary)";
  AssessmentOptOutOptions2["RISE_BENCHMARK_MODULES_WRITING_ELEMENTARY"] = "RISE Benchmark Modules Writing (Elementary)";
  AssessmentOptOutOptions2["RISE_INTERIM_ENGLISH_LANGUAGE_ARTS_ELA_ELEMENTARY"] = "RISE Interim English Language Arts (ELA) (Elementary)";
  AssessmentOptOutOptions2["RISE_INTERIM_MATHEMATICS_ELEMENTARY"] = "RISE Interim Mathematics (Elementary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_ENGLISH_LANGUAGE_ARTS_ELA_ELEMENTARY"] = "RISE Summative English Language Arts (ELA) (Elementary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_MATHEMATICS_ELEMENTARY"] = "Rise Summative Mathematics (Elementary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_SCIENCE_ELEMENTARY"] = "RISE Summative Science (Elementary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_WRITING_GRADE_5_ELEMENTARY"] = "RISE Summative Writing (Grade 5) (Elementary)";
  AssessmentOptOutOptions2["ACT_SECONDARY"] = "ACT (Secondary)";
  AssessmentOptOutOptions2["CTE_SKILL_CERTIFICATE_REQUIRED_FOR_CERTIFICATION_SECONDARY"] = "CTE Skill Certificate Required for Certification (Secondary)";
  AssessmentOptOutOptions2["DLM_ENGLISH_LANGUAGE_ARTS_ELA_SECONDARY"] = "DLM English Language Arts (ELA) (Secondary)";
  AssessmentOptOutOptions2["DLM_MATHEMATICS_SECONDARY"] = "DLM Mathematics (Secondary)";
  AssessmentOptOutOptions2["DLM_SCIENCE_SECONDARY"] = "DLM Science (Secondary)";
  AssessmentOptOutOptions2["GENERAL_FINANCIAL_LITERACY_GFL_SECONDARY"] = "General Financial Literacy (GFL) (Secondary)";
  AssessmentOptOutOptions2["HIGH_SCHOOL_CIVICS_EXAM_REQUIRED_FOR_GRADUATION_SECONDARY"] = "High School Civics Exam Required for Graduation (Secondary)";
  AssessmentOptOutOptions2["HIGH_SCHOOL_CORE_BENCHMARKS_ENGLISH_LANGUAGE_ARTS_ELA_SECONDARY"] = "High School Core Benchmarks English Language Arts (ELA) (Secondary)";
  AssessmentOptOutOptions2["HIGH_SCHOOL_CORE_BENCHMARKS_MATHEMATICS_SECONDARY"] = "High School Core Benchmarks Mathematics (Secondary)";
  AssessmentOptOutOptions2["HIGH_SCHOOL_CORE_BENCHMARKS_SCIENCE_SECONDARY"] = "High School Core Benchmarks Science (Secondary)";
  AssessmentOptOutOptions2["RISE_BENCHMARK_MODULES_ENGLISH_LANGUAGE_ARTS_ELA_SECONDARY"] = "RISE Benchmark Modules English Language Arts (ELA) (Secondary)";
  AssessmentOptOutOptions2["RISE_BENCHMARK_MODULES_MATHEMATICS_SECONDARY"] = "RISE Benchmark Modules Mathematics (Secondary)";
  AssessmentOptOutOptions2["RISE_BENCHMARKS_MODULES_SCIENCE_SECONDARY"] = "RISE Benchmarks Modules Science (Secondary)";
  AssessmentOptOutOptions2["RISE_INTERIM_ENGLISH_LANGUAGE_ARTS_ELA_SECONDARY"] = "RISE Interim English Language Arts (ELA) (Secondary)";
  AssessmentOptOutOptions2["RISE_INTERIM_MATHEMATICS_SECONDARY"] = "RISE Interim Mathematics (Secondary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_ENGLISH_LANGUAGE_ARTS_ELA_SECONDARY"] = "RISE Summative English Language Arts (ELA) (Secondary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_MATHEMATICS_SECONDARY"] = "RISE Summative Mathematics (Secondary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_SCIENCE_SECONDARY"] = "RISE Summative Science (Secondary)";
  AssessmentOptOutOptions2["RISE_SUMMATIVE_WRITING_GRADE_8_SECONDARY"] = "RISE Summative Writing (Grade 8) (Secondary)";
  AssessmentOptOutOptions2["UTAH_ASPIRE_PLUS_READING_SECONDARY"] = "Utah Aspire Plus Reading (Secondary)";
  AssessmentOptOutOptions2["UTAH_ASPIRE_PLUS_ENGLISH_SECONDARY"] = "Utah Aspire Plus English (Secondary)";
  AssessmentOptOutOptions2["UTAH_ASPIRE_PLUS_MATHEMATICS_SECONDARY"] = "Utah Aspire Plus Mathematics (Secondary)";
  AssessmentOptOutOptions2["UTAH_ASPIRE_PLUS_SCIENCE_SECONDARY"] = "Utah Aspire Plus Science (Secondary)";
  AssessmentOptOutOptions2["ENGLISH_LANGUAGE_ARTS"] = "English Language Arts";
  AssessmentOptOutOptions2["MATH"] = "Math";
})(AssessmentOptOutOptions || (AssessmentOptOutOptions = {}));
