import {StudentStatus} from "../../../../../core/enums/index.js";
export const BaseStatuses = [
  {label: "Inactive", value: StudentStatus.INACTIVE},
  {label: "Applied", value: StudentStatus.APPLIED},
  {label: "Accepted", value: StudentStatus.ACCEPTED},
  {label: "Pending", value: StudentStatus.PENDING},
  {label: "Active", value: StudentStatus.ACTIVE},
  {label: "Withdrawn", value: StudentStatus.WITHDRAWN}
];
export const BaseSpecialEds = [
  {
    label: "None",
    value: 0
  },
  {
    label: "IEP",
    value: 1
  },
  {
    label: "504",
    value: 2
  }
];
export const BaseDiplomaSeekingOptions = [
  {
    label: "No",
    value: "0"
  },
  {
    label: "Yes",
    value: "1"
  }
];
