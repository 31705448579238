export var StudentRecordFileKind;
(function(StudentRecordFileKind2) {
  StudentRecordFileKind2["STUDENT_PACKET"] = "Student Packet";
  StudentRecordFileKind2["WITHDRAWAL_FORM"] = "Withdrawal Form";
  StudentRecordFileKind2["OPT_OUT_FORM"] = "Opt-out Form";
  StudentRecordFileKind2["USIRS"] = "USIR";
  StudentRecordFileKind2["SCHEDULE"] = "Schedule";
  StudentRecordFileKind2["WEEKLY_LEARNING_LOG"] = "Weekly Learning Log";
  StudentRecordFileKind2["AFFIDAVIT"] = "Affidavit";
  StudentRecordFileKind2["TRANSITION_AFFIDAVIT"] = "Transition Affidavit";
  StudentRecordFileKind2["UNOFFICIAL_TRANSCRIPT"] = "Unofficial Transcript";
})(StudentRecordFileKind || (StudentRecordFileKind = {}));
