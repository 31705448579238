import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {diplomaAnswerGql} from "../../../graphql/queries/diploma.js";
export const useDiplomaSeekingOptionsByStudentIdAndSchoolYearId = (schoolYearId, studentId, skip) => {
  const [diplomaAnswer, setDiplomaAnswer] = useState();
  const [diplomaOptions, setDiplomaOptions] = useState([
    {
      option_id: 1,
      label: "Yes",
      value: false
    },
    {
      option_id: 2,
      label: "No",
      value: false
    }
  ]);
  const {
    loading: diplomaAnswerLoading,
    data: diplomaAnswerData,
    refetch
  } = useQuery(diplomaAnswerGql, {
    variables: {
      diplomaAnswerInput: {
        schoolYearId,
        studentId
      }
    },
    skip,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!diplomaAnswerLoading) {
      setDiplomaAnswer(diplomaAnswerData?.getDiplomaAnswer?.answer);
      if (diplomaAnswerData && diplomaAnswerData.getDiplomaAnswer) {
        setDiplomaOptions([
          {
            option_id: 1,
            label: "Yes",
            value: diplomaAnswerData.getDiplomaAnswer.answer === 1
          },
          {
            option_id: 2,
            label: "No",
            value: diplomaAnswerData.getDiplomaAnswer.answer === 0
          }
        ]);
      } else {
        setDiplomaOptions([
          {
            option_id: 1,
            label: "Yes",
            value: false
          },
          {
            option_id: 2,
            label: "No",
            value: false
          }
        ]);
      }
    }
  }, [diplomaAnswerLoading, diplomaAnswerData]);
  return {
    diplomaAnswer,
    diplomaOptions,
    diplomaAnswerRefetch: refetch
  };
};
