import React from "../../../_snowpack/pkg/react.js";
import ErrorOutlineIcon from "../../../_snowpack/pkg/@mui/icons-material/ErrorOutline.js";
import ScheduleIcon from "../../../_snowpack/pkg/@mui/icons-material/Schedule.js";
import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {EMPTY_STRING} from "../../constants/index.js";
import {ApplicationStatus, MthRoute} from "../../enums/index.js";
import {MthColor, PacketStatus, ScheduleStatus, StudentNotification, StudentStatus} from "../../enums/index.js";
import {DEFAULT_PROFILE_PAGE, StudentProfilePage} from "../../../screens/HomeroomStudentProfile/types.js";
import {nowBetweenUtcInterval} from "../date/date.util.js";
import {isEligible} from "../isEligible/isEligible.util.js";
export const getStudentNotificationData = (student, currentYear, nextYear, studentStatistics, isFromDashboard, epic3733Story4142 = false, handleWithdrawnStudent, timezone) => {
  const {status} = student;
  const currentYearId = currentYear?.map((schoolYear) => schoolYear?.school_year_id)[0];
  const nextYearId = nextYear?.map((schoolYear) => schoolYear?.school_year_id)[0];
  const currentYearStatusObject = status?.filter((studentStatus) => currentYearId === studentStatus?.school_year_id)?.at(-1);
  const currentYearStatus = currentYearStatusObject?.status ?? StudentStatus.NO_STATUS;
  const nextYearStatusObject = status?.filter((studentStatus) => nextYearId === studentStatus?.school_year_id).at(-1);
  const nextYearStatus = nextYearStatusObject?.status ?? StudentStatus.NO_STATUS;
  const hideStudentsStatus = [
    StudentStatus.WITHDRAWN,
    StudentStatus.DELETED,
    StudentStatus.INACTIVE,
    StudentStatus.NO_STATUS
  ];
  let notification = getNotificationBySY(student, currentYear[0], currentYearId, currentYearStatus, studentStatistics, isFromDashboard, handleWithdrawnStudent);
  const noNotification = isNil(notification.circleData.message) && notification.circleData.progress === 0 && notification.circleData.color === MthColor.GREEN;
  if (nextYearStatus !== StudentStatus.NO_STATUS) {
    if ((hideStudentsStatus.includes(currentYearStatus) || noNotification) && !hideStudentsStatus.includes(nextYearStatus)) {
      notification = getNotificationBySY(student, nextYear[0], nextYearId, nextYearStatus, studentStatistics, isFromDashboard, handleWithdrawnStudent);
    }
  } else {
    if (epic3733Story4142 && noNotification) {
      const showITRNotification = isEligible(currentYear, nextYear, timezone ?? EMPTY_STRING, student);
      if (showITRNotification)
        notification = {
          circleData: {
            mobileColor: MthColor.BUTTON_LINEAR_GRADIENT,
            mobileText: StudentNotification.SUBMIT_INTENT_TO_RE_ENROLL,
            message: StudentNotification.SUBMIT_INTENT_TO_RE_ENROLL,
            color: MthColor.MTHORANGE,
            progress: 100,
            icon: /* @__PURE__ */ React.createElement(ErrorOutlineIcon, {
              sx: {color: MthColor.MTHORANGE, mt: 0.5, mb: -0.75, cursor: "pointer"}
            })
          },
          showData: true,
          link: `${MthRoute.HOMEROOM}/${student?.student_id}?${DEFAULT_PROFILE_PAGE}=${StudentProfilePage.STUDENT}`,
          homeroomLink: `${MthRoute.HOMEROOM}/${student?.student_id}`
        };
    }
  }
  return {...notification, currentYearStatus};
};
const getNotificationBySY = (student, schoolYear, schoolYearId, studentStatus, studentStatistics, isFromDashboard, handleWithdrawnStudent) => {
  const {applications, packets, StudentSchedules, homeroom} = student;
  const currentHomeroom = homeroom?.find((hm) => Number(hm.school_year_id) === schoolYearId);
  const currentDate = new Date();
  const applicationStatus = applications?.filter((studentApplication) => schoolYearId === studentApplication?.school_year_id).at(-1)?.status;
  const midYearApplication = applications?.filter((studentApplication) => schoolYearId === studentApplication?.school_year_id).at(-1)?.midyear_application;
  const isScheduleBuilderOpenFullYear = nowBetweenUtcInterval(new Date(schoolYear.schedule_builder_open), new Date(schoolYear.schedule_builder_close));
  const isScheduleBuilderOpenMidYear = new Date(schoolYear.midyear_schedule_open) <= currentDate && new Date(schoolYear.midyear_schedule_close) > currentDate;
  const isScheduleBuilderOpen = midYearApplication ? isScheduleBuilderOpenMidYear : isScheduleBuilderOpenFullYear;
  const firstSemesterSchedule = StudentSchedules?.filter((schedule) => Number(schedule?.StudentId) === Number(student?.student_id) && schedule?.SchoolYearId === schoolYearId && !schedule?.is_second_semester)?.at(-1)?.status;
  const secondSemesterSchedule = StudentSchedules?.filter((schedule) => Number(schedule?.StudentId) === Number(student?.student_id) && schedule?.SchoolYearId === schoolYearId && schedule?.is_second_semester)?.at(-1)?.status;
  const enrollmentLink = `${MthRoute.HOMEROOM + MthRoute.ENROLLMENT}/${student?.student_id}`;
  const homeroomLink = `${MthRoute.HOMEROOM}/${student?.student_id}`;
  const scheduleLink = `${MthRoute.HOMEROOM + MthRoute.SUBMIT_SCHEDULE}/${student?.student_id}`;
  const scheduleBuilderLink = `${MthRoute.HOMEROOM + MthRoute.SUBMIT_SCHEDULE}/${student?.student_id}?backTo=${location.pathname}`;
  const homeroomDefaultPage = `${MthRoute.HOMEROOM}/${student?.student_id}?${DEFAULT_PROFILE_PAGE}=${StudentProfilePage.HOMEROOM}`;
  const enrollmentPacketStatus = packets?.find((packet) => packet?.school_year_id === schoolYearId)?.status;
  let showData = true;
  let link = "";
  let circleData = {
    progress: 0,
    color: MthColor.GREEN
  };
  const scheduleIcon = /* @__PURE__ */ React.createElement(ScheduleIcon, {
    sx: {color: MthColor.MTHGREEN, mt: 0.5, mb: -0.75, cursor: "pointer"}
  });
  const exclamationIcon = /* @__PURE__ */ React.createElement(ErrorOutlineIcon, {
    sx: {color: MthColor.MTHORANGE, mt: 0.5, mb: -0.75, cursor: "pointer"}
  });
  if (studentStatus === StudentStatus.WITHDRAWN || studentStatus === StudentStatus.DELETED || studentStatus === StudentStatus.INACTIVE || studentStatus === StudentStatus.NO_STATUS) {
    circleData = {
      mobileColor: MthColor.BUTTON_LINEAR_GRADIENT,
      mobileText: StudentNotification.RE_APPLY,
      message: StudentNotification.RE_APPLY,
      color: MthColor.MTHORANGE,
      progress: 0,
      icon: /* @__PURE__ */ React.createElement(ErrorOutlineIcon, {
        sx: {color: MthColor.MTHORANGE, mt: 0.5, mb: -0.75, cursor: "pointer"},
        onClick: handleWithdrawnStudent
      })
    };
  } else if (applicationStatus === ApplicationStatus.SUBMITTED || studentStatus === StudentStatus.REAPPLIED) {
    link = MthRoute.HOMEROOM;
    circleData = {
      mobileColor: MthColor.MTHGREEN,
      mobileText: StudentNotification.APPLICATION_PENDING_APPROVAL,
      message: StudentNotification.APPLICATION_PENDING_APPROVAL,
      color: MthColor.MTHGREEN,
      progress: 25,
      icon: scheduleIcon
    };
  } else if (!isNil(enrollmentPacketStatus) || schoolYear.schedule) {
    if (enrollmentPacketStatus === PacketStatus.SUBMITTED || enrollmentPacketStatus === PacketStatus.RESUBMITTED) {
      link = homeroomLink;
      circleData = {
        mobileColor: MthColor.MTHGREEN,
        mobileText: StudentNotification.ENROLLMENT_PENDING_APPROVAL,
        message: StudentNotification.ENROLLMENT_PACKET_PENDING_APPROVAL,
        color: MthColor.MTHGREEN,
        progress: 50,
        icon: scheduleIcon
      };
    } else if (enrollmentPacketStatus === PacketStatus.NOT_STARTED || enrollmentPacketStatus === PacketStatus.STARTED) {
      link = enrollmentLink;
      circleData = {
        mobileColor: MthColor.BUTTON_LINEAR_GRADIENT,
        mobileText: StudentNotification.SUBMIT_NOW,
        message: StudentNotification.PLEASE_SUBMIT_ENROLLMENT_PACKET,
        progress: 50,
        color: MthColor.MTHORANGE,
        icon: exclamationIcon
      };
    } else if (enrollmentPacketStatus === PacketStatus.MISSING_INFO) {
      link = enrollmentLink;
      circleData = {
        mobileColor: MthColor.BUTTON_LINEAR_GRADIENT,
        mobileText: StudentNotification.RESUBMIT_NOW,
        message: StudentNotification.PLEASE_RESUBMIT_ENROLLMENT_PACKET,
        progress: 50,
        color: MthColor.MTHORANGE,
        icon: exclamationIcon
      };
    } else if (schoolYear.schedule || !currentHomeroom || currentHomeroom) {
      if (!isScheduleBuilderOpen && firstSemesterSchedule === ScheduleStatus.NOT_SUBMITTED && schoolYear.schedule) {
        link = null;
        circleData = {
          mobileColor: MthColor.MTHGREEN,
          mobileText: StudentNotification.WAITING_FOR_SCHEDULE_BUILDER_TO_OPEN,
          message: StudentNotification.WAITING_FOR_SCHEDULE_BUILDER_TO_OPEN,
          color: MthColor.MTHGREEN,
          progress: 75,
          icon: scheduleIcon
        };
      } else {
        if (schoolYear.schedule && isNil(secondSemesterSchedule) && firstSemesterSchedule === ScheduleStatus.UPDATES_REQUIRED) {
          link = scheduleBuilderLink;
          circleData = {
            mobileColor: MthColor.MTHORANGE,
            mobileText: StudentNotification.RESUBMIT_SCHEDULE,
            message: StudentNotification.RESUBMIT_SCHEDULE,
            color: MthColor.MTHORANGE,
            progress: 75,
            icon: exclamationIcon
          };
        } else if (schoolYear.schedule && isNil(secondSemesterSchedule) && (firstSemesterSchedule === ScheduleStatus.NOT_SUBMITTED || firstSemesterSchedule === ScheduleStatus.DRAFT)) {
          link = scheduleLink;
          circleData = {
            mobileColor: MthColor.MTHGREEN,
            mobileText: StudentNotification.SUBMIT_SCHEDULE,
            message: StudentNotification.SUBMIT_SCHEDULE,
            color: MthColor.MTHORANGE,
            progress: 75,
            icon: exclamationIcon
          };
        } else if (schoolYear.schedule && isNil(secondSemesterSchedule) && (firstSemesterSchedule === ScheduleStatus.SUBMITTED || firstSemesterSchedule === ScheduleStatus.RESUBMITTED || firstSemesterSchedule === ScheduleStatus.UPDATES_REQUESTED)) {
          link = homeroomLink;
          circleData = {
            mobileColor: MthColor.MTHGREEN,
            mobileText: StudentNotification.SCHEDULE_PENDING_APPROVAL,
            message: StudentNotification.SCHEDULE_PENDING_APPROVAL,
            color: MthColor.MTHGREEN,
            progress: 75,
            icon: scheduleIcon
          };
        } else if (schoolYear.schedule && (secondSemesterSchedule === ScheduleStatus.NOT_SUBMITTED || secondSemesterSchedule === ScheduleStatus.DRAFT)) {
          link = scheduleBuilderLink;
          circleData = {
            mobileColor: MthColor.MTHGREEN,
            mobileText: StudentNotification.SUBMIT_SECOND_SEMESTER_SCHEDULE,
            message: StudentNotification.SUBMIT_SECOND_SEMESTER_SCHEDULE,
            color: MthColor.MTHORANGE,
            progress: 100,
            icon: scheduleIcon
          };
        } else if (schoolYear.schedule && (secondSemesterSchedule === ScheduleStatus.SUBMITTED || secondSemesterSchedule === ScheduleStatus.RESUBMITTED || secondSemesterSchedule === ScheduleStatus.UPDATES_REQUESTED)) {
          link = homeroomLink;
          circleData = {
            mobileColor: MthColor.MTHGREEN,
            mobileText: StudentNotification.SECOND_SEMESTER_SCHEDULE_PENDING_APPROVAL,
            message: StudentNotification.SECOND_SEMESTER_SCHEDULE_PENDING_APPROVAL,
            color: MthColor.MTHGREEN,
            progress: 75,
            icon: scheduleIcon
          };
        } else if (schoolYear.schedule && secondSemesterSchedule === ScheduleStatus.UPDATES_REQUIRED) {
          link = scheduleBuilderLink;
          circleData = {
            mobileColor: MthColor.MTHORANGE,
            mobileText: StudentNotification.RESUBMIT_SCHEDULE,
            message: StudentNotification.RESUBMIT_SCHEDULE,
            color: MthColor.MTHORANGE,
            progress: 100,
            icon: exclamationIcon
          };
        } else if (!currentHomeroom) {
          link = homeroomLink;
          circleData = {
            mobileColor: MthColor.MTHGREEN,
            mobileText: StudentNotification.HOMEROOM_ASSIGNMENT_IN_PROGRESS,
            message: StudentNotification.HOMEROOM_ASSIGNMENT_IN_PROGRESS,
            color: MthColor.MTHGREEN,
            progress: 100,
            icon: scheduleIcon
          };
        } else if (currentHomeroom) {
          link = homeroomDefaultPage;
          const {graded, resubmitRequiredCount, zeroCount} = studentStatistics;
          if (!graded) {
            showData = false;
          }
          link = homeroomDefaultPage;
          const learingLogIcon = /* @__PURE__ */ React.createElement(ErrorOutlineIcon, {
            sx: {color: MthColor.RED, mt: 0.5, mb: -0.75, cursor: "pointer"}
          });
          if ((zeroCount ?? 0) > 0) {
            circleData = {
              mobileColor: MthColor.RED,
              mobileText: StudentNotification.MISSING_LEARNING_LOG,
              message: StudentNotification.MISSING_LEARNING_LOG,
              color: MthColor.RED,
              progress: 100,
              icon: learingLogIcon
            };
          }
          if ((resubmitRequiredCount ?? 0) > 0) {
            const message = isFromDashboard ? StudentNotification.RESUBMIT_NEEDED : StudentNotification.RESUBMIT_LEARNING_LOG;
            circleData = {
              mobileColor: MthColor.RED,
              mobileText: message,
              message,
              color: MthColor.RED,
              progress: 100,
              icon: learingLogIcon
            };
          }
        }
      }
    }
  }
  return {
    circleData,
    showData,
    link,
    homeroomLink: homeroomDefaultPage
  };
};
