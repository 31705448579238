import {useState} from "../../../_snowpack/pkg/react.js";
import {useMutation} from "../../../_snowpack/pkg/@apollo/client.js";
import {useFlag} from "../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {EPIC_2209_STORY_3780, UNASSIGNED, UNASSIGN_SIGNAL} from "../../constants/index.js";
import {SOEOperations} from "../../enums/index.js";
import {assignStudentToSOEGql, transferStudentSOEMutation} from "../../../screens/Admin/SiteManagement/services.js";
import {generateOptOutPDFMutation} from "../../../screens/Admin/TestingPreference/services.js";
import {generateSoeTransferPdf} from "../../services/index.js";
export const useSoeOperations = () => {
  const epic2209story3780 = useFlag(EPIC_2209_STORY_3780);
  const [operation, setOperation] = useState();
  const [operationProgress, setOperationProgress] = useState(-1);
  const [operationErrors, setOperationErrors] = useState([]);
  const [transferStudentsSOEMutation] = useMutation(transferStudentSOEMutation);
  const [generateOptOutPDF] = useMutation(generateOptOutPDFMutation);
  const [assignStudentToSOEMutation] = useMutation(assignStudentToSOEGql);
  const transferStudentsSOE = async (schoolPartnerIdToTransfer, schoolYearId, studentIds, studentList, shouldGenerateTransferForm = false) => {
    setOperation(SOEOperations.TRANSFER);
    setOperationProgress(0);
    setOperationErrors([]);
    for (const [index, studentId] of studentIds.map((item) => parseInt(item)).entries()) {
      const student = studentList.find((i) => String(i.studentId) === String(studentId));
      const schoolPartnerToTransferId = Number(schoolPartnerIdToTransfer);
      const studentNameId = !isNil(student) ? student.name : `${studentId}`;
      try {
        const response = await transferStudentsSOEMutation({
          variables: {
            assignStudentToSoeInput: {
              school_partner_id: schoolPartnerToTransferId,
              school_year_id: schoolYearId,
              student_ids: [studentId]
            }
          }
        });
        if (response.data.transferStudentSOE.successStudentsIds[0] === studentId) {
          await generateOptOutPDF({
            variables: {
              optOutFormArgs: {
                school_year: schoolYearId,
                optOutStudentIds: [studentId]
              }
            }
          });
          if (epic2209story3780 && shouldGenerateTransferForm)
            await generateSoeTransferPdf(schoolYearId, studentId);
        } else {
          console.error(`Error transfering student: ${studentNameId}`);
          setOperationErrors((opErrors) => [...opErrors, `Error transfering student: ${studentNameId}`]);
        }
        setOperationProgress((index + 1) / studentIds.length * 100);
      } catch (error) {
        console.error(error);
        setOperationErrors((opErrors) => [...opErrors, `Error transfering student: ${studentNameId}`]);
      }
    }
  };
  const assignStudentsSOE = async (schoolPartnerIdToAssign, schoolYearId, studentIds, studentList) => {
    setOperation(SOEOperations.ASSIGN);
    setOperationProgress(0);
    setOperationErrors([]);
    for (const [index, studentId] of studentIds.map((item) => parseInt(item)).entries()) {
      const student = studentList.find((i) => String(i.studentId) === String(studentId));
      const studentNameId = !isNil(student) ? student.name : `${studentId}`;
      try {
        await assignStudentToSOEMutation({
          variables: {
            assignStudentToSoeInput: {
              school_partner_id: schoolPartnerIdToAssign === UNASSIGNED.toLowerCase() ? UNASSIGN_SIGNAL : parseInt(schoolPartnerIdToAssign),
              school_year_id: schoolYearId,
              student_ids: [studentId]
            }
          }
        });
        await generateOptOutPDF({
          variables: {
            optOutFormArgs: {
              school_year: schoolYearId,
              optOutStudentIds: [studentId]
            }
          }
        });
        setOperationProgress((index + 1) / studentIds.length * 100);
      } catch (error) {
        console.error(error);
        setOperationErrors((opErrors) => [...opErrors, `Error assigning student: ${studentNameId}`]);
      }
    }
  };
  const clearSoeOperation = () => setOperation(void 0);
  return {
    transferStudentsSOE,
    assignStudentsSOE,
    operation,
    operationProgress,
    operationErrors,
    clearSoeOperation
  };
};
