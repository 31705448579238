export var INSERT_DESCRIPTION;
(function(INSERT_DESCRIPTION2) {
  INSERT_DESCRIPTION2["USER"] = "User's First Name";
  INSERT_DESCRIPTION2["PARENT"] = "Parent's First Name";
  INSERT_DESCRIPTION2["STUDENT"] = "Student's First Name";
  INSERT_DESCRIPTION2["YEAR"] = "School Year";
  INSERT_DESCRIPTION2["DEADLINE"] = "The deadline that the packet information must be all submitted";
  INSERT_DESCRIPTION2["TEACHER"] = "Teacher's Full Name";
  INSERT_DESCRIPTION2["LINK"] = "The link for the parent to access student's packet";
  INSERT_DESCRIPTION2["PERIOD_LIST"] = "List of Periods that need to be changed";
  INSERT_DESCRIPTION2["FILES"] = "Title for standard response";
  INSERT_DESCRIPTION2["INSTRUCTIONS"] = "Description for standard response";
  INSERT_DESCRIPTION2["APPLICATION_YEAR"] = "School Year";
  INSERT_DESCRIPTION2["STUDENT_GRADE_LEVEL"] = "Current grade level (6) (Kindergarten)";
  INSERT_DESCRIPTION2["LOG"] = "Title of Learning Log";
  INSERT_DESCRIPTION2["GRADE"] = "Grade of Learning Log";
})(INSERT_DESCRIPTION || (INSERT_DESCRIPTION = {}));
