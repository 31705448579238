import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getEventTypesQuery} from "../../../screens/Admin/Calendar/services.js";
export const useEventTypeListByRegionId = (regionId) => {
  const [eventTypeLists, setEventTypeLists] = useState([]);
  const {loading, data, error} = useQuery(getEventTypesQuery, {
    variables: {
      regionId
    },
    skip: regionId ? false : true,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data?.eventTypes) {
      setEventTypeLists(data?.eventTypes?.filter((item) => !item.archived)?.map((eventType) => ({
        name: eventType.name,
        color: eventType.color
      })));
    }
  }, [loading, data]);
  return {
    loading,
    data: eventTypeLists,
    error
  };
};
