export var DiplomaOptions;
(function(DiplomaOptions2) {
  DiplomaOptions2["DIPLOMA_SEEKING"] = "Diploma-seeking";
  DiplomaOptions2["NON_DIPLOMA_SEEKING"] = "Non Diploma-seeking";
})(DiplomaOptions || (DiplomaOptions = {}));
export var TestiongOptions;
(function(TestiongOptions2) {
  TestiongOptions2["TESTING_OPT_IN"] = "Opt-in";
  TestiongOptions2["TESTING_OPT_OUT"] = "Opt-out";
})(TestiongOptions || (TestiongOptions = {}));
export var DiplomaOptionValue;
(function(DiplomaOptionValue2) {
  DiplomaOptionValue2[DiplomaOptionValue2["NON_DIPLOMA_SEEKING"] = 0] = "NON_DIPLOMA_SEEKING";
  DiplomaOptionValue2[DiplomaOptionValue2["DIPLOMA_SEEKING"] = 1] = "DIPLOMA_SEEKING";
})(DiplomaOptionValue || (DiplomaOptionValue = {}));
