import React, {useState, useContext} from "../../../../../_snowpack/pkg/react.js";
import {useMutation} from "../../../../../_snowpack/pkg/@apollo/client.js";
import {Alert} from "../../../../../_snowpack/pkg/@mui/material.js";
import {useFlag} from "../../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {useFormContext} from "../../../../../_snowpack/pkg/react-hook-form.js";
import {EmailModal} from "../../../../../components/EmailModal/EmailModal.js";
import {StandardResponseOptionType} from "../../../../../components/EmailModal/StandardReponses/types.js";
import {EMPTY_STRING, EPIC_3658_BUG_4453} from "../../../../../core/constants/index.js";
import {EmailTemplateEnum, EmailType, EnrollmentPacketFormProperty, PacketStatus, datePatterns} from "../../../../../core/enums/index.js";
import {UserContext} from "../../../../../providers/UserContext/UserProvider.js";
import {
  longestCommonSubsequence,
  htmlToText,
  getYearText,
  removeDuplicateNewLines,
  utcToTimezoned,
  getTimezone,
  formatDateByPattern
} from "../../../../../core/utils/index.js";
import {sendEmailMutation} from "../../services.js";
import {studentContext} from "../providers.js";
import EnrollmentWarnSaveModal from "./ConfirmSaveModal.js";
export const PacketConfirmModals = ({packet, submitForm, selectedYear}) => {
  const student = useContext(studentContext);
  const {me} = useContext(UserContext);
  const {watch, setValue} = useFormContext();
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [sendPacketEmail] = useMutation(sendEmailMutation);
  const [emailFrom, setEmailFrom] = useState("");
  const timezone = getTimezone(me);
  const epic3658bug4453 = useFlag(EPIC_3658_BUG_4453);
  let currentSchoolYearStatus = epic3658bug4453 && selectedYear ? selectedYear : student?.current_school_year_status;
  const school_year_id = packet?.school_year_id ?? currentSchoolYearStatus?.school_year_id;
  if (packet?.school_year_id !== currentSchoolYearStatus?.school_year_id && epic3658bug4453) {
    const packetSchoolYear = student?.applications?.find((application) => application.school_year_id === packet.school_year_id)?.school_year;
    if (packetSchoolYear)
      currentSchoolYearStatus = packetSchoolYear;
  }
  const [
    notes,
    showMissingInfoModal,
    showAgeIssueModal,
    showSaveWarnModal,
    missingInfoAlert,
    saveAlert,
    saveError,
    preSaveStatus
  ] = watch([
    EnrollmentPacketFormProperty.NOTES,
    EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL,
    EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL,
    EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL,
    EnrollmentPacketFormProperty.MISSING_INFO_ALERT,
    EnrollmentPacketFormProperty.SAVE_ALERT,
    EnrollmentPacketFormProperty.SAVE_ERROR,
    EnrollmentPacketFormProperty.PRE_SAVE_STATUS
  ]);
  function onSubmit(status) {
    setValue("status", status || preSaveStatus);
    submitForm();
  }
  const setEmailBodyInfo = (body) => {
    const yearText = getYearText({
      dateBegin: currentSchoolYearStatus?.date_begin,
      dateEnd: currentSchoolYearStatus?.date_end,
      midyearApplication: student?.applications?.at(-1)?.school_year_id === school_year_id ? student?.applications?.at(-1)?.midyear_application : false
    });
    let url = window.location.href;
    url = url.substring(0, url.indexOf("/", url.indexOf("//") + 2));
    const grade_level = student.grade_levels?.at(-1)?.grade_level.toLowerCase() === "k" ? "Kindergarten" : student.grade_levels?.at(-1)?.grade_level;
    return body.toString().replace(/\[STUDENT_ID\]/g, student.student_id + "").replace(/\[FILES\]/g, packet.missing_files).replace(/\[LINK\]/g, `<a href="${url}/homeroom/enrollment/${student.student_id}">${url}/homeroom/enrollment/${student.student_id}</a>`).replace(/\[STUDENT\]/g, student.person.first_name).replace(/\[PARENT\]/g, student.parent.person.first_name).replace(/\[STUDENT_GRADE_LEVEL\]/g, grade_level || " ").replace(/\[YEAR\]/g, yearText).replace(/\[APPLICATION_YEAR\]/g, yearText);
  };
  const setEmailCompareBodyInfo = (body) => {
    const yearText = getYearText({
      dateBegin: currentSchoolYearStatus?.date_begin,
      dateEnd: currentSchoolYearStatus?.date_end,
      midyearApplication: student?.applications?.at(-1)?.school_year_id === school_year_id ? student?.applications?.at(-1)?.midyear_application : false
    });
    let url = window.location.href;
    url = url.substring(0, url.indexOf("/", url.indexOf("//") + 2));
    const grade_level = student.grade_levels?.at(-1)?.grade_level.toLowerCase() === "k" ? "Kindergarten" : student.grade_levels?.at(-1)?.grade_level;
    return body.toString().replace(/\[STUDENT_ID\]/g, String(student.student_id)).replace(/\[LINK\]/g, `<a href="${url}/homeroom/enrollment/${student.student_id}">${url}/homeroom/enrollment/${student.student_id}</a>`).replace(/\[STUDENT\]/g, student.person.first_name).replace(/\[PARENT\]/g, student.parent.person.first_name).replace(/\[STUDENT_GRADE_LEVEL\]/g, grade_level || " ").replace(/\[YEAR\]/g, yearText).replace(/\[APPLICATION_YEAR\]/g, yearText);
  };
  const handleEmailSend = (subject, body, options) => {
    try {
      let bodyData = setEmailBodyInfo(body);
      const bodyDataCompare = setEmailCompareBodyInfo(emailTemplate.body);
      sendPacketEmail({
        variables: {
          emailInput: {
            content: bodyData,
            email: student?.parent.person.email,
            subject,
            recipients: null,
            from: emailFrom !== emailTemplate?.from ? emailFrom : emailTemplate && emailTemplate?.from ? emailTemplate?.from : null,
            bcc: emailTemplate && emailTemplate?.bcc ? emailTemplate?.bcc : EMPTY_STRING,
            template_name: options.type === StandardResponseOptionType.AGE_ISSUE ? EmailTemplateEnum.AGE_ISSUE : EmailTemplateEnum.MISSING_INFORMATION,
            region_id: me?.selectedRegionId
          }
        }
      });
      bodyData = bodyData.replace(' target="_self"', "");
      const commonText = longestCommonSubsequence(bodyDataCompare, bodyData);
      const commonLines = commonText.split("\n");
      let uniqueToSecondString = bodyData;
      commonLines.forEach((line) => {
        uniqueToSecondString = uniqueToSecondString.replace(line, "");
      });
      uniqueToSecondString = htmlToText(uniqueToSecondString);
      setValue("notes", constructPacketNotes(notes || "", options, options.type, uniqueToSecondString));
      if (options.type === StandardResponseOptionType.AGE_ISSUE) {
        setValue(EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL, false);
        setValue(EnrollmentPacketFormProperty.AGE_ISSUE, true);
        onSubmit();
      } else if (options.type === StandardResponseOptionType.MISSING_INFO) {
        setValue(EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL, false);
        setValue(EnrollmentPacketFormProperty.PRE_SAVE_STATUS, PacketStatus.MISSING_INFO);
        setValue(EnrollmentPacketFormProperty.MISSING_FILES, options.values.map((v) => v.title));
        onSubmit(PacketStatus.MISSING_INFO);
      }
    } catch (e) {
      console.error("Error while attempting to send email:", e);
    }
  };
  const constructPacketNotes = (oldNotes, options, type, emailText) => {
    const date = new Date();
    const zonedDate = utcToTimezoned(date, timezone);
    const formattedDate = formatDateByPattern(zonedDate, datePatterns.ZMONTH_ZDAY_4DYEAR);
    let newNotes = `${formattedDate} - ${type === StandardResponseOptionType.AGE_ISSUE ? PacketStatus.AGE_ISSUE : PacketStatus.MISSING_INFO}
`;
    let writingBody = "";
    const yearText = getYearText({
      dateBegin: currentSchoolYearStatus?.date_begin,
      dateEnd: currentSchoolYearStatus?.date_end,
      midyearApplication: student?.applications?.at(-1)?.school_year_id === student?.current_school_year_status.school_year_id ? student?.applications?.at(-1)?.midyear_application : false
    });
    let url = window.location.href;
    url = url.substring(0, url.indexOf("/", url.indexOf("//") + 2));
    const grade_level = student.grade_levels?.at(-1)?.grade_level.toLowerCase() === "k" ? "Kindergarten" : student.grade_levels?.at(-1)?.grade_level;
    emailText = emailText.replace(/\n+$/, "");
    writingBody = emailText;
    options.values.map((option) => {
      option.title = option.title.replace(/\[STUDENT_ID\]/g, String(student.student_id)).replace(/\[FILES\]/g, packet.missing_files).replace(/\[LINK\]/g, `<a href="${url}/homeroom/enrollment/${student.student_id}">${url}/homeroom/enrollment/${student.student_id}</a>`).replace(/\[STUDENT\]/g, student.person.first_name).replace(/\[PARENT\]/g, student.parent.person.first_name).replace(/\[STUDENT_GRADE_LEVEL\]/g, grade_level || " ").replace(/\[YEAR\]/g, yearText).replace(/\[APPLICATION_YEAR\]/g, yearText);
      if (writingBody.includes(option.title.trim())) {
        writingBody = writingBody.replace(`${option.title.trim()}

`, `${option.title.trim()}
`);
        writingBody = writingBody.replace(option.title.trim(), `-${option.title.trim()}`);
      } else {
        if (writingBody) {
          writingBody = writingBody + "\n";
        }
        writingBody = `${writingBody}-${option.title}
${option.extraText}`;
      }
    });
    writingBody = removeDuplicateNewLines(writingBody);
    writingBody = writingBody.replace(/\[STUDENT_ID\]/g, student.student_id + "").replace(/\[FILES\]/g, packet.missing_files).replace(/\[LINK\]/g, `<a href="${url}/homeroom/enrollment/${student.student_id}">${url}/homeroom/enrollment/${student.student_id}</a>`).replace(/\[STUDENT\]/g, student.person.first_name).replace(/\[PARENT\]/g, student.parent.person.first_name).replace(/\[STUDENT_GRADE_LEVEL\]/g, grade_level || " ").replace(/\[YEAR\]/g, yearText).replace(/\[APPLICATION_YEAR\]/g, yearText);
    if (writingBody) {
      newNotes += writingBody;
    }
    if (oldNotes.length)
      return newNotes + "\n\n" + oldNotes;
    return newNotes + "\n";
  };
  if (showMissingInfoModal)
    return /* @__PURE__ */ React.createElement(EmailModal, {
      handleModem: () => setValue(EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL, false),
      title: `Missing Information on ${student.person.first_name}\u2019s Enrollment Packet`,
      options: emailTemplate && emailTemplate.standard_responses && JSON.parse(emailTemplate?.standard_responses),
      handleSubmit: handleEmailSend,
      setEmailTemplate,
      type: EmailType.MISSING_INFO,
      setEmailFrom,
      emailFrom,
      setEmailBodyInfo,
      schoolYearId: school_year_id,
      midYear: student?.applications?.at(-1)?.school_year_id === school_year_id ? student?.applications?.at(-1)?.midyear_application : false
    });
  if (showAgeIssueModal) {
    return /* @__PURE__ */ React.createElement(EmailModal, {
      handleModem: () => setValue(EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL, false),
      title: `Age Issue on ${student.person.first_name}\u2019s Enrollment Packet`,
      options: emailTemplate && emailTemplate.standard_responses && JSON.parse(emailTemplate?.standard_responses),
      handleSubmit: handleEmailSend,
      setEmailTemplate,
      type: EmailType.AGE_ISSUE,
      setEmailFrom,
      emailFrom,
      setEmailBodyInfo,
      schoolYearId: school_year_id,
      midYear: student?.applications?.at(-1)?.school_year_id === school_year_id ? student?.applications?.at(-1)?.midyear_application : false
    });
  }
  if (showSaveWarnModal)
    return /* @__PURE__ */ React.createElement(EnrollmentWarnSaveModal, {
      onClose: () => {
        setValue(EnrollmentPacketFormProperty.SHOW_VALIDATION_ERRORS, true);
        setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, false);
      },
      onSave: () => {
        onSubmit();
        setValue(EnrollmentPacketFormProperty.SHOW_VALIDATION_ERRORS, false);
        setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, false);
      }
    });
  if (missingInfoAlert) {
    return /* @__PURE__ */ React.createElement(Alert, {
      sx: {
        position: "relative",
        bottom: "25px",
        marginBottom: "15px"
      },
      onClose: () => {
        setValue(EnrollmentPacketFormProperty.MISSING_INFO_ALERT, false);
      },
      severity: "error"
    }, "This packet has not yet been submitted");
  }
  if (saveAlert && saveAlert.length || saveError) {
    return /* @__PURE__ */ React.createElement(Alert, {
      sx: {
        position: "relative",
        bottom: "25px",
        marginBottom: "15px"
      },
      onClose: () => {
        setValue(EnrollmentPacketFormProperty.SAVE_ALERT, "");
      },
      severity: saveError ? "error" : "success"
    }, saveError || saveAlert);
  }
  return /* @__PURE__ */ React.createElement("div", null);
};
