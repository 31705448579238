import {QUESTION_TYPE} from "../../core/enums/index.js";
export const QuestionTypes = [
  {
    value: QUESTION_TYPE.DROPDOWN,
    label: "Drop Down"
  },
  {
    value: QUESTION_TYPE.TEXTFIELD,
    label: "Text Field"
  },
  {
    value: QUESTION_TYPE.CHECKBOX,
    label: "Checkbox"
  },
  {
    value: QUESTION_TYPE.AGREEMENT,
    label: "Agreement"
  },
  {
    value: QUESTION_TYPE.MULTIPLECHOICES,
    label: "Multiple Choices"
  },
  {
    value: QUESTION_TYPE.CALENDAR,
    label: "Calendar"
  },
  {
    value: QUESTION_TYPE.INFORMATION,
    label: "Information"
  }
];
export var ValidationType;
(function(ValidationType2) {
  ValidationType2[ValidationType2["NONE"] = 0] = "NONE";
  ValidationType2[ValidationType2["NUMBER"] = 2] = "NUMBER";
  ValidationType2[ValidationType2["EMAIL"] = 1] = "EMAIL";
  ValidationType2[ValidationType2["PHONE"] = 3] = "PHONE";
  ValidationType2[ValidationType2["PHONEOPTIN"] = 4] = "PHONEOPTIN";
})(ValidationType || (ValidationType = {}));
export var ValidationMessage;
(function(ValidationMessage2) {
  ValidationMessage2["NONE"] = "";
  ValidationMessage2["NUMBER"] = "Please enter numbers only.";
  ValidationMessage2["EMAIL"] = "Please enter valid email address.";
  ValidationMessage2["PHONE"] = "Please enter valid phone number.";
  ValidationMessage2["PHONEOPTIN"] = "Please enter valid phone number.";
})(ValidationMessage || (ValidationMessage = {}));
export const defaultQuestions = [
  {
    question: "Parent Preferred First Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "parent_preferred_first_name"
  },
  {
    question: "Parent Preferred Last Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "parent_preferred_last_name"
  },
  {
    question: "Parent Legal First Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "parent_first_name"
  },
  {
    question: "Parent Legal Last Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "parent_last_name"
  },
  {
    question: "Secondary Parent First Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "packet_secondary_contact_first"
  },
  {
    question: "Secondary Parent Last Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "packet_secondary_contact_last"
  },
  {
    question: "Phone Number",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "parent_phone_number",
    validation: 3
  },
  {
    question: "Parent Email",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "parent_email",
    validation: 1
  },
  {
    question: "Student Legal First Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "student_first_name"
  },
  {
    question: "Student Legal Last Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "student_last_name"
  },
  {
    question: "Student Legal Middle Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "student_middle_name"
  },
  {
    question: "Student Preferred First Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "student_preferred_first_name"
  },
  {
    question: "Student Preferred Last Name",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "student_preferred_last_name"
  },
  {
    question: "Student Grade Level",
    type: QUESTION_TYPE.DROPDOWN,
    slug: "student_grade_level"
  },
  {
    question: "Street",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "address_street"
  },
  {
    question: "Street 2",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "address_street2"
  },
  {
    question: "City",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "address_city"
  },
  {
    question: "State",
    type: QUESTION_TYPE.DROPDOWN,
    slug: "address_state"
  },
  {
    question: "Zip Code",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "address_zip",
    validation: 2
  },
  {
    question: "Student Date of Birth",
    type: QUESTION_TYPE.CALENDAR,
    slug: "student_date_of_birth"
  },
  {
    question: "Student Gender",
    type: QUESTION_TYPE.MULTIPLECHOICES,
    slug: "student_gender"
  },
  {
    question: "School District",
    type: QUESTION_TYPE.DROPDOWN,
    slug: "address_school_district"
  },
  {
    question: "County",
    type: QUESTION_TYPE.DROPDOWN,
    slug: "address_county_id"
  },
  {
    question: "Parent Email Confirmation",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "parent_emailConfirm"
  },
  {
    question: "Student Email",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "student_email",
    validation: 1
  },
  {
    question: "Student Email Confirmation",
    type: QUESTION_TYPE.TEXTFIELD,
    slug: "student_emailConfirm",
    validation: 1
  }
];
