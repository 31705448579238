import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {EMPTY_STRING} from "../../constants/index.js";
import {PacketStatus, datePatterns} from "../../enums/index.js";
import {formatUtcToTzByPattern} from "../index.js";
export const checkIfPacketIsReEnroll = (studentId, students) => {
  if (students) {
    const studentPackets = students.find((student) => Number(student.student_id) === studentId)?.packets;
    if (!studentPackets)
      return false;
    const reEnrollPackets = studentPackets.filter((packet) => packet.status === PacketStatus.MISSING_INFO && Boolean(packet.reenroll));
    if (reEnrollPackets.length > 0)
      return true;
  }
  return false;
};
export const findAcceptedDateApplicationByPacket = (studentId, students, timezone) => {
  if (isNil(students) || students.length === 0)
    return EMPTY_STRING;
  const student = students.find((student2) => Number(student2.student_id) === studentId);
  if (isNil(student) || isNil(student.packets) || student.packets.length === 0 || isNil(student.applications) || student.applications.length === 0)
    return EMPTY_STRING;
  const studentApplications = student.applications;
  const studentPackets = student.packets;
  const packetToSubmit = studentPackets.find((packet) => packet.status === PacketStatus.NOT_STARTED || packet.status === PacketStatus.STARTED);
  if (isNil(packetToSubmit))
    return EMPTY_STRING;
  const applicationAcceptedDate = studentApplications.find((application) => Number(application.school_year_id) === Number(packetToSubmit.school_year_id))?.date_accepted;
  if (applicationAcceptedDate)
    return formatUtcToTzByPattern(applicationAcceptedDate, timezone, datePatterns.MONTH_DAY_4DYEAR);
  return EMPTY_STRING;
};
export function checkDeadline(deadline) {
  if (deadline) {
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear();
    const currentDateMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const currentDateDay = currentDate.getDate().toString().padStart(2, "0");
    const currentDateFormatted = `${currentDateYear}-${currentDateMonth}-${currentDateDay}`;
    const deadlineDate = new Date(deadline);
    const deadlineYear = deadlineDate.getFullYear();
    const deadlineMonth = (deadlineDate.getMonth() + 1).toString().padStart(2, "0");
    const deadlineDay = deadlineDate.getDate().toString().padStart(2, "0");
    const deadlineDateFormatted = `${deadlineYear}-${deadlineMonth}-${deadlineDay}`;
    return currentDateFormatted > deadlineDateFormatted;
  }
  return false;
}
