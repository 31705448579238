import {r as react} from "./index-c6c556cc.js";
const ThemeContext = /* @__PURE__ */ react.createContext(null);
var ThemeContext$1 = ThemeContext;
function useTheme$1() {
  const theme = react.useContext(ThemeContext$1);
  return theme;
}
function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
function useTheme(defaultTheme = null) {
  const contextTheme = useTheme$1();
  return !contextTheme || isObjectEmpty(contextTheme) ? defaultTheme : contextTheme;
}
export {ThemeContext$1 as T, useTheme$1 as a, useTheme as u};
