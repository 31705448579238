import {KINDERGARTEN_LABEL, PRE_KINDERGARTEN_LABEL} from "../constants/grades.constant.js";
export var StudentsGradeLevel;
(function(StudentsGradeLevel2) {
  StudentsGradeLevel2["K"] = "K";
  StudentsGradeLevel2[StudentsGradeLevel2["PRE_KINDERGARTEN"] = PRE_KINDERGARTEN_LABEL] = "PRE_KINDERGARTEN";
  StudentsGradeLevel2[StudentsGradeLevel2["KINDERGARTEN"] = KINDERGARTEN_LABEL] = "KINDERGARTEN";
  StudentsGradeLevel2["FIRST"] = "1";
  StudentsGradeLevel2["SECOND"] = "2";
  StudentsGradeLevel2["THIRD"] = "3";
  StudentsGradeLevel2["FOURTH"] = "4";
  StudentsGradeLevel2["FIFTH"] = "5";
  StudentsGradeLevel2["SIXTH"] = "6";
  StudentsGradeLevel2["SEVENTH"] = "7";
  StudentsGradeLevel2["EIGHTH"] = "8";
  StudentsGradeLevel2["NINTH"] = "9";
  StudentsGradeLevel2["TENTH"] = "10";
  StudentsGradeLevel2["ELEVENTH"] = "11";
  StudentsGradeLevel2["TWELFTH"] = "12";
  StudentsGradeLevel2["ALL"] = "all";
  StudentsGradeLevel2["FIRST_THRU_EIGHTH"] = "1-8";
  StudentsGradeLevel2["NINTH_THRU_TWELFTH"] = "9-12";
})(StudentsGradeLevel || (StudentsGradeLevel = {}));
export var Kindergarten;
(function(Kindergarten2) {
  Kindergarten2["K"] = "K";
  Kindergarten2["KINDERGARTEN"] = "Kindergarten";
})(Kindergarten || (Kindergarten = {}));
