import {_ as _objectWithoutPropertiesLoose} from "./objectWithoutPropertiesLoose-fe69c3b9.js";
import {_ as _extends} from "./extends-1379b0c5.js";
import {r as react} from "./index-c6c556cc.js";
import {c as clsx} from "./clsx.m-e1b0361e.js";
import {s as styled} from "./styled-d39d6e04.js";
import {u as useThemeProps} from "./useThemeProps-58507f80.js";
import {g as generateUtilityClasses} from "./generateUtilityClasses-f1d2e319.js";
import {g as generateUtilityClass, c as composeClasses} from "./generateUtilityClass-a8a7346c.js";
import {B as ButtonGroupContext} from "./ButtonGroupContext-4277550c.js";
import {j as jsxRuntime} from "./jsx-runtime-c4f7c71f.js";
import {b as alpha} from "./createTheme-1192a460.js";
import {c as capitalize} from "./createTheme-723fa293.js";
function getButtonGroupUtilityClass(slot) {
  return generateUtilityClass("MuiButtonGroup", slot);
}
const buttonGroupClasses = generateUtilityClasses("MuiButtonGroup", ["root", "contained", "outlined", "text", "disableElevation", "disabled", "fullWidth", "vertical", "grouped", "groupedHorizontal", "groupedVertical", "groupedText", "groupedTextHorizontal", "groupedTextVertical", "groupedTextPrimary", "groupedTextSecondary", "groupedOutlined", "groupedOutlinedHorizontal", "groupedOutlinedVertical", "groupedOutlinedPrimary", "groupedOutlinedSecondary", "groupedContained", "groupedContainedHorizontal", "groupedContainedVertical", "groupedContainedPrimary", "groupedContainedSecondary"]);
var buttonGroupClasses$1 = buttonGroupClasses;
const _excluded = ["children", "className", "color", "component", "disabled", "disableElevation", "disableFocusRipple", "disableRipple", "fullWidth", "orientation", "size", "variant"];
const overridesResolver = (props, styles) => {
  const {
    ownerState
  } = props;
  return [{
    [`& .${buttonGroupClasses$1.grouped}`]: styles.grouped
  }, {
    [`& .${buttonGroupClasses$1.grouped}`]: styles[`grouped${capitalize(ownerState.orientation)}`]
  }, {
    [`& .${buttonGroupClasses$1.grouped}`]: styles[`grouped${capitalize(ownerState.variant)}`]
  }, {
    [`& .${buttonGroupClasses$1.grouped}`]: styles[`grouped${capitalize(ownerState.variant)}${capitalize(ownerState.orientation)}`]
  }, {
    [`& .${buttonGroupClasses$1.grouped}`]: styles[`grouped${capitalize(ownerState.variant)}${capitalize(ownerState.color)}`]
  }, styles.root, styles[ownerState.variant], ownerState.disableElevation === true && styles.disableElevation, ownerState.fullWidth && styles.fullWidth, ownerState.orientation === "vertical" && styles.vertical];
};
const useUtilityClasses = (ownerState) => {
  const {
    classes,
    color,
    disabled,
    disableElevation,
    fullWidth,
    orientation,
    variant
  } = ownerState;
  const slots = {
    root: ["root", variant, orientation === "vertical" && "vertical", fullWidth && "fullWidth", disableElevation && "disableElevation"],
    grouped: ["grouped", `grouped${capitalize(orientation)}`, `grouped${capitalize(variant)}`, `grouped${capitalize(variant)}${capitalize(orientation)}`, `grouped${capitalize(variant)}${capitalize(color)}`, disabled && "disabled"]
  };
  return composeClasses(slots, getButtonGroupUtilityClass, classes);
};
const ButtonGroupRoot = styled("div", {
  name: "MuiButtonGroup",
  slot: "Root",
  overridesResolver
})(({
  theme,
  ownerState
}) => _extends({
  display: "inline-flex",
  borderRadius: (theme.vars || theme).shape.borderRadius
}, ownerState.variant === "contained" && {
  boxShadow: (theme.vars || theme).shadows[2]
}, ownerState.disableElevation && {
  boxShadow: "none"
}, ownerState.fullWidth && {
  width: "100%"
}, ownerState.orientation === "vertical" && {
  flexDirection: "column"
}, {
  [`& .${buttonGroupClasses$1.grouped}`]: _extends({
    minWidth: 40,
    "&:not(:first-of-type)": _extends({}, ownerState.orientation === "horizontal" && {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }, ownerState.orientation === "vertical" && {
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0
    }, ownerState.variant === "outlined" && ownerState.orientation === "horizontal" && {
      marginLeft: -1
    }, ownerState.variant === "outlined" && ownerState.orientation === "vertical" && {
      marginTop: -1
    }),
    "&:not(:last-of-type)": _extends({}, ownerState.orientation === "horizontal" && {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }, ownerState.orientation === "vertical" && {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    }, ownerState.variant === "text" && ownerState.orientation === "horizontal" && {
      borderRight: theme.vars ? `1px solid rgba(${theme.vars.palette.common.onBackgroundChannel} / 0.23)` : `1px solid ${theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)"}`
    }, ownerState.variant === "text" && ownerState.orientation === "vertical" && {
      borderBottom: theme.vars ? `1px solid rgba(${theme.vars.palette.common.onBackgroundChannel} / 0.23)` : `1px solid ${theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)"}`
    }, ownerState.variant === "text" && ownerState.color !== "inherit" && {
      borderColor: theme.vars ? `rgba(${theme.vars.palette[ownerState.color].mainChannel} / 0.5)` : alpha(theme.palette[ownerState.color].main, 0.5)
    }, ownerState.variant === "outlined" && ownerState.orientation === "horizontal" && {
      borderRightColor: "transparent"
    }, ownerState.variant === "outlined" && ownerState.orientation === "vertical" && {
      borderBottomColor: "transparent"
    }, ownerState.variant === "contained" && ownerState.orientation === "horizontal" && {
      borderRight: `1px solid ${(theme.vars || theme).palette.grey[400]}`,
      [`&.${buttonGroupClasses$1.disabled}`]: {
        borderRight: `1px solid ${(theme.vars || theme).palette.action.disabled}`
      }
    }, ownerState.variant === "contained" && ownerState.orientation === "vertical" && {
      borderBottom: `1px solid ${(theme.vars || theme).palette.grey[400]}`,
      [`&.${buttonGroupClasses$1.disabled}`]: {
        borderBottom: `1px solid ${(theme.vars || theme).palette.action.disabled}`
      }
    }, ownerState.variant === "contained" && ownerState.color !== "inherit" && {
      borderColor: (theme.vars || theme).palette[ownerState.color].dark
    }, {
      "&:hover": _extends({}, ownerState.variant === "outlined" && ownerState.orientation === "horizontal" && {
        borderRightColor: "currentColor"
      }, ownerState.variant === "outlined" && ownerState.orientation === "vertical" && {
        borderBottomColor: "currentColor"
      })
    }),
    "&:hover": _extends({}, ownerState.variant === "contained" && {
      boxShadow: "none"
    })
  }, ownerState.variant === "contained" && {
    boxShadow: "none"
  })
}));
const ButtonGroup = /* @__PURE__ */ react.forwardRef(function ButtonGroup2(inProps, ref) {
  const props = useThemeProps({
    props: inProps,
    name: "MuiButtonGroup"
  });
  const {
    children,
    className,
    color = "primary",
    component = "div",
    disabled = false,
    disableElevation = false,
    disableFocusRipple = false,
    disableRipple = false,
    fullWidth = false,
    orientation = "horizontal",
    size = "medium",
    variant = "outlined"
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded);
  const ownerState = _extends({}, props, {
    color,
    component,
    disabled,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    fullWidth,
    orientation,
    size,
    variant
  });
  const classes = useUtilityClasses(ownerState);
  const context = react.useMemo(() => ({
    className: classes.grouped,
    color,
    disabled,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    fullWidth,
    size,
    variant
  }), [color, disabled, disableElevation, disableFocusRipple, disableRipple, fullWidth, size, variant, classes.grouped]);
  return /* @__PURE__ */ jsxRuntime.jsx(ButtonGroupRoot, _extends({
    as: component,
    role: "group",
    className: clsx(classes.root, className),
    ref,
    ownerState
  }, other, {
    children: /* @__PURE__ */ jsxRuntime.jsx(ButtonGroupContext.Provider, {
      value: context,
      children
    })
  }));
});
var __pika_web_default_export_for_treeshaking__ = ButtonGroup;
export {__pika_web_default_export_for_treeshaking__ as _};
