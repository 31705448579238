import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {datePatterns} from "../../enums/index.js";
import {formatDateByPattern, utcDateFromString} from "../date/date.util.js";
export const getNextSchoolYear = (currentSchoolYear, schoolYears) => {
  const yearStringLength = 4;
  const nextYear = [];
  for (const selectedSchoolYear of currentSchoolYear) {
    const selectedYearNumber = Number(selectedSchoolYear.date_begin.slice(0, yearStringLength));
    for (const schoolYear of schoolYears) {
      const yearNumber = Number(schoolYear.date_begin.slice(0, yearStringLength));
      if (selectedYearNumber + 1 === yearNumber) {
        nextYear.push(schoolYear);
      }
    }
  }
  return nextYear;
};
export const schoolYearLabel = (schoolYear, beginFormat = datePatterns.FULL_YEAR, endFormat = datePatterns.SHORT_YEAR) => {
  if (schoolYear && schoolYear.date_begin && schoolYear.date_end) {
    const dateBegin = utcDateFromString(schoolYear?.date_begin);
    const dateEnd = utcDateFromString(schoolYear?.date_end);
    return `${formatDateByPattern(dateBegin, beginFormat)}-${formatDateByPattern(dateEnd, endFormat)}`;
  }
  return "";
};
export const schoolYearShortLabel = (schoolYear) => {
  return schoolYearLabel(schoolYear, datePatterns.SHORT_YEAR, datePatterns.SHORT_YEAR);
};
export const getActiveSchoolYear = (schoolYears) => {
  if (isNil(schoolYears) || schoolYears.length === 0)
    return void 0;
  const now = new Date();
  const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0));
  const activeSY = schoolYears.find((sy) => nowUTC.getTime() > utcDateFromString(sy.date_begin).getTime() && nowUTC.getTime() < utcDateFromString(sy.date_end).getTime());
  return activeSY ?? schoolYears.at(0);
};
