import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {GetCurrentSchoolYearByRegionId} from "../../components/announcements/services.js";
export const useCurrentSchoolYearByRegionId = (regionId) => {
  const {loading, data, error, refetch} = useQuery(GetCurrentSchoolYearByRegionId, {
    variables: {
      regionId
    },
    skip: !regionId,
    fetchPolicy: "network-only"
  });
  return {
    loading,
    data: data?.schoolyear_getcurrent,
    error,
    refetch
  };
};
