import {StudentsGradeLevel} from "../enums/grade-levels.enum.js";
export const GradeLevels = [
  StudentsGradeLevel.ALL,
  StudentsGradeLevel.PRE_KINDERGARTEN,
  StudentsGradeLevel.KINDERGARTEN,
  StudentsGradeLevel.FIRST,
  StudentsGradeLevel.SECOND,
  StudentsGradeLevel.THIRD,
  StudentsGradeLevel.FOURTH,
  StudentsGradeLevel.FIFTH,
  StudentsGradeLevel.SIXTH,
  StudentsGradeLevel.SEVENTH,
  StudentsGradeLevel.EIGHTH,
  StudentsGradeLevel.NINTH,
  StudentsGradeLevel.TENTH,
  StudentsGradeLevel.ELEVENTH,
  StudentsGradeLevel.TWELFTH
];
