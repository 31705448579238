export var ResourceRequestAction;
(function(ResourceRequestAction2) {
  ResourceRequestAction2[ResourceRequestAction2["ACCEPTED_EMAIL"] = 1] = "ACCEPTED_EMAIL";
  ResourceRequestAction2[ResourceRequestAction2["ACCEPTED"] = 2] = "ACCEPTED";
  ResourceRequestAction2[ResourceRequestAction2["NO_ACTION"] = 3] = "NO_ACTION";
})(ResourceRequestAction || (ResourceRequestAction = {}));
export var ResourceRequestText;
(function(ResourceRequestText2) {
  ResourceRequestText2["ACCEPTED_EMAIL"] = "Mark Request as Accepted and Email the users";
  ResourceRequestText2["ACCEPTED"] = "Mark Request as Accepted";
  ResourceRequestText2["DEDUCTION"] = "Create Direct Deduction for Resources with a cost";
  ResourceRequestText2["PROCEED"] = "How would you like to proceed?";
})(ResourceRequestText || (ResourceRequestText = {}));
