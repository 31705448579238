export var PROGRAM_YEAR_STATUS_LABEL;
(function(PROGRAM_YEAR_STATUS_LABEL2) {
  PROGRAM_YEAR_STATUS_LABEL2["NEW"] = "New";
  PROGRAM_YEAR_STATUS_LABEL2["RETURNING"] = "Returning";
  PROGRAM_YEAR_STATUS_LABEL2["TRANSFERRED"] = "Transferred";
  PROGRAM_YEAR_STATUS_LABEL2["SIBLING"] = "Sibling";
})(PROGRAM_YEAR_STATUS_LABEL || (PROGRAM_YEAR_STATUS_LABEL = {}));
export var PROGRAM_YEAR_STATUS_VALUE;
(function(PROGRAM_YEAR_STATUS_VALUE2) {
  PROGRAM_YEAR_STATUS_VALUE2[PROGRAM_YEAR_STATUS_VALUE2["NEW"] = 0] = "NEW";
  PROGRAM_YEAR_STATUS_VALUE2[PROGRAM_YEAR_STATUS_VALUE2["RETURNING"] = 1] = "RETURNING";
  PROGRAM_YEAR_STATUS_VALUE2[PROGRAM_YEAR_STATUS_VALUE2["TRANSFERRED"] = 2] = "TRANSFERRED";
  PROGRAM_YEAR_STATUS_VALUE2[PROGRAM_YEAR_STATUS_VALUE2["SIBLING"] = 3] = "SIBLING";
})(PROGRAM_YEAR_STATUS_VALUE || (PROGRAM_YEAR_STATUS_VALUE = {}));
