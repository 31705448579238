import {B as Batch, c as createHttpRequest, a as createFlushController, b as arrayFrom, v as validateAndBuildConfiguration, o as objectValues, i as includes, C as ConsoleApiName, d as assign, e as display, O as ONE_KIBI_BYTE, R as RawReportType, s as serializeConfiguration, E as ErrorSource, f as computeStackTrace, g as computeRawError, h as clocksNow, j as sanitize, k as combine, m as monitored, l as createContextManager, n as BoundedBuffer, p as makePublicApi, q as monitor, r as deepClone, t as timeStampNow, u as canUseEventBridge, w as checkUser, x as sanitizeUser, y as startSessionManager, z as Observable, A as performDraw, D as createEventRateLimiter, F as getRelativeTime, G as isEmptyObject, H as willSyntheticsInjectRum, I as addTelemetryDebug, J as getSyntheticsTestId, K as getSyntheticsResultId, L as initConsoleObservable, M as initReportObservable, N as getFileFromStackTraceString, P as noop, Q as initXhrObservable, S as initFetchObservable, T as isServerError, U as toStackTraceString, V as tryToClone, W as readBytesFromStream, X as trackRuntimeError, Y as AbstractLifeCycle, Z as getEventBridge, _ as sendToExtension, $ as createPageExitObservable, a0 as addTelemetryConfiguration, a1 as startTelemetry, a2 as isTelemetryReplicationAllowed, a3 as defineGlobal, a4 as getGlobalObject} from "../common/user-8744800c.js";
function startBatchWithReplica(configuration, endpoint, reportError, pageExitObservable, sessionExpireObservable, replicaEndpoint) {
  var primaryBatch = createBatch(endpoint);
  var replicaBatch;
  if (replicaEndpoint) {
    replicaBatch = createBatch(replicaEndpoint);
  }
  function createBatch(endpointBuilder) {
    return new Batch(createHttpRequest(endpointBuilder, configuration.batchBytesLimit, reportError), createFlushController({
      messagesLimit: configuration.batchMessagesLimit,
      bytesLimit: configuration.batchBytesLimit,
      durationLimit: configuration.flushTimeout,
      pageExitObservable,
      sessionExpireObservable
    }), configuration.messageBytesLimit);
  }
  return {
    add: function(message, replicated) {
      if (replicated === void 0) {
        replicated = true;
      }
      primaryBatch.add(message);
      if (replicaBatch && replicated) {
        replicaBatch.add(message);
      }
    }
  };
}
function removeDuplicates(array) {
  var set = new Set();
  array.forEach(function(item) {
    return set.add(item);
  });
  return arrayFrom(set);
}
var DEFAULT_REQUEST_ERROR_RESPONSE_LENGTH_LIMIT = 32 * ONE_KIBI_BYTE;
function validateAndBuildLogsConfiguration(initConfiguration) {
  var baseConfiguration = validateAndBuildConfiguration(initConfiguration);
  var forwardConsoleLogs = validateAndBuildForwardOption(initConfiguration.forwardConsoleLogs, objectValues(ConsoleApiName), "Forward Console Logs");
  var forwardReports = validateAndBuildForwardOption(initConfiguration.forwardReports, objectValues(RawReportType), "Forward Reports");
  if (!baseConfiguration || !forwardConsoleLogs || !forwardReports) {
    return;
  }
  if (initConfiguration.forwardErrorsToLogs && !includes(forwardConsoleLogs, ConsoleApiName.error)) {
    forwardConsoleLogs.push(ConsoleApiName.error);
  }
  return assign({
    forwardErrorsToLogs: initConfiguration.forwardErrorsToLogs !== false,
    forwardConsoleLogs,
    forwardReports,
    requestErrorResponseLengthLimit: DEFAULT_REQUEST_ERROR_RESPONSE_LENGTH_LIMIT
  }, baseConfiguration);
}
function validateAndBuildForwardOption(option, allowedValues, label) {
  if (option === void 0) {
    return [];
  }
  if (!(option === "all" || Array.isArray(option) && option.every(function(api) {
    return includes(allowedValues, api);
  }))) {
    display.error("".concat(label, ' should be "all" or an array with allowed values "').concat(allowedValues.join('", "'), '"'));
    return;
  }
  return option === "all" ? allowedValues : removeDuplicates(option);
}
function serializeLogsConfiguration(configuration) {
  var baseSerializedInitConfiguration = serializeConfiguration(configuration);
  return assign({
    forward_errors_to_logs: configuration.forwardErrorsToLogs,
    forward_console_logs: configuration.forwardConsoleLogs,
    forward_reports: configuration.forwardReports
  }, baseSerializedInitConfiguration);
}
var __decorate = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var StatusType = {
  debug: "debug",
  error: "error",
  info: "info",
  warn: "warn"
};
var HandlerType = {
  console: "console",
  http: "http",
  silent: "silent"
};
var STATUSES = Object.keys(StatusType);
var Logger = function() {
  function Logger2(handleLogStrategy, name, handlerType, level, loggerContext) {
    if (handlerType === void 0) {
      handlerType = HandlerType.http;
    }
    if (level === void 0) {
      level = StatusType.debug;
    }
    if (loggerContext === void 0) {
      loggerContext = {};
    }
    this.handleLogStrategy = handleLogStrategy;
    this.handlerType = handlerType;
    this.level = level;
    this.contextManager = createContextManager("logger context");
    this.contextManager.set(assign({}, loggerContext, name ? {logger: {name}} : void 0));
  }
  Logger2.prototype.log = function(message, messageContext, status, error) {
    if (status === void 0) {
      status = StatusType.info;
    }
    var errorContext;
    if (status === StatusType.error) {
      errorContext = {origin: ErrorSource.LOGGER};
    }
    if (error !== void 0 && error !== null) {
      var stackTrace = error instanceof Error ? computeStackTrace(error) : void 0;
      var rawError = computeRawError({
        stackTrace,
        originalError: error,
        nonErrorPrefix: "Provided",
        source: ErrorSource.LOGGER,
        handling: "handled",
        startClocks: clocksNow()
      });
      errorContext = {
        origin: ErrorSource.LOGGER,
        stack: rawError.stack,
        kind: rawError.type,
        message: rawError.message
      };
    }
    var sanitizedMessageContext = sanitize(messageContext);
    var context = errorContext ? combine({error: errorContext}, sanitizedMessageContext) : sanitizedMessageContext;
    this.handleLogStrategy({
      message: sanitize(message),
      context,
      status
    }, this);
  };
  Logger2.prototype.debug = function(message, messageContext, error) {
    this.log(message, messageContext, StatusType.debug, error);
  };
  Logger2.prototype.info = function(message, messageContext, error) {
    this.log(message, messageContext, StatusType.info, error);
  };
  Logger2.prototype.warn = function(message, messageContext, error) {
    this.log(message, messageContext, StatusType.warn, error);
  };
  Logger2.prototype.error = function(message, messageContext, error) {
    this.log(message, messageContext, StatusType.error, error);
  };
  Logger2.prototype.setContext = function(context) {
    this.contextManager.set(context);
  };
  Logger2.prototype.getContext = function() {
    return this.contextManager.get();
  };
  Logger2.prototype.addContext = function(key, value) {
    this.contextManager.add(key, value);
  };
  Logger2.prototype.removeContext = function(key) {
    this.contextManager.remove(key);
  };
  Logger2.prototype.setHandler = function(handler) {
    this.handlerType = handler;
  };
  Logger2.prototype.getHandler = function() {
    return this.handlerType;
  };
  Logger2.prototype.setLevel = function(level) {
    this.level = level;
  };
  Logger2.prototype.getLevel = function() {
    return this.level;
  };
  __decorate([
    monitored
  ], Logger2.prototype, "log", null);
  return Logger2;
}();
function makeLogsPublicApi(startLogsImpl) {
  var isAlreadyInitialized = false;
  var globalContextManager = createContextManager("global context");
  var userContextManager = createContextManager("user");
  var customLoggers = {};
  var getInternalContextStrategy = function() {
    return void 0;
  };
  var beforeInitLoggerLog = new BoundedBuffer();
  var handleLogStrategy = function(logsMessage, logger, savedCommonContext, date) {
    if (savedCommonContext === void 0) {
      savedCommonContext = deepClone(buildCommonContext());
    }
    if (date === void 0) {
      date = timeStampNow();
    }
    beforeInitLoggerLog.add(function() {
      return handleLogStrategy(logsMessage, logger, savedCommonContext, date);
    });
  };
  var getInitConfigurationStrategy = function() {
    return void 0;
  };
  var mainLogger = new Logger(function() {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      params[_i] = arguments[_i];
    }
    return handleLogStrategy.apply(void 0, params);
  });
  function buildCommonContext() {
    return {
      view: {
        referrer: document.referrer,
        url: window.location.href
      },
      context: globalContextManager.getContext(),
      user: userContextManager.getContext()
    };
  }
  return makePublicApi({
    logger: mainLogger,
    init: monitor(function(initConfiguration) {
      var _a2;
      getInitConfigurationStrategy = function() {
        return deepClone(initConfiguration);
      };
      if (canUseEventBridge()) {
        initConfiguration = overrideInitConfigurationForBridge(initConfiguration);
      }
      if (!canInitLogs(initConfiguration)) {
        return;
      }
      var configuration = validateAndBuildLogsConfiguration(initConfiguration);
      if (!configuration) {
        return;
      }
      _a2 = startLogsImpl(initConfiguration, configuration, buildCommonContext, mainLogger), handleLogStrategy = _a2.handleLog, getInternalContextStrategy = _a2.getInternalContext;
      beforeInitLoggerLog.drain();
      isAlreadyInitialized = true;
    }),
    getLoggerGlobalContext: monitor(globalContextManager.get),
    getGlobalContext: monitor(globalContextManager.getContext),
    setLoggerGlobalContext: monitor(globalContextManager.set),
    setGlobalContext: monitor(globalContextManager.setContext),
    addLoggerGlobalContext: monitor(globalContextManager.add),
    setGlobalContextProperty: monitor(globalContextManager.setContextProperty),
    removeLoggerGlobalContext: monitor(globalContextManager.remove),
    removeGlobalContextProperty: monitor(globalContextManager.removeContextProperty),
    clearGlobalContext: monitor(globalContextManager.clearContext),
    createLogger: monitor(function(name, conf) {
      if (conf === void 0) {
        conf = {};
      }
      customLoggers[name] = new Logger(function() {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          params[_i] = arguments[_i];
        }
        return handleLogStrategy.apply(void 0, params);
      }, sanitize(name), conf.handler, conf.level, sanitize(conf.context));
      return customLoggers[name];
    }),
    getLogger: monitor(function(name) {
      return customLoggers[name];
    }),
    getInitConfiguration: monitor(function() {
      return getInitConfigurationStrategy();
    }),
    getInternalContext: monitor(function(startTime) {
      return getInternalContextStrategy(startTime);
    }),
    setUser: monitor(function(newUser) {
      if (checkUser(newUser)) {
        userContextManager.setContext(sanitizeUser(newUser));
      }
    }),
    getUser: monitor(userContextManager.getContext),
    setUserProperty: monitor(function(key, property) {
      var _a2;
      var sanitizedProperty = sanitizeUser((_a2 = {}, _a2[key] = property, _a2))[key];
      userContextManager.setContextProperty(key, sanitizedProperty);
    }),
    removeUserProperty: monitor(userContextManager.removeContextProperty),
    clearUser: monitor(userContextManager.clearContext)
  });
  function overrideInitConfigurationForBridge(initConfiguration) {
    return assign({}, initConfiguration, {clientToken: "empty"});
  }
  function canInitLogs(initConfiguration) {
    if (isAlreadyInitialized) {
      if (!initConfiguration.silentMultipleInit) {
        display.error("DD_LOGS is already initialized.");
      }
      return false;
    }
    return true;
  }
}
var LOGS_SESSION_KEY = "logs";
function startLogsSessionManager(configuration) {
  var sessionManager = startSessionManager(configuration.sessionStoreStrategyType, LOGS_SESSION_KEY, function(rawTrackingType) {
    return computeSessionState(configuration, rawTrackingType);
  });
  return {
    findTrackedSession: function(startTime) {
      var session = sessionManager.findActiveSession(startTime);
      return session && session.trackingType === "1" ? {
        id: session.id
      } : void 0;
    },
    expireObservable: sessionManager.expireObservable
  };
}
function startLogsSessionManagerStub(configuration) {
  var isTracked = computeTrackingType(configuration) === "1";
  var session = isTracked ? {} : void 0;
  return {
    findTrackedSession: function() {
      return session;
    },
    expireObservable: new Observable()
  };
}
function computeTrackingType(configuration) {
  if (!performDraw(configuration.sessionSampleRate)) {
    return "0";
  }
  return "1";
}
function computeSessionState(configuration, rawSessionType) {
  var trackingType = hasValidLoggerSession(rawSessionType) ? rawSessionType : computeTrackingType(configuration);
  return {
    trackingType,
    isTracked: trackingType === "1"
  };
}
function hasValidLoggerSession(trackingType) {
  return trackingType === "0" || trackingType === "1";
}
var _a$2;
var STATUS_PRIORITIES = (_a$2 = {}, _a$2[StatusType.debug] = 0, _a$2[StatusType.info] = 1, _a$2[StatusType.warn] = 2, _a$2[StatusType.error] = 3, _a$2);
function startLoggerCollection(lifeCycle) {
  function handleLog(logsMessage, logger, savedCommonContext, savedDate) {
    var messageContext = logsMessage.context;
    if (isAuthorized(logsMessage.status, HandlerType.console, logger)) {
      display(logsMessage.status, logsMessage.message, combine(logger.getContext(), messageContext));
    }
    lifeCycle.notify(0, {
      rawLogsEvent: {
        date: savedDate || timeStampNow(),
        message: logsMessage.message,
        status: logsMessage.status,
        origin: ErrorSource.LOGGER
      },
      messageContext,
      savedCommonContext,
      logger
    });
  }
  return {
    handleLog
  };
}
function isAuthorized(status, handlerType, logger) {
  var loggerHandler = logger.getHandler();
  var sanitizedHandlerType = Array.isArray(loggerHandler) ? loggerHandler : [loggerHandler];
  return STATUS_PRIORITIES[status] >= STATUS_PRIORITIES[logger.getLevel()] && includes(sanitizedHandlerType, handlerType);
}
function startLogsAssembly(sessionManager, configuration, lifeCycle, buildCommonContext, mainLogger, reportError) {
  var statusWithCustom = STATUSES.concat(["custom"]);
  var logRateLimiters = {};
  statusWithCustom.forEach(function(status) {
    logRateLimiters[status] = createEventRateLimiter(status, configuration.eventRateLimiterThreshold, reportError);
  });
  lifeCycle.subscribe(0, function(_a2) {
    var _b, _c, _d;
    var rawLogsEvent = _a2.rawLogsEvent, _e = _a2.messageContext, messageContext = _e === void 0 ? void 0 : _e, _f = _a2.savedCommonContext, savedCommonContext = _f === void 0 ? void 0 : _f, _g = _a2.logger, logger = _g === void 0 ? mainLogger : _g;
    var startTime = getRelativeTime(rawLogsEvent.date);
    var session = sessionManager.findTrackedSession(startTime);
    if (!session) {
      return;
    }
    var commonContext = savedCommonContext || buildCommonContext();
    var log = combine({
      service: configuration.service,
      session_id: session.id,
      usr: !isEmptyObject(commonContext.user) ? commonContext.user : void 0,
      view: commonContext.view
    }, commonContext.context, getRUMInternalContext(startTime), rawLogsEvent, logger.getContext(), messageContext);
    if (!isAuthorized(rawLogsEvent.status, HandlerType.http, logger) || ((_b = configuration.beforeSend) === null || _b === void 0 ? void 0 : _b.call(configuration, log)) === false || ((_c = log.error) === null || _c === void 0 ? void 0 : _c.origin) !== ErrorSource.AGENT && ((_d = logRateLimiters[log.status]) !== null && _d !== void 0 ? _d : logRateLimiters["custom"]).isLimitReached()) {
      return;
    }
    lifeCycle.notify(1, log);
  });
}
var logsSentBeforeRumInjectionTelemetryAdded = false;
function getRUMInternalContext(startTime) {
  var browserWindow = window;
  if (willSyntheticsInjectRum()) {
    var context = getInternalContextFromRumGlobal(browserWindow.DD_RUM_SYNTHETICS);
    if (!context && !logsSentBeforeRumInjectionTelemetryAdded) {
      logsSentBeforeRumInjectionTelemetryAdded = true;
      addTelemetryDebug("Logs sent before RUM is injected by the synthetics worker", {
        testId: getSyntheticsTestId(),
        resultId: getSyntheticsResultId()
      });
    }
    return context;
  }
  return getInternalContextFromRumGlobal(browserWindow.DD_RUM);
  function getInternalContextFromRumGlobal(rumGlobal) {
    if (rumGlobal && rumGlobal.getInternalContext) {
      return rumGlobal.getInternalContext(startTime);
    }
  }
}
var _a$1;
var LogStatusForApi = (_a$1 = {}, _a$1[ConsoleApiName.log] = StatusType.info, _a$1[ConsoleApiName.debug] = StatusType.debug, _a$1[ConsoleApiName.info] = StatusType.info, _a$1[ConsoleApiName.warn] = StatusType.warn, _a$1[ConsoleApiName.error] = StatusType.error, _a$1);
function startConsoleCollection(configuration, lifeCycle) {
  var consoleSubscription = initConsoleObservable(configuration.forwardConsoleLogs).subscribe(function(log) {
    lifeCycle.notify(0, {
      rawLogsEvent: {
        date: timeStampNow(),
        message: log.message,
        origin: ErrorSource.CONSOLE,
        error: log.api === ConsoleApiName.error ? {
          origin: ErrorSource.CONSOLE,
          stack: log.stack,
          fingerprint: log.fingerprint
        } : void 0,
        status: LogStatusForApi[log.api]
      }
    });
  });
  return {
    stop: function() {
      consoleSubscription.unsubscribe();
    }
  };
}
var _a;
var LogStatusForReport = (_a = {}, _a[RawReportType.cspViolation] = StatusType.error, _a[RawReportType.intervention] = StatusType.error, _a[RawReportType.deprecation] = StatusType.warn, _a);
function startReportCollection(configuration, lifeCycle) {
  var reportSubscription = initReportObservable(configuration.forwardReports).subscribe(function(report) {
    var message = report.message;
    var status = LogStatusForReport[report.type];
    var error;
    if (status === StatusType.error) {
      error = {
        kind: report.subtype,
        origin: ErrorSource.REPORT,
        stack: report.stack
      };
    } else if (report.stack) {
      message += " Found in ".concat(getFileFromStackTraceString(report.stack));
    }
    lifeCycle.notify(0, {
      rawLogsEvent: {
        date: timeStampNow(),
        message,
        origin: ErrorSource.REPORT,
        error,
        status
      }
    });
  });
  return {
    stop: function() {
      reportSubscription.unsubscribe();
    }
  };
}
function startNetworkErrorCollection(configuration, lifeCycle) {
  if (!configuration.forwardErrorsToLogs) {
    return {stop: noop};
  }
  var xhrSubscription = initXhrObservable().subscribe(function(context) {
    if (context.state === "complete") {
      handleResponse("xhr", context);
    }
  });
  var fetchSubscription = initFetchObservable().subscribe(function(context) {
    if (context.state === "resolve") {
      handleResponse("fetch", context);
    }
  });
  function handleResponse(type, request) {
    if (!configuration.isIntakeUrl(request.url) && (isRejected(request) || isServerError(request.status))) {
      if ("xhr" in request) {
        computeXhrResponseData(request.xhr, configuration, onResponseDataAvailable);
      } else if (request.response) {
        computeFetchResponseText(request.response, configuration, onResponseDataAvailable);
      } else if (request.error) {
        computeFetchErrorText(request.error, configuration, onResponseDataAvailable);
      }
    }
    function onResponseDataAvailable(responseData) {
      lifeCycle.notify(0, {
        rawLogsEvent: {
          message: "".concat(format(type), " error ").concat(request.method, " ").concat(request.url),
          date: request.startClocks.timeStamp,
          error: {
            origin: ErrorSource.NETWORK,
            stack: responseData || "Failed to load"
          },
          http: {
            method: request.method,
            status_code: request.status,
            url: request.url
          },
          status: StatusType.error,
          origin: ErrorSource.NETWORK
        }
      });
    }
  }
  return {
    stop: function() {
      xhrSubscription.unsubscribe();
      fetchSubscription.unsubscribe();
    }
  };
}
function computeXhrResponseData(xhr, configuration, callback) {
  if (typeof xhr.response === "string") {
    callback(truncateResponseText(xhr.response, configuration));
  } else {
    callback(xhr.response);
  }
}
function computeFetchErrorText(error, configuration, callback) {
  callback(truncateResponseText(toStackTraceString(computeStackTrace(error)), configuration));
}
function computeFetchResponseText(response, configuration, callback) {
  var clonedResponse = tryToClone(response);
  if (!clonedResponse || !clonedResponse.body) {
    callback();
  } else if (!window.TextDecoder) {
    clonedResponse.text().then(monitor(function(text) {
      return callback(truncateResponseText(text, configuration));
    }), monitor(function(error) {
      return callback("Unable to retrieve response: ".concat(error));
    }));
  } else {
    truncateResponseStream(clonedResponse.body, configuration.requestErrorResponseLengthLimit, function(error, responseText) {
      if (error) {
        callback("Unable to retrieve response: ".concat(error));
      } else {
        callback(responseText);
      }
    });
  }
}
function isRejected(request) {
  return request.status === 0 && request.responseType !== "opaque";
}
function truncateResponseText(responseText, configuration) {
  if (responseText.length > configuration.requestErrorResponseLengthLimit) {
    return "".concat(responseText.substring(0, configuration.requestErrorResponseLengthLimit), "...");
  }
  return responseText;
}
function format(type) {
  if (type === "xhr") {
    return "XHR";
  }
  return "Fetch";
}
function truncateResponseStream(stream, bytesLimit, callback) {
  readBytesFromStream(stream, function(error, bytes, limitExceeded) {
    if (error) {
      callback(error);
    } else {
      var responseText = new TextDecoder().decode(bytes);
      if (limitExceeded) {
        responseText += "...";
      }
      callback(void 0, responseText);
    }
  }, {
    bytesLimit,
    collectStreamBody: true
  });
}
function startRuntimeErrorCollection(configuration, lifeCycle) {
  if (!configuration.forwardErrorsToLogs) {
    return {stop: noop};
  }
  var rawErrorObservable = new Observable();
  var stopRuntimeErrorTracking = trackRuntimeError(rawErrorObservable).stop;
  var rawErrorSubscription = rawErrorObservable.subscribe(function(rawError) {
    lifeCycle.notify(0, {
      rawLogsEvent: {
        message: rawError.message,
        date: rawError.startClocks.timeStamp,
        error: {
          kind: rawError.type,
          origin: ErrorSource.SOURCE,
          stack: rawError.stack
        },
        origin: ErrorSource.SOURCE,
        status: StatusType.error
      }
    });
  });
  return {
    stop: function() {
      stopRuntimeErrorTracking();
      rawErrorSubscription.unsubscribe();
    }
  };
}
var LifeCycle = AbstractLifeCycle;
function startLogsBatch(configuration, lifeCycle, reportError, pageExitObservable, sessionExpireObservable) {
  var _a2;
  var batch = startBatchWithReplica(configuration, configuration.logsEndpointBuilder, reportError, pageExitObservable, sessionExpireObservable, (_a2 = configuration.replica) === null || _a2 === void 0 ? void 0 : _a2.logsEndpointBuilder);
  lifeCycle.subscribe(1, function(serverLogsEvent) {
    batch.add(serverLogsEvent);
  });
}
function startLogsBridge(lifeCycle) {
  var bridge = getEventBridge();
  lifeCycle.subscribe(1, function(serverLogsEvent) {
    bridge.send("log", serverLogsEvent);
  });
}
function startInternalContext(sessionManager) {
  return {
    get: function(startTime) {
      var trackedSession = sessionManager.findTrackedSession(startTime);
      if (trackedSession) {
        return {
          session_id: trackedSession.id
        };
      }
    }
  };
}
function startLogs(initConfiguration, configuration, buildCommonContext, mainLogger) {
  var lifeCycle = new LifeCycle();
  lifeCycle.subscribe(1, function(log) {
    return sendToExtension("logs", log);
  });
  var reportError = function(error) {
    lifeCycle.notify(0, {
      rawLogsEvent: {
        message: error.message,
        date: error.startClocks.timeStamp,
        error: {
          origin: ErrorSource.AGENT
        },
        origin: ErrorSource.AGENT,
        status: StatusType.error
      }
    });
    addTelemetryDebug("Error reported to customer", {"error.message": error.message});
  };
  var pageExitObservable = createPageExitObservable();
  var session = configuration.sessionStoreStrategyType && !canUseEventBridge() && !willSyntheticsInjectRum() ? startLogsSessionManager(configuration) : startLogsSessionManagerStub(configuration);
  var telemetry = startLogsTelemetry(configuration, reportError, pageExitObservable, session.expireObservable);
  telemetry.setContextProvider(function() {
    var _a2, _b, _c, _d, _e, _f;
    return {
      application: {
        id: (_a2 = getRUMInternalContext()) === null || _a2 === void 0 ? void 0 : _a2.application_id
      },
      session: {
        id: (_b = session.findTrackedSession()) === null || _b === void 0 ? void 0 : _b.id
      },
      view: {
        id: (_d = (_c = getRUMInternalContext()) === null || _c === void 0 ? void 0 : _c.view) === null || _d === void 0 ? void 0 : _d.id
      },
      action: {
        id: (_f = (_e = getRUMInternalContext()) === null || _e === void 0 ? void 0 : _e.user_action) === null || _f === void 0 ? void 0 : _f.id
      }
    };
  });
  startNetworkErrorCollection(configuration, lifeCycle);
  startRuntimeErrorCollection(configuration, lifeCycle);
  startConsoleCollection(configuration, lifeCycle);
  startReportCollection(configuration, lifeCycle);
  var handleLog = startLoggerCollection(lifeCycle).handleLog;
  startLogsAssembly(session, configuration, lifeCycle, buildCommonContext, mainLogger, reportError);
  if (!canUseEventBridge()) {
    startLogsBatch(configuration, lifeCycle, reportError, pageExitObservable, session.expireObservable);
  } else {
    startLogsBridge(lifeCycle);
  }
  addTelemetryConfiguration(serializeLogsConfiguration(initConfiguration));
  var internalContext = startInternalContext(session);
  return {
    handleLog,
    getInternalContext: internalContext.get
  };
}
function startLogsTelemetry(configuration, reportError, pageExitObservable, sessionExpireObservable) {
  var _a2;
  var telemetry = startTelemetry("browser-logs-sdk", configuration);
  if (canUseEventBridge()) {
    var bridge_1 = getEventBridge();
    telemetry.observable.subscribe(function(event) {
      return bridge_1.send("internal_telemetry", event);
    });
  } else {
    var telemetryBatch_1 = startBatchWithReplica(configuration, configuration.rumEndpointBuilder, reportError, pageExitObservable, sessionExpireObservable, (_a2 = configuration.replica) === null || _a2 === void 0 ? void 0 : _a2.rumEndpointBuilder);
    telemetry.observable.subscribe(function(event) {
      return telemetryBatch_1.add(event, isTelemetryReplicationAllowed(configuration));
    });
  }
  return telemetry;
}
var datadogLogs = makeLogsPublicApi(startLogs);
defineGlobal(getGlobalObject(), "DD_LOGS", datadogLogs);
export {datadogLogs};
