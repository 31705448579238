import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import dayjs from "../../../_snowpack/pkg/dayjs.js";
import {sortBy} from "../../../_snowpack/pkg/lodash.js";
import {getReimbursementSchoolYearsByRegionId} from "../../../graphql/queries/school-year.js";
export const useReimbursementRequestSchoolYears = (regionId) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState(0);
  const {loading, data, error} = useQuery(getReimbursementSchoolYearsByRegionId, {
    variables: {
      regionId
    },
    skip: !regionId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data?.reimbursementRequestSchoolYears) {
      const schoolYearsArray = [];
      data.reimbursementRequestSchoolYears.map((item) => {
        if (new Date(item.date_begin) <= new Date() && new Date(item.date_end) >= new Date())
          setSelectedYearId(+item?.school_year_id);
        schoolYearsArray.push({
          label: `${dayjs(item.date_begin).format("YYYY")}-${dayjs(item.date_end).format("YY")} Summary`,
          value: item.school_year_id
        });
      });
      setDropdownItems(sortBy(schoolYearsArray, "label"));
    }
  }, [loading, data]);
  return {loading, reimbursementSchoolYears: dropdownItems, selectedYearId, setSelectedYearId, error};
};
