import {useCallback, useEffect} from "../../../_snowpack/pkg/react.js";
import {useRecoilState} from "../../../_snowpack/pkg/recoil.js";
import {reloadComponentState} from "../../../providers/Store/State.js";
export function useReload() {
  const [reloading, setReloading] = useRecoilState(reloadComponentState);
  const reload = useCallback(() => {
    setReloading(true);
  }, [setReloading]);
  useEffect(() => {
    if (reloading) {
      setReloading(false);
    }
  }, [reloading, setReloading]);
  return [reload, reloading];
}
