import {MthTitle} from "./title.enum.js";
export var ReimbursementFormType;
(function(ReimbursementFormType2) {
  ReimbursementFormType2["CUSTOM_BUILT"] = "CUSTOM_BUILT";
  ReimbursementFormType2["TECHNOLOGY"] = "TECHNOLOGY";
  ReimbursementFormType2["THIRD_PARTY_PROVIDER"] = "THIRD_PARTY_PROVIDER";
  ReimbursementFormType2["SUPPLEMENTAL"] = "SUPPLEMENTAL";
  ReimbursementFormType2["REQUIRED_SOFTWARE"] = "REQUIRED_SOFTWARE";
  ReimbursementFormType2["NONE"] = "NONE";
})(ReimbursementFormType || (ReimbursementFormType = {}));
export const ReimbursementValues = {
  [MthTitle.CUSTOM_BUILT]: ReimbursementFormType.CUSTOM_BUILT,
  [MthTitle.TECHNOLOGY_ALLOWANCE]: ReimbursementFormType.TECHNOLOGY,
  [MthTitle.THIRD_PARTY_PROVIDER]: ReimbursementFormType.THIRD_PARTY_PROVIDER,
  [MthTitle.SUPPLEMENTAL_LEARNING_FUNDS]: ReimbursementFormType.SUPPLEMENTAL,
  [MthTitle.REQUIRED_SOFTWARE]: ReimbursementFormType.REQUIRED_SOFTWARE
};
export const ReimbursementKeyValues = {
  [ReimbursementFormType.CUSTOM_BUILT]: MthTitle.CUSTOM_BUILT,
  [ReimbursementFormType.TECHNOLOGY]: MthTitle.TECHNOLOGY_ALLOWANCE,
  [ReimbursementFormType.THIRD_PARTY_PROVIDER]: MthTitle.THIRD_PARTY_PROVIDER,
  [ReimbursementFormType.SUPPLEMENTAL]: MthTitle.SUPPLEMENTAL_LEARNING_FUNDS,
  [ReimbursementFormType.REQUIRED_SOFTWARE]: MthTitle.REQUIRED_SOFTWARE
};
