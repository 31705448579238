import React from "../../_snowpack/pkg/react.js";
import {Divider, ListItem, ListItemText} from "../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../_snowpack/pkg/@mui/system.js";
import {MthColor} from "../../core/enums/index.js";
export const Metadata = ({
  image,
  title,
  subtitle,
  secondaryAction,
  verticle,
  disableGutters,
  divider,
  borderBottom
}) => !verticle ? /* @__PURE__ */ React.createElement(ListItem, {
  sx: !borderBottom ? {px: 0} : {px: 0, paddingBottom: "3px", borderBottom: "5px solid " + MthColor.MTHBLUE},
  secondaryAction,
  disableGutters
}, divider && /* @__PURE__ */ React.createElement(Divider, {
  sx: {
    background: "black",
    height: 35,
    marginX: 3
  },
  variant: "middle",
  orientation: "vertical"
}), image && image, /* @__PURE__ */ React.createElement(ListItemText, {
  disableTypography: true,
  primary: title,
  secondary: subtitle
})) : /* @__PURE__ */ React.createElement(Box, {
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  display: "flex"
}, image && image, /* @__PURE__ */ React.createElement(ListItemText, {
  disableTypography: true,
  primary: title,
  secondary: subtitle
}));
