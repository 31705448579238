import React from "../../_snowpack/pkg/react.js";
import {AvatarGroup, Tooltip, Avatar} from "../../_snowpack/pkg/@mui/material.js";
import {isNil} from "../../_snowpack/pkg/lodash.js";
import {ActiveStudentStatuses, EMPTY_STRING, UNASSIGNED} from "../../core/constants/index.js";
import {AnnouncementFilterProgramYearType, GRADE_LEVEL_LABEL, StudentStatus} from "../../core/enums/index.js";
import {getStringInitialLetter, getProfilePhoto} from "../../core/utils/student/student.util.js";
export function parseJsonStringArray(json) {
  try {
    if (json) {
      const res = JSON.parse(json);
      return res?.map((item) => String(item));
    }
    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
}
export const filterStudents = (students, filter_program_years, filter_grades, filter_school_partners, filter_application_statuses) => {
  const programYearFilterValid = filter_program_years && filter_program_years?.length > 0;
  const includeMidyear = Boolean(filter_program_years?.includes(AnnouncementFilterProgramYearType.MID_YEAR));
  const includeFullyear = Boolean(filter_program_years?.includes(AnnouncementFilterProgramYearType.SCHOOL_YEAR));
  return students?.filter((student) => {
    const studentCurrentSchoolyear = student?.current_school_year_status?.school_year_id;
    const studentMidyear = Boolean(student?.current_school_year_status?.midyear_application);
    const currentYearStatus = student.status.find((status) => Number(status.school_year_id) === Number(studentCurrentSchoolyear));
    if (isNil(currentYearStatus))
      return false;
    const activeStatuses = ActiveStudentStatuses.map((status) => String(status));
    const appStatuses = filter_application_statuses ? filter_application_statuses?.filter((status) => activeStatuses?.includes(status)) : [];
    if (!appStatuses || appStatuses?.length === 0) {
      appStatuses.push(...activeStatuses);
    }
    if (appStatuses?.includes(String(StudentStatus.APPLIED)) && !appStatuses?.includes(String(StudentStatus.REAPPLIED))) {
      appStatuses.push(String(StudentStatus.REAPPLIED));
    }
    const includeByStatus = appStatuses?.includes(String(currentYearStatus.status));
    const studentGradeLevels = student.grade_levels?.filter((gradeLevel) => Number(gradeLevel.school_year_id) === Number(studentCurrentSchoolyear));
    const studentGradeLevel = studentGradeLevels?.length > 0 ? studentGradeLevels[0] : null;
    if (!studentGradeLevel) {
      return false;
    }
    const kinVariations = [
      GRADE_LEVEL_LABEL.KINDERGARTEN,
      GRADE_LEVEL_LABEL.KINDER,
      GRADE_LEVEL_LABEL.KIN,
      GRADE_LEVEL_LABEL.K
    ];
    const isVariationOfKin = kinVariations.includes(studentGradeLevel?.grade_level || EMPTY_STRING);
    const includeByGrade = filter_grades?.includes(isVariationOfKin ? GRADE_LEVEL_LABEL.KINDERGARTEN : studentGradeLevel.grade_level);
    const midyearOrFullyearMatch = includeMidyear && studentMidyear || includeFullyear && !studentMidyear;
    const programYearFilter = programYearFilterValid ? midyearOrFullyearMatch : true;
    return studentGradeLevel && includeByGrade && includeByStatus && programYearFilter;
  })?.filter((student) => {
    if (filter_school_partners && filter_school_partners?.length > 0) {
      const unassignedInFilter = filter_school_partners?.includes(UNASSIGNED);
      const validatedSoe = student.currentSoe?.filter((soe) => Number(soe.school_year_id) === Number(student?.current_school_year_status?.school_year_id));
      if (validatedSoe?.length === 0) {
        return unassignedInFilter;
      }
      const validatedSoeIds = validatedSoe?.map((soe) => Number(soe.school_partner_id));
      const hasOverlap = filter_school_partners.some((school_partner_id) => validatedSoeIds?.includes(Number(school_partner_id)));
      return hasOverlap;
    }
    return true;
  });
};
export const avatarGroup = (props) => {
  const {filter_program_years, students, filter_grades, filter_school_partners, filter_application_statuses, max} = props;
  const MAX_AVATARS = 20;
  const filtered_students = filterStudents(students, filter_program_years, filter_grades, filter_school_partners, filter_application_statuses);
  if (filtered_students?.length > 0) {
    return /* @__PURE__ */ React.createElement(AvatarGroup, {
      max: max ?? MAX_AVATARS,
      sx: {
        maxWidth: "300px",
        justifyContent: "start"
      },
      spacing: 0
    }, filtered_students?.map((student, index) => /* @__PURE__ */ React.createElement(Tooltip, {
      key: index,
      title: student.person.preferred_first_name ? student.person.preferred_first_name : student.person.first_name
    }, /* @__PURE__ */ React.createElement(Avatar, {
      alt: student.person.preferred_first_name ? student.person.preferred_first_name : student.person.first_name,
      src: getProfilePhoto(student.person)
    }, getStringInitialLetter(student.person.preferred_first_name ? student.person.preferred_first_name : student.person.first_name)))));
  } else {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
};
