import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {QuestionTypes} from "../../constants/index.js";
import {LearningLogQuestionActions} from "../../enums/index.js";
const parseJSON = (jsonText) => {
  try {
    return JSON.parse(jsonText);
  } catch {
    return [];
  }
};
export const shouldEnableQuestion = (parentQuestion, childQuestion) => {
  try {
    if (isNil(parentQuestion) || isNil(childQuestion))
      return false;
    if (isNil(parentQuestion?.answer))
      return false;
    if (!parentQuestion.active)
      return false;
    if (parentQuestion?.type === QuestionTypes.AGREEMENT && !!parentQuestion.answer) {
      return true;
    }
    if (parentQuestion?.type === QuestionTypes.MULTIPLE_CHOSE) {
      const parentQuestionAnsw = parseJSON(parentQuestion.answer);
      const selectedOption = parentQuestionAnsw.find((x) => x.value === true);
      return !isNil(selectedOption) && selectedOption.action === LearningLogQuestionActions.SHOW_CONDITIONAL_QUESTION;
    }
    if (parentQuestion?.type === QuestionTypes.CHECK_BOX) {
      const parentQuestionAnsw = parseJSON(parentQuestion.answer).map((q) => String(q));
      const parentQuestionOptions = parentQuestion.Options || [];
      const shouldEnable = parentQuestionOptions.some((option) => parentQuestionAnsw.includes(String(option?.value)) && option?.action === LearningLogQuestionActions.SHOW_CONDITIONAL_QUESTION);
      return shouldEnable;
    }
    if (parentQuestion?.type !== QuestionTypes.AGREEMENT && parentQuestion?.active) {
      const parentQuestionOptions = parentQuestion?.Options || [];
      const selectedOption = parentQuestionOptions.find((x) => x.value === parentQuestion.answer);
      return !isNil(selectedOption) && selectedOption.action === LearningLogQuestionActions.SHOW_CONDITIONAL_QUESTION;
    }
    return false;
  } catch (error) {
    return false;
  }
};
