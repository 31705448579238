import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {EMPTY_STRING} from "../../constants/index.js";
import {getITRAnswersByStudentsAndSY} from "../../../graphql/queries/intent-to-reenroll.js";
import {getNextSchoolYear, isEligible} from "../../utils/index.js";
export const getStudentsITR = (students, schoolYears, timezone) => {
  const [studentsAnsweredITR, setStudentsAnsweredITR] = useState([]);
  const [eligibleStudents, setEligibleStudents] = useState([]);
  const [studentsSubmitITR, setStudentsSubmitITR] = useState([]);
  const [getStudentWithAnswer] = useLazyQuery(getITRAnswersByStudentsAndSY, {
    fetchPolicy: "cache-first"
  });
  const currentSY = schoolYears.filter((schoolYear) => schoolYear.IsCurrentYear);
  const nextSY = getNextSchoolYear(currentSY, schoolYears);
  useEffect(() => {
    if (students && students.length > 0 && currentSY && nextSY) {
      const auxEligibleStudents = students.reduce((prevStudents, currStudent) => {
        const isEligibleStudent = isEligible(currentSY, nextSY, timezone, currStudent);
        return isEligibleStudent ? [...prevStudents, currStudent] : prevStudents;
      }, []);
      const studentsId = auxEligibleStudents.map((student) => Number(student.student_id));
      if (studentsId.length > 0 && nextSY[0].school_year_id) {
        setEligibleStudents(auxEligibleStudents);
        validateStudents(studentsId, Number(nextSY[0].school_year_id));
      }
    }
  }, [students, schoolYears]);
  useEffect(() => {
    if (eligibleStudents) {
      const filterStudentsTodoITR = eligibleStudents.filter((student) => !studentsAnsweredITR.includes(Number(student.student_id)));
      setStudentsSubmitITR(filterStudentsTodoITR);
    }
  }, [studentsAnsweredITR]);
  const validateStudents = async (students2, schoolYearId) => {
    const {
      data: {getITRAnswersByStudentsAndSY: getITRAnswersByStudentsAndSY2}
    } = await getStudentWithAnswer({
      variables: {
        students: students2,
        schoolYearId
      }
    });
    setStudentsAnsweredITR(getITRAnswersByStudentsAndSY2.map((data) => Number(data.student_id)));
  };
  return {
    studentsSubmitITR,
    openDate: currentSY[0]?.intent_to_re_enroll_open ?? EMPTY_STRING,
    closeDate: currentSY[0]?.intent_to_re_enroll_close ?? EMPTY_STRING
  };
};
