import React, {useEffect, useRef, useState} from "../../_snowpack/pkg/react.js";
import {ArrowDropDown} from "../../_snowpack/pkg/@mui/icons-material.js";
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  CircularProgress,
  Box,
  TableFooter,
  TableSortLabel,
  styled
} from "../../_snowpack/pkg/@mui/material.js";
import {visuallyHidden} from "../../_snowpack/pkg/@mui/utils.js";
import {DragDropContext, Droppable} from "../../_snowpack/pkg/react-beautiful-dnd.js";
import {MthCheckbox} from "../MthCheckbox/index.js";
import MthTableRow from "./MthTableRow.js";
import {mthTableClasses} from "./styles.js";
import {MthColor, Order, SortedStatus} from "../../core/enums/index.js";
import {convertWidth} from "../../core/utils/mthtable/mthtable.util.js";
const CssTableSortLabel = styled(TableSortLabel)(() => ({
  ".MuiTableSortLabel-iconDirectionDesc": {
    color: `${MthColor.SYSTEM_01} !important`,
    fontSize: "22px",
    marginLeft: "16px",
    marginRight: "-40px"
  },
  ".MuiTableSortLabel-iconDirectionAsc": {
    color: `${MthColor.SYSTEM_01} !important`,
    fontSize: "22px",
    marginLeft: "16px",
    marginRight: "-40px"
  }
}));
const MthTable = ({
  fields,
  items,
  loading,
  selectable = false,
  showSelectAll = true,
  disableSelectAll = false,
  size = "medium",
  checkBoxColor = "primary",
  oddBg = true,
  borderBottom = true,
  isDraggable = false,
  defaultOrder = Order.DESC,
  defaultOrderBy = "name",
  onArrange,
  onSelectionChange,
  onSortChange,
  onSelectionHandle,
  sx = [],
  isTableCellBorder,
  labelSize = 18,
  overFlowHidden,
  testId,
  noCellItemPadding
}) => {
  const [numSelected, setNumSelected] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);
  const tableRef = useRef(null);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const handleRequestSort = (fieldKey) => {
    const isAsc = orderBy === fieldKey && order === Order.ASC;
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(fieldKey);
    if (onSortChange)
      onSortChange(fieldKey, isAsc ? Order.DESC : Order.ASC);
  };
  const handleToggleCheck = (item) => {
    const isUnselect = item.isSelected;
    item.isSelected = !item.isSelected;
    checkSelectedItems();
    handleSelectionChange(false);
    if (onSelectionHandle)
      onSelectionHandle(item, isUnselect);
  };
  const handleToggleCheckAll = (checked) => {
    items.map((item) => item.isSelected = checked && item.selectable !== false);
    checkSelectedItems();
    handleSelectionChange(true);
  };
  const checkSelectedItems = () => {
    setRowCount(items?.filter((item) => item.selectable !== false).length || 0);
    setNumSelected(items?.filter((item) => item.isSelected)?.length || 0);
  };
  const handleSelectionChange = (isAll) => {
    if (onSelectionChange)
      onSelectionChange(items, isAll);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    if (onArrange) {
      const newItems = reorder(items, result.source.index, result.destination.index);
      onArrange(newItems);
    }
  };
  useEffect(() => {
    checkSelectedItems();
  }, [items]);
  useEffect(() => {
    const handleWindowResize = () => {
      setTableWidth(tableRef.current?.clientWidth || 0);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    ref: tableRef
  }, /* @__PURE__ */ React.createElement(TableContainer, null, /* @__PURE__ */ React.createElement(Table, {
    ...testId ? {"data-testid": testId} : {},
    sx: [
      mthTableClasses.table,
      ...Array.isArray(sx) ? sx : [sx],
      {overflow: overFlowHidden === true ? "hidden" : "unset"}
    ],
    className: `${size} ${oddBg ? "" : "noOddBg"} ${borderBottom && !oddBg ? "" : "noBorderBottom"}`
  }, /* @__PURE__ */ React.createElement(TableHead, null, /* @__PURE__ */ React.createElement(TableRow, null, selectable && /* @__PURE__ */ React.createElement(TableCell, {
    className: "checkWrap"
  }, /* @__PURE__ */ React.createElement(MthCheckbox, {
    color: "primary",
    size,
    indeterminate: numSelected > 0 && numSelected < rowCount,
    checked: rowCount > 0 && numSelected === rowCount,
    onChange: (_e, checked) => handleToggleCheckAll(checked),
    sx: {visibility: !showSelectAll ? "hidden" : "unset"},
    disabled: disableSelectAll
  })), fields.map((field) => /* @__PURE__ */ React.createElement(TableCell, {
    key: field.key,
    width: convertWidth(field.width || 0, tableRef.current?.clientWidth || 0),
    sx: {paddingX: noCellItemPadding ? "0 !important" : "inherit"}
  }, field.sortable ? /* @__PURE__ */ React.createElement(CssTableSortLabel, {
    active: true,
    direction: orderBy === field.key ? order : Order.DESC,
    onClick: () => handleRequestSort(field.key),
    IconComponent: ArrowDropDown
  }, /* @__PURE__ */ React.createElement(Box, {
    fontSize: labelSize
  }, field.label), orderBy === field.key ? /* @__PURE__ */ React.createElement(Box, {
    component: "span",
    sx: visuallyHidden
  }, order === Order.DESC ? SortedStatus.SORTED_DESCENDING : SortedStatus.SORTED_ASCENDING) : null) : /* @__PURE__ */ React.createElement(Box, {
    fontSize: labelSize
  }, field.label))))), !!isDraggable ? /* @__PURE__ */ React.createElement(DragDropContext, {
    onDragEnd
  }, /* @__PURE__ */ React.createElement(Droppable, {
    droppableId: "droppable"
  }, (provided) => /* @__PURE__ */ React.createElement(TableBody, {
    ...provided.droppableProps,
    ref: provided.innerRef
  }, items.map((item, index) => /* @__PURE__ */ React.createElement(MthTableRow, {
    key: `${item.key}`,
    tableWidth,
    index,
    fields,
    item,
    selectable,
    isDraggable,
    size,
    checkBoxColor,
    handleToggleCheck,
    noCellItemPadding
  })), provided.placeholder))) : /* @__PURE__ */ React.createElement(TableBody, null, items.map((item, index) => /* @__PURE__ */ React.createElement(MthTableRow, {
    key: `${item.key}`,
    tableWidth,
    index,
    fields,
    item,
    selectable,
    isDraggable,
    size,
    checkBoxColor,
    handleToggleCheck,
    isTableCellBorder,
    noCellItemPadding
  }))), !items.length && Boolean(tableWidth) && /* @__PURE__ */ React.createElement(TableFooter, null, /* @__PURE__ */ React.createElement(TableRow, null, /* @__PURE__ */ React.createElement("td", {
    colSpan: fields.length
  }, loading && /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    justifyContent: "center",
    py: 1
  }, /* @__PURE__ */ React.createElement(CircularProgress, null))))))));
};
export default MthTable;
