export var EmailCategoryEnum;
(function(EmailCategoryEnum2) {
  EmailCategoryEnum2["USERS"] = "Users";
  EmailCategoryEnum2["APPLICATIONS"] = "Applications";
  EmailCategoryEnum2["PACKETS"] = "Enrollment Packets";
  EmailCategoryEnum2["SCHEDULES"] = "Schedules";
  EmailCategoryEnum2["WITHDRAWAL"] = "Withdrawal";
  EmailCategoryEnum2["DIRECTORDERS"] = "Direct Orders";
  EmailCategoryEnum2["REIMBURSEMENTS"] = "Reimbursements";
  EmailCategoryEnum2["HOMEROOM_RESOURCES"] = "Homeroom Resources";
  EmailCategoryEnum2["INTENT_TO_REENROLL"] = "Intent to Re-enroll";
  EmailCategoryEnum2["HOMEROOM"] = "Homeroom";
})(EmailCategoryEnum || (EmailCategoryEnum = {}));
export var EmailTemplateEnum;
(function(EmailTemplateEnum2) {
  EmailTemplateEnum2["APPLICATION_PAGE"] = "Application Page";
  EmailTemplateEnum2["AGE_ISSUE"] = "Age Issue";
  EmailTemplateEnum2["SCHEDULE_ACCEPTED"] = "Accepted";
  EmailTemplateEnum2["SECOND_SEMESTER_ACCEPTED"] = "2nd Semester Accepted";
  EmailTemplateEnum2["SECOND_SEMESTER_UNLOCKED"] = "2nd Semester Unlocked";
  EmailTemplateEnum2["APPLICATION_ACCEPTED"] = "Application Accepted";
  EmailTemplateEnum2["APPLICATION_RECEIVED"] = "Application Received";
  EmailTemplateEnum2["EMAIL_CHANGED"] = "Email Changed";
  EmailTemplateEnum2["EMAIL_VERIFICATION"] = "Email Verification";
  EmailTemplateEnum2["ENROLLMENT_PACKET_PAGE"] = "Enrollment Packet Page";
  EmailTemplateEnum2["FORGOT_PASSWORD"] = "Forgot Password";
  EmailTemplateEnum2["MISSING_INFORMATION"] = "Missing Information";
  EmailTemplateEnum2["NOTIFY_OF_WITHDRAW"] = "Notify of Withdraw";
  EmailTemplateEnum2["PACKET_ACCEPTED"] = "Packet Accepted";
  EmailTemplateEnum2["PACKET_REMINDERS"] = "Packet Reminders";
  EmailTemplateEnum2["UNDECLARED_WITHDRAW"] = "Undeclared Withdraw";
  EmailTemplateEnum2["UPDATES_ALLOWED"] = "Updates Allowed";
  EmailTemplateEnum2["UPDATES_REQUIRED"] = "Updates Required";
  EmailTemplateEnum2["WITHDRAW_CONFIRMATION"] = "Withdraw Confirmation";
  EmailTemplateEnum2["WITHDRAW_PAGE"] = "Withdraw Page";
  EmailTemplateEnum2["HOMEROOM_RESOURCES_ACCEPTED"] = "Homeroom Resource Accepted";
  EmailTemplateEnum2["REMINDERS"] = "Reminder";
  EmailTemplateEnum2["HOMEROOM_REMINDERS"] = "Homeroom Reminder";
  EmailTemplateEnum2["AUTO_GRADE"] = "Auto-grade";
  EmailTemplateEnum2["HOMEROOM_AUTO_GRADE"] = "Homeroom Auto-grade";
  EmailTemplateEnum2["RESUBMIT_REQUIRED"] = "Resubmit Required";
  EmailTemplateEnum2["INTENTO_TO_REENROLL_REMINDER"] = "Intent to Re-enroll Reminder";
  EmailTemplateEnum2["RB_UPDATES_REQUIRED"] = "Reimbursement Updates Required";
  EmailTemplateEnum2["RB_APPROVED"] = "Reimbursement Approved";
  EmailTemplateEnum2["RB_PAID"] = "Reimbursement Paid";
  EmailTemplateEnum2["DO_UPDATES_REQUIRED"] = "Direct Order Updates Required";
  EmailTemplateEnum2["DO_APPROVED"] = "Direct Order Approved";
  EmailTemplateEnum2["DO_ORDERED"] = "Direct Order Ordered";
  EmailTemplateEnum2["DEADLINE"] = "deadline";
  EmailTemplateEnum2["CUSTOM_DEADLINE"] = "custom_deadline";
})(EmailTemplateEnum || (EmailTemplateEnum = {}));
export var EmailTemplateDescription;
(function(EmailTemplateDescription2) {
  EmailTemplateDescription2["RESOURCE"] = "Title of Resource";
  EmailTemplateDescription2["USERNAME"] = "User Name for Account";
  EmailTemplateDescription2["PASSWORD"] = "Password for Account";
  EmailTemplateDescription2["PARENT_EMAIL"] = "Parent Email";
  EmailTemplateDescription2["STUDENT_EMAIL"] = "Student Email";
  EmailTemplateDescription2["CONFIRMATION"] = "Confirmation Number(s)";
  EmailTemplateDescription2["ADMIN_FULL_NAME"] = "User\u2019s First and Last Name that orders the request";
  EmailTemplateDescription2["ADMIN_USER_EMAIL"] = "User\u2019s email that orders the request";
  EmailTemplateDescription2["DEADLINE"] = "Due Date";
})(EmailTemplateDescription || (EmailTemplateDescription = {}));
export var EmailType;
(function(EmailType2) {
  EmailType2["MISSING_INFO"] = "missingInfo";
  EmailType2["AGE_ISSUE"] = "ageIssue";
})(EmailType || (EmailType = {}));
export var EmailVerificationType;
(function(EmailVerificationType2) {
  EmailVerificationType2[EmailVerificationType2["NEW_ACCOUNT"] = 0] = "NEW_ACCOUNT";
  EmailVerificationType2[EmailVerificationType2["EMAIL_CHANGED"] = 1] = "EMAIL_CHANGED";
})(EmailVerificationType || (EmailVerificationType = {}));
