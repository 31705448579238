import {_ as _extends} from "./extends-1379b0c5.js";
import {_ as _objectWithoutPropertiesLoose} from "./objectWithoutPropertiesLoose-fe69c3b9.js";
import {r as react} from "./index-c6c556cc.js";
import {c as clsx} from "./clsx.m-e1b0361e.js";
import {s as styled, d as defaultStyleFunctionSx} from "./styleFunctionSx-1fe2764e.js";
import {u as useTheme} from "./useTheme-b68d9fcc.js";
import {j as jsxRuntime} from "./jsx-runtime-c4f7c71f.js";
import {e as extendSxProp} from "./extendSxProp-3f7ad40b.js";
const _excluded = ["className", "component"];
function createBox(options = {}) {
  const {
    defaultTheme,
    defaultClassName = "MuiBox-root",
    generateClassName,
    styleFunctionSx = defaultStyleFunctionSx
  } = options;
  const BoxRoot = styled("div")(styleFunctionSx);
  const Box = /* @__PURE__ */ react.forwardRef(function Box2(inProps, ref) {
    const theme = useTheme(defaultTheme);
    const _extendSxProp = extendSxProp(inProps), {
      className,
      component = "div"
    } = _extendSxProp, other = _objectWithoutPropertiesLoose(_extendSxProp, _excluded);
    return /* @__PURE__ */ jsxRuntime.jsx(BoxRoot, _extends({
      as: component,
      ref,
      className: clsx(className, generateClassName ? generateClassName(defaultClassName) : defaultClassName),
      theme
    }, other));
  });
  return Box;
}
export {createBox as c};
