import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {GetLearingLogQuestionByAssignmentIdQuery} from "../../../graphql/queries/learning-log-question.js";
import {QuestionTypes} from "../../constants/question.constant.js";
export const useLearningLogQuestionsByAssignmentId = (assignment_id, grade_level, studentLearningLogs) => {
  const [questions, setQuestions] = useState([]);
  const [getLearningLogQuestion, {loading: learningLogQuestionLoading, data: learningLogQuestionData, refetch}] = useLazyQuery(GetLearingLogQuestionByAssignmentIdQuery, {
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (assignment_id) {
      getLearningLogQuestion({
        variables: {
          assignmentId: +assignment_id
        }
      });
    }
  }, [assignment_id]);
  const parseQuestions = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("An error occured trying to parse answers");
      console.error(error);
      return [];
    }
  };
  useEffect(() => {
    if (!learningLogQuestionLoading && learningLogQuestionData?.getLearningLogQuestionByAssignmentId) {
      const answers = parseQuestions(studentLearningLogs?.at(0)?.meta || "[]");
      setQuestions(learningLogQuestionData?.getLearningLogQuestionByAssignmentId?.filter((item) => item?.type === QuestionTypes.SUBJECT_QUESTION || item.grades && grade_level && item.grades !== "[]" && item?.type !== QuestionTypes.SUBJECT_QUESTION && JSON.parse(item.grades)?.includes(`${grade_level}`) || !item.grades || item.grades === "[]" || item.grades === "null")?.sort((a, b) => {
        if (a.order > b.order)
          return 1;
        if (a.order < b.order)
          return -1;
        return 0;
      }).map((question) => ({
        ...question,
        required: JSON.parse(question.validations || "[]")?.includes("required") ? true : false,
        Validations: JSON.parse(question.validations || "[]"),
        Options: JSON.parse(question.options || "[]"),
        Grades: JSON.parse(question.grades || "[]"),
        grades: JSON.parse(question.grades || "[]"),
        active: !question.parent_slug ? true : false,
        answer: answers?.find((item) => item.slug === question.slug)?.answer,
        file: answers?.find((item) => item.slug === question.slug)?.file ?? void 0
      })));
    }
  }, [learningLogQuestionLoading, learningLogQuestionData]);
  return {
    learningLogQuestions: questions,
    setLearingLogQuestions: setQuestions,
    refetch,
    loading: learningLogQuestionLoading
  };
};
