import * as __SNOWPACK_ENV__ from "../../../_snowpack/env.js";
import {isNil, isString} from "../../../_snowpack/pkg/lodash.js";
import {EMPTY_STRING} from "../../constants/index.js";
import {StudentsGradeLevel, MthTitle} from "../../enums/index.js";
export const getTeacherProfilePhoto = (avatarUrl) => {
  if (!avatarUrl)
    return EMPTY_STRING;
  const s3URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_BASE_S3_IMAGE_URL;
  return `${s3URL}${avatarUrl}`;
};
export const getProfilePhoto = (person) => {
  if (!person?.photo)
    return EMPTY_STRING;
  const s3URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_BASE_S3_IMAGE_URL;
  return s3URL + person.photo;
};
export const getStringInitialLetter = (name) => {
  if (isString(name) && name.trim().length > 0) {
    name = name.trim();
    return name[0].trim().toUpperCase();
  } else {
    return EMPTY_STRING;
  }
};
export const formatStudentGrade = (gradeLevel) => {
  if (!gradeLevel)
    return EMPTY_STRING;
  if (gradeLevel === StudentsGradeLevel.KINDERGARTEN)
    return `${gradeLevel}`;
  if (gradeLevel === StudentsGradeLevel.FIRST)
    return `${gradeLevel}st ${MthTitle.GRADE}`;
  if (gradeLevel === StudentsGradeLevel.SECOND)
    return `${gradeLevel}nd ${MthTitle.GRADE}`;
  if (gradeLevel === StudentsGradeLevel.THIRD)
    return `${gradeLevel}rd ${MthTitle.GRADE}`;
  return `${gradeLevel}th ${MthTitle.GRADE}`;
};
export const getStudentGradeLevelBySY = (student, school_year_id) => {
  if (isNil(student) || isNil(school_year_id))
    return EMPTY_STRING;
  const findGrade = student.grade_levels.find((gradeLevel) => Number(gradeLevel.school_year_id) === Number(school_year_id));
  return findGrade ? findGrade.grade_level : EMPTY_STRING;
};
