import {g as getDefaultExportFromCjs, c as createCommonjsModule} from "../../common/_commonjsHelpers-53bde9c7.js";
import {i as interopRequireDefault, r as require$$0} from "../../common/createSvgIcon-1c4aa2bb.js";
import {j as jsxRuntime} from "../../common/jsx-runtime-c4f7c71f.js";
import "../../common/createSvgIcon-e773c0f9.js";
import "../../common/extends-1379b0c5.js";
import "../../common/index-c6c556cc.js";
import "../../common/objectWithoutPropertiesLoose-fe69c3b9.js";
import "../../common/clsx.m-e1b0361e.js";
import "../../common/useThemeProps-58507f80.js";
import "../../common/defaultTheme-0a91cf2d.js";
import "../../common/createTheme-1192a460.js";
import "../../common/createTheme-723fa293.js";
import "../../common/useThemeProps-dbfcde13.js";
import "../../common/getThemeProps-c16b8f7c.js";
import "../../common/useTheme-b68d9fcc.js";
import "../../common/useThemeWithoutDefault-a6f20aad.js";
import "../../common/styled-d39d6e04.js";
import "../../common/createStyled-e5386f10.js";
import "../../common/styleFunctionSx-1fe2764e.js";
import "../../common/emotion-styled.browser.esm-9e1b737f.js";
import "../../common/emotion-is-prop-valid.esm-047f3cc2.js";
import "../../common/emotion-element-cbed451f.browser.esm-6f9225f1.js";
import "../../common/generateUtilityClass-a8a7346c.js";
import "../../common/ClassNameGenerator-1fa9df9c.js";
import "../../common/generateUtilityClasses-f1d2e319.js";
import "../../common/useForkRef-8d56fe3c.js";
import "../../common/createChainedFunction-b32cfe42.js";
import "../../common/debounce-735db077.js";
import "../../common/isMuiElement-dcb6bc25.js";
import "../../common/ownerDocument-176afcdd.js";
import "../../common/ownerWindow-2abea87b.js";
import "../../common/useEnhancedEffect-f0068896.js";
import "../../common/useId-60647959.js";
import "../../common/useControlled-697dd2b3.js";
import "../../common/useEventCallback-fd4680bd.js";
import "../../common/useIsFocusVisible-974ba34c.js";
var InfoOutlined = createCommonjsModule(function(module, exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = void 0;
  var _createSvgIcon = interopRequireDefault(require$$0);
  var _default = (0, _createSvgIcon.default)(/* @__PURE__ */ (0, jsxRuntime.jsx)("path", {
    d: "M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
  }), "InfoOutlined");
  exports.default = _default;
});
var __pika_web_default_export_for_treeshaking__ = /* @__PURE__ */ getDefaultExportFromCjs(InfoOutlined);
export {__pika_web_default_export_for_treeshaking__ as default};
