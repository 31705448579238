export const SNOWPACK_PUBLIC_APP_STAGE = "production";
export const SNOWPACK_PUBLIC_API_URL = "https://api.infocenter.tech/graphql";
export const SNOWPACK_PUBLIC_API_V3_URL = "https://services-api-v3.infocenter.tech";
export const SNOWPACK_PUBLIC_MEDIA_SERVICE_URL = "https://media-api.infocenter.tech";
export const SNOWPACK_PUBLIC_S3_URL = "https://media-api.infocenter.tech/upload";
export const SNOWPACK_PUBLIC_S3_UPLOAD = "https://media-api.infocenter.tech/uploadProfilePhoto";
export const SNOWPACK_PUBLIC_S3_STUDENT_RECORD_FILES_DOWNLOAD = "https://media-api.infocenter.tech/downloadStudentRecordFiles";
export const SNOWPACK_PUBLIC_COUNTIES_TEMPLATE = "https://content.infocenter.tech/templates/CountiesTemplate.csv";
export const SNOWPACK_PUBLIC_SCHOOL_DISTRICT_TEMPLATE = "https://content.infocenter.tech/templates/SchoolDistrictsTemplate.csv";
export const SNOWPACK_PUBLIC_BASE_S3_UPLOAD_URL = "https://media-api.infocenter.tech";
export const SNOWPACK_PUBLIC_BASE_S3_IMAGE_URL = "https://content.infocenter.tech/";
export const SNOWPACK_PUBLIC_WEB_URL = "https://infocenter.tech";
export const SNOWPACK_PUBLIC_FEATURE_FLAG_URL = "https://features.v2-staging.mytechhigh.com/proxy";
export const SNOWPACK_PUBLIC_FEATURE_FLAG_CLIENT_KEY = "c4muE3V2ywKE5QP";
export const SNOWPACK_PUBLIC_GOOGLE_ANALYTICS_ID = "G-167KSF2MMK";
export const SNOWPACK_PUBLIC_FEATURE_FLAG_APP_NAME = "Default";
export const SNOWPACK_PUBLIC_FEATURE_FLAG_ENV = "production";
export const SNOWPACK_PUBLIC_HYPERWALLET_URL = "https://api.paylution.com/rest/widgets/transfer-methods/";
export const SNOWPACK_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL = "300";
export const SNOWPACK_PUBLIC_MAINTENANCE_PAGE_POLLING = "60000";
export const SNOWPACK_PUBLIC_MAINTENANCE_PAGE_URL = "https://api.infocenter.tech/maintenance";
export const SNOWPACK_PUBLIC_MB_SIZE_LIMIT = "25";
export const SNOWPACK_PUBLIC_APP_VERSION_POLLING_RATE = "60000";
export const SNOWPACK_PUBLIC_APP_ENVIRONMENT = "prod";
export const SNOWPACK_PUBLIC_DATADOG_APPLICATION_ID = "15b62402-7f8e-41cd-b1ed-d9ac9ff05f40";
export const SNOWPACK_PUBLIC_DATADOG_CLIENT_TOKEN = "pubb914db6cf09f749490343ba35c7a4b5e";
export const SNOWPACK_PUBLIC_APP_VERSION = "P202409111025";
export const MODE = "production";
export const NODE_ENV = "production";
export const SSR = false;
