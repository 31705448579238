export var DisplayToOptions;
(function(DisplayToOptions2) {
  DisplayToOptions2["ParentsObservers"] = "parentsObservers";
  DisplayToOptions2["Students"] = "students";
  DisplayToOptions2["TeachersAssistants"] = "teachersAssistants";
  DisplayToOptions2["Admin"] = "admin";
})(DisplayToOptions || (DisplayToOptions = {}));
export var DisplayToLabels;
(function(DisplayToLabels2) {
  DisplayToLabels2["parentsObservers"] = "Parents/Observers";
  DisplayToLabels2["students"] = "Students";
  DisplayToLabels2["teachersAssistants"] = "Teachers & Assistants";
  DisplayToLabels2["admin"] = "Admin";
  DisplayToLabels2["users"] = "Users";
  DisplayToLabels2["filterMessage"] = "Please select at least one filter";
  DisplayToLabels2["startDate"] = "Start Date";
  DisplayToLabels2["endDate"] = "End Date";
  DisplayToLabels2["startDateMessage"] = "Please select Start Date";
  DisplayToLabels2["endDateMessage"] = " Please select End Date";
  DisplayToLabels2["titleMessage"] = "Please enter Popup Title";
  DisplayToLabels2["popupMessage"] = "Please enter PopUp content";
  DisplayToLabels2["filter"] = "Filter";
})(DisplayToLabels || (DisplayToLabels = {}));
