export var ScheduleReportColumn;
(function(ScheduleReportColumn2) {
  ScheduleReportColumn2["SOE"] = "soe";
  ScheduleReportColumn2["STATUS"] = "status";
  ScheduleReportColumn2["STATUS_DATE"] = "status_date";
  ScheduleReportColumn2["STUDENT_ID"] = "student_id";
  ScheduleReportColumn2["STUDENT_LEGAL_NAME"] = "student_legal_name";
  ScheduleReportColumn2["STUDENT_PREFERRED_NAME"] = "student_preferred_name";
  ScheduleReportColumn2["STUDENT_GRADE"] = "student_grade";
  ScheduleReportColumn2["YEAR"] = "year";
  ScheduleReportColumn2["PERIOD"] = "period";
  ScheduleReportColumn2["PERIOD_CATEGORY"] = "period_category";
  ScheduleReportColumn2["SUBJECT"] = "subject";
  ScheduleReportColumn2["SUBJECT_TITLE"] = "subject_title";
  ScheduleReportColumn2["COURSE_CODE"] = "course_code";
  ScheduleReportColumn2["TEACHER_NAME"] = "teacher_name";
  ScheduleReportColumn2["COURSE_TYPE"] = "course_type";
  ScheduleReportColumn2["DESCRIPTION"] = "description";
})(ScheduleReportColumn || (ScheduleReportColumn = {}));
