import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getSubjectsQuery} from "../../../screens/Admin/Curriculum/CourseCatalog/services.js";
export const useSubjects = (schoolYearId, searchField = "", isActive = true, hasAllItem = false) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [checkBoxItems, setCheckBoxItems] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const {loading, data, error, refetch} = useQuery(getSubjectsQuery, {
    variables: {
      findSubjectsInput: {schoolYearId, searchField, isActive}
    },
    skip: !schoolYearId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (data?.subjects) {
      const {subjects: subjects2} = data;
      const subjectItems = subjects2.map((item) => ({
        value: item.subject_id,
        label: item.name
      }));
      setDropdownItems(hasAllItem ? [{label: "All", value: -1}, ...subjectItems] : subjectItems);
      setCheckBoxItems(subjects2.map((item) => ({
        value: item.subject_id.toString(),
        label: item.name
      })));
      setSubjects(subjects2);
    }
  }, [loading, data]);
  return {
    loading,
    subjects,
    dropdownItems,
    checkBoxItems,
    error,
    refetch
  };
};
