import {d as defaultTheme} from "./defaultTheme-0a91cf2d.js";
import {c as createStyled, s as shouldForwardProp} from "./createStyled-e5386f10.js";
const rootShouldForwardProp = (prop) => shouldForwardProp(prop) && prop !== "classes";
const slotShouldForwardProp = shouldForwardProp;
const styled = createStyled({
  defaultTheme,
  rootShouldForwardProp
});
var styled$1 = styled;
export {slotShouldForwardProp as a, rootShouldForwardProp as r, styled$1 as s};
