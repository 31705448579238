import axios from "../../_snowpack/pkg/axios.js";
import {isNil} from "../../_snowpack/pkg/lodash.js";
import {ASYNC_DOWNLOAD_ENDPOINT_PREFIX} from "../constants/index.js";
import {AsyncDownloadRequestType, INFOCENTER_V3_API} from "../enums/index.js";
import {SNOWPACK_PUBLIC_MEDIA_SERVICE_URL} from "../envs/index.js";
import {apiGet, apiPost} from "./index.js";
import {EMPTY_STRING} from "../constants/empty-string.constant.js";
const getSignedS3Link = async (fileId, filePath) => {
  try {
    let urlParameter;
    if (!isNil(fileId)) {
      urlParameter = `fileId=${encodeURIComponent(fileId)}`;
    } else if (!isNil(filePath) && filePath.trim() !== EMPTY_STRING) {
      urlParameter = `filePath=${encodeURIComponent(filePath)}`;
    } else {
      return EMPTY_STRING;
    }
    const token = localStorage.getItem("JWT");
    const response = await axios.get(`${SNOWPACK_PUBLIC_MEDIA_SERVICE_URL}/downloadLink?${urlParameter}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return EMPTY_STRING;
};
export const getSignedS3LinkById = (fileId) => getSignedS3Link(fileId);
export const getSignedS3LinkByPath = (filePath) => getSignedS3Link(void 0, filePath);
export const getUnofficialTranscript = async (studentId, schoolYearId) => {
  if (isNil(schoolYearId) || isNil(studentId)) {
    throw `Error retrieving school year ID: ${schoolYearId} or student ID: ${studentId}`;
  }
  const controller = "unofficial-transcripts";
  const endpoint = `generate/schoolyear/${schoolYearId}/studentId/${studentId}`;
  return await apiGet(INFOCENTER_V3_API.FILE_SERVICE, controller, endpoint);
};
export const getLearningLogPdf = async (studentId, assignmentId) => {
  if (isNil(assignmentId) || isNil(studentId)) {
    throw `Error retrieving assignment ID: ${assignmentId} or student ID: ${studentId}`;
  }
  const service = "learning-log";
  const endpoint = `learning-log-file/student-id/${studentId}/assignment-id/${assignmentId}/`;
  return await apiGet(INFOCENTER_V3_API.FILE_SERVICE, service, endpoint);
};
export const enqueueStudentRecordsAsyncDownloadRequest = async (body) => {
  await apiPost(INFOCENTER_V3_API.FILE_SERVICE, ASYNC_DOWNLOAD_ENDPOINT_PREFIX, AsyncDownloadRequestType.STUDENT_RECORDS, body);
};
export const getOptOutForm = async (optOutParams) => {
  const {studentId, schoolYearId} = optOutParams;
  if (isNil(schoolYearId) || isNil(studentId)) {
    throw `Error retrieving school year ID: ${schoolYearId} or student ID: ${studentId}`;
  }
  const controller = "opt-out-form";
  const endpoint = `generate/schoolyear/${schoolYearId}/studentId/${studentId}`;
  return await apiGet(INFOCENTER_V3_API.FILE_SERVICE, controller, endpoint);
};
