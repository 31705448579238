import {outlinedInputClasses, inputLabelClasses} from "../../_snowpack/pkg/@mui/material.js";
import {MthColor} from "../../core/enums/index.js";
export const useStyles = {
  modalCard: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxWidth: "828px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflowY: "auto",
    maxHeight: "97vh"
  },
  editor: {
    border: `1px solid ${MthColor.MEDIUMGRAY}`,
    borderRadius: 1,
    marginBottom: "24px",
    "div.DraftEditor-editorContainer": {
      minHeight: "200px",
      maxHeight: "250px",
      overflow: "scroll",
      overflowX: "hidden",
      padding: 1,
      ".public-DraftStyleDefault-block": {
        margin: 0
      }
    }
  },
  toolBar: {
    borderBottom: `1px solid ${MthColor.MEDIUMGRAY}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 1
  },
  cancelButton: {
    borderRadius: 10,
    background: MthColor.SYSTEM_08,
    width: "200px",
    marginRight: 1,
    fontSize: "12px"
  },
  submitButton: {
    borderRadius: 10,
    width: "200px",
    marginLeft: 1,
    fontSize: "12px",
    background: MthColor.BLACK_GRADIENT
  },
  icon: {
    marginRight: 2,
    color: MthColor.SYSTEM_08,
    cursor: "pointer"
  },
  from: {
    marginTop: 2
  },
  subject: {
    marginTop: 0
  },
  isActive: {
    color: "black"
  },
  "ul.primary-bullet": {
    listStyleType: "lower-roman"
  },
  "ul.sub-bullet": {
    listStyleType: "square",
    marginLeft: "-2em"
  },
  dropdown: {
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: MthColor.SYSTEM_07,
      borderWidth: "2px"
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: MthColor.SYSTEM_07,
      borderWidth: "2px"
    },
    [`& .${inputLabelClasses.root}.${inputLabelClasses.focused}`]: {
      transform: "translate(14px, -11px) scale(1)"
    },
    [`& .${inputLabelClasses.root}.${inputLabelClasses.shrink}`]: {
      transform: "translate(14px, -11px) scale(1)"
    },
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline} span`]: {
      fontSize: 16
    },
    marginTop: "40px",
    marginBottom: "50px",
    width: "100%"
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  textFieldError: {
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: MthColor.RED
    },
    marginY: 2,
    width: "100%",
    [`& .${inputLabelClasses.root}.${inputLabelClasses.focused}`]: {
      transform: "translate(14px, -11px) scale(1)"
    },
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline} span`]: {
      fontSize: 16
    },
    marginTop: "40px",
    marginBottom: "50px"
  },
  description: {fontWeight: 600, color: MthColor.SYSTEM_12, fontSize: "18px", marginLeft: "30px"}
};
