import {d as defaultTheme} from "./defaultTheme-0a91cf2d.js";
import {u as useThemeProps$1} from "./useThemeProps-dbfcde13.js";
function useThemeProps({
  props,
  name
}) {
  return useThemeProps$1({
    props,
    name,
    defaultTheme
  });
}
export {useThemeProps as u};
