import {useContext} from "../../../_snowpack/pkg/react.js";
import dayjs from "../../../_snowpack/pkg/dayjs.js";
import {useHistory, useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import {CLIENT_ERROR_RESPONSES, MthRoute, QUERY_PARAMETERS} from "../../enums/index.js";
import {S3_UPLOAD_URL} from "../../envs/s3-upload-url.js";
import {AuthContext} from "../../../providers/AuthProvider/AuthContext.js";
import {UserContext} from "../../../providers/UserContext/UserProvider.js";
export const useUploadFile = () => {
  const history = useHistory();
  const {setMe} = useContext(UserContext);
  const {signOut} = useContext(AuthContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const logout = () => {
    setMe(null);
    signOut();
    history.push({
      pathname: MthRoute.DASHBOARD,
      search: new URLSearchParams({[QUERY_PARAMETERS.EXPIRATION]: currentPath}).toString()
    });
  };
  const uploadFile = async (file, category, region, studentId) => {
    try {
      if (file) {
        const bodyFormData = new FormData();
        bodyFormData.append("file", file);
        bodyFormData.append("category", category);
        bodyFormData.append("region", region);
        bodyFormData.append("year", dayjs().format("YYYY"));
        if (studentId)
          bodyFormData.append("studentId", `${studentId}`);
        const response = await fetch(S3_UPLOAD_URL, {
          method: "POST",
          body: bodyFormData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("JWT")}`
          }
        });
        if ([CLIENT_ERROR_RESPONSES.CONFLICT, CLIENT_ERROR_RESPONSES.UNAUTHORIZED].includes(response.status)) {
          const result2 = await response.json();
          if (response.status === CLIENT_ERROR_RESPONSES.UNAUTHORIZED) {
            logout();
          }
          return {
            success: false,
            errors: [result2?.message],
            errorCodes: [response.status]
          };
        }
        const result = await response.json();
        return result;
      }
      return {
        success: false,
        errors: ["Invalid File"]
      };
    } catch (error) {
      return {
        success: false,
        errors: ["Invalid File"]
      };
    }
  };
  return {uploadFile};
};
