export var StudentLearningLogStatus;
(function(StudentLearningLogStatus2) {
  StudentLearningLogStatus2["GRADED"] = "Graded";
  StudentLearningLogStatus2["AUTO_GRADED"] = "Auto-graded";
  StudentLearningLogStatus2["STARTED"] = "Started";
  StudentLearningLogStatus2["RESUBMIT"] = "Resubmit";
  StudentLearningLogStatus2["RESUBMITTED"] = "Resubmitted";
  StudentLearningLogStatus2["SUBMITTED"] = "Submitted";
  StudentLearningLogStatus2["AVAILABLE"] = "Available";
  StudentLearningLogStatus2["EXCUSED"] = "Excused";
  StudentLearningLogStatus2["LOCKED"] = "Locked";
  StudentLearningLogStatus2["NANDA"] = "N/A";
  StudentLearningLogStatus2["GRADE"] = "Grade";
  StudentLearningLogStatus2["RESUBMIT_REQUIRED"] = "Resubmit Required";
  StudentLearningLogStatus2["EXCUSE"] = "Excuse";
})(StudentLearningLogStatus || (StudentLearningLogStatus = {}));
export const MISSING = "Missing";
