export var EnrollmentHeadCellsId;
(function(EnrollmentHeadCellsId2) {
  EnrollmentHeadCellsId2["SUBMITTED"] = "submitted";
  EnrollmentHeadCellsId2["STATUS"] = "status";
  EnrollmentHeadCellsId2["DEADLINE"] = "deadline";
  EnrollmentHeadCellsId2["STUDENT"] = "student";
  EnrollmentHeadCellsId2["GRADE"] = "grade";
  EnrollmentHeadCellsId2["PARENT"] = "parent";
  EnrollmentHeadCellsId2["STUDENT_STATUS"] = "studentStatus";
  EnrollmentHeadCellsId2["EMAILED"] = "emailed";
  EnrollmentHeadCellsId2["LAST_UPDATED"] = "lastUpdated";
  EnrollmentHeadCellsId2["LAST_SUBMITTED"] = "lastSubmitted";
})(EnrollmentHeadCellsId || (EnrollmentHeadCellsId = {}));
export var EnrollmentHeadCellsLabel;
(function(EnrollmentHeadCellsLabel2) {
  EnrollmentHeadCellsLabel2["DATE"] = "Date";
  EnrollmentHeadCellsLabel2["STATUS"] = "Status";
  EnrollmentHeadCellsLabel2["DEADLINE"] = "Deadline";
  EnrollmentHeadCellsLabel2["STUDENT"] = "Student";
  EnrollmentHeadCellsLabel2["GRADE"] = "Grade";
  EnrollmentHeadCellsLabel2["PARENT"] = "Parent";
  EnrollmentHeadCellsLabel2["PACKET"] = "Packet";
  EnrollmentHeadCellsLabel2["EMAILED"] = "Emailed";
  EnrollmentHeadCellsLabel2["LAST_UPDATED"] = "Last Updated";
  EnrollmentHeadCellsLabel2["LAST_SUBMITTED"] = "Last Submitted";
})(EnrollmentHeadCellsLabel || (EnrollmentHeadCellsLabel = {}));
export var EnrollmentResultsProps;
(function(EnrollmentResultsProps2) {
  EnrollmentResultsProps2["ID"] = "id";
  EnrollmentResultsProps2["SUBMITTED"] = "submitted";
  EnrollmentResultsProps2["STATUS"] = "status";
  EnrollmentResultsProps2["DEADLINE"] = "deadline";
  EnrollmentResultsProps2["STUDENT"] = "student";
  EnrollmentResultsProps2["GRADE_LEVEL"] = "grade_level";
  EnrollmentResultsProps2["PARENT"] = "parent";
  EnrollmentResultsProps2["STUDENT_STATUS"] = "studentStatus";
  EnrollmentResultsProps2["EMAILED"] = "emailed";
  EnrollmentResultsProps2["DELETE"] = "delete";
  EnrollmentResultsProps2["LAST_UPDATED"] = "lastUpdated";
  EnrollmentResultsProps2["LAST_SUBMITTED"] = "lastSubmitted";
})(EnrollmentResultsProps || (EnrollmentResultsProps = {}));
