import {useEffect, useMemo, useState} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import dayjs from "../../../_snowpack/pkg/dayjs.js";
import {sortBy} from "../../../_snowpack/pkg/lodash.js";
import {getActiveStudentHomeroomSchoolYearsQuery} from "../../../graphql/queries/school-year.js";
export const useActiveHomeroomSchoolYears = (studentId) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState(0);
  const [getActiveHomeroomSchoolYears, {loading, data, error, refetch}] = useLazyQuery(getActiveStudentHomeroomSchoolYearsQuery, {
    fetchPolicy: "cache-first"
  });
  useEffect(() => {
    if (studentId) {
      getActiveHomeroomSchoolYears({
        variables: {
          studentId
        }
      });
    }
  }, [studentId]);
  useEffect(() => {
    if (data?.activeHomeroomSchoolYears) {
      const {activeHomeroomSchoolYears: schoolYears2} = data;
      setDropdownItems(sortBy(schoolYears2, "date_begin").map((item) => ({
        value: item.school_year_id,
        label: `${dayjs(item.date_begin).format("YYYY")}-${dayjs(item.date_end).format("YY")}`
      })));
      setSchoolYears(schoolYears2);
    }
  }, [loading, data]);
  useEffect(() => {
    if (schoolYears?.length) {
      const currentYear = schoolYears.filter((item) => item.IsCurrentYear)[0];
      setSelectedYearId(currentYear?.school_year_id);
    } else {
      setSelectedYearId(void 0);
    }
  }, [schoolYears]);
  const selectedYear = useMemo(() => {
    if (selectedYearId && schoolYears.length) {
      return schoolYears.find((item) => item.school_year_id == selectedYearId);
    } else
      return void 0;
  }, [selectedYearId, schoolYears]);
  return {
    loading,
    schoolYears,
    dropdownItems,
    error,
    refetchSchoolYear: refetch,
    selectedYearId,
    setSelectedYearId,
    selectedYear
  };
};
