import {g as getThemeProps} from "./getThemeProps-c16b8f7c.js";
import {u as useTheme} from "./useTheme-b68d9fcc.js";
function useThemeProps({
  props,
  name,
  defaultTheme
}) {
  const theme = useTheme(defaultTheme);
  const mergedProps = getThemeProps({
    theme,
    name,
    props
  });
  return mergedProps;
}
export {useThemeProps as u};
