import React from "../../../../_snowpack/pkg/react.js";
import {datadogLogs} from "../../../../_snowpack/pkg/@datadog/browser-logs.js";
import {Button} from "../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../_snowpack/pkg/@mui/system.js";
import {useFlag} from "../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import axios from "../../../../_snowpack/pkg/axios.js";
import {useInterval} from "../../../../_snowpack/pkg/usehooks-ts.js";
import {EMPTY_STRING, OPERATIONAL_FORCE_REFRESH} from "../../../constants/index.js";
import {MthTitle} from "../../../enums/index.js";
import {
  SNOWPACK_PUBLIC_APP_VERSION,
  SNOWPACK_PUBLIC_APP_VERSION_POLLING_RATE,
  SNOWPACK_PUBLIC_WEB_URL
} from "../../../envs/index.js";
import {useIsMobile} from "../../../hooks/index.js";
const axiosVersionCheckConfig = {
  url: `${SNOWPACK_PUBLIC_WEB_URL}/version.json`,
  method: "GET"
};
import {getStyles} from "./AutoUpdateBanner.styles.js";
export const BANNER_HEIGHT = 60;
export const AutoUpdateBanner = ({
  setVersionUpdateRequired,
  versionUpdateRequired
}) => {
  const operationalForceRefresh = useFlag(OPERATIONAL_FORCE_REFRESH);
  const isMobile = useIsMobile();
  const shouldPollForNewVersion = operationalForceRefresh && !versionUpdateRequired && SNOWPACK_PUBLIC_APP_VERSION !== EMPTY_STRING;
  useInterval(async () => {
    const response = await axios(axiosVersionCheckConfig);
    if (response.status === 200) {
      const newAppVersion = response.data.version;
      if (newAppVersion !== SNOWPACK_PUBLIC_APP_VERSION) {
        datadogLogs.logger.log(`New version is ready to be updated: ${newAppVersion}`, {
          currentVersion: SNOWPACK_PUBLIC_APP_VERSION,
          newVersion: newAppVersion
        });
        setVersionUpdateRequired(true);
      }
    } else {
      datadogLogs.logger.error("Error Retrieving latest version...", {responseStatus: response.status});
    }
  }, shouldPollForNewVersion ? SNOWPACK_PUBLIC_APP_VERSION_POLLING_RATE : null);
  const styles = getStyles(isMobile);
  if (versionUpdateRequired)
    return /* @__PURE__ */ React.createElement(Box, {
      sx: styles.container
    }, /* @__PURE__ */ React.createElement(Box, {
      sx: styles.textContainer
    }, MthTitle.UPDATE_BANNER_TEXT), /* @__PURE__ */ React.createElement(Box, {
      sx: styles.buttonContainer
    }, /* @__PURE__ */ React.createElement(Button, {
      variant: "contained",
      onClick: () => {
        datadogLogs.logger.log("Updating to the new version...");
        window.location.reload();
      },
      sx: styles.button
    }, MthTitle.UPDATE_BANNER_BTN)));
  return null;
};
