import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {UNASSIGNED} from "../../constants/index.js";
import {GetSchoolsOfEnrollment} from "../../../screens/Admin/SiteManagement/SchoolPartner/services.js";
export const useSchoolPartnerListByRegionIdReports = (regionId, schoolYearIds) => {
  const [schoolofEnrollments, setSchoolofEnrollments] = useState([]);
  const {
    loading,
    data: schoolOfEnrollments,
    error
  } = useQuery(GetSchoolsOfEnrollment, {
    variables: {
      schoolPartnerArgs: {
        region_id: regionId,
        sort: schoolYearIds ? {column: "name", direction: "ASC"} : null,
        school_year_id: schoolYearIds
      }
    },
    skip: !regionId || !schoolYearIds,
    fetchPolicy: "network-only"
  });
  const [schoolOfEnrollmentList, setSchoolOfEnrollmentList] = useState([]);
  useEffect(() => {
    if (schoolOfEnrollments?.getSchoolsOfEnrollmentByRegion) {
      const auxSOE = [];
      schoolOfEnrollments?.getSchoolsOfEnrollmentByRegion.forEach((item) => {
        item.schoolEnrollmentYear.forEach((enrollmentYear) => {
          if (enrollmentYear.active) {
            const exists = auxSOE.find((soe) => soe.abbreviation === item.abbreviation);
            if (exists)
              exists.school_partner_id = `${exists.school_partner_id},${enrollmentYear.id}`;
            else
              auxSOE.push({
                abbreviation: item.abbreviation,
                school_partner_id: item.id,
                active: true
              });
          }
        });
      });
      auxSOE.unshift({abbreviation: UNASSIGNED, school_partner_id: UNASSIGNED, active: true});
      setSchoolOfEnrollmentList(auxSOE.filter((item) => !!item.active).map((item) => ({
        label: item.abbreviation,
        value: `${item.school_partner_id}`
      })));
    }
  }, [schoolOfEnrollments]);
  return {
    loading,
    selectedSchoolofEnrollments: schoolofEnrollments,
    setSelectedSchoolofEnrollments: setSchoolofEnrollments,
    schoolOfEnrollmentList,
    error
  };
};
