import {ColumnValueEnum} from "../../../screens/Admin/Reports/Master/Results/defaultValues.js";
export const removeUniqueIdColumns = (columns, featureFlag) => {
  if (featureFlag) {
    const result = columns.reduce((prev, current) => {
      if (current.value === ColumnValueEnum.PARENT_UNIQUE_ID || current.value === ColumnValueEnum.STUDENT_UNIQUE_ID)
        return prev;
      prev.push(current);
      return prev;
    }, []);
    return result;
  }
  return columns;
};
