import React, {useState} from "../../../../_snowpack/pkg/react.js";
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import {WarningModal} from "../../../../components/WarningModal/Warning.js";
import {
  SoETransferOption,
  SoETransferOptionText,
  TEXT_SIZE,
  WarningMessagesButtons,
  WarningMessagesSubtitles,
  WarningMessagesTitles
} from "../../../enums/index.js";
import {Paragraph} from "../../shared/Paragraph/index.js";
import {testIDs} from "./__tests__/StudentSoeTransferModal.testids.js";
export const StudentSoeTransferModal = ({
  unassign = false,
  handleSubmit,
  handleClose
}) => {
  const [transferOption, setTransferOption] = useState(unassign ? SoETransferOption.UNASSSIGN : void 0);
  const [error, setError] = useState(false);
  const internalHandleSubmit = () => {
    if (transferOption)
      handleSubmit(transferOption);
    else
      setError(true);
  };
  const handleOptionSelected = (option) => {
    setError(false);
    setTransferOption(option);
  };
  return /* @__PURE__ */ React.createElement(WarningModal, {
    title: WarningMessagesTitles.SCHOOL_OF_ENROLLMENT,
    subtitle: WarningMessagesSubtitles.SOE_TRANSFER,
    btntitle: WarningMessagesButtons.ASSIGN,
    canceltitle: WarningMessagesButtons.CANCEL,
    handleModem: handleClose,
    handleSubmit: internalHandleSubmit,
    textCenter: true,
    modalWidth: "500px",
    upperCase: false,
    error: !transferOption && error
  }, unassign ? /* @__PURE__ */ React.createElement(Typography, {
    sx: {
      marginBottom: "10px",
      width: "100%",
      textAlign: "center"
    }
  }, SoETransferOptionText.UNASSSIGN) : /* @__PURE__ */ React.createElement(Box, {
    sx: {marginLeft: "40px", marginTop: "30px", overflow: "auto", textAlign: "start"}
  }, /* @__PURE__ */ React.createElement(FormControl, null, /* @__PURE__ */ React.createElement(RadioGroup, {
    "aria-labelledby": "demo-controlled-radio-buttons-group",
    name: "controlled-radio-buttons-group",
    value: transferOption,
    onChange: (e) => handleOptionSelected(Number(e.target.value))
  }, /* @__PURE__ */ React.createElement(FormControlLabel, {
    value: SoETransferOption.ASSIGN,
    "data-testid": testIDs.optionAssign,
    control: /* @__PURE__ */ React.createElement(Radio, {
      sx: {marginRight: "20px"}
    }),
    label: /* @__PURE__ */ React.createElement(Paragraph, {
      size: TEXT_SIZE.LARGE,
      fontWeight: "600"
    }, SoETransferOptionText.ASSIGN)
  }), /* @__PURE__ */ React.createElement(FormControlLabel, {
    value: SoETransferOption.ASSIGN_AND_FORM,
    "data-testid": testIDs.optionAssignAndForm,
    control: /* @__PURE__ */ React.createElement(Radio, {
      sx: {marginRight: "20px"}
    }),
    label: /* @__PURE__ */ React.createElement(Paragraph, {
      size: TEXT_SIZE.LARGE,
      fontWeight: "600"
    }, SoETransferOptionText.ASSIGN_AND_FORM)
  })))));
};
