export var MthUpdatePassword;
(function(MthUpdatePassword2) {
  MthUpdatePassword2["CHANGE_PASSWORD"] = "Change Password";
  MthUpdatePassword2["NEW_PASSWORD_SAVED"] = "New Password Saved";
  MthUpdatePassword2["REENTER_NEW_PASSWORD"] = "Re-enter New Password";
  MthUpdatePassword2["NEW_PASSWORD"] = "New Password";
  MthUpdatePassword2["PASSWORD"] = "Password";
  MthUpdatePassword2["PASSWORD_HINT"] = "Passwords must contain 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character.";
  MthUpdatePassword2["CURRENT_PASSWORD"] = "Current Password";
})(MthUpdatePassword || (MthUpdatePassword = {}));
