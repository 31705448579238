import React from "../../_snowpack/pkg/react.js";
import ErrorOutlineIcon from "../../_snowpack/pkg/@mui/icons-material/ErrorOutline.js";
import {Box, Button, Modal} from "../../_snowpack/pkg/@mui/material.js";
import {useFlag} from "../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {EPIC_3537_STORY_5147} from "../../core/constants/index.js";
import {MthColor} from "../../core/enums/index.js";
import {Paragraph} from "../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../core/components/shared/Subtitle/Subtitle.js";
import {useStyles} from "./styles.js";
export const CustomConfirmModal = ({
  header,
  headerFontSize,
  content,
  confirmBtnTitle = "Yes",
  cancelBtnTitle = "Cancel",
  maxWidth = 441,
  height = 295,
  padding = "32px",
  handleConfirmModalChange
}) => {
  const epic3537story5147 = useFlag(EPIC_3537_STORY_5147);
  const classes = useStyles;
  const handleConfirm = () => {
    handleConfirmModalChange(true);
  };
  const handleCancel = () => {
    handleConfirmModalChange(false);
  };
  return /* @__PURE__ */ React.createElement(Modal, {
    open: true
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {...classes.modalCard, maxWidth, height, paddingX: padding}
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.header
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: headerFontSize
  }, header)), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.content
  }, /* @__PURE__ */ React.createElement(ErrorOutlineIcon, {
    style: classes.errorOutline
  }), /* @__PURE__ */ React.createElement(Paragraph, {
    size: "large",
    color: MthColor.SYSTEM_01,
    textAlign: "center",
    sx: epic3537story5147 ? {whiteSpace: "pre-line"} : {}
  }, content), /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "row"
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    disableElevation: true,
    sx: classes.cancelButton,
    onClick: handleCancel
  }, cancelBtnTitle), /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    disableElevation: true,
    sx: classes.submitButton,
    onClick: handleConfirm
  }, confirmBtnTitle)))));
};
