export var ProgramDetailSlugs;
(function(ProgramDetailSlugs2) {
  ProgramDetailSlugs2["DIPLOMA_SEEKING_STATUS"] = "diplomaSeekingStatus";
  ProgramDetailSlugs2["SCHOOL_YEAR"] = "schoolYear";
  ProgramDetailSlugs2["STUDENT_STATUS"] = "studentStatus";
  ProgramDetailSlugs2["STUDENT_STATUS_DATE"] = "studentStatusDate";
  ProgramDetailSlugs2["SOE"] = "soe";
  ProgramDetailSlugs2["SCHOOL_DISTRICT_CODES"] = "schoolDistrictCodes";
  ProgramDetailSlugs2["SOE_STATUS"] = "soeStatus";
})(ProgramDetailSlugs || (ProgramDetailSlugs = {}));
