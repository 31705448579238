import React, {useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../../../_snowpack/pkg/@apollo/client.js";
import {Grid} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../../_snowpack/pkg/@mui/system.js";
import {useFormContext} from "../../../../../_snowpack/pkg/react-hook-form.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor} from "../../../../../core/enums/index.js";
import {getSignatureFile} from "../../../../../graphql/queries/get-signature-file.js";
export const SignatureComp = () => {
  const {watch} = useFormContext();
  const signature_file_id = watch("signature_file_id");
  const [signedUrl, setSignedUrl] = useState("");
  const [getSignatureFileUrl, {loading: signatureFileUrlLoading, data: signatureFileData}] = useLazyQuery(getSignatureFile, {
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (signature_file_id) {
      getSignatureFileUrl({
        variables: {
          fileId: signature_file_id
        }
      });
    }
  }, [signature_file_id]);
  useEffect(() => {
    if (!signatureFileUrlLoading && signatureFileData?.signatureFile?.signedUrl) {
      setSignedUrl(signatureFileData?.signatureFile?.signedUrl);
    }
  }, [signatureFileUrlLoading, signatureFileData]);
  return /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    sx: {display: "flex", justifyContent: "center", marginTop: "150px"}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 9,
    sm: 9,
    xs: 9,
    sx: {textAlign: "center"}
  }, signedUrl && /* @__PURE__ */ React.createElement("img", {
    src: signedUrl,
    alt: "signature",
    style: {width: "50%"}
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 9,
    sm: 9,
    xs: 9
  }, /* @__PURE__ */ React.createElement("hr", {
    style: {borderTop: `solid 1px ${MthColor.SYSTEM_01}`, borderBottom: "0"}
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 12,
    sm: 12,
    xs: 12,
    sx: {display: "flex", justifyContent: "center"}
  }, /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "column"
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    size: "small",
    fontWeight: "500"
  }, "Signature"))));
};
