import {atom} from "../../_snowpack/pkg/recoil.js";
import {EMPTY_STRING} from "../../core/constants/index.js";
export const loadingState = atom({
  key: "loading",
  default: false
});
export const editModeLearningLogState = atom({
  key: "editMode",
  default: false
});
export const saveLearningLogState = atom({
  key: "saveLearningLog",
  default: false
});
export const studentRegionFromTeacherState = atom({
  key: "studentRegionFromTeacher",
  default: {regionId: 1, regionName: EMPTY_STRING}
});
export const reloadComponentState = atom({
  key: "reloadComponent",
  default: false
});
export const confirmDialogBeforeLeave = atom({
  key: "confirmDialogBeforeLeave",
  default: {
    activate: false,
    header: EMPTY_STRING,
    content: EMPTY_STRING
  }
});
export const modalConfirmDialogBeforeLeave = atom({
  key: "modalConfirmDialogBeforeLeave",
  default: false
});
export const regionSelectedDialogBeforeLeave = atom({
  key: "regionSelectedDialogBeforeLeave",
  default: null
});
export const isLoggingOut = atom({
  key: "isLoggingOut",
  default: false
});
