export var StateCourseCodesField;
(function(StateCourseCodesField2) {
  StateCourseCodesField2["STATE_COURSE_CODES"] = "stateCourseCodes";
  StateCourseCodesField2["TEACHER"] = "teacher";
  StateCourseCodesField2["STATE_CODE"] = "stateCode";
  StateCourseCodesField2["STATE_CODES"] = "stateCodes";
  StateCourseCodesField2["PAGE_LIMIT"] = "pageLimit";
  StateCourseCodesField2["CURRENT_PAGE"] = "currentPage";
  StateCourseCodesField2["TITLE_ID"] = "titleId";
  StateCourseCodesField2["GRADE"] = "grade";
  StateCourseCodesField2["SUBJECT"] = "subject";
  StateCourseCodesField2["TITLE_NAME"] = "titleName";
  StateCourseCodesField2["ACTION"] = "action";
})(StateCourseCodesField || (StateCourseCodesField = {}));
