import {compare, isTypeDate, isValidDate} from "../date/date.util.js";
import {gradeToNumber} from "../grade-text/grade-text.util.js";
export var SortType;
(function(SortType2) {
  SortType2["NONE"] = "";
  SortType2["GRADE"] = "grade";
})(SortType || (SortType = {}));
export const sortByProperties = (data, sortOptions, customType = {}, formatters = {}, disableSort = false) => {
  if (disableSort)
    return data;
  return data.sort((a, b) => {
    for (const property in sortOptions) {
      if (sortOptions.hasOwnProperty(property)) {
        if (sortOptions[property] == null) {
          continue;
        }
        const sortOrder = sortOptions[property] === "desc" ? -1 : 1;
        let valueA;
        let valueB;
        if (formatters[property]) {
          valueA = formatters[property](a) ?? "";
          valueB = formatters[property](b) ?? "";
        } else {
          valueA = property.split(".").reduce((o, k) => o?.[k] ?? "", a);
          valueB = property.split(".").reduce((o, k) => o?.[k] ?? "", b);
        }
        const customSortType = customType[property] ?? null;
        if (customSortType === SortType.GRADE) {
          try {
            valueA = gradeToNumber(valueA);
          } catch (error) {
            valueA = -1;
          }
          try {
            valueB = gradeToNumber(valueB);
          } catch (error) {
            valueB = -1;
          }
        } else if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
          valueA = Number(valueA);
          valueB = Number(valueB);
        } else if (isTypeDate(valueA) && isTypeDate(valueB)) {
          valueA = compare(valueA, valueB);
          valueB = 0;
        } else if (isValidDate(valueA) && isValidDate(valueB)) {
          valueA = compare(new Date(valueA), new Date(valueB));
          valueB = 0;
        } else if (typeof valueA === "string" && typeof valueB === "string") {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
        }
        if (valueA !== valueB) {
          const result = valueA > valueB ? 1 * sortOrder : -1 * sortOrder;
          return result;
        }
      }
    }
    return 0;
  });
};
