import React from "../../../../../_snowpack/pkg/react.js";
import {Grid} from "../../../../../_snowpack/pkg/@mui/material.js";
import {MthBulletEditor} from "../../../../../components/MthBulletEditor/index.js";
import {EMPTY_STRING} from "../../../../../core/constants/index.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/index.js";
import {TEXT_SIZE} from "../../../../../core/enums/index.js";
import {subtitle} from "./Subtitle.enum.js";
export const ShareTextArea = ({
  subtittleStyle,
  textFieldStyle,
  testId,
  notes,
  setNotes,
  subtitle: subtitle2,
  editable,
  isEditedByExternal
}) => {
  return /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: TEXT_SIZE.LARGE,
    textAlign: "left",
    sx: subtittleStyle
  }, subtitle2), /* @__PURE__ */ React.createElement(MthBulletEditor, {
    testId,
    classStyle: textFieldStyle,
    readOnly: !editable,
    value: notes || EMPTY_STRING,
    maxHeight: "145px",
    height: "145px",
    setValue: setNotes,
    hideToolbar: true,
    isEditedByExternal
  }));
};
export const FamilyNotesAndStudentNotes = ({
  subtittleStyle,
  textFieldStyle,
  familyNotesTestId,
  studentNotesTestId,
  notes,
  setNotes,
  studentNotes,
  setStudentNotes,
  isFamilyNotesEditable,
  isEditedByExternal
}) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ShareTextArea, {
    subtittleStyle,
    textFieldStyle,
    testId: familyNotesTestId,
    notes,
    setNotes,
    subtitle: subtitle.FAMILY_NOTES,
    editable: isFamilyNotesEditable
  }), /* @__PURE__ */ React.createElement(ShareTextArea, {
    subtittleStyle,
    textFieldStyle,
    testId: studentNotesTestId,
    notes: studentNotes,
    setNotes: setStudentNotes,
    subtitle: subtitle.STUDENT_NOTES,
    editable: !isFamilyNotesEditable,
    isEditedByExternal
  }));
};
