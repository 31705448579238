export var ReimbursementQuestionSlug;
(function(ReimbursementQuestionSlug2) {
  ReimbursementQuestionSlug2["STUDENT_ID"] = "reimbursement_student_id";
  ReimbursementQuestionSlug2["FORM_TYPE"] = "reimbursement_form_type";
  ReimbursementQuestionSlug2["TOTAL_AMOUNT"] = "reimbursement_total_amount_requested";
  ReimbursementQuestionSlug2["SIGNATURE_NAME"] = "reimbursement_signature_name";
  ReimbursementQuestionSlug2["RECEIPTS"] = "reimbursement_receipts";
  ReimbursementQuestionSlug2["PERIOD"] = "reimbursement_period";
  ReimbursementQuestionSlug2["NEW_DEVICE"] = "reimbursement_new_device";
  ReimbursementQuestionSlug2["NEW_DEVICE_INFORMATION"] = "reimbursement_new_device_information";
  ReimbursementQuestionSlug2["NEW_DEVICE_ITEM_NAME"] = "reimbursement_new_device_item_name";
  ReimbursementQuestionSlug2["NEW_DEVICE_ITEM_SERIAL_NUMBER"] = "reimbursement_new_device_item_serial_number";
})(ReimbursementQuestionSlug || (ReimbursementQuestionSlug = {}));
export var ReimbursementQuestionTitle;
(function(ReimbursementQuestionTitle2) {
  ReimbursementQuestionTitle2["STUDENT"] = "Student";
  ReimbursementQuestionTitle2["TYPE"] = "Type";
  ReimbursementQuestionTitle2["RECEIPTS"] = "Receipts";
  ReimbursementQuestionTitle2["TOTAL_AMOUNT"] = "Total Amount Requested";
  ReimbursementQuestionTitle2["SIGNATURE"] = "Signature";
  ReimbursementQuestionTitle2["PERIOD_RESOURCES"] = "Periods | Resources";
  ReimbursementQuestionTitle2["PERIODS"] = "Periods";
  ReimbursementQuestionTitle2["RESOURCES"] = "Resources";
})(ReimbursementQuestionTitle || (ReimbursementQuestionTitle = {}));
