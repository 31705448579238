import {MthColor} from "../../core/enums/index.js";
export const useStyles = {
  root: {
    flexGrow: 1
  },
  pageNumber: {
    "& .MuiButtonBase-root": {
      color: MthColor.VORTEX,
      fontWeight: 700
    },
    "& .MuiPaginationItem-previousNext": {
      color: MthColor.BLACK
    }
  }
};
