import {useContext, useEffect, useMemo, useState} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {useFlag} from "../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {orderBy} from "../../../_snowpack/pkg/lodash.js";
import {EPIC_3666_BUG_3798} from "../../constants/index.js";
import {RoleLevel, ScheduleStatus, dateSettings} from "../../enums/index.js";
import {UserContext} from "../../../providers/UserContext/UserProvider.js";
import {getActiveScheduleSchoolYearsQuery} from "../../../screens/Homeroom/Schedule/services.js";
import {formatSchoolYear, getTimezone} from "../../utils/index.js";
export const useActiveScheduleSchoolYears = (studentId, defaultSchoolYearId) => {
  const {me} = useContext(UserContext);
  const timezone = me ? getTimezone(me) : dateSettings.UTC;
  const [dropdownItems, setDropdownItems] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedYearId, setSelectedYearId] = useState();
  const [activeScheduleYearId, setActiveScheduleYearId] = useState();
  const epic3666Bug3798 = useFlag(EPIC_3666_BUG_3798);
  const isNotAdmin = epic3666Bug3798 && me?.level !== RoleLevel.SUPER_ADMIN;
  const [getActiveScheduleSchoolYears, {loading, data, error, refetch}] = useLazyQuery(getActiveScheduleSchoolYearsQuery, {
    fetchPolicy: "cache-first"
  });
  useEffect(() => {
    if (studentId) {
      getActiveScheduleSchoolYears({
        variables: {
          studentId,
          isNotAdmin
        }
      });
    }
  }, [studentId]);
  useEffect(() => {
    if (data?.activeScheduleSchoolYears) {
      const {activeScheduleSchoolYears: schoolYears2} = data;
      setDropdownItems(orderBy(schoolYears2, "date_begin", isNotAdmin ? "desc" : "asc").slice(-4).map((item) => ({
        value: item.school_year_id,
        label: formatSchoolYear(item.date_begin, item.date_end, timezone)
      })));
      setSchoolYears(schoolYears2);
    }
  }, [loading, data]);
  useEffect(() => {
    if (schoolYears?.length) {
      const currentYear = schoolYears.filter((item) => item.IsCurrentYear)[0];
      const activeScheduleYear = currentYear?.ScheduleStatus === ScheduleStatus.SUBMITTED || currentYear?.ScheduleStatus === ScheduleStatus.RESUBMITTED || currentYear?.ScheduleStatus === ScheduleStatus.ACCEPTED ? currentYear : schoolYears.filter((item) => (item.ScheduleStatus === ScheduleStatus.SUBMITTED || item.ScheduleStatus === ScheduleStatus.ACCEPTED) && item.IsScheduleBuilderOpen)[0];
      const latestPendingYear = schoolYears.filter((item) => item.ScheduleStatus === null && item.IsScheduleBuilderOpen).at(-1);
      const defaultSchoolYear = schoolYears.filter((item) => item.school_year_id == defaultSchoolYearId)[0];
      setSelectedYearId(defaultSchoolYear?.school_year_id || activeScheduleYear?.school_year_id || latestPendingYear?.school_year_id || currentYear?.school_year_id || schoolYears.at(-1)?.school_year_id);
      setActiveScheduleYearId(activeScheduleYear?.school_year_id);
    } else {
      setSelectedYearId(void 0);
    }
  }, [schoolYears]);
  const selectedYear = useMemo(() => {
    if (selectedYearId && schoolYears.length) {
      return schoolYears.find((item) => item.school_year_id == selectedYearId);
    } else
      return void 0;
  }, [selectedYearId, schoolYears]);
  return {
    loading,
    schoolYears,
    dropdownItems,
    error,
    refetchSchoolYear: refetch,
    selectedYearId,
    setSelectedYearId,
    selectedYear,
    activeScheduleYearId
  };
};
