export var SEMESTER_TYPE;
(function(SEMESTER_TYPE2) {
  SEMESTER_TYPE2["NONE"] = "NONE";
  SEMESTER_TYPE2["PERIOD"] = "PERIOD";
  SEMESTER_TYPE2["SUBJECT"] = "SUBJECT";
})(SEMESTER_TYPE || (SEMESTER_TYPE = {}));
export const SEMESTER_MESSAGE = {
  [SEMESTER_TYPE.NONE]: "None",
  [SEMESTER_TYPE.PERIOD]: "Unlock this Period",
  [SEMESTER_TYPE.SUBJECT]: "Only unlock Subjects mapped to this Period"
};
