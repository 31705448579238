export var MthColor;
(function(MthColor2) {
  MthColor2["TANGERINE"] = "#F24915";
  MthColor2["SKY"] = "#03A4EA";
  MthColor2["ICE"] = "#FAFAF6";
  MthColor2["VORTEX"] = "#141A1F";
  MthColor2["ACTIVE_LINK_BG"] = "rgba(242, 73, 21, 0.04)";
  MthColor2["RGBA_PURPLE"] = "rgba(62, 39, 131, 0)";
  MthColor2["RED"] = "#D23C33";
  MthColor2["RED_SECONDARY"] = "red";
  MthColor2["GRAY"] = "#CCCCCC";
  MthColor2["GRAY_7F"] = "#7f7f7f";
  MthColor2["LIGHTGRAY"] = "#F2F2F2";
  MthColor2["MEDIUMGRAY"] = "#D1D1D1";
  MthColor2["DARKGRAY"] = "#545454";
  MthColor2["BLACK"] = "#000000";
  MthColor2[MthColor2["MTHBLUE"] = MthColor2.TANGERINE] = "MTHBLUE";
  MthColor2[MthColor2["MTHBLUE_DISABLED"] = `${MthColor2.VORTEX}80`] = "MTHBLUE_DISABLED";
  MthColor2[MthColor2["MTHPURPLE"] = MthColor2.VORTEX] = "MTHPURPLE";
  MthColor2["MTHORANGE"] = "#EC5925";
  MthColor2["MTHGREEN"] = "#2B9EB7";
  MthColor2["GREEN"] = "#38892B";
  MthColor2["LIGHTGREEN"] = "rgba(50,200,23,0.3)";
  MthColor2[MthColor2["LIGHTGREEN2"] = MthColor2.GREEN] = "LIGHTGREEN2";
  MthColor2[MthColor2["LIGHTPURPLE"] = MthColor2.VORTEX] = "LIGHTPURPLE";
  MthColor2["LIGHTRED"] = "#fdeee9";
  MthColor2["LIGHTBLUE"] = "#ececff";
  MthColor2["WHITE"] = "#FFFFFF";
  MthColor2["MTHBLACK"] = "#333333";
  MthColor2["LIGHTGRAY_02"] = "#EEEEEE";
  MthColor2["BG_MAIN"] = "#FAFAFA";
  MthColor2["MTHLIGHTGRAY"] = "rgba(26, 26, 26, 0.25)";
  MthColor2["SYSTEM_01"] = "#0E0E0E";
  MthColor2["SYSTEM_02"] = "#313131";
  MthColor2["SYSTEM_03"] = "#575757";
  MthColor2["SYSTEM_04"] = "#6E6E6E";
  MthColor2["SYSTEM_05"] = "#767676";
  MthColor2["SYSTEM_06"] = "#A1A1A1";
  MthColor2["SYSTEM_07"] = "#CCCCCC";
  MthColor2["SYSTEM_08"] = "#E7E7E7";
  MthColor2["SYSTEM_09"] = "#EEF4F8";
  MthColor2["SYSTEM_10"] = "#CCCCCC";
  MthColor2["SYSTEM_11"] = "#898C8F";
  MthColor2["SYSTEM_12"] = "#A3A3A4";
  MthColor2["SYSTEM_13"] = "#1A1A1A";
  MthColor2["SYSTEM_14"] = "#F5F5F5";
  MthColor2["SCROLLBAR_THUMB"] = "#888888";
  MthColor2["SCROLLBAR_THUMB_HOVER"] = "#555555";
  MthColor2["DROPDOWN_BORDER"] = "rgba(0, 0, 0, 0.23)";
  MthColor2[MthColor2["PRIMARY_MEDIUM_MOUSEOVER"] = MthColor2.SKY] = "PRIMARY_MEDIUM_MOUSEOVER";
  MthColor2["BLACK_MOUSEOVER"] = "#292626";
  MthColor2[MthColor2["PRIMARY_SMALL_DEFAULT"] = MthColor2.SKY] = "PRIMARY_SMALL_DEFAULT";
  MthColor2["ERROR_SNACKBAR_BG"] = "rgba(236, 89, 37, 0.7)";
  MthColor2["ORIGINAL_SETTINGS_BACKGROUND"] = "background-color: rgb(250,250,250)";
  MthColor2["UPDATED_SETTINGS_BACKGROUND"] = "background-color: rgb(255,255,255)";
  MthColor2["DISABLED"] = "#0E0E0E80";
  MthColor2["SHADOW"] = "rgba(0, 0, 0, 0.05)";
  MthColor2["LIGHTREDBG"] = "rgba(236, 89, 37, 0.1)";
  MthColor2["OVERLAY"] = "#535252";
  MthColor2["YELLOW"] = "#D3AD0A";
  MthColor2["YELLOW2"] = "#FFD626";
  MthColor2["LL_LINK"] = "#038ac5";
  MthColor2[MthColor2["BUTTON_LINEAR_GRADIENT"] = `linear-gradient(to right, ${MthColor2.SKY}, ${MthColor2.SKY}), ${MthColor2.SKY}`] = "BUTTON_LINEAR_GRADIENT";
  MthColor2[MthColor2["BUTTON_LINEAR_GRADIENT_DARK"] = `linear-gradient(to right, ${MthColor2.VORTEX}, ${MthColor2.VORTEX}), ${MthColor2.VORTEX}`] = "BUTTON_LINEAR_GRADIENT_DARK";
  MthColor2[MthColor2["BUTTON_LINEAR_GRADIENT_DARK_HOVER"] = MthColor2.BUTTON_LINEAR_GRADIENT_DARK] = "BUTTON_LINEAR_GRADIENT_DARK_HOVER";
  MthColor2[MthColor2["RED_GRADIENT"] = `radial-gradient(circle, ${MthColor2.VORTEX} 0%, ${MthColor2.VORTEX} calc(100% - 8px), ${MthColor2.RED} calc(100% - 7px), ${MthColor2.RED} 100%), ${MthColor2.RED}`] = "RED_GRADIENT";
  MthColor2[MthColor2["BUTTON_RED_GRADIENT"] = MthColor2.RED_GRADIENT] = "BUTTON_RED_GRADIENT";
  MthColor2[MthColor2["BLUE_GRADIENT"] = `linear-gradient(to right, ${MthColor2.TANGERINE}, ${MthColor2.TANGERINE}), ${MthColor2.TANGERINE}`] = "BLUE_GRADIENT";
  MthColor2[MthColor2["YELLOW_GRADIENT"] = `radial-gradient(circle, ${MthColor2.VORTEX} 0%, ${MthColor2.VORTEX} calc(100% - 8px), ${MthColor2.YELLOW2} calc(100% - 7px), ${MthColor2.YELLOW2} 100%), ${MthColor2.YELLOW2}`] = "YELLOW_GRADIENT";
  MthColor2[MthColor2["GREEN_GRADIENT"] = `radial-gradient(circle, ${MthColor2.VORTEX} 0%, ${MthColor2.VORTEX} calc(100% - 8px), ${MthColor2.GREEN} calc(100% - 7px), ${MthColor2.GREEN} 100%), ${MthColor2.GREEN}`] = "GREEN_GRADIENT";
  MthColor2[MthColor2["BLACK_GRADIENT"] = `radial-gradient(circle, ${MthColor2.VORTEX} 0%, ${MthColor2.VORTEX} calc(100% - 8px), ${MthColor2.SYSTEM_02} calc(100% - 7px), ${MthColor2.SYSTEM_02} 100%), ${MthColor2.SYSTEM_02}`] = "BLACK_GRADIENT";
  MthColor2[MthColor2["ORANGE_GRADIENT"] = `radial-gradient(circle, ${MthColor2.VORTEX} 0%, ${MthColor2.VORTEX} calc(100% - 8px), ${MthColor2.MTHORANGE} calc(100% - 7px), ${MthColor2.MTHORANGE} 100%), ${MthColor2.MTHORANGE}`] = "ORANGE_GRADIENT";
  MthColor2[MthColor2["MTHGREEN_GRADIENT"] = `radial-gradient(circle, ${MthColor2.VORTEX} 0%, ${MthColor2.VORTEX} calc(100% - 8px), ${MthColor2.MTHGREEN} calc(100% - 7px), ${MthColor2.MTHGREEN} 100%), ${MthColor2.MTHGREEN}`] = "MTHGREEN_GRADIENT";
})(MthColor || (MthColor = {}));
