import React, {useState} from "../../../../../../_snowpack/pkg/react.js";
import InfoIcon from "../../../../../../_snowpack/pkg/@mui/icons-material/InfoOutlined.js";
import {Box, Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Typography} from "../../../../../../_snowpack/pkg/@mui/material.js";
import {ActivateOption, MthColor} from "../../../../../../core/enums/index.js";
export const ActiveModal = ({
  title,
  description,
  subDescription,
  onClose,
  onActive,
  confirmStr = "Confirm",
  cancelStr = "Cancel",
  backgroundColor = MthColor.WHITE
}) => {
  const [activeOption, setActiveOption] = useState(ActivateOption.DELETE_WITHDRAWAL_FORM);
  const handleChange = (e) => {
    setActiveOption(parseInt(e.target.value));
  };
  const handleWithdraw = () => {
    onActive(activeOption);
  };
  return /* @__PURE__ */ React.createElement(Modal, {
    open: true,
    "aria-labelledby": "child-modal-title",
    disableAutoFocus: true,
    "aria-describedby": "child-modal-description"
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "550px",
      height: "auto",
      bgcolor: backgroundColor ? backgroundColor : MthColor.SYSTEM_09,
      borderRadius: 2,
      display: "flex",
      justifyContent: "center",
      p: 4
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {textAlign: "center"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    fontWeight: "bold"
  }, title), /* @__PURE__ */ React.createElement(InfoIcon, {
    sx: {fontSize: 50, margin: "20px 0px"}
  }), /* @__PURE__ */ React.createElement(Typography, {
    fontWeight: "bold"
  }, description), subDescription && /* @__PURE__ */ React.createElement(Typography, null, subDescription), /* @__PURE__ */ React.createElement(Box, {
    sx: {marginTop: "30px"}
  }, /* @__PURE__ */ React.createElement(FormControl, null, /* @__PURE__ */ React.createElement(RadioGroup, {
    "aria-labelledby": "demo-controlled-radio-buttons-group",
    name: "controlled-radio-buttons-group",
    value: activeOption,
    onChange: handleChange
  }, /* @__PURE__ */ React.createElement(FormControlLabel, {
    value: ActivateOption.DELETE_WITHDRAWAL_FORM,
    control: /* @__PURE__ */ React.createElement(Radio, null),
    label: "Delete Withdraw Form from Records"
  }), /* @__PURE__ */ React.createElement(FormControlLabel, {
    value: ActivateOption.KEEP_WITHDRAWAL_FORM,
    control: /* @__PURE__ */ React.createElement(Radio, null),
    label: "Keep Withdraw Form"
  })))), /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", justifyContent: "space-between", marginTop: "30px", gap: "20px"}
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {width: "160px", height: "36px", background: MthColor.SYSTEM_08, borderRadius: "50px"},
    onClick: onClose
  }, cancelStr), /* @__PURE__ */ React.createElement(Button, {
    sx: {
      width: "160px",
      height: "36px",
      background: "#43484F",
      borderRadius: "50px",
      color: "white"
    },
    onClick: handleWithdraw
  }, confirmStr)))));
};
