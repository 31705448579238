import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getReimbursementQuestionsQuery} from "../../../graphql/queries/reimbursement-question.js";
export const useReimbursementQuestions = (schoolYearId, formType, isDirectOrder) => {
  const [questions, setQuestions] = useState([]);
  const {loading, data, error, refetch} = useQuery(getReimbursementQuestionsQuery, {
    variables: {
      schoolYearId: +(schoolYearId || 0),
      reimbursementFormType: formType,
      isDirectOrder: isDirectOrder ? true : false
    },
    skip: !schoolYearId || !formType,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (data?.reimbursementQuestions) {
      const {reimbursementQuestions} = data;
      setQuestions(reimbursementQuestions?.map((question) => {
        let options;
        try {
          options = JSON.parse(question?.options);
        } catch (e) {
          options = [];
        }
        return {...question, Options: options};
      }) || []);
    }
  }, [loading, data]);
  return {
    loading,
    reimbursementQuestions: questions,
    error,
    refetch
  };
};
