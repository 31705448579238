import * as __SNOWPACK_ENV__ from "../../_snowpack/env.js";
import React, {useContext} from "../../_snowpack/pkg/react.js";
import {ApolloClient, InMemoryCache, ApolloProvider as ApProvider, concat, HttpLink} from "../../_snowpack/pkg/@apollo/client.js";
import {setContext} from "../../_snowpack/pkg/@apollo/client/link/context.js";
import {useFlag} from "../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {jwtDecode} from "../../_snowpack/pkg/jwt-decode.js";
import {isNil} from "../../_snowpack/pkg/lodash.js";
import {useHistory, useLocation} from "../../_snowpack/pkg/react-router-dom.js";
import {EPIC_1582_STORY_4078} from "../../core/constants/index.js";
import {MthRoute, QUERY_PARAMETERS} from "../../core/enums/index.js";
import {AuthContext} from "../AuthProvider/AuthContext.js";
import {UserContext} from "../UserContext/UserProvider.js";
const MILISECONDS = 1e3;
const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / MILISECONDS);
    return decodedToken.exp && decodedToken.exp < currentTime;
  } catch (error) {
    console.error("there was an error validating token expiration");
    console.error(error);
    return true;
  }
};
export const ApolloProvider = ({children, apolloNetworkstatusLink}) => {
  const epic_1582_story_4078 = useFlag(EPIC_1582_STORY_4078);
  const {credentials} = useContext(AuthContext);
  const history = useHistory();
  const {setMe} = useContext(UserContext);
  const {signOut} = useContext(AuthContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const logout = () => {
    setMe(null);
    signOut();
    history.push({
      pathname: MthRoute.DASHBOARD,
      search: new URLSearchParams({[QUERY_PARAMETERS.EXPIRATION]: currentPath}).toString()
    });
  };
  const getToken = () => {
    if (typeof credentials === "string")
      return credentials;
    else if (!isNil(localStorage.getItem("masquerade")))
      return localStorage.getItem("masquerade");
    else
      return localStorage.getItem("JWT");
  };
  const authLink = setContext((_, {headers}) => {
    const token = getToken();
    if (token && isTokenExpired(token)) {
      logout();
    }
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });
  const httpLink = new HttpLink({uri: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL});
  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false
    }),
    link: epic_1582_story_4078 ? apolloNetworkstatusLink.concat(concat(authLink, httpLink)) : concat(authLink, httpLink)
  });
  return /* @__PURE__ */ React.createElement(ApProvider, {
    client
  }, children);
};
