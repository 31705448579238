import {r as react} from "./common/index-c6c556cc.js";
import {l as lodash_debounce} from "./common/index-43475f19.js";
import "./common/_commonjsHelpers-53bde9c7.js";
var useIsomorphicLayoutEffect = typeof window !== "undefined" ? react.useLayoutEffect : react.useEffect;
function useEventListener(eventName, handler, element, options) {
  const savedHandler = react.useRef(handler);
  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  react.useEffect(() => {
    const targetElement = (element == null ? void 0 : element.current) ?? window;
    if (!(targetElement && targetElement.addEventListener))
      return;
    const listener = (event) => {
      savedHandler.current(event);
    };
    targetElement.addEventListener(eventName, listener, options);
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
}
function useInterval(callback, delay) {
  const savedCallback = react.useRef(callback);
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  react.useEffect(() => {
    if (delay === null) {
      return;
    }
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
function useUnmount(func) {
  const funcRef = react.useRef(func);
  funcRef.current = func;
  react.useEffect(() => () => {
    funcRef.current();
  }, []);
}
function useDebounceCallback(func, delay = 500, options) {
  const debouncedFunc = react.useRef();
  useUnmount(() => {
    if (debouncedFunc.current) {
      debouncedFunc.current.cancel();
    }
  });
  const debounced = react.useMemo(() => {
    const debouncedFuncInstance = lodash_debounce(func, delay, options);
    const wrappedFunc = (...args) => {
      return debouncedFuncInstance(...args);
    };
    wrappedFunc.cancel = () => {
      debouncedFuncInstance.cancel();
    };
    wrappedFunc.isPending = () => {
      return !!debouncedFunc.current;
    };
    wrappedFunc.flush = () => {
      return debouncedFuncInstance.flush();
    };
    return wrappedFunc;
  }, [func, delay, options]);
  react.useEffect(() => {
    debouncedFunc.current = lodash_debounce(func, delay, options);
  }, [func, delay, options]);
  return debounced;
}
var IS_SERVER6 = typeof window === "undefined";
function useWindowSize(options = {}) {
  let {initializeWithValue = true} = options;
  if (IS_SERVER6) {
    initializeWithValue = false;
  }
  const [windowSize, setWindowSize] = react.useState(() => {
    if (initializeWithValue) {
      return {
        width: window.innerWidth,
        height: window.innerHeight
      };
    }
    return {
      width: void 0,
      height: void 0
    };
  });
  const debouncedSetWindowSize = useDebounceCallback(setWindowSize, options == null ? void 0 : options.debounceDelay);
  function handleSize() {
    const setSize = (options == null ? void 0 : options.debounceDelay) ? debouncedSetWindowSize : setWindowSize;
    setSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }
  useEventListener("resize", handleSize);
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, []);
  return windowSize;
}
export {useDebounceCallback, useInterval, useWindowSize};
