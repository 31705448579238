export var UsernameFormat;
(function(UsernameFormat2) {
  UsernameFormat2["FIRST_LAST"] = "FIRST_LAST";
  UsernameFormat2["LAST_FIRST_YEAR"] = "LAST_FIRST_YEAR";
  UsernameFormat2["LAST_FIRST"] = "LAST_FIRST";
  UsernameFormat2["LAST_FIRSTINITIAL"] = "LAST_FIRSTINITIAL";
  UsernameFormat2["LAST_FIRST_OE"] = "LAST_FIRST_OE";
  UsernameFormat2["LAST_FIRST_MTH"] = "LAST_FIRST_MTH";
  UsernameFormat2["LAST_FIRST_BIRTH_YEAR"] = "LAST_FIRST_BIRTH_YEAR";
  UsernameFormat2["FIRST_LAST_DOMAIN"] = "FIRST_LAST_DOMAIN";
  UsernameFormat2["FIRST_LAST_OPENED_DOMAIN"] = "FIRST_LAST_OPENED_DOMAIN";
  UsernameFormat2["STUDENT_EMAIL"] = "STUDENT_EMAIL";
  UsernameFormat2["PARENT_EMAIL"] = "PARENT_EMAIL";
  UsernameFormat2["GENERIC"] = "GENERIC";
  UsernameFormat2["ID_FIRSTINITIAL_LASTINITIAL"] = "ID_FIRSTINITIAL_LASTINITIAL";
})(UsernameFormat || (UsernameFormat = {}));
