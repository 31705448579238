export var ReportQuestionType;
(function(ReportQuestionType2) {
  ReportQuestionType2[ReportQuestionType2["ALL"] = 0] = "ALL";
  ReportQuestionType2[ReportQuestionType2["DEFAULT"] = 1] = "DEFAULT";
  ReportQuestionType2[ReportQuestionType2["APPLICATION"] = 2] = "APPLICATION";
  ReportQuestionType2[ReportQuestionType2["ENROLLMENT"] = 3] = "ENROLLMENT";
  ReportQuestionType2[ReportQuestionType2["PROGRAM_DETAILS"] = 4] = "PROGRAM_DETAILS";
  ReportQuestionType2[ReportQuestionType2["CUSTOM"] = 5] = "CUSTOM";
})(ReportQuestionType || (ReportQuestionType = {}));
export var ReportQuestionTypeLabel;
(function(ReportQuestionTypeLabel2) {
  ReportQuestionTypeLabel2["ALL"] = "All";
  ReportQuestionTypeLabel2["DEFAULT"] = "Default Questions";
  ReportQuestionTypeLabel2["APPLICATION"] = "Application Questions";
  ReportQuestionTypeLabel2["ENROLLMENT"] = "Enrollment Questions";
  ReportQuestionTypeLabel2["PROGRAM_DETAILS"] = "Program Details";
  ReportQuestionTypeLabel2["CUSTOM"] = "Custom Questions";
})(ReportQuestionTypeLabel || (ReportQuestionTypeLabel = {}));
