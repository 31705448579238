import React, {useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import {Box, Checkbox, Typography} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Grid} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Controller, useFormContext} from "../../../../../_snowpack/pkg/react-hook-form.js";
import {MthColor} from "../../../../../core/enums/index.js";
import {isValidDate} from "../helpers.js";
import {CustomDateInput} from "./CustomDateInput.js";
export const VaccinesInfoHeader = () => {
  const {watch, setValue, control} = useFormContext();
  const [immunizations, enableExemptionDate] = watch(["immunizations", "enableExemptionDate"]);
  const [fullExempt, setFullExempt] = useState(false);
  useEffect(() => {
    let _enableExemptiondate = false;
    let _fullExempt = true;
    if (immunizations) {
      for (const im of immunizations) {
        if (im.value === "Exempt") {
          _enableExemptiondate = true;
        } else {
          _fullExempt = false;
        }
      }
    }
    setValue("enableExemptionDate", _enableExemptiondate);
    setFullExempt(_fullExempt);
  }, [immunizations]);
  function toggleExempt() {
    setValue("immunizations", immunizations.map((im) => ({
      ...im,
      value: !fullExempt ? "Exempt" : "",
      siblings: immunizations.filter((i) => i?.immunization_id && im?.immunization?.consecutives?.includes(+i?.immunization_id))
    })));
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    sx: {gap: "25px"}
  }, /* @__PURE__ */ React.createElement(Box, {
    style: {marginRight: 5}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true
  }, /* @__PURE__ */ React.createElement(Typography, {
    fontSize: "14px",
    color: MthColor.SYSTEM_01,
    fontWeight: "700"
  }, "Exempt"), /* @__PURE__ */ React.createElement(Checkbox, {
    color: "primary",
    checked: fullExempt,
    onChange: toggleExempt
  }))), /* @__PURE__ */ React.createElement(Box, {
    style: {marginRight: 5}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true
  }, /* @__PURE__ */ React.createElement(Typography, {
    sx: {marginBottom: "5px"},
    component: "div",
    fontSize: "14px",
    color: MthColor.SYSTEM_01,
    fontWeight: "700"
  }, "Exemption Date"), /* @__PURE__ */ React.createElement(Controller, {
    name: "exemptionDate",
    control,
    render: ({field}) => /* @__PURE__ */ React.createElement(CustomDateInput, {
      initVal: field.value,
      onChange: (v) => field.onChange(v),
      disabled: !enableExemptionDate,
      showError: enableExemptionDate && !isValidDate(field.value)
    })
  }))), /* @__PURE__ */ React.createElement(Box, {
    style: {marginRight: 5}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true
  }, /* @__PURE__ */ React.createElement(Typography, {
    component: "div",
    fontSize: "14px",
    color: MthColor.SYSTEM_01,
    fontWeight: "700"
  }, "Medical Exemption"), /* @__PURE__ */ React.createElement(Controller, {
    name: "medicalExempt",
    control,
    render: ({field}) => /* @__PURE__ */ React.createElement(Checkbox, {
      color: "primary",
      checked: field.value,
      onChange: () => field.onChange(!field.value),
      disabled: !enableExemptionDate
    })
  })))));
};
