export var HomeroomAssignmentsButtonsTitles;
(function(HomeroomAssignmentsButtonsTitles2) {
  HomeroomAssignmentsButtonsTitles2["ASSIGN_SIBLINGS"] = "Assign Siblings";
  HomeroomAssignmentsButtonsTitles2["TRANSFER"] = "Transfer";
  HomeroomAssignmentsButtonsTitles2["ASSIGN"] = "Assign";
})(HomeroomAssignmentsButtonsTitles || (HomeroomAssignmentsButtonsTitles = {}));
export var TransferStudentAutoGrade;
(function(TransferStudentAutoGrade2) {
  TransferStudentAutoGrade2["NA"] = "N/A";
  TransferStudentAutoGrade2["ZERO"] = "0";
  TransferStudentAutoGrade2["TRANSFER"] = "Transfer";
})(TransferStudentAutoGrade || (TransferStudentAutoGrade = {}));
