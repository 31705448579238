import * as __SNOWPACK_ENV__ from "./_snowpack/env.js";
import React, {useEffect, useState} from "./_snowpack/pkg/react.js";
import {Backdrop, CircularProgress, CssBaseline} from "./_snowpack/pkg/@mui/material.js";
import ThemeProvider from "./_snowpack/pkg/@mui/system/ThemeProvider.js";
import {FlagProvider, useFlag} from "./_snowpack/pkg/@unleash/proxy-client-react.js";
import {createNetworkStatusNotifier} from "./_snowpack/pkg/react-apollo-network-status.js";
import {BrowserRouter as Router} from "./_snowpack/pkg/react-router-dom.js";
import {RecoilRoot} from "./_snowpack/pkg/recoil.js";
import WebFont from "./_snowpack/pkg/webfontloader.js";
import {theme} from "./core/utils/index.js";
import {MthLoading} from "./components/MthLoading/MthLoading.js";
import {UserLeaveConfirmation} from "./components/UserLeaveConfirmation/UserLeaveConfirmation.js";
import {EPIC_1582_STORY_4078} from "./core/constants/feature-flag.constant.js";
import {MthColor} from "./core/enums/color.enum.js";
import {ApolloProvider} from "./providers/ApolloProvider/ApolloProvider.js";
import {AuthProvider} from "./providers/AuthProvider/AuthProvider.js";
import BackStackProvider from "./providers/BackStackProvider/BackStackProvider.js";
import {ProfileProvider} from "./providers/ProfileProvider/ProfileProvider.js";
import {TabContext, UserContext} from "./providers/UserContext/UserProvider.js";
import {Root} from "./root/Root.js";
import "./config/datadog-rum-config.js";
const {link: apolloNetworkstatusLink, useApolloNetworkStatus} = createNetworkStatusNotifier();
function GlobalLoadingIndicator() {
  const epic_1582_story_4078 = useFlag(EPIC_1582_STORY_4078);
  const status = useApolloNetworkStatus();
  if (epic_1582_story_4078 && status.numPendingQueries + status.numPendingMutations > 0) {
    return /* @__PURE__ */ React.createElement(Backdrop, {
      sx: {color: "#fff", zIndex: (theme2) => theme2.zIndex.drawer + 1e5},
      open: true
    }, /* @__PURE__ */ React.createElement(CircularProgress, {
      color: "inherit"
    }));
  } else {
    return null;
  }
}
export const App = () => {
  const [me, setMe] = useState(null);
  const [tab, setTab] = useState(null);
  const [visitedTabs, setVisitedTabs] = useState([]);
  const userContext = React.useMemo(() => ({
    me,
    setMe
  }), [me]);
  const tabContext = React.useMemo(() => ({
    tab,
    setTab,
    visitedTabs,
    setVisitedTabs
  }), [tab, visitedTabs]);
  useEffect(() => {
    const gaScript = document.createElement("script");
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_GOOGLE_ANALYTICS_ID}`;
    gaScript.async = true;
    const gaConfigScript = document.createElement("script");
    gaConfigScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_GOOGLE_ANALYTICS_ID}');

    `;
    document.head.appendChild(gaScript);
    document.head.appendChild(gaConfigScript);
  }, []);
  const featureFlagConfig = {
    url: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_FEATURE_FLAG_URL,
    clientKey: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_FEATURE_FLAG_CLIENT_KEY,
    refreshInterval: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL ?? 300,
    appName: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_FEATURE_FLAG_APP_NAME,
    environment: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_FEATURE_FLAG_ENV
  };
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["VisbyCF"],
        urls: ["/fonts.css"]
      }
    });
  }, []);
  return /* @__PURE__ */ React.createElement(FlagProvider, {
    config: featureFlagConfig
  }, /* @__PURE__ */ React.createElement(Router, {
    getUserConfirmation: (message, callback) => {
      return UserLeaveConfirmation(message, callback);
    }
  }, /* @__PURE__ */ React.createElement(BackStackProvider, null, /* @__PURE__ */ React.createElement(ThemeProvider, {
    theme
  }, /* @__PURE__ */ React.createElement(AuthProvider, null, /* @__PURE__ */ React.createElement(UserContext.Provider, {
    value: userContext
  }, /* @__PURE__ */ React.createElement(ApolloProvider, {
    apolloNetworkstatusLink
  }, /* @__PURE__ */ React.createElement(TabContext.Provider, {
    value: tabContext
  }, /* @__PURE__ */ React.createElement(ProfileProvider, null, /* @__PURE__ */ React.createElement(CssBaseline, null), /* @__PURE__ */ React.createElement(RecoilRoot, null, /* @__PURE__ */ React.createElement(GlobalLoadingIndicator, null), /* @__PURE__ */ React.createElement(Root, null), /* @__PURE__ */ React.createElement(MthLoading, null), /* @__PURE__ */ React.createElement("svg", {
    width: 0,
    height: 0
  }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", {
    id: "mth_loading_gradient",
    x1: "0%",
    y1: "0%",
    x2: "0%",
    y2: "100%"
  }, /* @__PURE__ */ React.createElement("stop", {
    offset: "30%",
    stopColor: MthColor.MTHBLUE
  }), /* @__PURE__ */ React.createElement("stop", {
    offset: "100%",
    stopColor: MthColor.MTHPURPLE
  }))))))))))))));
};
