import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {InputAdornment, TextField} from "../../_snowpack/pkg/@mui/material.js";
import {EMPTY_STRING, isDecimal, isNumber, isPrice} from "../../core/constants/index.js";
export const MthNumberInput = (props) => {
  const {numberType, InputProps, value: defaultValue, onChangeValue, inputAdornmentColor, ...otherProps} = props;
  const [value, setValue] = useState("");
  const handleChange = (newValue) => {
    switch (numberType) {
      case "price": {
        if (!newValue || isPrice.test(newValue)) {
          setValue(newValue);
          onChangeValue(newValue === EMPTY_STRING ? null : Number(newValue) === 0 ? 0 : Number(newValue) || null);
        }
        break;
      }
      case "numeric": {
        if (!newValue || isNumber.test(newValue)) {
          setValue(newValue);
          onChangeValue(Number(newValue) || null);
        }
        break;
      }
      case "decimal": {
        if (!newValue || isDecimal.test(newValue)) {
          if (newValue.endsWith(".") && Number(newValue) === defaultValue) {
            setValue(newValue);
          } else {
            onChangeValue(Number(newValue) || null);
          }
        }
        break;
      }
    }
  };
  useEffect(() => {
    setValue(defaultValue === 0 ? "0" : (defaultValue || "").toString());
  }, [defaultValue]);
  return /* @__PURE__ */ React.createElement(TextField, {
    InputProps: {
      startAdornment: numberType === "price" ? /* @__PURE__ */ React.createElement(InputAdornment, {
        position: "start"
      }, /* @__PURE__ */ React.createElement("p", {
        style: inputAdornmentColor ? {color: inputAdornmentColor} : {}
      }, "$")) : "",
      ...InputProps
    },
    value: value || "",
    onChange: (event) => {
      handleChange(event?.target?.value);
    },
    ...otherProps,
    type: "text"
  });
};
export default MthNumberInput;
