export var SPECIAL_EDUCATION_LABEL;
(function(SPECIAL_EDUCATION_LABEL2) {
  SPECIAL_EDUCATION_LABEL2["NONE"] = "None";
  SPECIAL_EDUCATION_LABEL2["IEP"] = "IEP";
  SPECIAL_EDUCATION_LABEL2["S504"] = "504";
  SPECIAL_EDUCATION_LABEL2["EXIT"] = "Exit";
})(SPECIAL_EDUCATION_LABEL || (SPECIAL_EDUCATION_LABEL = {}));
export var SPECIAL_EDUCATION_VALUE;
(function(SPECIAL_EDUCATION_VALUE2) {
  SPECIAL_EDUCATION_VALUE2[SPECIAL_EDUCATION_VALUE2["NONE"] = 0] = "NONE";
  SPECIAL_EDUCATION_VALUE2[SPECIAL_EDUCATION_VALUE2["IEP"] = 1] = "IEP";
  SPECIAL_EDUCATION_VALUE2[SPECIAL_EDUCATION_VALUE2["S504"] = 2] = "S504";
  SPECIAL_EDUCATION_VALUE2[SPECIAL_EDUCATION_VALUE2["EXIT"] = 3] = "EXIT";
})(SPECIAL_EDUCATION_VALUE || (SPECIAL_EDUCATION_VALUE = {}));
