import React from "../../_snowpack/pkg/react.js";
import ErrorOutlineIcon from "../../_snowpack/pkg/@mui/icons-material/ErrorOutline.js";
import {Button, Modal, Typography, Box, CircularProgress} from "../../_snowpack/pkg/@mui/material.js";
import {MthColor} from "../../core/enums/index.js";
import {Paragraph} from "../../core/components/shared/Paragraph/Paragraph.js";
import {testIDs} from "./__tests__/Warning.testIds.js";
import {useStyles} from "./styles.js";
export const WarningModal = ({
  testId,
  handleModem,
  title,
  subtitle,
  secondSubtitle,
  btntitle = "Submit",
  canceltitle = "",
  handleSubmit,
  showIcon = true,
  children,
  textCenter,
  modalWidth,
  error = false,
  upperCase = true,
  disableButton = false,
  progress = false,
  sx = {}
}) => {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement(Modal, {
    "data-testid": testId,
    open: true,
    onClose: handleModem,
    "aria-labelledby": "modal-modal-title",
    "aria-describedby": "modal-modal-description"
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.modalCard,
    sx: {width: modalWidth ? modalWidth : "470px", ...sx}
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.header,
    sx: {textTransform: upperCase ? "capitalize" : "inherit"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    fontSize: "20px",
    fontWeight: "bold"
  }, title)), /* @__PURE__ */ React.createElement(Box, {
    className: classes.content
  }, showIcon && /* @__PURE__ */ React.createElement(ErrorOutlineIcon, {
    className: classes.errorOutline
  }), /* @__PURE__ */ React.createElement(Paragraph, {
    size: "large",
    fontWeight: "600",
    color: !error ? MthColor.SYSTEM_01 : MthColor.RED,
    sx: {textAlign: textCenter ? "center" : "left", mt: showIcon ? "0" : "56px"}
  }, subtitle), secondSubtitle && /* @__PURE__ */ React.createElement(Paragraph, {
    size: "large",
    fontWeight: "600",
    color: !error ? MthColor.SYSTEM_01 : MthColor.RED,
    sx: {textAlign: textCenter ? "center" : "left", mt: showIcon ? "0" : "56px"}
  }, secondSubtitle), children, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", flexDirection: "row", justifyContent: "center", width: "100%"}
  }, canceltitle != "" && /* @__PURE__ */ React.createElement(Button, {
    "data-testid": testIDs.cancelButton,
    variant: "contained",
    disableElevation: true,
    className: classes.button,
    onClick: handleModem
  }, canceltitle), !disableButton && /* @__PURE__ */ React.createElement(Button, {
    "data-testid": testIDs.positiveCTAButton(btntitle),
    variant: "contained",
    disableElevation: true,
    className: classes.submit,
    onClick: handleSubmit
  }, btntitle)), progress && /* @__PURE__ */ React.createElement(CircularProgress, {
    size: 20,
    sx: {marginTop: 3}
  }))));
};
