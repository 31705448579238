export var QUESTION_TYPE;
(function(QUESTION_TYPE2) {
  QUESTION_TYPE2[QUESTION_TYPE2["DROPDOWN"] = 1] = "DROPDOWN";
  QUESTION_TYPE2[QUESTION_TYPE2["TEXTFIELD"] = 2] = "TEXTFIELD";
  QUESTION_TYPE2[QUESTION_TYPE2["CHECKBOX"] = 3] = "CHECKBOX";
  QUESTION_TYPE2[QUESTION_TYPE2["AGREEMENT"] = 4] = "AGREEMENT";
  QUESTION_TYPE2[QUESTION_TYPE2["MULTIPLECHOICES"] = 5] = "MULTIPLECHOICES";
  QUESTION_TYPE2[QUESTION_TYPE2["CALENDAR"] = 6] = "CALENDAR";
  QUESTION_TYPE2[QUESTION_TYPE2["INFORMATION"] = 7] = "INFORMATION";
  QUESTION_TYPE2[QUESTION_TYPE2["UPLOAD"] = 8] = "UPLOAD";
  QUESTION_TYPE2[QUESTION_TYPE2["SIGNATURE"] = 9] = "SIGNATURE";
  QUESTION_TYPE2[QUESTION_TYPE2["TEXTBOX"] = 10] = "TEXTBOX";
  QUESTION_TYPE2[QUESTION_TYPE2["SPECIAL_EDUCATION"] = 11] = "SPECIAL_EDUCATION";
  QUESTION_TYPE2[QUESTION_TYPE2["PROGRAM_STATE"] = 12] = "PROGRAM_STATE";
  QUESTION_TYPE2[QUESTION_TYPE2["PROGRAM_YEAR"] = 13] = "PROGRAM_YEAR";
})(QUESTION_TYPE || (QUESTION_TYPE = {}));
export var EnrollmentQuestionFields;
(function(EnrollmentQuestionFields2) {
  EnrollmentQuestionFields2["ID"] = "id";
  EnrollmentQuestionFields2["GROUP_ID"] = "group_id";
  EnrollmentQuestionFields2["TYPE"] = "type";
  EnrollmentQuestionFields2["QUESTION"] = "question";
  EnrollmentQuestionFields2["DESCRIPTION"] = "description";
  EnrollmentQuestionFields2["OPTIONS"] = "options";
  EnrollmentQuestionFields2["REQUIRED"] = "required";
  EnrollmentQuestionFields2["MISSEDINFO"] = "missedInfo";
  EnrollmentQuestionFields2["ORDER"] = "order";
  EnrollmentQuestionFields2["ADDITIONAL_QUESTION"] = "additional_question";
  EnrollmentQuestionFields2["VALIDATION"] = "validation";
  EnrollmentQuestionFields2["DISPLAY_ADMIN"] = "display_admin";
  EnrollmentQuestionFields2["DEFAULT_QUESTION"] = "default_question";
  EnrollmentQuestionFields2["SLUG"] = "slug";
})(EnrollmentQuestionFields || (EnrollmentQuestionFields = {}));
