export const containerBoxStyles = {position: "relative"};
export const progressCircleContainerStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  zIndex: (theme) => theme.zIndex.drawer + 10
};
export const progressCircleStyles = {"svg circle": {stroke: "url(#mth_loading_gradient)"}};
