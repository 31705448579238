import {createContext} from "../../_snowpack/pkg/react.js";
const profileContext = {
  showModal: () => {
  },
  hideModal: () => {
  },
  store: false,
  setStore: () => {
  }
};
export const ProfileContext = createContext(profileContext);
