import React, {useContext} from "../../../../../_snowpack/pkg/react.js";
import {Box, Grid} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor} from "../../../../../core/enums/index.js";
import {PacketModalQuestionsContext} from "../providers.js";
import {PacketQuestionItem} from "./PacketQuestionItem.js";
export const Info = () => {
  const questions = useContext(PacketModalQuestionsContext);
  return questions?.length > 0 ? questions?.map((tab, tab_index) => {
    return tab?.groups?.filter((group) => group.questions.filter((q) => q.display_admin).length > 0).map((group, group_index) => {
      const questionsArr = group.questions.map((q) => {
        const arr = [q];
        let current = q, child;
        while (child = group.questions.find((x) => x.additional_question == current.slug)) {
          arr.push(child);
          current = child;
        }
        return arr;
      });
      const questionsLists = questionsArr.filter((item) => !item[0].additional_question);
      return /* @__PURE__ */ React.createElement(Box, {
        key: `${tab_index}_${group_index}`
      }, /* @__PURE__ */ React.createElement(Subtitle, {
        color: MthColor.SYSTEM_01,
        size: "small",
        fontWeight: "700",
        sx: {marginTop: 2}
      }, group?.group_name !== "root" && group?.group_name), /* @__PURE__ */ React.createElement(Grid, {
        container: true,
        rowSpacing: 2,
        columnSpacing: {xs: 1, sm: 2, md: 3}
      }, questionsLists?.map((item, index) => item[0].display_admin && /* @__PURE__ */ React.createElement(PacketQuestionItem, {
        key: index,
        item
      }))));
    });
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null);
};
