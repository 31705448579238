import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {isEmpty} from "../../../_snowpack/pkg/lodash.js";
import {useDebounceCallback} from "../../../_snowpack/pkg/usehooks-ts.js";
import {checkEmailInUse} from "../../../screens/HomeroomStudentProfile/Student/StudentProfile/service.js";
export const useIsUniqueEmail = (emailToCheck, currentEmail, waitTime = 350) => {
  const [uniqueEmail, setUniqueEmail] = useState(true);
  const [checkIsEmailInUse] = useLazyQuery(checkEmailInUse);
  const checkUniqueEmail = async (newEmail) => {
    await checkIsEmailInUse({variables: {email: newEmail}}).then((res) => {
      const emailInUse = res.data.checkUserExistByEmail || res.data.studentEmailTaken;
      setUniqueEmail(!emailInUse || newEmail === currentEmail || isEmpty(newEmail));
    });
  };
  const debouncedChangeHandler = useDebounceCallback(checkUniqueEmail, waitTime);
  useEffect(() => {
    debouncedChangeHandler(emailToCheck);
  }, [emailToCheck]);
  return uniqueEmail;
};
