import {DisplayToLabels, DisplayToOptions} from "../../types/index.js";
export const renderDisplayTo = (config) => {
  const labels = {
    [DisplayToOptions.ParentsObservers]: DisplayToLabels.parentsObservers,
    [DisplayToOptions.Students]: DisplayToLabels.students,
    [DisplayToOptions.TeachersAssistants]: DisplayToLabels.teachersAssistants,
    [DisplayToOptions.Admin]: DisplayToLabels.admin
  };
  const selectedLabels = Object.keys(config).filter((key) => config[key]).map((key) => labels[key]);
  return selectedLabels.join(", ");
};
