import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getResourcesQuery} from "../../../graphql/queries/resource.js";
export const useResources = (schoolYearId) => {
  const [checkBoxItems, setCheckBoxItems] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [resources, setResources] = useState([]);
  const {loading, data, error, refetch} = useQuery(getResourcesQuery, {
    variables: {schoolYearId: +schoolYearId},
    skip: !schoolYearId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (data?.resources) {
      const {resources: resources2} = data;
      setCheckBoxItems((resources2 || []).map((item) => ({
        label: item.title,
        value: item.resource_id?.toString()
      })));
      setDropdownItems((resources2 || []).filter((item) => Boolean(item.is_active)).map((item) => ({
        label: item.title,
        value: item.resource_id
      })));
      setResources(resources2 || []);
    }
  }, [loading, data]);
  return {
    loading,
    resources,
    checkBoxItems,
    dropdownItems,
    error,
    refetch
  };
};
