import {MthColor} from "../../../core/enums/index.js";
export const useStyles = {
  content: {
    padding: "24px 32px",
    margin: 2,
    maxHeight: "calc(100vh - 32px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      background: MthColor.WHITE
    },
    "&::-webkit-scrollbar-thumb": {
      background: MthColor.SCROLLBAR_THUMB
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: MthColor.SCROLLBAR_THUMB_HOVER
    }
  },
  select: {
    maxWidth: "150px",
    "&:before": {
      borderColor: MthColor.WHITE
    },
    "&:after": {
      borderColor: MthColor.WHITE
    }
  },
  selectTP: {
    maxWidth: "150px",
    height: "35px",
    textAlign: "left",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: MthColor.RED,
      display: "none"
    }
  },
  selectSoE: {
    height: "35px",
    textAlign: "left",
    minWidth: "100px",
    "& svg": {
      color: MthColor.SYSTEM_01
    },
    "& .MuiInputBase-input": {padding: "5px 0px"},
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: MthColor.RED,
      display: "none"
    }
  },
  backgroundSelect: {
    fontSize: "12px",
    borderRadius: "15px",
    minWidth: "80px",
    height: "29px",
    textAlign: "center",
    background: MthColor.BUTTON_LINEAR_GRADIENT,
    color: MthColor.LIGHTGRAY,
    "&:before": {
      borderColor: MthColor.BUTTON_LINEAR_GRADIENT
    },
    "&:after": {
      borderColor: MthColor.BUTTON_LINEAR_GRADIENT
    }
  },
  checkBox: {
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: 600
  },
  label: {
    fontSize: "12px",
    lineHeight: "22px",
    marginBottom: "8px",
    fontWeight: 600
  },
  labelDisabled: {
    fontSize: "12px",
    lineHeight: "22px",
    marginBottom: "8px",
    fontWeight: 600,
    color: MthColor.DISABLED
  },
  note: {
    fontSize: "22px",
    lineHeight: "22px",
    marginBottom: "8px",
    fontWeight: 600
  },
  text: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& fieldset": {
      borderWidth: "1px !important"
    },
    "& input": {
      fontSize: "14px",
      lineHeight: "16px",
      padding: "16px",
      fontWeight: "700",
      color: MthColor.BLACK
    },
    "& textarea": {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "700",
      color: MthColor.BLACK
    }
  },
  dropdown: {
    "& .MuiFormControl-root .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled) fieldset": {
      borderColor: MthColor.DROPDOWN_BORDER,
      borderRadius: "8px"
    },
    "& .MuiInputBase-input": {
      color: MthColor.BLACK,
      fontSize: "14px",
      fontWeight: "700"
    }
  },
  snackbar: {
    minWidth: "95%",
    "& .MuiSnackbarContent-root": {
      backgroundColor: MthColor.ERROR_SNACKBAR_BG,
      minWidth: "100%",
      borderRadius: "8px"
    }
  }
};
