import {US_STATES} from "../../constants/index.js";
export const DEMO_REGION_CODE = "DEMO";
export const sortRegions = (allRegions = []) => {
  const regions = [];
  allRegions.forEach((region) => {
    let i;
    for (i = 0; i < regions.length; i++) {
      if (regions[i] && regions[i].name !== void 0 && regions[i].name > region.name)
        break;
    }
    regions.splice(i, 0, region);
  });
  return regions;
};
export const getRegionCode = (regionName) => {
  return US_STATES.find((item) => item.label === regionName)?.value || "";
};
export const getTimezone = (me) => {
  if (me.selectedRegionId === void 0) {
    const region = me.userRegion?.at(0);
    return String(region?.regionDetail?.timezone);
  } else {
    const selectedRegionId = me.selectedRegionId;
    const region = me.userRegion?.find((region2) => Number(region2.region_id) === Number(selectedRegionId));
    return String(region?.regionDetail.timezone);
  }
};
