import React from "../../../../_snowpack/pkg/react.js";
import {Typography} from "../../../../_snowpack/pkg/@mui/material.js";
export const Title = ({
  sx,
  fontWeight,
  children,
  size,
  color,
  textAlign,
  testId,
  lineHeight
}) => {
  const fontSize = () => size === "large" ? 32 : size === "medium" ? 28 : size === "small" ? 24 : size ? size : 24;
  return /* @__PURE__ */ React.createElement(Typography, {
    fontWeight: fontWeight || "bold",
    fontSize,
    color,
    textAlign,
    sx,
    "data-testid": testId,
    lineHeight
  }, children);
};
