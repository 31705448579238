import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {GetTeachersByUserIdQuery} from "../../../screens/Teacher/services/teachers.js";
export const useTeachers = ({
  userId,
  avatarUrl
}) => {
  const [teachers, setTeachers] = useState([]);
  const {loading, data, error, refetch} = useQuery(GetTeachersByUserIdQuery, {
    variables: {
      userId: Number(userId)
    },
    skip: userId ? false : true,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data) {
      let primaryTeachers = [];
      let additionalTeachers = [];
      data.getTeachersByUserId.results?.map((item) => {
        if (Number(item?.PrimaryTeacher?.user_id) === Number(userId)) {
          primaryTeachers = [
            ...primaryTeachers,
            {
              classId: Number(item?.class_id) ?? 0,
              className: item.class_name,
              firstName: item?.PrimaryTeacher?.first_name ?? "",
              lastName: item?.PrimaryTeacher?.last_name ?? "",
              user_id: Number(item.PrimaryTeacher?.user_id),
              avatarUrl: item?.PrimaryTeacher?.avatarUrl ?? avatarUrl,
              ungradedLogs: Number(item?.ungraded) ?? 0,
              studentCount: item.HomeroomStudents.length
            }
          ];
        }
        if (item?.addition_id) {
          const parsedAdditionalTeachers = JSON.parse(item.addition_id);
          const filteredTeacher = parsedAdditionalTeachers?.find((obj) => Number(obj.user_id) === Number(userId));
          if (filteredTeacher) {
            additionalTeachers = [
              ...additionalTeachers,
              {
                classId: Number(item?.class_id) ?? 0,
                className: item.class_name,
                firstName: filteredTeacher?.first_name ?? "",
                lastName: filteredTeacher?.last_name ?? "",
                user_id: Number(filteredTeacher?.user_id),
                avatarUrl: filteredTeacher?.avatarUrl ?? avatarUrl,
                ungradedLogs: Number(item?.ungraded) ?? 0,
                studentCount: item.HomeroomStudents.length
              }
            ];
          }
        }
      });
      setTeachers([
        ...primaryTeachers.sort((a, b) => (a?.className ?? "") > (b?.className ?? "") ? 1 : -1),
        ...additionalTeachers.sort((a, b) => (a?.className ?? "") > (b?.className ?? "") ? 1 : -1)
      ]);
    }
  }, [data, loading]);
  return {
    loading,
    teachers,
    error,
    refetch
  };
};
