import React from "../../_snowpack/pkg/react.js";
import CloseIcon from "../../_snowpack/pkg/@mui/icons-material/Close.js";
import ErrorOutlineIcon from "../../_snowpack/pkg/@mui/icons-material/ErrorOutline.js";
import {Box, Button, Grid, Modal, Typography} from "../../_snowpack/pkg/@mui/material.js";
import DOMPurify from "../../_snowpack/pkg/dompurify.js";
import {customModalClasses} from "./styles.js";
import {MthColor, fontFamilyEnum} from "../../core/enums/index.js";
import {commonClasses} from "../../styles/common.style.js";
export const CustomModal = ({
  testId,
  title,
  description,
  subDescription,
  onClose,
  onConfirm,
  confirmStr = "Confirm",
  cancelStr = "Cancel",
  backgroundColor = MthColor.WHITE,
  showIcon = true,
  showCancel = true,
  isResourceModal = true,
  topRightCancel = false,
  descriptionSx,
  onCancelIcon
}) => {
  return /* @__PURE__ */ React.createElement(Modal, {
    "data-testid": testId,
    open: true,
    "aria-labelledby": "child-modal-title",
    disableAutoFocus: true,
    "aria-describedby": "child-modal-description"
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      ...commonClasses.modalWrap,
      maxWidth: subDescription ? "550px" : "450px",
      backgroundColor,
      borderRadius: 2,
      ...!topRightCancel ? {p: 4} : {paddingTop: 2, paddingRight: 4, paddingBottom: 4, paddingLeft: 4}
    }
  }, topRightCancel && /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    direction: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: customModalClasses.cancelIcon,
    onClick: onCancelIcon
  }, /* @__PURE__ */ React.createElement(CloseIcon, {
    fontSize: "small"
  }))), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      textAlign: "center"
    }
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    fontWeight: "bold",
    fontFamily: isResourceModal ? fontFamilyEnum.HeavyFont : fontFamilyEnum.VisbyFont
  }, title), showIcon && /* @__PURE__ */ React.createElement(ErrorOutlineIcon, {
    sx: {fontSize: 50, margin: "20px 0px"}
  }), /* @__PURE__ */ React.createElement(Typography, {
    sx: {
      fontSize: isResourceModal ? 14 : "1rem",
      fontFamily: isResourceModal ? fontFamilyEnum.HeavyFont : fontFamilyEnum.VisbyFont,
      ...descriptionSx ? descriptionSx : {}
    },
    marginTop: showIcon ? 0 : 4,
    component: "p",
    dangerouslySetInnerHTML: {__html: DOMPurify.sanitize(description) || ""}
  }), subDescription && /* @__PURE__ */ React.createElement(Typography, null, subDescription), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      justifyContent: "center",
      marginTop: "30px",
      gap: "40px"
    }
  }, showCancel && /* @__PURE__ */ React.createElement(Button, {
    sx: customModalClasses.cancelBtn,
    onClick: onClose
  }, cancelStr), /* @__PURE__ */ React.createElement(Button, {
    sx: customModalClasses.confirmBtn,
    onClick: onConfirm
  }, confirmStr)))));
};
