export var FileKind;
(function(FileKind2) {
  FileKind2["STUDENT_PACKET"] = "Student Packet";
  FileKind2["WITHDRAWAL_FORM"] = "Withdrawal Form";
  FileKind2["OPT_OUT_FORM_ELEMENTARY"] = "Opt-out Form Elementary";
  FileKind2["OPT_OUT_FORM_SECONDARY"] = "Opt-out Form Secondary";
  FileKind2["OPT_OUT_FORM_OREGON"] = "Opt-out Form Oregon";
  FileKind2["USIRS"] = "USIR";
  FileKind2["SCHEDULE"] = "Schedule";
  FileKind2["WEEKLY_LEARNING_LOG"] = "Weekly Learning Log";
  FileKind2["AFFIDAVIT"] = "Affidavit";
})(FileKind || (FileKind = {}));
