import {MthColor} from "../../core/enums/index.js";
export const bulletEditorClasses = {
  editor: {
    border: `1px solid ${MthColor.MEDIUMGRAY}`,
    borderRadius: 1,
    marginBottom: "24px"
  },
  editorInvalid: {
    borderColor: MthColor.RED
  }
};
