import React, {useEffect} from "../../../../_snowpack/pkg/react.js";
import {useFormContext} from "../../../../_snowpack/pkg/react-hook-form.js";
import {PACKET_STATUS_OPTIONS} from "../../../../core/constants/index.js";
import {DropDown} from "../../../../core/components/shared/DropDown/DropDown.js";
import {MthColor} from "../../../../core/enums/index.js";
export const EnrollmentPacketDropDownButton = () => {
  const {watch, setValue} = useFormContext();
  const [status] = watch(["status", "packetStatuses"]);
  const handlePacketStatus = (name) => {
    setValue("status", name);
    setValue("preSaveStatus", name);
  };
  useEffect(() => {
    if (PACKET_STATUS_OPTIONS) {
      setValue("packetStatuses", PACKET_STATUS_OPTIONS.map((item) => `${item.value}`));
    }
  }, [PACKET_STATUS_OPTIONS]);
  return /* @__PURE__ */ React.createElement(DropDown, {
    sx: {
      fontSize: "12px",
      padding: "2px 30px 1px 40px",
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      height: "35px",
      borderRadius: "8px",
      "& .MuiSelect-select": {
        color: "white",
        fontWeight: 500,
        fontSize: "12px"
      },
      "& .MuiSvgIcon-root": {
        color: "white !important"
      }
    },
    dropDownItems: PACKET_STATUS_OPTIONS,
    defaultValue: status,
    borderNone: true,
    setParentValue: (val) => {
      handlePacketStatus(`${val}`);
    }
  });
};
