import {gql} from "../../_snowpack/pkg/@apollo/client.js";
export const GetLearingLogQuestionByAssignmentIdQuery = gql`
  query GetLearningLogQuestionByAssignmentId($assignmentId: Int!) {
    getLearningLogQuestionByAssignmentId(assignmentId: $assignmentId) {
      id
      assignment_id
      slug
      parent_slug
      question
      type
      options
      default_question
      grades
      order
      page
      validations
    }
  }
`;
