import React, {useContext, useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {useMutation, useQuery} from "../../../../_snowpack/pkg/@apollo/client.js";
import {Grid, Box} from "../../../../_snowpack/pkg/@mui/material.js";
import DeleteIcon from "../../../../assets/icons/icon-delete-small.svg";
import {Paragraph} from "../../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor, QUESTION_TYPE} from "../../../../core/enums/index.js";
import {CustomModal} from "../../SiteManagement/EnrollmentSetting/components/CustomModal/CustomModals.js";
import {deletePacketDocumentFileMutation, getPacketFiles} from "../services.js";
import {PacketModalQuestionsContext} from "./providers.js";
import {useStyles} from "./styles.js";
export const EnrollmentPacketDocument = ({packetData}) => {
  const questions = useContext(PacketModalQuestionsContext);
  const classes = useStyles;
  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [deleteFile] = useMutation(deletePacketDocumentFileMutation);
  const {loading, data, refetch} = useQuery(getPacketFiles, {
    variables: {
      fileIds
    },
    fetchPolicy: "network-only"
  });
  const onDeletefile = (id) => {
    setOpenConfirmModal(true);
    setSelectedFileId(id);
  };
  const onDeleteConfirm = async () => {
    await deleteFile({variables: {fileId: `${selectedFileId}`}});
    refetch();
    setOpenConfirmModal(false);
  };
  useEffect(() => {
    if (packetData?.files?.length > 0) {
      const ids = [];
      for (const packetfile of packetData.files) {
        ids.push(packetfile.mth_file_id);
      }
      setFileIds(ids.toString());
    }
  }, [packetData]);
  useEffect(() => {
    if (data !== void 0) {
      const filesData = [];
      if (packetData?.files?.length > 0) {
        for (const packetfile of packetData.files) {
          for (const file of data.packetFiles.results) {
            if (parseInt(packetfile.mth_file_id) === parseInt(file.file_id)) {
              const tempFile = {
                file_id: file.file_id,
                kind: packetfile.kind,
                name: file.name,
                url: file.signedUrl
              };
              filesData.push(tempFile);
            }
          }
        }
      }
      setFiles(filesData);
    }
  }, [loading, data]);
  const openNewWindow = (url, name) => {
    if (url) {
      window.open(url, name, "500");
    }
  };
  return /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    sx: {paddingTop: "20px"}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 12,
    sm: 12,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: {fontSize: "18px", marginBottom: 1},
    color: MthColor.SYSTEM_01,
    fontWeight: "700"
  }, "Documents")), /* @__PURE__ */ React.createElement(Grid, {
    container: true
  }, /* @__PURE__ */ React.createElement(Grid, {
    sx: {
      "&.MuiGrid-root": {
        maxWidth: "640px"
      }
    },
    item: true,
    md: 12,
    sm: 12,
    xs: 12
  }, questions?.length > 0 && questions?.find((tab) => tab.tab_name === "Documents")?.groups[0]?.questions?.map((q, index) => {
    if (q.type === QUESTION_TYPE.UPLOAD) {
      const filteredFiles = files?.filter((file) => file.kind === q.options?.[0].label);
      return /* @__PURE__ */ React.createElement(Box, {
        sx: {display: "flex", alignItems: "flex-start", gap: 4, marginBottom: "8px"}
      }, /* @__PURE__ */ React.createElement(Box, {
        sx: {minWidth: "200px", maxWidth: "200px"}
      }, /* @__PURE__ */ React.createElement(Paragraph, {
        key: index,
        color: MthColor.SYSTEM_01,
        sx: {fontSize: "14px"},
        fontWeight: "400"
      }, q.question)), /* @__PURE__ */ React.createElement(Box, {
        sx: {
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(160px, 1fr))",
          columnGap: 2
        }
      }, files?.length > 0 ? filteredFiles.length > 0 ? filteredFiles.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }).map((obj, fIndex) => {
        return /* @__PURE__ */ React.createElement(Paragraph, {
          key: `file-${fIndex}`,
          color: MthColor.PRIMARY_MEDIUM_MOUSEOVER,
          fontWeight: "400",
          sx: {
            fontSize: "14px",
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "4px",
            wordBreak: "break-all"
          }
        }, /* @__PURE__ */ React.createElement("a", {
          onClick: () => openNewWindow(obj.url, obj.name),
          target: "_blank",
          style: {cursor: "pointer"},
          rel: "noreferrer"
        }, obj.name.split(".").slice(0, -1).join(".")), /* @__PURE__ */ React.createElement("img", {
          src: DeleteIcon,
          style: classes.deleteIcon,
          onClick: () => onDeletefile(obj.file_id)
        }));
      }) : /* @__PURE__ */ React.createElement(Paragraph, {
        color: MthColor.SYSTEM_06,
        sx: {fontSize: "14px"},
        fontWeight: "400"
      }, "Not found") : /* @__PURE__ */ React.createElement(Paragraph, {
        color: MthColor.SYSTEM_06,
        sx: {fontSize: "14px"},
        fontWeight: "400"
      }, "Not found")));
    } else {
      return void 0;
    }
  }))), openConfirmModal && /* @__PURE__ */ React.createElement(CustomModal, {
    title: "Delete File",
    description: "Are you sure you want to delete this file?",
    confirmStr: "Delete",
    cancelStr: "Cancel",
    onConfirm: onDeleteConfirm,
    onClose: () => setOpenConfirmModal(false)
  }));
};
