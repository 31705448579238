export var ScheduleStatus;
(function(ScheduleStatus2) {
  ScheduleStatus2["DRAFT"] = "Draft";
  ScheduleStatus2["SUBMITTED"] = "Submitted";
  ScheduleStatus2["ACCEPTED"] = "Accepted";
  ScheduleStatus2["RESUBMITTED"] = "Resubmitted";
  ScheduleStatus2["UPDATES_REQUESTED"] = "Updates Requested";
  ScheduleStatus2["UPDATES_REQUIRED"] = "Updates Required";
  ScheduleStatus2["NOT_SUBMITTED"] = "Not Submitted";
})(ScheduleStatus || (ScheduleStatus = {}));
