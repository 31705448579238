import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {Box, Checkbox, Chip, FormControlLabel} from "../../_snowpack/pkg/@mui/material.js";
import {EMPTY_STRING} from "../../core/constants/index.js";
import {DisplayHTML} from "../../core/components/shared/DisplayHTML/DisplayHTML.js";
import {Paragraph} from "../../core/components/shared/Paragraph/Paragraph.js";
import {MthColor, MthTitle} from "../../core/enums/index.js";
import {extractContent} from "../../core/utils/index.js";
const MthCheckbox = (props) => {
  const {
    label,
    labelSx,
    wrapSx,
    faded = false,
    disableColorChangeWhenDisabled = false,
    disabled,
    dangerouslySetLabelHTML,
    rowRequiredQuestionMark = false,
    ...otherProps
  } = props;
  const [extractedLabel, setExtractedLabel] = useState(dangerouslySetLabelHTML?.__html);
  useEffect(() => {
    setExtractedLabel(extractContent(dangerouslySetLabelHTML?.__html ?? EMPTY_STRING));
  }, [dangerouslySetLabelHTML]);
  const renderCheckbox = () => /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start"
  }, rowRequiredQuestionMark && /* @__PURE__ */ React.createElement(Chip, {
    size: "small",
    label: MthTitle.REQUIRED,
    variant: "outlined",
    color: "error",
    sx: {fontWeight: "400", fontSize: 10}
  }), /* @__PURE__ */ React.createElement(Checkbox, {
    ...otherProps,
    disabled: !!disabled,
    sx: {
      "&.Mui-checked, &.MuiCheckbox-indeterminate": {
        color: faded ? MthColor.GRAY_7F : otherProps.color === "secondary" ? MthColor.VORTEX : MthColor.SYSTEM_01
      },
      "&:not(.Mui-disabled) .MuiSvgIcon-root": {
        color: faded ? MthColor.GRAY_7F : otherProps.color === "secondary" ? MthColor.VORTEX : MthColor.SYSTEM_01
      },
      "&.Mui-disabled:not(.Mui-checked)": {
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          width: otherProps.size === "small" ? 16 : 18,
          height: otherProps.size === "small" ? 16 : 18,
          borderRadius: "2px",
          border: disableColorChangeWhenDisabled ? `solid 2px ${MthColor.BLACK}` : `solid 2px ${MthColor.SYSTEM_12}`,
          backgroundColor: disableColorChangeWhenDisabled ? MthColor.WHITE : MthColor.SYSTEM_07
        },
        "& svg": {
          opacity: 0
        }
      },
      "&.Mui-checked.Mui-disabled": {
        "& svg": {
          opacity: disableColorChangeWhenDisabled ? 1 : 0.5
        }
      },
      ...otherProps.sx
    }
  }));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, Boolean(label) || Boolean(extractedLabel) ? /* @__PURE__ */ React.createElement(FormControlLabel, {
    control: renderCheckbox(),
    label: /* @__PURE__ */ React.createElement(Paragraph, {
      size: "large",
      sx: {
        ml: rowRequiredQuestionMark ? 0 : 1.5,
        mt: rowRequiredQuestionMark ? 5 : 0,
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: 1,
        color: disabled ? MthColor.GRAY : MthColor.BLACK,
        ...labelSx
      }
    }, Boolean(label) ? label : Boolean(extractedLabel) ? /* @__PURE__ */ React.createElement(DisplayHTML, {
      text: dangerouslySetLabelHTML.__html
    }) : EMPTY_STRING),
    sx: {
      ".MuiFormControlLabel-label.Mui-disabled": {
        color: "unset"
      },
      ml: 0,
      mr: 0,
      ...wrapSx
    }
  }) : renderCheckbox());
};
export default MthCheckbox;
