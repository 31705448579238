import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getEmailTemplateByNameAndSchoolYearQuery} from "../../../graphql/queries/email-template.js";
export const useEmailTemplateByNameAndSchoolYearId = (templateName, schoolYearId, mid_year = false, regionId) => {
  const [emailSubject, setEmailSubject] = useState("");
  const [emailFrom, setEmailFrom] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [standardResponse, setStandardResponse] = useState("");
  const [emailTempate, setEmailTemplate] = useState();
  const {
    data: emailTemplateData,
    loading,
    error,
    refetch
  } = useQuery(getEmailTemplateByNameAndSchoolYearQuery, {
    variables: {
      templateName,
      schoolYearId,
      midYear: mid_year,
      regionId
    },
    skip: !templateName || !schoolYearId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (emailTemplateData) {
      const {getEmailTemplateByNameAndSchoolYearId} = emailTemplateData;
      if (getEmailTemplateByNameAndSchoolYearId) {
        const {subject, from, body, standard_responses} = getEmailTemplateByNameAndSchoolYearId;
        setEmailSubject(subject);
        setEmailFrom(from);
        setEmailBody(body);
        setStandardResponse(standard_responses);
        setEmailTemplate(getEmailTemplateByNameAndSchoolYearId);
      }
    }
  }, [emailTemplateData]);
  return {
    loading,
    from: emailFrom,
    subject: emailSubject,
    body: emailBody,
    standardResponse,
    setFrom: setEmailFrom,
    setBody: setEmailBody,
    setSubject: setEmailSubject,
    emailTemplate: emailTempate,
    refetch,
    error
  };
};
