export var ReimbursementRequestStatus;
(function(ReimbursementRequestStatus2) {
  ReimbursementRequestStatus2["DRAFT"] = "Draft";
  ReimbursementRequestStatus2["PAID"] = "Paid";
  ReimbursementRequestStatus2["SUBMITTED"] = "Submitted";
  ReimbursementRequestStatus2["RESUBMITTED"] = "Resubmitted";
  ReimbursementRequestStatus2["UPDATES_REQUIRED"] = "Updates Required";
  ReimbursementRequestStatus2["APPROVED"] = "Approved";
  ReimbursementRequestStatus2["ORDERED"] = "Ordered";
  ReimbursementRequestStatus2["PROCESSED"] = "Processed";
  ReimbursementRequestStatus2["REPROCESSED"] = "Reprocessed";
  ReimbursementRequestStatus2["RETURNED"] = "Returned";
})(ReimbursementRequestStatus || (ReimbursementRequestStatus = {}));
