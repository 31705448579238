import {
  calculateGradeNumber,
  getGradeBySchoolYearId,
  gradeToAbbreviation,
  nowBetweenInterval,
  numToStringGrade
} from "../index.js";
import {StudentStatus} from "../../enums/student-status.enum.js";
export const isEligible = (currentSchoolYear, nextSchoolYear, timezone, currStudent) => {
  if (currentSchoolYear && currentSchoolYear.length > 0) {
    const {intent_to_re_enroll_open, intent_to_re_enroll_close} = currentSchoolYear[0];
    if (currStudent && currStudent.status?.length > 0 && intent_to_re_enroll_open && intent_to_re_enroll_close) {
      const currentSchoolYearId = currentSchoolYear[0]?.school_year_id;
      const currentSchoolYearDate = currentSchoolYear[0]?.date_begin;
      const actualGrade = getGradeBySchoolYearId(currStudent, currentSchoolYearId);
      const nextNumGrade = calculateGradeNumber(actualGrade, currentSchoolYearDate, nextSchoolYear[0]?.date_begin);
      const nextGrade = numToStringGrade(nextNumGrade, false);
      const studentStatusForCurrentYear = currStudent.status.find((status) => status.school_year_id === currentSchoolYearId)?.status;
      const isOpen = nowBetweenInterval(intent_to_re_enroll_open, intent_to_re_enroll_close, timezone);
      const isNextGradeInNextYear = nextSchoolYear[0]?.grades.split(",").some((grade) => gradeToAbbreviation(grade) === gradeToAbbreviation(nextGrade));
      if (nextGrade && studentStatusForCurrentYear === StudentStatus.ACTIVE && isOpen && isNextGradeInNextYear) {
        return true;
      }
    }
  }
  return false;
};
