import {useContext, useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery, useMutation, useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {isArray} from "../../../_snowpack/pkg/lodash.js";
import {StudentStatus} from "../../enums/index.js";
import {saveIntentToReEnrollAnswer} from "../../../graphql/mutation/answer-to-reenroll.js";
import {
  getIntentToReEnrollAnswer,
  getPacketsByStudent,
  getStatusesByStudent,
  getSchedulesByStudentId
} from "../../../graphql/queries/intent-to-reenroll.js";
import {UserContext} from "../../../providers/UserContext/UserProvider.js";
export const useHandleIntentToReEnroll = (student, schoolYearId, setError, epic3733bug4987 = false) => {
  const studentId = Number(student?.student_id);
  const {me, setMe} = useContext(UserContext);
  const [answerReEnroll, setAnswerReEnroll] = useState(null);
  const [answerReEnrollReason, setAnswerReEnrollReason] = useState(null);
  const [submitReEnroll, {loading: isSaving}] = useMutation(saveIntentToReEnrollAnswer);
  const {data: intentToReEnrollAnswer, loading: isLoadingAnswer} = useQuery(getIntentToReEnrollAnswer, {
    variables: {
      schoolYearId,
      studentId
    },
    skip: !studentId || !schoolYearId,
    fetchPolicy: "network-only"
  });
  const [getPackets, {loading: isLoadingPackets}] = useLazyQuery(getPacketsByStudent, {
    variables: {
      studentId
    },
    fetchPolicy: "network-only"
  });
  const [getStatuses, {loading: isLoadingStatus}] = useLazyQuery(getStatusesByStudent, {
    variables: {
      studentId
    },
    fetchPolicy: "network-only"
  });
  const [getSchedules, {loading: isLoadingSchedule}] = useLazyQuery(getSchedulesByStudentId, {
    variables: {
      studentId
    },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (intentToReEnrollAnswer && !isLoadingAnswer) {
      const intentToReEnrollAnswerData = intentToReEnrollAnswer.getIntentToReEnrollAnswer;
      if (intentToReEnrollAnswerData) {
        const {answer_intent_to_reenroll, reason_intent_to_reenroll} = intentToReEnrollAnswerData;
        setAnswerReEnroll(answer_intent_to_reenroll);
        setAnswerReEnrollReason(reason_intent_to_reenroll);
      }
    }
  }, [intentToReEnrollAnswer, isLoadingAnswer]);
  const handleSubmit = async (studentId2, schoolyearId, answerIntentToReenroll, reasonIntentToReenroll) => {
    try {
      const responseData = await submitReEnroll({
        variables: {
          answerIntentToReenroll,
          schoolyearId,
          studentId: studentId2,
          reasonIntentToReenroll
        }
      });
      const {
        data: {saveIntentToReEnrollAnswer: enrollResponse}
      } = responseData;
      if (!enrollResponse?.response) {
        setError(true);
        return;
      }
      saveMe(studentId2);
    } catch (error) {
      setError(true);
    }
  };
  const saveMe = async (studentId2) => {
    if (student && student.packets && student.packets.length > 0) {
      const {
        data: {getPacketsByStudent: packets}
      } = await getPackets();
      const {
        data: {getStatusesByStudent: statuses}
      } = await getStatuses();
      let studentSchedules = student.StudentSchedules;
      if (statuses && isArray(statuses) && statuses.at(-1).status === StudentStatus.PENDING && epic3733bug4987) {
        const {
          data: {scheduleByStudentId: schedules}
        } = await getSchedules();
        studentSchedules = schedules;
      }
      const newStudentInfo = {
        ...student,
        packets,
        status: statuses,
        StudentSchedules: studentSchedules
      };
      const newMe = {
        ...me,
        students: me?.students?.map((studentMe) => Number(studentMe.student_id) === Number(studentId2) ? newStudentInfo : studentMe)
      };
      setMe({...newMe});
    }
  };
  const isLoading = isLoadingAnswer || isLoadingPackets || isSaving || isLoadingStatus || isLoadingSchedule;
  return {answerReEnroll, answerReEnrollReason, handleSubmit, isLoading};
};
