import {createContext} from "../../_snowpack/pkg/react.js";
import {atom} from "../../_snowpack/pkg/recoil.js";
export var HyperwalletAccountStatus;
(function(HyperwalletAccountStatus2) {
  HyperwalletAccountStatus2["PRE_ACTIVATED"] = "PRE_ACTIVATED";
  HyperwalletAccountStatus2["ACTIVATED"] = "ACTIVATED";
  HyperwalletAccountStatus2["LOCKED"] = "LOCKED";
  HyperwalletAccountStatus2["FROZEN"] = "FROZEN";
  HyperwalletAccountStatus2["DE_ACTIVATED"] = "DE_ACTIVATED";
})(HyperwalletAccountStatus || (HyperwalletAccountStatus = {}));
export var HyperwalletVerificationStatus;
(function(HyperwalletVerificationStatus2) {
  HyperwalletVerificationStatus2["NOT_REQUIRED"] = "NOT_REQUIRED";
  HyperwalletVerificationStatus2["REQUIRED"] = "REQUIRED";
  HyperwalletVerificationStatus2["FAILED"] = "FAILED";
  HyperwalletVerificationStatus2["UNDER_REVIEW"] = "UNDER_REVIEW";
  HyperwalletVerificationStatus2["VERIFIED"] = "VERIFIED";
})(HyperwalletVerificationStatus || (HyperwalletVerificationStatus = {}));
const userContext = {
  me: null,
  setMe: () => {
  }
};
const tabContext = {
  tab: null,
  setTab: () => {
  },
  visitedTabs: [],
  setVisitedTabs: () => {
  }
};
export const UserContext = createContext(userContext);
export const TabContext = createContext(tabContext);
function parseUserClassSelected() {
  try {
    return Number(JSON.parse(localStorage.getItem("selectedClass") || "0")) ?? null;
  } catch (e) {
    return null;
  }
}
function parseUserRegionState() {
  try {
    return JSON.parse(localStorage.getItem("selectedRegion") || "");
  } catch (e) {
    return null;
  }
}
export const userRegionState = atom({
  key: "userRegion",
  default: parseUserRegionState()
});
export const userClassSelected = atom({
  key: "userClass",
  default: parseUserClassSelected()
});
