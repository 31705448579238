export var ScheduleFieldKey;
(function(ScheduleFieldKey2) {
  ScheduleFieldKey2["DATE"] = "date";
  ScheduleFieldKey2["STATUS"] = "status";
  ScheduleFieldKey2["STUDENT"] = "student";
  ScheduleFieldKey2["GRADE"] = "grade";
  ScheduleFieldKey2["PARENT"] = "parent";
  ScheduleFieldKey2["DIPLOMA"] = "diploma";
  ScheduleFieldKey2["EMAILED"] = "emailed";
  ScheduleFieldKey2["BLANK"] = "blank";
})(ScheduleFieldKey || (ScheduleFieldKey = {}));
export var ScheduleFieldLabel;
(function(ScheduleFieldLabel2) {
  ScheduleFieldLabel2["DATE"] = "Date";
  ScheduleFieldLabel2["STATUS"] = "Status";
  ScheduleFieldLabel2["STUDENT"] = "Student";
  ScheduleFieldLabel2["GRADE"] = "Grade";
  ScheduleFieldLabel2["PARENT"] = "Parent";
  ScheduleFieldLabel2["DIPLOMA"] = "Diploma";
  ScheduleFieldLabel2["EMAILED"] = "Emailed";
  ScheduleFieldLabel2["BLANK"] = "";
})(ScheduleFieldLabel || (ScheduleFieldLabel = {}));
