import React from "../../_snowpack/pkg/react.js";
import InfoIcon from "../../_snowpack/pkg/@mui/icons-material/InfoOutlined.js";
import {Box, Button, Modal, Typography} from "../../_snowpack/pkg/@mui/material.js";
import ReactDOM from "../../_snowpack/pkg/react-dom.js";
import {MthColor} from "../../core/enums/index.js";
import {Paragraph} from "../../core/components/shared/Paragraph/Paragraph.js";
import {useStyles} from "./styles.js";
export const UserLeaveConfirmation = (message, callback) => {
  const container = document.createElement("div");
  const classes = useStyles;
  container.setAttribute("custom-confirm-view", "");
  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
  };
  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
  };
  document.body.appendChild(container);
  const {header, content, bgColor} = JSON.parse(message);
  ReactDOM.render(/* @__PURE__ */ React.createElement(Modal, {
    open: true
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {...classes.modalCard, backgroundColor: bgColor}
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.header
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    fontWeight: "bold"
  }, header)), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.content
  }, /* @__PURE__ */ React.createElement(InfoIcon, {
    sx: {fontSize: 50, margin: "20px 0px"}
  }), /* @__PURE__ */ React.createElement(Paragraph, {
    size: "large",
    color: MthColor.SYSTEM_01,
    sx: {textAlign: "center"}
  }, content), /* @__PURE__ */ React.createElement(Box, {
    display: "flex",
    flexDirection: "row"
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    disableElevation: true,
    sx: classes.cancelButton,
    onClick: handleCancel
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    disableElevation: true,
    sx: classes.submitButton,
    onClick: handleConfirm
  }, "Yes"))))), container);
};
