import * as __SNOWPACK_ENV__ from "../../../../../_snowpack/env.js";
import React from "../../../../../_snowpack/pkg/react.js";
import {useMutation} from "../../../../../_snowpack/pkg/@apollo/client.js";
import AccountBoxOutlinedIcon from "../../../../../_snowpack/pkg/@mui/icons-material/AccountBoxOutlined.js";
import AddIcon from "../../../../../_snowpack/pkg/@mui/icons-material/Add.js";
import {Avatar, Button, Tooltip} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../../_snowpack/pkg/@mui/system.js";
import {useHistory} from "../../../../../_snowpack/pkg/react-router-dom.js";
import {Metadata} from "../../../../../components/Metadata/Metadata.js";
import {EMPTY_STRING} from "../../../../../core/constants/index.js";
import {Paragraph} from "../../../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor, MthRoute} from "../../../../../core/enums/index.js";
import {becomeUserMutation} from "../../../../../graphql/mutation/user.js";
import {getStringInitialLetter} from "../../../../../core/utils/index.js";
export const Header = ({
  userData,
  setOpenObserverModal,
  observers,
  handleChangeParent,
  selectedParent,
  parentId,
  me
}) => {
  const handleOpenObserverModal = () => {
    setOpenObserverModal(true);
  };
  const history = useHistory();
  const [becomeUserAction] = useMutation(becomeUserMutation);
  const becomeUser = (id) => {
    becomeUserAction({
      variables: {
        userId: Number(id)
      }
    }).then((resp) => {
      localStorage.setItem("masquerade", resp.data.masqueradeUser.jwt);
      localStorage.setItem("previousPage", location.href.replace(__SNOWPACK_ENV__.SNOWPACK_PUBLIC_WEB_URL, ""));
    }).then(() => {
      history.push(MthRoute.DASHBOARD.toString());
      location.reload();
    });
  };
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {cursor: "pointer"},
    onClick: () => handleChangeParent(userData)
  }, /* @__PURE__ */ React.createElement(Metadata, {
    title: userData && /* @__PURE__ */ React.createElement(Subtitle, {
      fontWeight: "800",
      color: selectedParent === parseInt(parentId) ? MthColor.MTHBLUE : MthColor.SYSTEM_01,
      size: "medium"
    }, userData.first_name, " ", userData.last_name),
    subtitle: /* @__PURE__ */ React.createElement(Box, {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center"
    }, /* @__PURE__ */ React.createElement(Paragraph, {
      fontWeight: "600",
      color: MthColor.SYSTEM_11,
      sx: {fontSize: "16px"}
    }, "Parent"), selectedParent === parseInt(parentId) && Boolean(me.masquerade) && /* @__PURE__ */ React.createElement(Tooltip, {
      title: "Masquerade",
      onClick: () => becomeUser(userData.user.user_id)
    }, /* @__PURE__ */ React.createElement(AccountBoxOutlinedIcon, {
      sx: {color: MthColor.MTHBLUE, marginLeft: 1, height: 15, width: 15, marginTop: 0.25}
    }))),
    image: /* @__PURE__ */ React.createElement(Avatar, {
      alt: userData && (userData.preferred_first_name ?? userData.first_name),
      src: EMPTY_STRING,
      variant: "rounded",
      style: {marginRight: 8}
    }, getStringInitialLetter(userData && (userData.preferred_first_name ?? userData.first_name)))
  })), observers.map((item, idx) => /* @__PURE__ */ React.createElement(Box, {
    sx: {
      marginLeft: "12px",
      cursor: "pointer"
    },
    key: idx,
    onClick: () => handleChangeParent(item)
  }, /* @__PURE__ */ React.createElement(Metadata, {
    title: /* @__PURE__ */ React.createElement(Subtitle, {
      fontWeight: "800",
      color: selectedParent === parseInt(item.observer_id) ? MthColor.MTHBLUE : MthColor.SYSTEM_01,
      size: "medium"
    }, item.user.first_name, " ", item.user.last_name),
    subtitle: /* @__PURE__ */ React.createElement(Box, {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center"
    }, /* @__PURE__ */ React.createElement(Paragraph, {
      fontWeight: "600",
      color: MthColor.SYSTEM_11,
      sx: {fontSize: "16px"}
    }, "Observer"), selectedParent === parseInt(parentId) && Boolean(me.masquerade) && /* @__PURE__ */ React.createElement(Tooltip, {
      title: "Masquerade",
      onClick: () => becomeUser(userData.user.user_id)
    }, /* @__PURE__ */ React.createElement(AccountBoxOutlinedIcon, {
      sx: {color: MthColor.MTHBLUE, marginLeft: 1, height: 15, width: 15, marginTop: 0.25}
    }))),
    image: /* @__PURE__ */ React.createElement(Avatar, {
      alt: item.person?.preferred_first_name ?? item.person?.first_name,
      src: EMPTY_STRING,
      variant: "rounded",
      style: {marginRight: 8}
    }, getStringInitialLetter(item.person?.preferred_first_name ?? item.person?.first_name))
  }))), /* @__PURE__ */ React.createElement(Button, {
    onClick: handleOpenObserverModal,
    disableElevation: true,
    variant: "contained",
    sx: {
      marginLeft: "12px",
      background: "#FAFAFA",
      color: "black",
      textTransform: "none",
      fontSize: "16px",
      "&:hover": {
        background: MthColor.SYSTEM_14,
        color: "#000"
      }
    },
    startIcon: /* @__PURE__ */ React.createElement(AddIcon, null)
  }, "Add Observer"));
};
