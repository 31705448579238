import React, {useContext, useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {useQuery, gql} from "../../../../_snowpack/pkg/@apollo/client.js";
import {Grid, Box} from "../../../../_snowpack/pkg/@mui/material.js";
import dayjs from "../../../../_snowpack/pkg/dayjs.js";
import timezone from "../../../../_snowpack/pkg/dayjs/plugin/timezone.js";
import utc from "../../../../_snowpack/pkg/dayjs/plugin/utc.js";
import {STATES_WITH_ABBREVIATION} from "../../../../core/constants/index.js";
import {Paragraph} from "../../../../core/components/shared/Paragraph/Paragraph.js";
import {Title} from "../../../../core/components/shared/Title/Title.js";
import {MthColor, RoleLabels} from "../../../../core/enums/index.js";
import {ProfileContext} from "../../../../providers/ProfileProvider/ProfileContext.js";
import {UserContext} from "../../../../providers/UserContext/UserProvider.js";
import {getStudentGradeLevelBySY, parseGradeLevel, phoneFormat} from "../../../../core/utils/index.js";
dayjs.extend(utc);
dayjs.extend(timezone);
const getSchoolYearsByRegionId = gql`
  query Region($regionId: ID!) {
    region(id: $regionId) {
      SchoolYears {
        school_year_id
        date_begin
        date_end
        grades
        birth_date_cut
        special_ed
        special_ed_options
        enrollment_packet
        date_reg_close
        date_reg_open
        midyear_application
        midyear_application_close
        midyear_application_open
      }
    }
  }
`;
export const EnrollmentJobsInfo = ({packet, handleModem, refetch}) => {
  const {showModal} = useContext(ProfileContext);
  const {me} = useContext(UserContext);
  const [specialEdOptions, setSpecialEdOptions] = useState([]);
  const {data: schoolYearData} = useQuery(getSchoolYearsByRegionId, {
    variables: {
      regionId: me?.selectedRegionId
    },
    fetchPolicy: "cache-and-network"
  });
  useEffect(() => {
    if (schoolYearData?.region?.SchoolYears) {
      const shoolYears = schoolYearData?.region?.SchoolYears || [];
      let special_ed_options = "";
      shoolYears.filter((item) => new Date(item.date_begin) <= new Date() && new Date(item.date_end) >= new Date()).map((item) => {
        if (item.special_ed == true && item.special_ed_options != "" && item.special_ed_options != null && special_ed_options == "")
          special_ed_options = item.special_ed_options;
      });
      if (special_ed_options == "")
        setSpecialEdOptions([]);
      else
        setSpecialEdOptions(special_ed_options.split(","));
    }
  }, [schoolYearData?.region?.SchoolYears]);
  const refreshModal = () => {
    refetch();
    handleModem();
  };
  const profileClicked = (profileData) => {
    handleModem();
    showModal(profileData, refreshModal);
  };
  const student = packet?.student;
  const age = student?.person.date_of_birth ? dayjs().diff(student?.person.date_of_birth, "years", false) : void 0;
  const street = student?.parent.person.address.street;
  const street2 = student?.parent.person.address.street2;
  const gradeLevel = getStudentGradeLevelBySY(student, Number(packet.school_year_id));
  function studentSPED() {
    if (specialEdOptions.length > Number(student?.special_ed))
      return specialEdOptions[Number(student?.special_ed)];
    return "None";
  }
  const getState = (state) => {
    if (state) {
      return state + ",";
    }
    const defaultState = student?.parent?.person?.user?.userRegions[0].regionDetail.name;
    if (defaultState && STATES_WITH_ABBREVIATION[defaultState]) {
      return STATES_WITH_ABBREVIATION[defaultState] + ",";
    }
    return "";
  };
  return /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    columnSpacing: 4
  }, !packet ? /* @__PURE__ */ React.createElement(Box, {
    sx: {minHeight: "200px"}
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, {
    sx: {
      "&.MuiGrid-root": {
        paddingRight: "3px"
      }
    },
    item: true,
    md: 4,
    sm: 12,
    xs: 12,
    xl: 6
  }, /* @__PURE__ */ React.createElement(Title, {
    sx: {
      color: MthColor.VORTEX,
      cursor: "pointer",
      textDecoration: "underline"
    },
    size: "small",
    fontWeight: "700"
  }, /* @__PURE__ */ React.createElement("span", {
    onClick: () => profileClicked({...student, role: RoleLabels.STUDENT})
  }, student?.person.first_name, " ", student?.person.last_name)), /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {marginY: "4px", fontSize: "14px"},
    color: MthColor.SYSTEM_01,
    fontWeight: "bold"
  }, /* @__PURE__ */ React.createElement("b", null, student?.person.preferred_first_name && student?.person.preferred_last_name ? `${student?.person.preferred_first_name} ${student?.person.preferred_last_name}` : "Not found")), /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {marginY: "4px", fontSize: "14px"},
    color: MthColor.SYSTEM_05,
    fontWeight: "bold"
  }, "Gender:", " ", /* @__PURE__ */ React.createElement("b", {
    style: {color: MthColor.SYSTEM_01}
  }, student?.person.gender ? student?.person.gender : "Not found")), /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {fontSize: "14px"},
    color: MthColor.SYSTEM_05,
    fontWeight: "bold"
  }, "DOB:", " ", /* @__PURE__ */ React.createElement("b", {
    style: {color: MthColor.SYSTEM_01}
  }, student?.person.date_of_birth ? dayjs(student?.person.date_of_birth).tz("UTC").format("MMMM D, YYYY") : "Not found", age ? ` (${age})` : "")), /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {marginY: "4px", fontSize: "14px"},
    color: MthColor.SYSTEM_01,
    fontWeight: "bold"
  }, parseGradeLevel(gradeLevel)), /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {marginY: "4px", fontSize: "14px"},
    color: MthColor.SYSTEM_05,
    fontWeight: "bold"
  }, "SPED: ", /* @__PURE__ */ React.createElement("span", {
    style: {color: MthColor.SYSTEM_01}
  }, studentSPED()))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 6,
    sm: 6,
    xs: 12,
    sx: {
      "&.MuiGrid-root": {
        maxWidth: "12rem"
      }
    }
  }, /* @__PURE__ */ React.createElement(Title, {
    sx: {
      color: MthColor.VORTEX,
      cursor: "pointer",
      textDecoration: "underline"
    },
    size: "small",
    fontWeight: "700"
  }, /* @__PURE__ */ React.createElement("span", {
    onClick: () => profileClicked({...student?.parent, role: RoleLabels.PARENT})
  }, student?.parent.person.first_name, " ", student?.parent.person.last_name)), /* @__PURE__ */ React.createElement(Paragraph, {
    color: MthColor.LIGHTPURPLE,
    sx: {fontSize: "14px", marginY: "4px", marginRight: "10px"},
    fontWeight: "400"
  }, student?.parent.person.email ? `${student?.parent.person.email}` : student?.parent.person.user.email ? student?.parent.person.user.email : "Not found"), /* @__PURE__ */ React.createElement(Paragraph, {
    color: MthColor.SYSTEM_01,
    sx: {fontSize: "14px", marginY: "4px"},
    fontWeight: "400"
  }, student?.parent.phone.number ? phoneFormat(student?.parent.phone.number) : "Not found"), /* @__PURE__ */ React.createElement(Paragraph, {
    color: MthColor.SYSTEM_01,
    sx: {fontSize: "14px", marginY: "4px"},
    fontWeight: "400"
  }, street ? `${street} ${street2 ? ", " + street2 : ""}` : "Not found"), /* @__PURE__ */ React.createElement(Paragraph, {
    color: MthColor.SYSTEM_01,
    sx: {fontSize: "14px", marginY: "4px"},
    fontWeight: "400"
  }, !student?.parent.person.address.city && !student?.parent.person.address.state && !student?.parent.person.address.zip ? "Not found" : `${student?.parent.person.address.city ? student?.parent.person.address.city + "," : ""} ${getState(student?.parent.person.address.state)}
                ${student?.parent.person.address.zip || ""}`))));
};
