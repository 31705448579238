export var PacketStatus;
(function(PacketStatus2) {
  PacketStatus2["MISSING_INFO"] = "Missing Info";
  PacketStatus2["SUBMITTED"] = "Submitted";
  PacketStatus2["RESUBMITTED"] = "Resubmitted";
  PacketStatus2["STARTED"] = "Started";
  PacketStatus2["ACCEPTED"] = "Accepted";
  PacketStatus2["CONDITIONAL"] = "Conditional";
  PacketStatus2["NOT_STARTED"] = "Not Started";
  PacketStatus2["AGE_ISSUE"] = "Age Issue";
  PacketStatus2["NOT_SUBMITTED"] = "Not Submitted";
})(PacketStatus || (PacketStatus = {}));
