import {MthColor} from "../../../enums/index.js";
import {BANNER_HEIGHT} from "./AutoUpdateBanner.js";
export const getStyles = (isMobile) => ({
  container: {
    background: MthColor.TANGERINE,
    marginTop: isMobile ? "80px" : "0px",
    display: "flex",
    height: isMobile ? "120px" : `${BANNER_HEIGHT}px`,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px"
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 5px",
    marginLeft: 2,
    color: MthColor.WHITE,
    fontWeight: isMobile ? null : 700
  },
  buttonContainer: {display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 5, marginRight: 2},
  button: {
    background: MthColor.WHITE,
    color: "black",
    borderRadius: "15px",
    "&:hover": {
      background: "#000",
      color: "#fff"
    },
    paddingY: 1,
    paddingX: 4
  }
});
