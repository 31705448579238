export var FiltersCommonStatus;
(function(FiltersCommonStatus2) {
  FiltersCommonStatus2["UNASSIGNED"] = "unassigned";
  FiltersCommonStatus2["ALL"] = "all";
})(FiltersCommonStatus || (FiltersCommonStatus = {}));
export var FilterOptionType;
(function(FilterOptionType2) {
  FilterOptionType2["CHECKBOX"] = "checkbox";
  FilterOptionType2["BUTTON"] = "button";
  FilterOptionType2["NONE"] = "none";
})(FilterOptionType || (FilterOptionType = {}));
export var FilterPosition;
(function(FilterPosition2) {
  FilterPosition2["LEFT"] = "left";
  FilterPosition2["RIGHT"] = "right";
})(FilterPosition || (FilterPosition = {}));
export var FilterSectionAlignItems;
(function(FilterSectionAlignItems2) {
  FilterSectionAlignItems2["AUTO"] = "auto";
  FilterSectionAlignItems2["END"] = "end";
})(FilterSectionAlignItems || (FilterSectionAlignItems = {}));
