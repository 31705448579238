import {useState, useCallback} from "../../../_snowpack/pkg/react.js";
import {useMutation} from "../../../_snowpack/pkg/@apollo/client.js";
import {useFlag} from "../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {omit} from "../../../_snowpack/pkg/lodash.js";
import {EPIC_3537_BUG_2359} from "../../constants/index.js";
import {EnrollmentPacketSaveAction, EnrollmentPacketSaveContext, PacketStatus} from "../../enums/index.js";
import {
  savePacketMutation,
  updateCreateStudentImmunizationMutation
} from "../../../screens/Admin/EnrollmentPackets/services.js";
export const useSubmitEnrollmentPacket = (packet, refetch, methods, questionsData, scheduleEnabled) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [updateCreateStudentImm] = useMutation(updateCreateStudentImmunizationMutation);
  const [savePacket] = useMutation(savePacketMutation, {
    fetchPolicy: "network-only"
  });
  const epic3537bug2359 = useFlag(EPIC_3537_BUG_2359);
  const onSubmit = useCallback(async (vals) => {
    setLoading(true);
    setError("");
    try {
      const status = vals.preSaveStatus;
      const packetId = packet?.packet_id;
      const metaField = packet?.meta;
      const schoolYearId = packet?.school_year_id;
      const student = packet?.student;
      const studentPerson = student?.person;
      const parent = student?.parent;
      const temp = {
        packet: {},
        student: {
          first_name: studentPerson?.first_name,
          last_name: studentPerson?.last_name,
          address: {}
        },
        parent: {...omit(parent?.person, ["person_id", "address", "emailConfirm"])},
        meta: {},
        packet_id: Number(packetId),
        admin_notes: vals.notes,
        status,
        exemption_form_date: vals.exemptionDate,
        medical_exemption: vals.medicalExempt ? 1 : 0,
        missing_files: status === EnrollmentPacketSaveAction.MISSING_INFO ? JSON.stringify(vals.missing_files) : "",
        last_clicked_action: vals.last_clicked_action
      };
      if (questionsData && questionsData?.length > 0) {
        questionsData.map((tab) => {
          tab?.groups?.map((group) => {
            group?.questions?.map((q) => {
              if (q.default_question) {
                if (q.slug.includes("packet_")) {
                  const fieldName = q.slug.split("packet_")[1];
                  temp.packet[fieldName] = vals[q.slug];
                } else if (q.slug.includes("student_")) {
                  const fieldName = q.slug.split("student_")[1];
                  if (fieldName !== "student_emailConfirm") {
                    temp.student[fieldName] = vals[q.slug];
                  }
                } else if (q.slug.includes("address_")) {
                  const fieldName = q.slug.split("address_")[1];
                  temp.student.address[fieldName] = vals[q.slug];
                } else if (q.slug.includes("parent_")) {
                  const fieldName = q.slug.split("parent_")[1];
                  if (fieldName !== "parent_emailConfirm") {
                    temp.parent[fieldName] = vals[q.slug];
                  }
                } else if (q.slug == "meta_special_education") {
                  temp.meta[q.slug] = Number(vals[q.slug]);
                } else {
                  temp.meta[q.slug] = vals[q.slug];
                }
              } else {
                temp.meta[q.slug] = vals[q.slug];
              }
            });
          });
        });
      }
      const parentLegalName = metaField && JSON.parse(metaField)?.meta_parentlegalname ? JSON.parse(metaField).meta_parentlegalname : "";
      temp.meta["meta_parentlegalname"] = parentLegalName;
      if (!temp.parent["email"]) {
        temp.parent["email"] = vals.parent.user.email;
      }
      await updateCreateStudentImm({
        variables: {
          input: vals.immunizations.map((v) => ({
            student_id: v.student_id,
            immunization_id: v.immunization_id,
            value: v.value,
            school_year_id: schoolYearId
          }))
        }
      });
      await savePacket({
        variables: {
          enrollmentPacketInput: {
            ...temp,
            student: omit(temp.student, ["emailConfirm"]),
            parent: omit(temp.parent, ["emailConfirm", "user"]),
            meta: JSON.stringify(temp.meta),
            save_context: EnrollmentPacketSaveContext.PACKET_ACTION,
            packet_id: Number(packetId)
          }
        }
      });
      refetch();
      if (status === EnrollmentPacketSaveAction.ACCEPTED) {
        methods.setValue("saveAlert", "The packet has been accepted");
        setTimeout(() => methods.setValue("saveAlert", ""), 5e3);
      } else if (epic3537bug2359 || ![PacketStatus.AGE_ISSUE, PacketStatus.MISSING_INFO].includes(status)) {
        methods.setValue("saveAlert", "Packet Saved");
        setTimeout(() => methods.setValue("saveAlert", ""), 5e3);
      }
    } catch (error2) {
      methods.setValue("saveError", "Error while updating packet");
      setTimeout(() => methods.setValue("saveError", ""), 5e3);
      setError(String(error2));
      console.error("Submission failed", error2);
    } finally {
      setLoading(false);
    }
  }, [packet, questionsData, scheduleEnabled]);
  return {
    onSubmit,
    loading,
    error
  };
};
