export var soeLowStatus;
(function(soeLowStatus2) {
  soeLowStatus2["ASSIGN"] = "assign";
  soeLowStatus2["UNASSIGNED"] = "unassigned";
})(soeLowStatus || (soeLowStatus = {}));
export var soeUppStatus;
(function(soeUppStatus2) {
  soeUppStatus2["ASSIGN"] = "Assign";
  soeUppStatus2["UNASSIGNED"] = "Unassigned";
})(soeUppStatus || (soeUppStatus = {}));
