import {r as react} from "./index-c6c556cc.js";
import {u as useEnhancedEffect} from "./useEnhancedEffect-f0068896.js";
function useEventCallback(fn) {
  const ref = react.useRef(fn);
  useEnhancedEffect(() => {
    ref.current = fn;
  });
  return react.useCallback((...args) => (0, ref.current)(...args), []);
}
export {useEventCallback as u};
