export var MthTitle;
(function(MthTitle2) {
  MthTitle2["LTI"] = "LTI";
  MthTitle2["CLEAR_CACHE"] = "Clear Cache";
  MthTitle2["ASSIGN_NEW_SOE"] = "Assign new SoE";
  MthTitle2["CLOSE"] = "Close";
  MthTitle2["DOWNLOAD"] = "Download";
  MthTitle2["DOWNLOAD_ICON"] = "Download Icon";
  MthTitle2["SEARCH_DOTS"] = "Search...";
  MthTitle2["RESULTS"] = "Results";
  MthTitle2["SHOW"] = "Show";
  MthTitle2["DESCRIPTION"] = "Description";
  MthTitle2["COURSE_TYPE"] = "Course Type";
  MthTitle2["TEACHER"] = "Teacher";
  MthTitle2["SELECT_ALL"] = "Select All";
  MthTitle2["NEW"] = "New";
  MthTitle2["RETURNING"] = "Returning";
  MthTitle2["SCHEDULE_REPORTS"] = "Schedules";
  MthTitle2["SCHOOL_PARTNER_REPORTS"] = "School Partner Reports";
  MthTitle2["APPLICATIONS_REPORTS"] = "Applications";
  MthTitle2["MASTER_REPORTS"] = "Master";
  MthTitle2["SCHOOL_YEAR"] = "School Year";
  MthTitle2["APPLICATIONS"] = "Applications";
  MthTitle2["YEAR"] = "Year";
  MthTitle2["MID_YEAR"] = "Mid-year";
  MthTitle2["MID_YEAR_APPLICATION"] = "Mid-year Application";
  MthTitle2["ENROLLMENT_PACKETS"] = "Enrollment Packets";
  MthTitle2["STATE"] = "State";
  MthTitle2["STATE_LOGO"] = "State Logo";
  MthTitle2["PROGRAM"] = "Program";
  MthTitle2["COUNTIES"] = "Counties";
  MthTitle2["SCHOOL_DISTRICTS"] = "School Districts";
  MthTitle2["GRADES"] = "Grades";
  MthTitle2["GRADES_REQUIRED"] = "Grades Required";
  MthTitle2["PROGRAM_YEAR_REQUIRED"] = "Program Year Required";
  MthTitle2["BIRTHDAY_CUT_OFF"] = "Birthday Cut-off";
  MthTitle2["SPECIAL_ED"] = "Special Ed";
  MthTitle2["SPECIAL_EDUCATION"] = "Special Education";
  MthTitle2["SHOW_ARCHIVED"] = "Show Archived";
  MthTitle2["HIDE_ARCHIVED"] = "Hide Archived";
  MthTitle2["ADD_ANNOUNCEMENT"] = "Add Announcement";
  MthTitle2["ADD_POPUP"] = "Add Popup";
  MthTitle2["ADD_ASSESSMENT"] = "Add Assessment";
  MthTitle2["NEW_ASSESSMENT"] = "New Assessment";
  MthTitle2["TEST_NAME"] = "Test Name";
  MthTitle2["INFORMATION"] = "Information";
  MthTitle2["OPTION_1"] = "Option 1";
  MthTitle2["ADD_OPTION"] = "Add Option";
  MthTitle2["ADD_NEW"] = "Add New";
  MthTitle2["UNSAVED_TITLE"] = "Unsaved Changes";
  MthTitle2["NOTIFICATION"] = "Notification";
  MthTitle2["UNSAVED_DESCRIPTION_DO"] = 'Are you sure you want to exit without sending this email? The status of "Order" will not be maintained';
  MthTitle2["UNSAVED_DESCRIPTION"] = "Are you sure you want to leave without saving changes?";
  MthTitle2["PROGRAM_SETTINGS"] = "Program Settings";
  MthTitle2["SCHEDULES"] = "Schedules";
  MthTitle2["DIPLOMA_SEEKING"] = "Diploma-seeking";
  MthTitle2["NON_DIPLOMA_SEEKING"] = "Non Diploma-seeking";
  MthTitle2["DIPLOMA_SEEKING_PATH"] = "Diploma-seeking Path";
  MthTitle2["TESTING_PREFERENCE"] = "Testing Preference";
  MthTitle2["APPLICATION_QUESTIONS"] = "Application Questions";
  MthTitle2["ENROLLMENT_QUESSTONS"] = "Enrollment Questions";
  MthTitle2["IMMUNIZATIONS"] = "Immunizations";
  MthTitle2["MID_YEAR_SCHEDULES"] = "Mid-year Schedules";
  MthTitle2["SCHEUDLE_BUILDER"] = "Schedule Builder";
  MthTitle2["SECOND_SEMESTER"] = "2nd Semester";
  MthTitle2["SECOND_SEMESTER_STARTS"] = "2nd Semester Starts";
  MthTitle2["HOMEROOM_RESOURCES"] = "Homeroom Resources";
  MthTitle2["HOMEROOM_RESOURCE"] = "Homeroom Resource";
  MthTitle2["SCHEDULE"] = "Schedule";
  MthTitle2["STEP_TESTING_PREFERENCE"] = "Testing";
  MthTitle2["STEP_OPT_OUT_FORM"] = "Opt";
  MthTitle2["STEP_DIPLOMA_SEEKING"] = "Diploma";
  MthTitle2["STEP_SCHEDULE_BUILDER"] = "Schedule";
  MthTitle2["REDUCES_FUNDS"] = "Reduces Funds";
  MthTitle2["ON_SITE_SPLIT_ENROLLMENT"] = "On-site Split Enrollment";
  MthTitle2["SAVE_CHANGES"] = "Save Changes";
  MthTitle2["ACCEPT"] = "Accept";
  MthTitle2["ACCEPT_AS_SECOND_SEMESTER"] = "Accept as 2nd semester";
  MthTitle2["SAVE_DRAFT"] = "Save Draft";
  MthTitle2["REQUEST_UPDATES"] = "Request Updates";
  MthTitle2["SUBMIT"] = "Submit";
  MthTitle2["SUBMIT_UPDATES"] = "Submit Updates";
  MthTitle2["SUBMIT_REQUEST_RESOURCES"] = "Submit Requests";
  MthTitle2["CONFIRM_RESOURCES"] = "Are you sure you want to leave without submitting your request for Homeroom Resources?";
  MthTitle2["LEARNING_LOGS"] = "Learning Logs";
  MthTitle2["EOY_LEARNING_LOGS"] = "End of Year Learning Log Deadline";
  MthTitle2["DIRECT_ORDER_REIMBURSEMENTS_INFO"] = "Direct Order & Reimbursement Information";
  MthTitle2["REIMBURSEMENTS"] = "Reimbursements";
  MthTitle2["DIRECT_ORDERS"] = "Direct Orders";
  MthTitle2["FIRST_SEMESTER"] = "1st Semester";
  MthTitle2["FIRST_SEMESTER_ENDS"] = "1st Semester Ends";
  MthTitle2["GRADE_TRANSITION"] = "12th Grade Transition Date";
  MthTitle2["GRADE_TRANSITION_ENABLE"] = "Grade Transition";
  MthTitle2["SUPPLEMENTAL_LEARNING_FUNDS"] = "Supplemental Learning Funds";
  MthTitle2["TECHNOLOGY"] = "Technology";
  MthTitle2["CUSTOM_BUILT"] = "Custom-built";
  MthTitle2["PASSWORD_HINT"] = "Passwords must contain 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character.";
  MthTitle2["DIRECT_ORDERS_REIMBURSEMENTS"] = "Direct Orders & Reimbursements";
  MthTitle2["DELETE_PACKET_TITLE"] = "Delete Packet";
  MthTitle2["DELETE_PACKET_DESCRIPTION"] = "Deleting the student packet will revert their status to blank. They will need to re-apply. Are you sure you want to delete the packet(s)?";
  MthTitle2["BTN_DELETE"] = "Delete";
  MthTitle2["TECHNOLOGY_ALLOWANCE"] = "Technology Allowance";
  MthTitle2["MY_TECH_HIGH_DIRECT"] = "My Tech High Direct";
  MthTitle2["THIRD_PARTY_PROVIDER"] = "3rd Party Provider";
  MthTitle2["REQUIRED_SOFTWARE"] = "Required Software";
  MthTitle2["REIMBURSEMENT_FORMS"] = "Reimbursement Forms";
  MthTitle2["DIRECT_ORDER_FORMS"] = "Direct Order Forms";
  MthTitle2["REQUIRE_UPDATES"] = "Require Updates";
  MthTitle2["UPDATES_REQUIRED"] = "Updates Required";
  MthTitle2["ALLOW_UPDATES"] = "Allow Updates";
  MthTitle2["DASHBOARD"] = "Dashboard";
  MthTitle2["HOMEROOM"] = "Homeroom";
  MthTitle2["SETTINGS"] = "Settings";
  MthTitle2["QUICK_LINKS"] = "Quick Links";
  MthTitle2["USERS"] = "Users";
  MthTitle2["USERS_REQUIRED"] = "Users Required";
  MthTitle2["REPORTS"] = "Reports";
  MthTitle2["RECORDS"] = "Records";
  MthTitle2["ENROLLMENT"] = "Enrollment";
  MthTitle2["CURRICULUM"] = "Curriculum";
  MthTitle2["CALENDAR"] = "Calendar";
  MthTitle2["ANNOUNCEMENTS"] = "Announcements";
  MthTitle2["ANNOUNCEMENT_POPUPS"] = "Announcement Popups";
  MthTitle2["STUDENTS"] = "Students";
  MthTitle2["SHOW_GRADUATED_COMPLETED"] = "Show Graduated/Completed";
  MthTitle2["COMMUNICATION"] = "Communication";
  MthTitle2["PARENT_LINK"] = "Parent Link";
  MthTitle2["PARENT"] = "Parent";
  MthTitle2["REPORT_TITLE"] = "Report title";
  MthTitle2["SELECTED_COLUMNS"] = "Selected Columns";
  MthTitle2["ADD_CUSTOM_COLUMN"] = "+ Add Custom Column";
  MthTitle2["ADD_COLUMNS"] = "Add Columns";
  MthTitle2["COLUMN_TITLE"] = "Column Title";
  MthTitle2["COLUMN_DATA"] = "Column Data";
  MthTitle2["REQUIRED"] = "Required";
  MthTitle2["ENTRY"] = "Entry";
  MthTitle2["SEARCH"] = "Search";
  MthTitle2["SEARCH_VIEW_ALL"] = "Search title or message";
  MthTitle2["EDIT"] = "Edit";
  MthTitle2["DELETE"] = "Delete";
  MthTitle2["MOVE"] = "Move";
  MthTitle2["UPLOAD_FILE"] = "Upload File";
  MthTitle2["MAX_UPLOAD_FILE_SIZE"] = "(Maximum of 20MB)";
  MthTitle2["FILTER"] = "Filter";
  MthTitle2["CLEAR_ALL"] = "Clear All";
  MthTitle2["SELECT_YEAR"] = "Select Year";
  MthTitle2["DIRECT_DEDUCTION"] = "Add Direct Deduction";
  MthTitle2["SUM"] = "Sum";
  MthTitle2["CANCEL"] = "Cancel";
  MthTitle2["DELETE_REQUEST"] = "Are you sure you want to delete this request?";
  MthTitle2["SEND"] = "Send";
  MthTitle2["SUBJECT"] = "Subject";
  MthTitle2["SUBJECT_TITLE"] = "Subject Title";
  MthTitle2["FROM_EMAIL"] = "From: email in template";
  MthTitle2["FROM"] = "From";
  MthTitle2["STATE_COURSE_CODES"] = "State Course Codes";
  MthTitle2["STATE_CODE"] = "State Code";
  MthTitle2["STATE_CODES"] = "State Codes";
  MthTitle2["TITLE_ID"] = "Title ID";
  MthTitle2["TITLE"] = "Title";
  MthTitle2["BLANK"] = "Blank";
  MthTitle2["IMPORT"] = "Import";
  MthTitle2["FAMILY_REIMBURSEMENT_TITLE"] = "Direct Orders & Requests for Reimbursement";
  MthTitle2["MY_TECH_HIGH"] = "My Tech High";
  MthTitle2["MTH"] = "MTH";
  MthTitle2["OPENED"] = "OpenEd";
  MthTitle2["TECH_TREP_ACADEMY"] = "Tech Trep Academy";
  MthTitle2["ADDITIONAL_INFORMATION"] = "Additional Information";
  MthTitle2["AMOUNT"] = "Amount";
  MthTitle2["TYPE"] = "Type";
  MthTitle2["STUDENT"] = "Student";
  MthTitle2["STUDENT_ID"] = "Student ID";
  MthTitle2["PROGRAM_YEAR"] = "Program Year";
  MthTitle2["STATUS"] = "Status";
  MthTitle2["GRADE_LEVEL"] = "Grade Level";
  MthTitle2["CURRICULUM_PROVIDER"] = "Curriculum Provider";
  MthTitle2["CURRENT_SOE"] = "Current SoE";
  MthTitle2["PREVIOUS_SOE"] = "Previous SoE";
  MthTitle2["SCHOOL_DISTRICT"] = "School District";
  MthTitle2["SOE_PREFERENCE"] = "SoE Preference";
  MthTitle2["SCHOOL_OF_ENROLLMENT"] = "School of Enrollment";
  MthTitle2["NOTES"] = "Notes";
  MthTitle2["ASSIGN"] = "Assign";
  MthTitle2["SOE"] = "SoE";
  MthTitle2["STUDENT_LEGAL_NAME"] = "Student Legal";
  MthTitle2["STUDENT_PREFERRED_NAME"] = "Student Preferred";
  MthTitle2["GRADE"] = "Grade";
  MthTitle2["STATUS_DATE"] = "Status Date";
  MthTitle2["PERIOD_CATEGORY"] = "Period Category";
  MthTitle2["PERIOD"] = "Period";
  MthTitle2["COURSE_CODE"] = "Course Code";
  MthTitle2["ORDER"] = "Order";
  MthTitle2["CONFIRMATION"] = "Confirmation:";
  MthTitle2["ORDER_CONFIRMATION"] = "Order Confirmation";
  MthTitle2["SCHEDULE_IS_REQUIRED"] = "Schedule is Required";
  MthTitle2["SUBMIT_NOW"] = "Submit Now";
  MthTitle2["RESUBMIT_NOW"] = "Resubmit Now";
  MthTitle2["STUDENT_LEGAL_LAST_FIRST"] = "Student Legal Last, First";
  MthTitle2["STUDENT_PREFERRED_LAST_FIRST"] = "Student Preferred Last, First";
  MthTitle2["MTH_PROVIDER"] = "MTH Provider";
  MthTitle2["OPENED_PROVIDER"] = "OpenEd Provider";
  MthTitle2["PROVIDER_COURSE"] = "Provider Course";
  MthTitle2["CUSTOM_DESCRIPTION"] = "Custom Description";
  MthTitle2["CREATE_HYPERWALLET_ACCOUNT"] = "Create a Hyperwallet Account";
  MthTitle2["SCHEDULE_STATUS"] = "Schedule Status";
  MthTitle2["HW_SYNC_TITLE"] = "Hyperwallet Sync";
  MthTitle2["HW_SYNC_MESSAGE"] = "Approved Request for Reimbursements are sent to Hyperwallet every Monday and Thursday at 3 am MT for payments";
  MthTitle2["HW_SYNC_APPROVED_MESSAGE"] = "Current Sum of Approved Request to be sent for";
  MthTitle2["AVERAGE"] = "Average";
  MthTitle2["LAUNCHPAD"] = "Launchpad";
  MthTitle2["OTHER"] = "Other";
  MthTitle2["PROVIDERS"] = "Providers";
  MthTitle2["TESTING_OPT_IN"] = "Testing Opt-in";
  MthTitle2["TESTING_OPT_OUT"] = "Testing Opt-out";
  MthTitle2["FEEDBACK"] = "Feedback";
  MthTitle2["WARNING"] = "Warning";
  MthTitle2["VIEW"] = "View";
  MthTitle2["EDIT_RESUBMIT"] = "Edit & Resubmit";
  MthTitle2["LATE"] = "Late";
  MthTitle2["CLEAR_FORMATTING"] = "Clear Formatting";
  MthTitle2["DEFAULT_QUESTION"] = "Default Question";
  MthTitle2["QUESTION"] = "Question";
  MthTitle2["ADDITIONAL_QUESTIONS"] = "Additional Questions";
  MthTitle2["DISCLAIMER_TEXT"] = "Disclaimer Text";
  MthTitle2["TEXT_MESSAGE"] = "I can receive critical, program-specific text messages via this number.";
  MthTitle2["VALIDATION"] = "Validation";
  MthTitle2["DISPLAY_FOR_ADMIN"] = "Display for Admin";
  MthTitle2["OK"] = "Ok";
  MthTitle2["ERROR_PROCESSING_APPLICATION"] = "There was an error when processing applications";
  MthTitle2["PROCESSING_APPLICATIONS"] = "Processing Applications";
  MthTitle2["PROCESSING_APPLICATIONS_SUBTITLE"] = "Processing applications... it might take 10 seconds per application, please don't close this browser or tab while processing.";
  MthTitle2["SESSION_EXPIRED"] = "Your session has expired";
  MthTitle2["LOGIN_AGAIN"] = "Please log in again";
  MthTitle2["SEARCH_TITLE"] = "Search title, message, or student";
  MthTitle2["FIRST_SEMESTER_AVERAGE"] = "1st Semester Average";
  MthTitle2["SECOND_SEMESTER_AVERAGE"] = "2nd Semester Average";
  MthTitle2["NUMBER_OF_ZEROS"] = "# of Zeros";
  MthTitle2["ZEROS_FIRST_SEMESTER"] = "# of Zeros in 1st semester";
  MthTitle2["ZEROS_SECOND_SEMESTER"] = "# of Zeros in 2nd semester";
  MthTitle2["NUMBER_OF_EXCUSED"] = "# of Excused";
  MthTitle2["NUMBER_OF_EXCUSED_LOGS"] = "# of Excused Logs";
  MthTitle2["MIN_NUBMER"] = "Min Number";
  MthTitle2["BELOW"] = "Below";
  MthTitle2["DATE"] = "Date";
  MthTitle2["ADDED_TO_HOMEROOM"] = "Added to Homeroon on or after";
  MthTitle2["STUDENT_STATUS"] = "Student Status";
  MthTitle2["USERNAME"] = "Username";
  MthTitle2["ORIGINALLY_SUBMITTED"] = "Originally Submitted";
  MthTitle2["STUDENT_DETAILS"] = "Student Details";
  MthTitle2["RESUBMIT"] = "Resubmit";
  MthTitle2["PENDING_APPROVAL"] = "Pending Approval";
  MthTitle2["PREFERRED_FIRST_NAME"] = "Preferred First Name";
  MthTitle2["PREFERRED_LAST_NAME"] = "Preferred Last Name";
  MthTitle2["ENROLLMENT_PACKET"] = "Enrollment Packet";
  MthTitle2["STUDENT_EMAIL"] = "Student Email";
  MthTitle2["EMAIL_IN_USE"] = "This email is already being used.";
  MthTitle2["DELETE_IMAGE"] = "Delete Image";
  MthTitle2["DELETE_CONFIRMATION"] = "Are you sure you want to delete this image";
  MthTitle2["STUDENT_ACCOUNT"] = "Student Account";
  MthTitle2["ACCOUNT_SUBTITLE"] = "An email will be sent to the student's email address. They will need to verify their account and create a password before they have access.";
  MthTitle2["INTENT_TO_RE_ENROLL"] = "Intent to Re-enroll";
  MthTitle2["INTENT_TO_RE_ENROLL_UPCOMING_SCHOOL_YEAR"] = "Intent to Re-enroll for Upcoming School Year";
  MthTitle2["NOTIFY_OF_WITHDRAW"] = "Notify of Withdraw";
  MthTitle2["SUBMIT_APPLICATION"] = "Submit Application";
  MthTitle2["ADD_ANOTHER_STUDENT"] = "Add Another Student";
  MthTitle2["INDICATE_REASON"] = "Please indicate the reason:";
  MthTitle2["NONE"] = "None";
  MthTitle2["SECONDARY_EMAIL"] = "Secondary Email";
  MthTitle2["DEFAULT_QUESTIONS"] = "Default Questions";
  MthTitle2["ITR_SUPPORT"] = "Please reach out to parent support if you wish to change your response from Yes to No";
  MthTitle2["DAYS_BEFORE_CLOSE_DATE"] = " (Days before Close Date)";
  MthTitle2["DAYS_BEFORE_DEADLINE"] = " (Days before deadline)";
  MthTitle2["UPDATE_BANNER_TEXT"] = "The version of the InfoCenter App you are using is currently out of date and needs to be updated.";
  MthTitle2["UPDATE_BANNER_BTN"] = "Update Now";
  MthTitle2["DELETE_ANNOUNCEMENT_POPUPS"] = "Delete Announcement Popups";
  MthTitle2["DELETE_ANNOUNCEMENT_POPUPS_CONTENT"] = "Are you sure you want to delete this Announcement Popup?";
  MthTitle2["CANCEL_CHANGES_ANNOUNCEMENT_POPUS_MESSAGE"] = "Are you sure you want to cancel changes?";
  MthTitle2["CONFIRM_PUBLISH_ANNOUNCEMENT_POPUPS"] = "Do you want to publish this announcement now?";
  MthTitle2["YES"] = "Yes";
  MthTitle2["NO"] = "No";
  MthTitle2["CANCEL_CHANGES"] = "Cancel Changes";
  MthTitle2["ADD_ANNOUNCEMENT_POPUP"] = "Add Announcement Popup";
  MthTitle2["EDIT_ANNOUNCEMENT_POPUP"] = "Edit Announcement Popup";
  MthTitle2["PUBLISH"] = "Publish";
  MthTitle2["SAVE"] = "Save";
  MthTitle2["SAVE_DRAFT_DISABLED"] = "You must submit the required updates to proceed.";
  MthTitle2["TRANSFER_STUDENT"] = "Transfer Student";
  MthTitle2["EDIT_STUDENT_RESPONSES"] = "Edit Student Responses";
  MthTitle2["WEEKLY_LL"] = "This Weekly Learning Log has been submitted.";
  MthTitle2["STUDENT_RESPONSES_SAVED"] = "Student responses are saved";
  MthTitle2["VIEW_ALL"] = "View All";
  MthTitle2["READ_MORE"] = "Read More";
  MthTitle2["RSVP"] = "RSVP";
  MthTitle2["EVENTS"] = "Events";
  MthTitle2["CONGRATULATIONS"] = "Congratulations!";
  MthTitle2["YOU_ARE_ALL_CAUGHT_UP"] = "You are all caught up.";
  MthTitle2["EMAIL_HISTORY"] = "Email History";
  MthTitle2["STUDENT_RECORD_FILE_REQUESTED"] = "Student Record File Requested";
  MthTitle2["FILE_REQUESTED_DESCRIPTION"] = "The file you requested will be sent to your email once is ready.";
  MthTitle2["FILE_REQUESTED_FAIL"] = "An error occurred while requesting file";
})(MthTitle || (MthTitle = {}));
