import {CourseType, MthTitle} from "../enums/index.js";
export const COURSE_TYPE_ITEMS = [
  {label: MthTitle.CUSTOM_BUILT, value: CourseType.CUSTOM_BUILT},
  {label: MthTitle.MY_TECH_HIGH_DIRECT, value: CourseType.MTH_DIRECT},
  {label: MthTitle.THIRD_PARTY_PROVIDER, value: CourseType.THIRD_PARTY_PROVIDER}
];
export const COURSE_TYPE_LABEL_MAP = {
  [CourseType.CUSTOM_BUILT]: MthTitle.CUSTOM_BUILT,
  [CourseType.MTH_DIRECT]: MthTitle.MY_TECH_HIGH_DIRECT,
  [CourseType.THIRD_PARTY_PROVIDER]: MthTitle.THIRD_PARTY_PROVIDER
};
