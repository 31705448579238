import {r as react} from "./index-c6c556cc.js";
import {r as reactDom} from "./index-510531f5.js";
import {u as useForkRef, s as setRef} from "./useForkRef-8d56fe3c.js";
import {u as useEnhancedEffect} from "./useEnhancedEffect-f0068896.js";
function getContainer(container) {
  return typeof container === "function" ? container() : container;
}
const Portal = /* @__PURE__ */ react.forwardRef(function Portal2(props, ref) {
  const {
    children,
    container,
    disablePortal = false
  } = props;
  const [mountNode, setMountNode] = react.useState(null);
  const handleRef = useForkRef(/* @__PURE__ */ react.isValidElement(children) ? children.ref : null, ref);
  useEnhancedEffect(() => {
    if (!disablePortal) {
      setMountNode(getContainer(container) || document.body);
    }
  }, [container, disablePortal]);
  useEnhancedEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode);
      return () => {
        setRef(ref, null);
      };
    }
    return void 0;
  }, [ref, mountNode, disablePortal]);
  if (disablePortal) {
    if (/* @__PURE__ */ react.isValidElement(children)) {
      return /* @__PURE__ */ react.cloneElement(children, {
        ref: handleRef
      });
    }
    return children;
  }
  return mountNode ? /* @__PURE__ */ reactDom.createPortal(children, mountNode) : mountNode;
});
var Portal$1 = Portal;
export {Portal$1 as P};
