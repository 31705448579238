import React from "../../../../_snowpack/pkg/react.js";
import {Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import DOMPurify from "../../../../_snowpack/pkg/dompurify.js";
import {EMPTY_STRING} from "../../../constants/index.js";
import {TagType} from "../../../enums/index.js";
export const DisplayHTML = ({
  text = EMPTY_STRING,
  dataTestId = EMPTY_STRING,
  tagType = TagType.DIV,
  allowIframe = false,
  removeMarginBlock = false
}) => {
  const sanitize = (text2, allowIframe2) => {
    if (allowIframe2)
      return DOMPurify.sanitize(text2, {
        ADD_TAGS: ["iframe"],
        ADD_ATTR: ["frameborder"]
      });
    return DOMPurify.sanitize(text2);
  };
  return /* @__PURE__ */ React.createElement(Typography, {
    component: tagType === TagType.PARAGRAPH ? "p" : "span",
    "data-testid": dataTestId,
    dangerouslySetInnerHTML: {
      __html: sanitize(text, allowIframe)
    },
    sx: removeMarginBlock ? {
      "> p": {
        marginBlockStart: 0
      }
    } : {}
  });
};
