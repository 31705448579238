import * as __SNOWPACK_ENV__ from "../../_snowpack/env.js";
import {MthColor} from "../../core/enums/index.js";
const IS_STAGING = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_STAGE === "staging";
const IS_DEMO = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_STAGE === "demo";
const sideMenuOpenSize = 260;
const sideMenuCloseSize = 66;
export const transtitionDuration = 0.2;
export const transitionsText = ` ${transtitionDuration}s ease-in-out`;
export const styles = {
  container: {
    position: "fixed",
    width: 260,
    transition: `width ${transitionsText}`,
    backgroundColor: IS_STAGING ? MthColor.LIGHTRED : IS_DEMO ? MthColor.LIGHTBLUE : MthColor.WHITE,
    height: "100vh",
    boxShadow: "0px 0px 36px rgba(0, 0, 0, 0.04)"
  },
  myTechHigh: {
    height: 75
  },
  logos: {
    marginRight: 25
  },
  sideMenuText: {
    color: MthColor.GRAY
  },
  navLink: {
    textDecoration: "none",
    color: MthColor.BLACK,
    display: "flex"
  },
  activeNavLink: {
    backgroundColor: MthColor.ACTIVE_LINK_BG,
    color: MthColor.MTHBLUE,
    borderLeft: "3px solid"
  },
  navbar: {
    height: "100%",
    width: "100%"
  },
  logoTitle: {
    marginTop: "25px"
  },
  mobileContainer: {
    position: "fixed",
    width: "100%",
    bgcolor: "#fff",
    height: "100vh"
  },
  mobileNavText: {
    lineHeight: "2",
    fontSize: "32px",
    fontWeight: "700",
    color: MthColor.SYSTEM_01
  },
  sideMenu: {
    sideMenuOpen: {
      size: sideMenuOpenSize,
      sizePx: `${sideMenuOpenSize}px`
    },
    sideMenuClose: {
      size: sideMenuCloseSize,
      sizePx: `${sideMenuCloseSize}px`
    },
    positionTop: "70px"
  },
  chevronStyle: {
    display: "flex",
    color: MthColor.BLACK,
    background: MthColor.WHITE,
    cursor: "pointer",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "#709EC2"
  },
  chevronContaineer: {
    position: "fixed",
    top: "70px",
    left: `${sideMenuOpenSize}px`,
    transition: `left ${transitionsText}`,
    zIndex: 7e5
  }
};
