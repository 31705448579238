import {gql} from "../../../../_snowpack/pkg/@apollo/client.js";
export const updateProfile = gql`
  mutation UpdateStudentProfile($updateStudentProfileInput: UpdateStudentProfileInput!) {
    updateStudentProfile(updateStudentProfileInput: $updateStudentProfileInput) {
      student_id
      person {
        preferred_first_name
        preferred_last_name
        email
        photo
      }
    }
  }
`;
export const removeProfilePhoto = gql`
  mutation RemoveStudentProfilePhoto($updateStudentProfileInput: UpdateStudentProfileInput!) {
    removeStudentProfilePhoto(updateStudentProfileInput: $updateStudentProfileInput) {
      student_id
      person {
        preferred_first_name
        preferred_last_name
        email
        photo
      }
    }
  }
`;
export const checkUserExistByEmail = gql`
  query User($email: String!) {
    checkUserExistByEmail(email: $email)
  }
`;
export const checkEmailInUse = gql`
  query checkEmailOnCoreUsersAndMTHperson($email: String!) {
    studentEmailTaken(email: $email)
    checkUserExistByEmail(email: $email)
  }
`;
