import {r as requiredArgs, c as toDate, b as addDays, t as toInteger} from "./common/index-bba0a9d7.js";
export {b as addDays, i as addYears, d as compareAsc, k as differenceInYears, e as endOfDay, o as format, u as getDay, l as isAfter, m as isBefore, v as isDate, q as isEqual, j as isValid, p as parse, s as startOfDay, n as startOfWeek} from "./common/index-bba0a9d7.js";
import "./common/index-ad5e4003.js";
function compareDesc(dirtyDateLeft, dirtyDateRight) {
  requiredArgs(2, arguments);
  var dateLeft = toDate(dirtyDateLeft);
  var dateRight = toDate(dirtyDateRight);
  var diff = dateLeft.getTime() - dateRight.getTime();
  if (diff > 0) {
    return -1;
  } else if (diff < 0) {
    return 1;
  } else {
    return diff;
  }
}
function subDays(dirtyDate, dirtyAmount) {
  requiredArgs(2, arguments);
  var amount = toInteger(dirtyAmount);
  return addDays(dirtyDate, -amount);
}
export {compareDesc, subDays};
