export var StudentNotification;
(function(StudentNotification2) {
  StudentNotification2["SUBMIT_SCHEDULE"] = "Submit Schedule";
  StudentNotification2["SUBMIT_SECOND_SEMESTER_SCHEDULE"] = "Submit 2nd Semester Schedule";
  StudentNotification2["RESUBMIT_SCHEDULE"] = "Resubmit Schedule";
  StudentNotification2["RESUBMIT_SECOND_SEMESTER_SCHEDULE"] = "Resubmit Schedule";
  StudentNotification2["SUBMIT_ENROLLMENT_PACKET"] = "Submit Enrollment Packet";
  StudentNotification2["RESUBMIT_ENROLLMENT_PACKET"] = "Resubmit Enrollment Packet";
  StudentNotification2["SUBMIT_REIMBURSEMENT"] = "Submit Reimbursement";
  StudentNotification2["RESUBMIT_REIMBURSEMENT"] = "Resubmit Reimbursement";
  StudentNotification2["SUBMIT_DIRECT_ORDER"] = "Submit Direct Order";
  StudentNotification2["RESUBMIT_DIRECT_ORDER"] = "Resubmit Direct Order";
  StudentNotification2["TESTING_PREFERNCE"] = "Testing Prefernce";
  StudentNotification2["MISSING_LEARNING_LOG"] = "Missing Learning Log";
  StudentNotification2["RESUBMIT_LEARNING_LOG"] = "Resubmit Learning Log";
  StudentNotification2["INTENT_TO_RE_ENROLL"] = "Intent to Re-enroll";
  StudentNotification2["SUBMIT_INTENT_TO_RE_ENROLL"] = "Submit Intent to Re-enroll";
  StudentNotification2["REQUEST_HOMEROOM_RESOURCES"] = "Request Homeroom Resources";
  StudentNotification2["SUBMIT_WITHDRAW"] = "Submit Withdraw";
  StudentNotification2["APPLICATION_PENDING_APPROVAL"] = "Application Pending Approval";
  StudentNotification2["APPLICATION_PENDING"] = "Application Pending";
  StudentNotification2["PLEASE_SUBMIT_ENROLLMENT_PACKET"] = "Please Submit Enrollment Packet";
  StudentNotification2["PLEASE_RESUBMIT_ENROLLMENT_PACKET"] = "Please Resubmit Enrollment Packet";
  StudentNotification2["ENROLLMENT_PACKET_PENDING_APPROVAL"] = "Enrollment Packet Pending Approval";
  StudentNotification2["ENROLLMENT_PENDING_APPROVAL"] = "Enrollment Pending Approval";
  StudentNotification2["SECOND_SEMESTER_SCHEDULE_PENDING_APPROVAL"] = "2nd Semester Schedule Pending Approval";
  StudentNotification2["HOMEROOM_ASSIGNMENT_IN_PROGRESS"] = "Homeroom Assignment in Progress";
  StudentNotification2["SCHEDULE_PENDING_APPROVAL"] = "Schedule Pending Approval";
  StudentNotification2["WAITING_FOR_SCHEDULE_BUILDER_TO_OPEN"] = "Waiting for Schedule Builder to Open";
  StudentNotification2["PLEASE_SUBMIT_SCHEDULE"] = "Please Submit a Schedule";
  StudentNotification2["PLEASE_RESUBMIT_SCHEDULE"] = "Please Resubmit a Schedule";
  StudentNotification2["SUBMIT_SCHEDULE_BUILDER"] = "Please Submit Schedule Builder";
  StudentNotification2["RESUBMIT_NEEDED"] = "Resubmit Needed";
  StudentNotification2["SUBMIT_NOW"] = "Submit Now";
  StudentNotification2["RESUBMIT_NOW"] = "Resubmit Now";
  StudentNotification2["RE_APPLY"] = "Re-apply";
})(StudentNotification || (StudentNotification = {}));
