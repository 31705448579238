import {useState, useEffect} from "../../../_snowpack/pkg/react.js";
export const useIsLandscape = () => {
  const [isLandscape, setIsLandscape] = useState(false);
  const handleOrientationChange = (event) => {
    setIsLandscape(event.matches);
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: landscape)");
    setIsLandscape(mediaQuery.matches);
    const handleChange = (event) => handleOrientationChange(event);
    mediaQuery.addEventListener("change", handleChange);
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);
  return isLandscape;
};
