import {useContext, useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {isNil, orderBy} from "../../../_snowpack/pkg/lodash.js";
import {parseJsonStringArray} from "../../../components/AvatarGroup/AvatarGroup.js";
import {EMPTY_STRING, ActiveStudentStatuses, VariationsOfKinder, UNASSIGNED} from "../../constants/index.js";
import {AnnouncementFilterProgramYearType, datePatterns, GRADE_LEVEL_LABEL} from "../../enums/index.js";
import {UserContext} from "../../../providers/UserContext/UserProvider.js";
import {getEventsQuery} from "../../../screens/Admin/Calendar/services.js";
import {endOfDay, formatDateByPattern, getTimezone, hexToRgbA, startOfDay, utcToTimezoned} from "../../utils/index.js";
export const useEventsByRegionIdAndFilterItem = ({
  regionId = 0,
  searchField = EMPTY_STRING,
  userId = 0,
  type
}) => {
  const {me} = useContext(UserContext);
  const students = me?.students ?? [];
  const timezone = getTimezone(me);
  const [calendarEventList, setCalendarEventList] = useState([]);
  const [events, setEvents] = useState([]);
  const isApplicate = (gradesFilter, programYearFilter, schoolFilter) => {
    let result = false;
    const grades = parseJsonStringArray(gradesFilter);
    const programYears = parseJsonStringArray(programYearFilter);
    const schools = parseJsonStringArray(schoolFilter);
    students?.forEach((student) => {
      if (student?.grade_levels?.length) {
        const studentGradeIsVariationOfKinder = VariationsOfKinder.includes(String(student?.current_school_year_status?.grade_level));
        const currentStudentStatus = student?.status.find((status) => status.school_year_id === student?.current_school_year_status?.school_year_id);
        const currentApplicationStatusRecord = student?.applications?.at(-1);
        const includeMidyear = Boolean(programYears.includes(AnnouncementFilterProgramYearType.MID_YEAR));
        const includeFullyear = Boolean(programYears.includes(AnnouncementFilterProgramYearType.SCHOOL_YEAR));
        const midYearAndMatch = includeMidyear && Boolean(currentApplicationStatusRecord?.midyear_application);
        const fullYearAndMatch = includeFullyear && !Boolean(currentApplicationStatusRecord?.midyear_application);
        const midyearOrFullyearMatch = midYearAndMatch || fullYearAndMatch;
        const programYearMatch = programYears.length > 0 ? midyearOrFullyearMatch : true;
        const studentStatusIsValid = !isNil(currentStudentStatus?.status) ? ActiveStudentStatuses.includes(Number(currentStudentStatus?.status)) : false;
        const studentGradeLevel = studentGradeIsVariationOfKinder ? GRADE_LEVEL_LABEL.KINDERGARTEN : student?.current_school_year_status?.grade_level;
        const gradeValid = studentGradeLevel && grades.includes(studentGradeLevel);
        const unassignedInFilter = schools.includes(UNASSIGNED);
        const validatedSoe = student?.currentSoe ? student?.currentSoe.filter((soe) => Number(soe.school_year_id) === Number(student.current_school_year_status.school_year_id)) : [];
        const unassignedMatch = validatedSoe.length === 0 && unassignedInFilter;
        const numericSchoolIds = validatedSoe.map((soe) => Number(soe.school_partner_id));
        const schoolMatch = schools.length > 0 ? schools.some((school_partner_id) => numericSchoolIds.includes(Number(school_partner_id))) : true;
        const schoolOrUnassignedMatch = schoolMatch || unassignedMatch;
        if (studentStatusIsValid && gradeValid && programYearMatch && schoolOrUnassignedMatch) {
          result = true;
        }
      }
    });
    return result;
  };
  const {loading, data, error, refetch} = useQuery(getEventsQuery, {
    variables: {
      findEventsByRegionIdSearch: {
        user_id: Number(userId),
        region_id: Number(regionId),
        search_field: searchField,
        type
      }
    },
    skip: regionId ? false : true,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data?.eventsByRegionId) {
      let eventLists = userId > 0 ? data?.eventsByRegionId.filter((item) => {
        const startEndValid = item.start_date && item.end_date;
        return isApplicate(item.filter_grades, item.filter_program_year, item.filter_school_of_enrollment) && startEndValid;
      }) : data?.eventsByRegionId.filter((item) => item.start_date && item.end_date);
      eventLists.forEach((item) => {
        item.start_date = utcToTimezoned(item.start_date, timezone);
        item.end_date = utcToTimezoned(item.end_date, timezone);
        item.startDateOnly = startOfDay(item.start_date);
        item.endDateOnly = startOfDay(item.end_date);
      });
      eventLists = orderBy(eventLists, ["startDateOnly", "endDateOnly", "EventType.priority", "start_date"], ["asc", "desc", "asc", "asc"]);
      setCalendarEventList(eventLists.map((event) => ({
        id: event.event_id,
        title: event.EventType.name || EMPTY_STRING,
        start: startOfDay(event.start_date),
        end: endOfDay(event.end_date),
        color: event.EventType.color || EMPTY_STRING,
        backgroundColor: hexToRgbA(event.EventType.color || EMPTY_STRING),
        allDay: true,
        hasRSVP: event.has_rsvp
      })));
      setEvents(eventLists.map((event) => ({
        eventId: event.event_id,
        title: event.title,
        eventTypeId: event.TypeId,
        eventTypeColor: event.EventType.color || EMPTY_STRING,
        eventTypeName: event.EventType.name || EMPTY_STRING,
        startDate: event.start_date,
        endDate: event.end_date,
        time: formatDateByPattern(new Date(event.start_date), datePatterns.HOUR_MINUTE),
        allDay: event.all_day,
        description: event.description,
        filters: {
          grades: event.filter_grades,
          other: event.filter_other,
          programYear: event.filter_program_year,
          provider: event.filter_provider,
          schoolOfEnrollment: event.filter_school_of_enrollment,
          users: event.filter_users
        },
        hasRSVP: event.has_rsvp
      })));
    } else {
      setCalendarEventList([]);
      setEvents([]);
    }
  }, [loading, data]);
  return {
    loading,
    calendarEventList,
    events,
    error,
    refetch
  };
};
