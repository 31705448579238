import React, {useCallback, useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import SearchIcon from "../../../../_snowpack/pkg/@mui/icons-material/Search.js";
import {InputAdornment, OutlinedInput} from "../../../../_snowpack/pkg/@mui/material.js";
import {debounce} from "../../../../_snowpack/pkg/lodash.js";
import {MthColor, MthTitle} from "../../../enums/index.js";
export const Search = ({setSearchField, sx, placeholder, waitTime = 300}) => {
  const [localSearchField, setLocalSearchField] = useState("");
  const changeHandler = (event = "") => setSearchField(event);
  const debouncedChangeHandler = useCallback(debounce(changeHandler, waitTime), []);
  useEffect(() => {
    debouncedChangeHandler(localSearchField);
  }, [localSearchField]);
  return /* @__PURE__ */ React.createElement(OutlinedInput, {
    "data-testid": "search",
    sx,
    onFocus: (e) => e.target.placeholder = "",
    onBlur: (e) => e.target.placeholder = placeholder ?? `${MthTitle.SEARCH}...`,
    size: "small",
    fullWidth: true,
    value: localSearchField,
    placeholder: placeholder ?? `${MthTitle.SEARCH}...`,
    onChange: (e) => setLocalSearchField(e.target.value),
    startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, {
      position: "start"
    }, /* @__PURE__ */ React.createElement(SearchIcon, {
      style: {color: MthColor.BLACK, height: "20px", width: "20px"}
    }))
  });
};
