import {ReimbursementRequestStatus} from "../../enums/reimbursement-request-status.enum.js";
import {ReimbursementType} from "../../enums/reimbursement-request-type.js";
function getReimbursementDatePaid(reimbursementRequest) {
  const reimbursementType = reimbursementRequest.reimbursement_type;
  const reimbursementStatus = reimbursementRequest.status;
  const datePaid = reimbursementRequest.date_paid;
  const dateOrdered = reimbursementRequest.date_ordered;
  if (reimbursementType === ReimbursementType.REIMBURSEMENT && reimbursementStatus === ReimbursementRequestStatus.PAID) {
    return datePaid;
  }
  if ((reimbursementType === ReimbursementType.DIRECT_ORDER || reimbursementType === ReimbursementType.DIRECT_DEDUCTION) && reimbursementStatus === ReimbursementRequestStatus.ORDERED) {
    return dateOrdered;
  }
  return null;
}
export default getReimbursementDatePaid;
