export var IndividualLearningLogsReportColumns;
(function(IndividualLearningLogsReportColumns2) {
  IndividualLearningLogsReportColumns2["STUDENT_FULL_NAME"] = "studentFullName";
  IndividualLearningLogsReportColumns2["LEVEL"] = "level";
  IndividualLearningLogsReportColumns2["PARENT_FULL_NAME"] = "parentFullName";
  IndividualLearningLogsReportColumns2["GRADE"] = "grade";
  IndividualLearningLogsReportColumns2["STATUS"] = "status";
})(IndividualLearningLogsReportColumns || (IndividualLearningLogsReportColumns = {}));
export var IndividualLearningLogsReportLabels;
(function(IndividualLearningLogsReportLabels2) {
  IndividualLearningLogsReportLabels2["STUDENT"] = "Student";
  IndividualLearningLogsReportLabels2["LEVEL"] = "Level";
  IndividualLearningLogsReportLabels2["PARENT"] = "Parent";
  IndividualLearningLogsReportLabels2["GRADE"] = "Grade";
  IndividualLearningLogsReportLabels2["STATUS"] = "Status";
})(IndividualLearningLogsReportLabels || (IndividualLearningLogsReportLabels = {}));
