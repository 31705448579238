import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {Pagination as MUIPagination} from "../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../_snowpack/pkg/@mui/system.js";
import {MthTitle} from "../../core/enums/index.js";
import {DropDown} from "../../core/components/shared/DropDown/DropDown.js";
import {Subtitle} from "../../core/components/shared/Subtitle/Subtitle.js";
import {useStyles} from "./styles.js";
export const Pagination = ({
  testId,
  setParentLimit,
  handlePageChange,
  defaultValue,
  numPages,
  currentPage,
  dropdownOptions = [
    {
      label: "25",
      value: 25
    },
    {
      label: "50",
      value: 50
    },
    {
      label: "100",
      value: 100
    },
    {
      label: "All",
      value: 1e3
    }
  ],
  fontWeight
}) => {
  const classes = useStyles;
  const [limit, setLimit] = useState(0);
  useEffect(() => {
    if (limit && setParentLimit)
      setParentLimit(limit);
  }, [limit]);
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    size: "medium",
    sx: {marginRight: "12px", fontWeight: fontWeight || "initial"}
  }, MthTitle.SHOW), /* @__PURE__ */ React.createElement(DropDown, {
    dropDownItems: dropdownOptions,
    setParentValue: (value) => {
      setLimit(Number(value));
    },
    alternate: true,
    size: "small",
    defaultValue: defaultValue || dropdownOptions[0].value,
    sx: {fontWeight: fontWeight || "initial"}
  })), /* @__PURE__ */ React.createElement(MUIPagination, {
    "data-testid": testId,
    count: numPages,
    size: "small",
    sx: classes.pageNumber,
    onChange: (_e, pageNum) => handlePageChange && handlePageChange(pageNum),
    page: currentPage,
    disabled: numPages < 2
  }));
};
