import React from "../../_snowpack/pkg/react.js";
import TodayIcon from "../../_snowpack/pkg/@mui/icons-material/Today.js";
import {IconButton, Stack, TextField} from "../../_snowpack/pkg/@mui/material.js";
import {AdapterDateFns} from "../../_snowpack/pkg/@mui/x-date-pickers/AdapterDateFns.js";
import {LocalizationProvider} from "../../_snowpack/pkg/@mui/x-date-pickers/LocalizationProvider.js";
import {MobileDatePicker} from "../../_snowpack/pkg/@mui/x-date-pickers/MobileDatePicker.js";
import dayjs from "../../_snowpack/pkg/dayjs.js";
import utc from "../../_snowpack/pkg/dayjs/plugin/utc.js";
import {isNil} from "../../_snowpack/pkg/lodash.js";
import {MYSQL_DATE_FORMAT} from "../../core/constants/index.js";
import {endOfDay, startOfDay, toUTCString, utcToTimezoned} from "../../core/utils/index.js";
dayjs.extend(utc);
export const MthDatePicker = ({
  date,
  label,
  dateFormat = MYSQL_DATE_FORMAT,
  minDate,
  maxDate,
  showCalendarIcon,
  disabled = false,
  timezone = null,
  dayEnd = false,
  handleChange
}) => {
  const convert2MidNight = (val) => {
    if (!isNil(timezone))
      return utcToTimezoned(val, timezone);
    const tempDate = typeof val === "string" ? new Date(val) : val;
    return dayjs(tempDate).add(tempDate.getTimezoneOffset() + 60, "minutes").toDate();
  };
  const convert2LocalDate = (val) => {
    if (!isNil(timezone))
      return dayEnd ? toUTCString(endOfDay(val), timezone) : toUTCString(startOfDay(val), timezone);
    return dayjs(val).subtract(val.getTimezoneOffset() - 60, "minutes").utc().format(dateFormat);
  };
  return /* @__PURE__ */ React.createElement(LocalizationProvider, {
    dateAdapter: AdapterDateFns
  }, /* @__PURE__ */ React.createElement(Stack, {
    spacing: 3,
    marginRight: 8
  }, /* @__PURE__ */ React.createElement(MobileDatePicker, {
    disabled,
    label: label || "Date",
    inputFormat: "MM/dd/yyyy",
    value: date && convert2MidNight(date),
    minDate: minDate && convert2MidNight(minDate),
    maxDate: maxDate && convert2MidNight(maxDate),
    onChange: (val) => {
      handleChange(val ? convert2LocalDate(val) : null);
    },
    renderInput: (params) => !showCalendarIcon ? /* @__PURE__ */ React.createElement(TextField, {
      ...params
    }) : /* @__PURE__ */ React.createElement(TextField, {
      ...params,
      variant: "outlined",
      margin: "normal",
      fullWidth: true,
      helperText: "",
      InputProps: {
        endAdornment: /* @__PURE__ */ React.createElement(IconButton, {
          size: "small"
        }, /* @__PURE__ */ React.createElement(TodayIcon, null))
      }
    })
  })));
};
