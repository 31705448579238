import dayjs from "../../../../_snowpack/pkg/dayjs.js";
import duration from "../../../../_snowpack/pkg/dayjs/plugin/duration.js";
dayjs.extend(duration);
export function isValidVaccInput(val, allowIM = true) {
  if (!val)
    return false;
  if (["EXEMPT", "NA"].includes(val.toUpperCase())) {
    return true;
  } else if (val.toUpperCase() === "IM" && allowIM) {
    return true;
  }
  return isValidDate(val);
}
export function isValidDate(date) {
  return dayjs(date, "MM/DD/YYYY", true).isValid();
}
export function getValidGrade(v) {
  if (!v)
    return -1;
  const g = +v;
  if (isNaN(g)) {
    return v.toLocaleLowerCase().startsWith("k") ? 0 : -1;
  }
  return g;
}
export function getDuration(interval, date) {
  if (!+interval || !+date)
    return null;
  return dayjs.duration(interval, date === 1 ? "days" : date === 2 ? "weeks" : "months");
}
export function checkImmmValueWithSpacing(item, all) {
  if (!item?.value)
    return true;
  if (!item.immunization?.consecutive_vaccine)
    return true;
  const itemDate = dayjs(item.value, "MM/DD/YYYY");
  if (!itemDate.isValid())
    return true;
  const conDate = dayjs(all.find((v) => v.immunization_id === item?.immunization?.consecutive_vaccine + "")?.value || "", "MM/DD/YYYY");
  if (!conDate.isValid())
    return true;
  const minDur = getDuration(item?.immunization?.min_spacing_interval || 0, item?.immunization?.min_spacing_date || 0);
  const maxDur = getDuration(item?.immunization?.max_spacing_interval || 0, item?.immunization?.max_spacing_date || 0);
  if (!minDur || !maxDur)
    return true;
  const dur = dayjs.duration(itemDate.diff(conDate));
  return dur.asDays() >= minDur.asDays() && dur.asDays() <= maxDur.asDays();
}
