export const GRADES = ["Pre-Kindergarten", "Kindergarten", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const GRADES_FULL = ["Pre-Kindergarten", "Kindergarten", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const GRADE_GROUPS = ["K", "1-8", "9-12"];
export const KINDERGARTEN_LABEL = "Kindergarten";
export const PRE_KINDERGARTEN_LABEL = "Pre-Kindergarten";
export const KINDERGARTEN_NUM_VALUE = -1;
export const PRE_KINDERGARTEN_NUM_VALUE = -2;
export const PRE_KINDER_ABBREVIATION = "Pre-K";
export const KINDER_ABBREVIATION = "K";
export const SELECT_GRADE = "-- Select Grade --";
export const GRADE_GROUPS_FULL = [PRE_KINDER_ABBREVIATION, KINDER_ABBREVIATION, "1-8", "9-12"];
export const PRE_KINDER_AGE = 4;
