import {c as createCommonjsModule, g as getDefaultExportFromCjs} from "./common/_commonjsHelpers-53bde9c7.js";
import {i as immutable_es} from "./common/immutable.es-8799c46e.js";
import {D as Draft} from "./common/Draft-f23abb15.js";
import "./common/index-c6c556cc.js";
import "./common/process-2545f00a.js";
import "./common/index-510531f5.js";
var htmlToDraftjs = createCommonjsModule(function(module, exports) {
  !function(e, t) {
    module.exports = t(immutable_es, Draft);
  }(window, function(n, r) {
    return o = {}, i.m = a = [function(e, t) {
      e.exports = n;
    }, function(e, t) {
      e.exports = r;
    }, function(e, t, n2) {
      e.exports = n2(3);
    }, function(e, t, n2) {
      n2.r(t);
      var v = n2(1), u = n2(0), s = function(e2) {
        var t2, n3 = null;
        return document.implementation && document.implementation.createHTMLDocument && ((t2 = document.implementation.createHTMLDocument("foo")).documentElement.innerHTML = e2, n3 = t2.getElementsByTagName("body")[0]), n3;
      }, x = function(e2, t2, n3) {
        var r3, i2 = e2.textContent;
        return i2.trim() === "" ? {chunk: (r3 = n3, {text: " ", inlines: [new u.OrderedSet()], entities: [r3], blocks: []})} : {chunk: {text: i2, inlines: Array(i2.length).fill(t2), entities: Array(i2.length).fill(n3), blocks: []}};
      }, M = function() {
        return {text: "\n", inlines: [new u.OrderedSet()], entities: new Array(1), blocks: []};
      }, k = function() {
        return {text: "", inlines: [], entities: [], blocks: []};
      }, E = function(e2, t2) {
        return {text: "", inlines: [], entities: [], blocks: [{type: e2, depth: 0, data: t2 || new u.Map({})}]};
      }, w = function(e2, t2, n3) {
        return {text: "\r", inlines: [], entities: [], blocks: [{type: e2, depth: Math.max(0, Math.min(4, t2)), data: n3 || new u.Map({})}]};
      }, T = function(e2) {
        return {text: "\r ", inlines: [new u.OrderedSet()], entities: [e2], blocks: [{type: "atomic", depth: 0, data: new u.Map({})}]};
      }, L = function(e2, t2) {
        return {text: e2.text + t2.text, inlines: e2.inlines.concat(t2.inlines), entities: e2.entities.concat(t2.entities), blocks: e2.blocks.concat(t2.blocks)};
      }, A = new u.Map({"header-one": {element: "h1"}, "header-two": {element: "h2"}, "header-three": {element: "h3"}, "header-four": {element: "h4"}, "header-five": {element: "h5"}, "header-six": {element: "h6"}, "unordered-list-item": {element: "li", wrapper: "ul"}, "ordered-list-item": {element: "li", wrapper: "ol"}, blockquote: {element: "blockquote"}, code: {element: "pre"}, atomic: {element: "figure"}, unstyled: {element: "p", aliasedElements: ["div"]}});
      var O = {code: "CODE", del: "STRIKETHROUGH", em: "ITALIC", strong: "BOLD", ins: "UNDERLINE", sub: "SUBSCRIPT", sup: "SUPERSCRIPT"};
      function S(e2) {
        return e2.style.textAlign ? new u.Map({"text-align": e2.style.textAlign}) : e2.style.marginLeft ? new u.Map({"margin-left": e2.style.marginLeft}) : void 0;
      }
      var _ = function(e2) {
        var t2 = void 0;
        if (e2 instanceof HTMLAnchorElement) {
          var n3 = {};
          t2 = e2.dataset && e2.dataset.mention !== void 0 ? (n3.url = e2.href, n3.text = e2.innerHTML, n3.value = e2.dataset.value, v.Entity.__create("MENTION", "IMMUTABLE", n3)) : (n3.url = e2.getAttribute && e2.getAttribute("href") || e2.href, n3.title = e2.innerHTML, n3.targetOption = e2.target, v.Entity.__create("LINK", "MUTABLE", n3));
        }
        return t2;
      };
      n2.d(t, "default", function() {
        return r2;
      });
      var d = " ", f = new RegExp("&nbsp;", "g"), j = true;
      function I(e2, t2, n3, r3, i2, a2) {
        var o2 = e2.nodeName.toLowerCase();
        if (a2) {
          var l = a2(o2, e2);
          if (l) {
            var c = v.Entity.__create(l.type, l.mutability, l.data || {});
            return {chunk: T(c)};
          }
        }
        if (o2 === "#text" && e2.textContent !== "\n")
          return x(e2, t2, i2);
        if (o2 === "br")
          return {chunk: M()};
        if (o2 === "img" && e2 instanceof HTMLImageElement) {
          var u2 = {};
          u2.src = e2.getAttribute && e2.getAttribute("src") || e2.src, u2.alt = e2.alt, u2.height = e2.style.height, u2.width = e2.style.width, e2.style.float && (u2.alignment = e2.style.float);
          var s2 = v.Entity.__create("IMAGE", "MUTABLE", u2);
          return {chunk: T(s2)};
        }
        if (o2 === "video" && e2 instanceof HTMLVideoElement) {
          var d2 = {};
          d2.src = e2.getAttribute && e2.getAttribute("src") || e2.src, d2.alt = e2.alt, d2.height = e2.style.height, d2.width = e2.style.width, e2.style.float && (d2.alignment = e2.style.float);
          var f2 = v.Entity.__create("VIDEO", "MUTABLE", d2);
          return {chunk: T(f2)};
        }
        if (o2 === "iframe" && e2 instanceof HTMLIFrameElement) {
          var m = {};
          m.src = e2.getAttribute && e2.getAttribute("src") || e2.src, m.height = e2.height, m.width = e2.width;
          var p = v.Entity.__create("EMBEDDED_LINK", "MUTABLE", m);
          return {chunk: T(p)};
        }
        var h, y = function(t3, n4) {
          var e3 = A.filter(function(e4) {
            return e4.element === t3 && (!e4.wrapper || e4.wrapper === n4) || e4.wrapper === t3 || e4.aliasedElements && -1 < e4.aliasedElements.indexOf(t3);
          }).keySeq().toSet().toArray();
          if (e3.length === 1)
            return e3[0];
        }(o2, r3);
        y && (o2 === "ul" || o2 === "ol" ? (r3 = o2, n3 += 1) : (y !== "unordered-list-item" && y !== "ordered-list-item" && (r3 = "", n3 = -1), j ? (h = E(y, S(e2)), j = false) : h = w(y, n3, S(e2)))), h = h || k(), t2 = function(e3, t3, n4) {
          var r4, i3 = O[e3];
          if (i3)
            r4 = n4.add(i3).toOrderedSet();
          else if (t3 instanceof HTMLElement) {
            var c2 = t3;
            r4 = (r4 = n4).withMutations(function(e4) {
              var t4 = c2.style.color, n5 = c2.style.backgroundColor, r5 = c2.style.fontSize, i4 = c2.style.fontFamily.replace(/^"|"$/g, ""), a3 = c2.style.fontWeight, o3 = c2.style.textDecoration, l2 = c2.style.fontStyle;
              t4 && e4.add("color-".concat(t4.replace(/ /g, ""))), n5 && e4.add("bgcolor-".concat(n5.replace(/ /g, ""))), r5 && e4.add("fontsize-".concat(r5.replace(/px$/g, ""))), i4 && e4.add("fontfamily-".concat(i4)), a3 === "bold" && e4.add(O.strong), o3 === "underline" && e4.add(O.ins), l2 === "italic" && e4.add(O.em);
            }).toOrderedSet();
          }
          return r4;
        }(o2, e2, t2);
        for (var b = e2.firstChild; b; ) {
          var g = I(b, t2, n3, r3, _(b) || i2, a2).chunk;
          h = L(h, g), b = b.nextSibling;
        }
        return {chunk: h};
      }
      function r2(e2, t2) {
        var n3, r3, i2, a2 = (n3 = t2, r3 = e2.trim().replace(f, d), (i2 = s(r3)) ? (j = true, {chunk: I(i2, new u.OrderedSet(), -1, "", void 0, n3).chunk}) : null);
        if (a2) {
          var o2 = a2.chunk, l = new u.OrderedMap({});
          o2.entities && o2.entities.forEach(function(e3) {
            e3 && (l = l.set(e3, v.Entity.__get(e3)));
          });
          var c = 0;
          return {contentBlocks: o2.text.split("\r").map(function(e3, t3) {
            var n4 = c + e3.length, r4 = o2 && o2.inlines.slice(c, n4), i3 = o2 && o2.entities.slice(c, n4), a3 = new u.List(r4.map(function(e4, t4) {
              var n5 = {style: e4, entity: null};
              return i3[t4] && (n5.entity = i3[t4]), v.CharacterMetadata.create(n5);
            }));
            return c = n4, new v.ContentBlock({key: Object(v.genKey)(), type: o2 && o2.blocks[t3] && o2.blocks[t3].type || "unstyled", depth: o2 && o2.blocks[t3] && o2.blocks[t3].depth, data: o2 && o2.blocks[t3] && o2.blocks[t3].data || new u.Map({}), text: e3, characterList: a3});
          }), entityMap: l};
        }
        return null;
      }
    }], i.c = o, i.d = function(e, t, n2) {
      i.o(e, t) || Object.defineProperty(e, t, {enumerable: true, get: n2});
    }, i.r = function(e) {
      typeof Symbol != "undefined" && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {value: "Module"}), Object.defineProperty(e, "__esModule", {value: true});
    }, i.t = function(t, e) {
      if (1 & e && (t = i(t)), 8 & e)
        return t;
      if (4 & e && typeof t == "object" && t && t.__esModule)
        return t;
      var n2 = Object.create(null);
      if (i.r(n2), Object.defineProperty(n2, "default", {enumerable: true, value: t}), 2 & e && typeof t != "string")
        for (var r2 in t)
          i.d(n2, r2, function(e2) {
            return t[e2];
          }.bind(null, r2));
      return n2;
    }, i.n = function(e) {
      var t = e && e.__esModule ? function() {
        return e.default;
      } : function() {
        return e;
      };
      return i.d(t, "a", t), t;
    }, i.o = function(e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, i.p = "", i(i.s = 2);
    function i(e) {
      if (o[e])
        return o[e].exports;
      var t = o[e] = {i: e, l: false, exports: {}};
      return a[e].call(t.exports, t, t.exports, i), t.l = true, t.exports;
    }
    var a, o;
  });
});
var __pika_web_default_export_for_treeshaking__ = /* @__PURE__ */ getDefaultExportFromCjs(htmlToDraftjs);
export {__pika_web_default_export_for_treeshaking__ as default};
