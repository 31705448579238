import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getAssessmentsBySchoolYearId} from "../../../graphql/queries/assessment.js";
export const useAssessmentsBySchoolYearId = (school_year_id) => {
  const [assessmentItems, setAssessmentItems] = useState([]);
  const {data, loading, refetch, error} = useQuery(getAssessmentsBySchoolYearId, {
    variables: {
      schoolYearId: school_year_id
    },
    skip: !school_year_id,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data?.getAssessmentsBySchoolYearId) {
      const items = data?.getAssessmentsBySchoolYearId;
      setAssessmentItems(items.map((item) => ({...item, assessment_id: Number(item.assessment_id)})));
    } else {
      setAssessmentItems([]);
    }
  }, [data, loading]);
  return {
    loading,
    assessments: assessmentItems,
    schoolYear: data?.getAssessmentsBySchoolYearId?.at(-1)?.SchoolYear,
    error,
    refetch
  };
};
