import {useState, useContext, useMemo, useEffect} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {gql} from "../../../_snowpack/pkg/@apollo/client.js";
import dayjs from "../../../_snowpack/pkg/dayjs.js";
import {isEmpty, sortBy} from "../../../_snowpack/pkg/lodash.js";
import {UserContext} from "../../../providers/UserContext/UserProvider.js";
export const getSchoolYearsQuery = gql`
  query GetSchoolYearsByRegionId($regionId: ID!) {
    getSchoolYearsByRegionId(region_id: $regionId) {
      school_year_id
      date_begin
      date_end
      midyear_application
      grades
      IsCurrentYear
      special_ed
      special_ed_options
      diploma_seeking
      reimbursements
      testing_preference
      testing_preference_title
      testing_preference_description
      opt_out_form_title
      opt_out_form_description
      date_reg_open
      date_reg_close
      schedule
      midyear_application_open
      midyear_application_close
      midyear_schedule_open
      midyear_schedule_close
      second_semester_open
      second_semester_close
      schedule_builder_open
      schedule_builder_close
      homeroom_resource_open
      homeroom_resource_close
      direct_order_open
      direct_order_close
      reimbursement_open
      reimbursement_close
      custom_built_open
      custom_built_close
      reimbursement_custom_built_open
      reimbursement_custom_built_close
      require_software_open
      require_software_close
      third_party_open
      third_party_close
      mid_direct_order_open
      mid_direct_order_close
      mid_reimbursement_open
      mid_reimbursement_close
      mid_custom_built_open
      mid_custom_built_close
      mid_reimbursement_custom_built_open
      mid_reimbursement_custom_built_close
      mid_require_software_open
      mid_require_software_close
      mid_third_party_open
      mid_third_party_close
      intent_to_re_enroll_open
      intent_to_re_enroll_close
      notify_of_withdraw
      direct_orders
      require_software
      learning_logs_first_second_semesters
      first_semester_ends
      second_semester_starts
      learning_logs_eoy_deadline
      grade_transition_graduated
      grade_transition_completed
      enable_grade_transition
      full_application_open
      full_application_close
      ScheduleBuilder {
        max_num_periods
        custom_built
        third_party_provider
      }
      ReimbursementSetting {
        is_merged_periods
        merged_periods
      }
    }
  }
`;
export const useSchoolYearsByRegionId = (regionId, defaultSchoolYearId, preservePreviousSelection, onlyPastYears) => {
  const {me} = useContext(UserContext);
  const selectedRegionId = regionId || me?.selectedRegionId;
  const [selectedYearId, setSelectedYearId] = useState();
  const {loading, data, error, refetch} = useQuery(getSchoolYearsQuery, {
    variables: {
      regionId: selectedRegionId
    },
    skip: selectedRegionId === void 0,
    fetchPolicy: "network-only"
  });
  const schoolYears = useMemo(() => {
    if (!loading && data && data.getSchoolYearsByRegionId) {
      return data.getSchoolYearsByRegionId;
    }
    return [];
  }, [data, loading]);
  const currentYear = useMemo(() => {
    return schoolYears.find((item) => item.IsCurrentYear);
  }, [schoolYears]);
  const dropdownItems = useMemo(() => {
    if (!isEmpty(schoolYears)) {
      let filteredSchoolYears = schoolYears;
      if (onlyPastYears) {
        filteredSchoolYears = schoolYears.filter((schoolYear) => new Date(schoolYear.date_end) <= new Date(currentYear?.date_end || ""));
      }
      return sortBy(filteredSchoolYears, "date_begin").map((item) => ({
        value: item.school_year_id,
        label: `${dayjs(item.date_begin).format("YYYY")}-${dayjs(item.date_end).format("YY")}`
      }));
    }
    return [];
  }, [loading, data]);
  useEffect(() => {
    if (preservePreviousSelection && selectedYearId) {
      const selectedYearOnCurrentYears = schoolYears.find((schoolYear) => schoolYear.school_year_id === selectedYearId);
      if (selectedYearOnCurrentYears)
        return;
    }
    if (schoolYears?.length) {
      if (defaultSchoolYearId)
        setSelectedYearId(defaultSchoolYearId);
      else {
        setSelectedYearId(currentYear?.school_year_id || schoolYears[0].school_year_id);
      }
    }
  }, [schoolYears]);
  const selectedYear = useMemo(() => {
    if (selectedYearId && schoolYears.length) {
      return schoolYears.find((item) => item.school_year_id === selectedYearId);
    } else
      return void 0;
  }, [selectedYearId, schoolYears]);
  return {
    error,
    selectedYearId,
    selectedYear,
    loading,
    schoolYears,
    currentYear,
    dropdownItems,
    refetchSchoolYear: refetch,
    setSelectedYearId
  };
};
