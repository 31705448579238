import {useMemo} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {getHomeroomSettingBySchoolYearIdQuery} from "../../../graphql/queries/homeroom-settings.js";
import {getStudentsStatistics} from "../../../graphql/queries/student-learning-log.js";
const useStudentStatistics = (studentIds, schoolYear, requiredData) => {
  const {data: studentStatisticsData} = useQuery(getStudentsStatistics, {
    variables: {
      studentsStatisticsRequest: {
        requiredData,
        schoolYearId: schoolYear,
        studentsIds: studentIds
      }
    },
    skip: !schoolYear || studentIds.some((item) => typeof item !== "number" || Number.isNaN(item)),
    fetchPolicy: "network-only"
  });
  const {data: homeroomSettingsData} = useQuery(getHomeroomSettingBySchoolYearIdQuery, {
    variables: {
      schoolYearId: schoolYear
    },
    skip: !schoolYear || studentIds.some((item) => typeof item !== "number" || Number.isNaN(item))
  });
  const studentStats = useMemo(() => {
    const homeroomSettings = homeroomSettingsData?.homeroomSettingBySchoolYearId?.at(-1);
    if (!Array.isArray(studentStatisticsData?.getStudentsStatistics) || !homeroomSettings)
      return [];
    const stats = studentStatisticsData?.getStudentsStatistics;
    const passAverage = homeroomSettings.passing_average ?? 0;
    const zeroCountSetting = homeroomSettings.zero_count ?? false;
    const _studentStats = stats.map((item) => ({
      studentId: item?.studentId ?? 0,
      average: item?.avgGrade ?? 0,
      zeroCount: item?.gradedZeroCount ?? 0,
      passed: (item?.avgGrade ?? 0) >= Number(passAverage) && (!zeroCountSetting || (item?.gradedZeroCount ?? 0) === 0),
      graded: !isNil(item?.avgGrade),
      resubmitRequiredCount: item?.resubmitRequiredLearningLogs ?? 0,
      avgGradeFirst: item?.avgGradeFirst ?? 0,
      firstPassed: (item?.avgGradeFirst ?? 0) >= Number(passAverage),
      firstGraded: !isNil(item?.avgGradeFirst),
      avgGradeSecond: item?.avgGradeSecond ?? 0,
      secondPassed: (item?.avgGradeSecond ?? 0) >= Number(passAverage),
      secondGraded: !isNil(item?.avgGradeSecond),
      excusedLearningLogs: item?.excusedLearningLogs ?? 0,
      semestersEnabled: item.semestersEnabled ?? false,
      zeroCountSetting
    }));
    return _studentStats;
  }, [studentStatisticsData, homeroomSettingsData]);
  return studentStats;
};
export default useStudentStatistics;
