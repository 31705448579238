export var ReimbursementText;
(function(ReimbursementText2) {
  ReimbursementText2["TITLE"] = "Receiving Reimbursement";
  ReimbursementText2["DESCRIPTION"] = "All reimbursement payments are processed securely through Hyperwallet. Please create a Hyperwallet account and select your desired transfer method. Once complete, you can access the Request for Reimbursement form.";
  ReimbursementText2["CREATE_HYPERWALLET_ACCOUNT"] = "Create Hyperwallet Account";
  ReimbursementText2["ADD_TRANSFER_METHOD"] = "Add Transfer Method";
  ReimbursementText2["EDIT_CURRENT_TRANSFER_METHOD"] = "Edit Transfer Method";
  ReimbursementText2["CHANGE_TRANSFER_METHOD"] = "Change Transfer Method";
  ReimbursementText2["TRANSACTIONS_DETAILS"] = "Transaction Details";
  ReimbursementText2["HYPERWALLET_DETAILS"] = "Hyperwallet Details";
  ReimbursementText2["CODE_LABEL"] = "Code";
  ReimbursementText2["MESSAGE_LABEL"] = "Message";
  ReimbursementText2["PAYMENT_TOKEN_LABEL"] = "Payment token";
  ReimbursementText2["DATE_LABEL"] = "Date";
  ReimbursementText2["TRANSACTION_STATUS"] = "Transaction status:";
  ReimbursementText2["TRANSACTION_TOKEN"] = "Transaction token:";
  ReimbursementText2["NO_TRANSACTION"] = "This reimbursement does not have a transaction";
  ReimbursementText2["ACTIVE_PAYMENT_METHOD"] = "The user has an active payment method.";
  ReimbursementText2["NO_PAYMENT_METHOD"] = "The user does not have an active payment method.";
  ReimbursementText2["PARENT_TITLE"] = "Parent payment account information";
  ReimbursementText2["ACCOUNT_STATUS_LABEL"] = "Account status:";
  ReimbursementText2["ACCOUNT_VERIFICATION_LABEL"] = "Account verification status:";
  ReimbursementText2["PAYMENT_METHOD_LABEL"] = "Payment method:";
  ReimbursementText2["PAYMENT_METHOD_STATUS_LABEL"] = "Payment method status:";
  ReimbursementText2["PAYMENT_METHOD_INFORMATION_UNAVAILABLE"] = "Payment method information not available";
  ReimbursementText2["NOT_PAYMENT_ACCOUNT_MESSAGE"] = "The user doesn't have an active payment account";
  ReimbursementText2["EMAIL_LABEL"] = "Email:";
  ReimbursementText2["FIRST_NAME"] = "First name";
  ReimbursementText2["LAST_NAME"] = "Last name";
  ReimbursementText2["DATE_OF_BIRTH"] = "Date of birth";
  ReimbursementText2["ADDRESS_LINE_1"] = "Address line 1";
  ReimbursementText2["ADDRESS_LINE_2"] = "Address line 2";
  ReimbursementText2["COUNTRY"] = "Country";
  ReimbursementText2["STATE"] = "State";
  ReimbursementText2["CITY"] = "City";
  ReimbursementText2["POSTAL_CODE"] = "Postal code";
  ReimbursementText2["CANCEL_BUTTON"] = "Cancel";
  ReimbursementText2["SUBMIT_BUTTON"] = "Create Account";
  ReimbursementText2["LOADING"] = "Loading...";
  ReimbursementText2["TRANSFER_METHOD_CREATED_SUCCESSFULL"] = "Payment method created successfully";
  ReimbursementText2["TRANSFER_METHOD_UPDATED_SUCCESSFULL"] = "Payment method updated successfully";
})(ReimbursementText || (ReimbursementText = {}));
