import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {TableCell, TableRow, styled, Collapse, Typography, TableBody, Table} from "../../_snowpack/pkg/@mui/material.js";
import {Draggable} from "../../_snowpack/pkg/react-beautiful-dnd.js";
import {MthCheckbox} from "../MthCheckbox/index.js";
import {MthColor} from "../../core/enums/index.js";
import {convertWidth} from "../../core/utils/mthtable/mthtable.util.js";
const StyledTableRow = styled(TableRow)(({}) => ({
  "& .MuiTableCell-root": {
    backgroundColor: MthColor.WHITE
  },
  "&:nth-of-type(2n+1) .MuiTableCell-root": {
    backgroundColor: "#FAFAFA",
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px"
    },
    "&:last-child": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px"
    }
  },
  td: {
    padding: "2px 4px 2px 0"
  },
  ".cell-item": {
    textAlign: "left"
  },
  ".cell-item.border-l": {
    borderLeft: "1px solid black",
    minHeight: "17px"
  }
}));
const getStyle = (key, value) => {
  switch (key) {
    case "status":
      return value === "Not Submitted" ? {color: MthColor.BLACK} : {color: MthColor.MTHBLUE};
    default:
      return {};
  }
};
const MthTableRow = ({
  tableWidth,
  fields,
  index,
  item,
  selectable = false,
  isDraggable,
  size,
  checkBoxColor,
  handleToggleCheck,
  isTableCellBorder = true,
  noCellItemPadding
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const renderDraggableTableRow = (isSelectable, provided, item2) => {
    return /* @__PURE__ */ React.createElement(TableRow, {
      ref: provided.innerRef,
      ...provided.draggableProps,
      sx: {
        "&:nth-of-type(2n+0) td": {
          backgroundColor: "white !important"
        },
        ...item2.sx
      }
    }, /* @__PURE__ */ React.createElement(TableCell, {
      colSpan: 12
    }, /* @__PURE__ */ React.createElement(Table, null, /* @__PURE__ */ React.createElement(TableBody, null, renderGeneralTableRow(isSelectable, provided, item2)))));
  };
  const handleClick = (field) => {
    if (field.onClick)
      field.onClick(index);
  };
  const renderGeneralTableRow = (isSelectable, provided, item2) => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(StyledTableRow, {
      className: isExpanded ? "expanded" : "",
      sx: item2.sx
    }, isSelectable && /* @__PURE__ */ React.createElement(TableCell, {
      className: "checkWrap"
    }, /* @__PURE__ */ React.createElement(MthCheckbox, {
      color: item2.isSelectedPartial ? "primary" : checkBoxColor,
      size,
      indeterminate: item2.isSelectedPartial,
      checked: item2.isSelected || false,
      onChange: () => handleToggleCheck(item2),
      disabled: item2.selectable === false
    })), fields.map((field, indexCol) => /* @__PURE__ */ React.createElement(TableCell, {
      "data-testid": `tableCell-${index}-${field.key}`,
      key: indexCol,
      width: convertWidth(field.width || 0, tableWidth),
      sx: getStyle(field.key, item2.columns[field.key]),
      onClick: () => handleClick(field)
    }, /* @__PURE__ */ React.createElement("div", {
      className: indexCol > 0 && indexCol + 1 !== fields.length && isTableCellBorder ? "border-l cell-item" : "cell-item",
      style: {paddingLeft: noCellItemPadding ? "0 !important" : "14px"}
    }, field.formatter ? field.formatter(item2, provided?.dragHandleProps) : item2.columns[field.key])))), Boolean(item2.expandNode) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(TableRow, null), /* @__PURE__ */ React.createElement(TableRow, null, /* @__PURE__ */ React.createElement(TableCell, {
      sx: {p: 0},
      colSpan: 12
    }, /* @__PURE__ */ React.createElement(Collapse, {
      in: isExpanded,
      timeout: "auto",
      unmountOnExit: true
    }, /* @__PURE__ */ React.createElement(Typography, {
      variant: "h6",
      gutterBottom: true,
      component: "div",
      border: "none"
    }, item2.expandNode))))));
  };
  useEffect(() => {
    item.toggleExpand = () => {
      setIsExpanded((pre) => !pre);
    };
  }, [item]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, isDraggable ? /* @__PURE__ */ React.createElement(Draggable, {
    key: index.toString(),
    draggableId: index.toString(),
    index,
    isDragDisabled: !isDraggable
  }, (provided) => renderDraggableTableRow(selectable, provided, item)) : renderGeneralTableRow(selectable, void 0, item));
};
export default MthTableRow;
