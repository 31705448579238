export var FileCategory;
(function(FileCategory2) {
  FileCategory2["PROFILE"] = "profile";
  FileCategory2["QUICK_LINKS"] = "quick_links";
  FileCategory2["SCHOOL_PARTNERS"] = "school_partners";
  FileCategory2["PACKET"] = "packet";
  FileCategory2["COUNTY"] = "county";
  FileCategory2["SCHOOL_DISTRICT"] = "schoolDistrict";
  FileCategory2["STATE_LOGO"] = "stateLogo";
  FileCategory2["SIGNATURE"] = "signature";
  FileCategory2["RESOURCES"] = "resources";
  FileCategory2["STUDENT_RECORDS"] = "Student_Records";
  FileCategory2["RECEIPT"] = "receipt";
  FileCategory2["LEARNING_LOG_QUESTIONS"] = "ll_questions";
})(FileCategory || (FileCategory = {}));
