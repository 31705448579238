import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getEnrollmentQuestionsGql} from "../../../graphql/queries/enrollment-question.js";
export const useEnrollmentPacketDocumentListByRegionId = (regionId, schoolYearId) => {
  const [enrollmentPacketDocumentList, setEnrollmentPacketDocumentList] = useState([]);
  const {data, loading, error} = useQuery(getEnrollmentQuestionsGql, {
    variables: {
      input: {region_id: regionId, school_year_id: schoolYearId, mid_year: false}
    },
    skip: !regionId || !schoolYearId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data?.getEnrollmentQuestions) {
      const documents = data?.getEnrollmentQuestions?.filter((item) => item.tab_name === "Documents")?.at(0);
      if (documents?.is_active) {
        setEnrollmentPacketDocumentList(documents?.groups?.at(0)?.questions?.filter((item) => item.required)?.map((question) => {
          return {
            label: question.question,
            value: question.options ? JSON.parse(question?.options)?.at(0)?.label : question.question
          };
        }));
      } else {
        setEnrollmentPacketDocumentList([]);
      }
    } else {
      setEnrollmentPacketDocumentList([]);
    }
  }, [loading, data]);
  return {
    loading,
    data: enrollmentPacketDocumentList,
    error
  };
};
