export var AccountSettingsEnum;
(function(AccountSettingsEnum2) {
  AccountSettingsEnum2["DELETE_IMAGE"] = "Delete Image";
  AccountSettingsEnum2["ARE_YOU_SURE"] = "Are you sure you want to delete this image";
  AccountSettingsEnum2["REMOVE_PROFILE_PICTURE"] = "Remove Profile Picture";
  AccountSettingsEnum2["UPLOAD_PHOTO"] = "Upload Photo";
  AccountSettingsEnum2["CHANGE_PASSWORD"] = "Change Password";
  AccountSettingsEnum2["CHANGE_EMAIL"] = "Change Email";
  AccountSettingsEnum2["CHANGE_EMAIL_SUBTITLE"] = "Changing your email requires a new verification link to be sent. You will be logged out until you verify your new email. Would you like to proceed?";
  AccountSettingsEnum2["CREATE_ACCOUNT"] = "Create Account";
  AccountSettingsEnum2["CREATE_STUDENT_ACCOUNT"] = "Create Student Account";
  AccountSettingsEnum2["CREATED_STUDENT_ACCOUNT"] = "Student Account Created";
})(AccountSettingsEnum || (AccountSettingsEnum = {}));
