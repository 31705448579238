import React from "../../_snowpack/pkg/react.js";
import {Backdrop, CircularProgress} from "../../_snowpack/pkg/@mui/material.js";
import {useRecoilState} from "../../_snowpack/pkg/recoil.js";
import {loadingState} from "../../providers/Store/State.js";
export const MthLoading = () => {
  const [open] = useRecoilState(loadingState);
  return /* @__PURE__ */ React.createElement(Backdrop, {
    sx: {color: "#000", zIndex: (theme) => theme.zIndex.drawer + 3e3},
    open
  }, /* @__PURE__ */ React.createElement(CircularProgress, {
    color: "inherit"
  }));
};
