import React, {useContext, useRef, useState, useMemo, useEffect} from "../../_snowpack/pkg/react.js";
import {useQuery} from "../../_snowpack/pkg/@apollo/client.js";
import {Alert, Button, Modal, OutlinedInput} from "../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../_snowpack/pkg/@mui/system.js";
import {ContentState, EditorState, convertToRaw} from "../../_snowpack/pkg/draft-js.js";
import draftToHtml from "../../_snowpack/pkg/draftjs-to-html.js";
import htmlToDraft from "../../_snowpack/pkg/html-to-draftjs.js";
import Wysiwyg from "../../_snowpack/pkg/react-draft-wysiwyg.js";
import {isExtraMergeHTMLTags} from "../../core/constants/index.js";
import {fontFamilyEnum, EmailType} from "../../core/enums/index.js";
import {getEmailTemplateQuery} from "../../graphql/queries/email-template.js";
import "../../_snowpack/pkg/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {UserContext} from "../../providers/UserContext/UserProvider.js";
import {Title} from "../../core/components/shared/Title/Title.js";
import {StandardResponses} from "./StandardReponses/StandardResponses.js";
import {useStyles} from "./styles.js";
export const EmailModal = ({
  handleSubmit,
  handleModem,
  title,
  options,
  setEmailTemplate,
  type,
  setEmailFrom,
  emailFrom,
  setEmailBodyInfo,
  schoolYearId,
  midYear
}) => {
  let actionType = "";
  if (type === "ageIssue") {
    actionType = "AGE_ISSUE";
  } else {
    actionType = "MISSING_INFO";
  }
  const {me} = useContext(UserContext);
  const [standard_responses, setStandardResponses] = useState([]);
  const [template, setTemplate] = useState(null);
  const [keyText, setKeyText] = useState("");
  const {data: emailTemplateData} = useQuery(getEmailTemplateQuery, {
    variables: {
      template: type === "ageIssue" ? "Age Issue" : "Missing Information",
      schoolYearId,
      midYear,
      regionId: me?.selectedRegionId
    },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (emailTemplateData !== void 0) {
      const {emailTemplateName} = emailTemplateData;
      if (emailTemplateName) {
        setEmailTemplate(emailTemplateName);
        setTemplate(emailTemplateName);
      }
    }
  }, [emailTemplateData]);
  const [subject, setSubject] = useState("");
  const [alert, setAlert] = useState(false);
  const [currentBlocks, setCurrentBlocks] = useState(0);
  const defaultEmail = useMemo(() => setEmailBodyInfo(""), []);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(defaultEmail)));
  const [body, setBody] = useState("");
  const editorRef = useRef(null);
  const classes = useStyles;
  useEffect(() => {
    if (template) {
      const {subject: subject2, from, body: body2} = template;
      setSubject(setEmailBodyInfo(subject2));
      setEmailFrom(from);
      if (body2) {
        setBody(body2);
        const contentBlock = htmlToDraft(setEmailBodyInfo(body2));
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setEditorState(EditorState.createWithContent(contentState));
        }
      }
    }
  }, [template]);
  useEffect(() => {
    let HtmlInput = body;
    const index = HtmlInput.indexOf("</p>", HtmlInput.indexOf("</p>") + 1) + 4;
    setKeyText(HtmlInput.slice(index, index + 20));
    let standardResponseExtraText = "", standardResponseExtraSpan = "";
    for (let i = 0; i < standard_responses.length; i++) {
      if (type === EmailType.AGE_ISSUE) {
        standardResponseExtraText += `<li>${standard_responses[i].title?.trim()}`;
        if (standard_responses[i].extraText.trim() !== "") {
          standardResponseExtraText += `<ul class="sub-bullet"><li>${standard_responses[i].extraText?.trim()?.replace(isExtraMergeHTMLTags, "")}</li></ul>`;
        }
        standardResponseExtraText += "</li>";
      } else if (type === EmailType.MISSING_INFO) {
        standardResponseExtraSpan += `<li>${standard_responses[i].title?.trim()}`;
        if (standard_responses[i].extraText.trim() !== "") {
          standardResponseExtraSpan += `<ul class="sub-bullet"><li>${standard_responses[i].extraText?.trim()?.replace(isExtraMergeHTMLTags, "")}</li></ul>`;
        }
        standardResponseExtraSpan += "</li>";
      }
    }
    HtmlInput = HtmlInput.replace(/\[INSTRUCTIONS\]/g, standardResponseExtraText).replace(/\[FILES\]/g, `<ul class="primary-bullet">${standardResponseExtraSpan}</ul>`);
    HtmlInput = setEmailBodyInfo(HtmlInput);
    const contentBlock = htmlToDraft(HtmlInput);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [body, standard_responses]);
  const handleEditorChange = (state) => {
    try {
      if (currentBlocks !== 0 && currentBlocks !== state.blocks.length) {
        editorRef.current.scrollIntoView({behavior: "smooth", block: "nearest"});
      }
      setCurrentBlocks(state.blocks.length);
    } catch {
    }
  };
  const onSubmit = () => {
    const email = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (email.search(/\[BLANK\]/g) >= 0) {
      setAlert(true);
      return;
    }
    if (handleSubmit) {
      handleSubmit(subject, draftToHtml(convertToRaw(editorState.getCurrentContent())), {
        values: standard_responses,
        type: actionType
      }, body, keyText);
    }
    handleModem(false);
  };
  return /* @__PURE__ */ React.createElement(Modal, {
    open: true,
    onClose: () => handleModem(),
    "aria-labelledby": "modal-modal-title",
    "aria-describedby": "modal-modal-description"
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.modalCard
  }, /* @__PURE__ */ React.createElement(Title, {
    fontWeight: "700"
  }, title), /* @__PURE__ */ React.createElement(Box, {
    sx: {padding: "40px", paddingBottom: alert ? "20px" : void 0}
  }, options && /* @__PURE__ */ React.createElement(StandardResponses, {
    options,
    type,
    setBody,
    standardResponses: standard_responses,
    setStandardResponses
  }), /* @__PURE__ */ React.createElement(OutlinedInput, {
    value: emailFrom,
    size: "small",
    fullWidth: true,
    onChange: (e) => setEmailFrom(e.target.value),
    placeholder: "From email",
    sx: classes.subject
  }), /* @__PURE__ */ React.createElement(OutlinedInput, {
    value: subject,
    size: "small",
    fullWidth: true,
    placeholder: "Subject",
    sx: classes.subject,
    onChange: (e) => setSubject(setEmailBodyInfo(e.target.value))
  }), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.editor
  }, /* @__PURE__ */ React.createElement(Wysiwyg.Editor, {
    onContentStateChange: handleEditorChange,
    editorRef: (ref) => editorRef.current = ref,
    editorState,
    onEditorStateChange: setEditorState,
    handlePastedText: () => false,
    toolbar: {
      options: [
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        "embedded",
        "image",
        "remove",
        "history"
      ],
      fontFamily: {
        options: Object.values(fontFamilyEnum),
        className: void 0,
        component: void 0,
        dropdownClassName: void 0
      }
    }
  }))), /* @__PURE__ */ React.createElement(Alert, {
    onClose: () => setAlert(false),
    sx: {marginBottom: "15px", display: alert ? "flex" : "none"},
    severity: "error"
  }, "Please replace the [BLANK] text in the specific instructions."), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%"
    }
  }, /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    color: "secondary",
    disableElevation: true,
    sx: classes.cancelButton,
    onClick: handleModem
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    disableElevation: true,
    sx: classes.submitButton,
    onClick: onSubmit
  }, "Send"))));
};
