export var fontFamilyEnum;
(function(fontFamilyEnum2) {
  fontFamilyEnum2["VisbyCFRegular"] = "Regular";
  fontFamilyEnum2["VisbyCFMedium"] = "Medium";
  fontFamilyEnum2["VisbyCFDemiBold"] = "Demi Bold";
  fontFamilyEnum2["VisbyCFBold"] = "Bold";
  fontFamilyEnum2["VisbyCFExtraBold"] = "Extra Bold";
  fontFamilyEnum2["VisbyCFHeavy"] = "Heavy";
  fontFamilyEnum2["HeavyFont"] = "Helvetica";
  fontFamilyEnum2["VisbyFont"] = "VisbyCF";
})(fontFamilyEnum || (fontFamilyEnum = {}));
