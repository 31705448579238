export var SOEFieldKey;
(function(SOEFieldKey2) {
  SOEFieldKey2["STUDENT"] = "student";
  SOEFieldKey2["GRADE"] = "grade";
  SOEFieldKey2["CITY"] = "city";
  SOEFieldKey2["PARENT"] = "parent";
  SOEFieldKey2["CURRENT_SOE"] = "currentSoe";
  SOEFieldKey2["PREVIOUS_SOE"] = "previousSoe";
})(SOEFieldKey || (SOEFieldKey = {}));
export var SOEFieldLabel;
(function(SOEFieldLabel2) {
  SOEFieldLabel2["STUDENT"] = "Student";
  SOEFieldLabel2["GRADE"] = "Grade";
  SOEFieldLabel2["CITY"] = "City";
  SOEFieldLabel2["PARENT"] = "Parent";
  SOEFieldLabel2["CURRENT_SOE"] = "Current Soe";
  SOEFieldLabel2["PREVIOUS_SOE"] = "Previous Soe";
})(SOEFieldLabel || (SOEFieldLabel = {}));
export var SoETransferOption;
(function(SoETransferOption2) {
  SoETransferOption2[SoETransferOption2["ASSIGN"] = 1] = "ASSIGN";
  SoETransferOption2[SoETransferOption2["ASSIGN_AND_FORM"] = 2] = "ASSIGN_AND_FORM";
  SoETransferOption2[SoETransferOption2["UNASSSIGN"] = 3] = "UNASSSIGN";
})(SoETransferOption || (SoETransferOption = {}));
export var SoETransferOptionText;
(function(SoETransferOptionText2) {
  SoETransferOptionText2["ASSIGN"] = "Assign New SOE";
  SoETransferOptionText2["ASSIGN_AND_FORM"] = "Assign New SOE and Create Transfer Form";
  SoETransferOptionText2["UNASSSIGN"] = "Are you sure you want to change this student to Unassigned?";
})(SoETransferOptionText || (SoETransferOptionText = {}));
