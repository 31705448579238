import React, {useEffect, useState, useContext, useMemo} from "../../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../../_snowpack/pkg/@apollo/client.js";
import {Button, Checkbox, FormControlLabel, Grid, TextField, Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../_snowpack/pkg/@mui/system.js";
import {useFlag} from "../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {CustomModal} from "../../../../components/CustomModal/CustomModals.js";
import {PhoneInput} from "../../../../components/PhoneInput/PhoneInput.js";
import {EPIC_275_STORY_5395, STATES_WITH_ABBREVIATION, USER_ID, US_STATES} from "../../../../core/constants/index.js";
import {DropDown} from "../../../../core/components/shared/DropDown/DropDown.js";
import {Paragraph} from "../../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../../core/components/shared/Subtitle/Subtitle.js";
import {MthColor, MthTitle, TEXT_SIZE} from "../../../../core/enums/index.js";
import {UserContext} from "../../../../providers/UserContext/UserProvider.js";
import {ReimbursementModal} from "../../Reimbursements/ReimbursementModal/index.js";
import {getSchoolDistrictCode} from "../../../../core/utils/index.js";
import {HomeroomResourceModal} from "../components/NewUserModal/HomeroomResourceModal.js";
import {FamilyNotesAndStudentNotes} from "../components/Share/FamilyNotesAndStudentNotes.js";
import {getCountiesByRegionId, getSchoolDistrictsByRegionId} from "../services.js";
import {useStyles} from "../styles.js";
import {testIDs} from "./__tests__/ParentProfile.testIds.js";
export const ParentProfile = ({
  userInfo,
  setUserInfo,
  phoneInfo,
  setPhoneInfo,
  notes,
  setNotes,
  studentNotes,
  setStudentNotes,
  applicationState,
  familyStudentIds,
  currentSchoolYear,
  nextSchoolYear
}) => {
  const {me} = useContext(UserContext);
  const [preferedFirstName, setPreferredFirstName] = useState("");
  const [preferedLastName, setPreferredLastName] = useState("");
  const [legalFirstName, setLegalFirstName] = useState("");
  const [legalMiddleName, setLegalMiddleName] = useState("");
  const [legalLastName, setLegalLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [county, setCounty] = useState(0);
  const [school_district, setSchoolDistrict] = useState("");
  const [state, setState] = useState(userInfo?.address?.state ? "" : STATES_WITH_ABBREVIATION[applicationState]);
  const [canMessage, setCanMessage] = useState(false);
  const [showReimbursementModal, setShowReimbursementModal] = useState(false);
  const classes = useStyles;
  const epic275story5395 = useFlag(EPIC_275_STORY_5395);
  const {loading: countyLoading, data: countyData} = useQuery(getCountiesByRegionId, {
    variables: {regionId: Number(me?.selectedRegionId)},
    fetchPolicy: "network-only"
  });
  const [counties, setCounties] = useState([]);
  const [clickedEvent, setClickedEvent] = useState({});
  const [warningPopup, setWarningPopup] = useState(false);
  const [ableToEdit, setAbleToEdit] = useState(false);
  const [selectedDrop, setSelectedDrop] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [showHomeroomResourceModal, setShowHomeroomResourceModal] = useState(false);
  const selectedYearId = useMemo(() => {
    return currentSchoolYear?.school_year_id || nextSchoolYear?.school_year_id;
  }, [currentSchoolYear, nextSchoolYear]);
  const setCancelWarningPopup = () => {
    setWarningPopup(false);
    setAbleToEdit(false);
  };
  const toggleHomeroomResourceModal = () => setShowHomeroomResourceModal(!showHomeroomResourceModal);
  const setConfirmWarningPopup = () => {
    setWarningPopup(false);
    if (selectedDrop == "County") {
      setCounty(Number(selectedValue));
      setUserInfo({...userInfo, address: {...userInfo.address, county_id: Number(selectedValue)}});
    } else if (selectedDrop == "School District") {
      setSchoolDistrict(selectedValue);
      setUserInfo({
        ...userInfo,
        address: {
          ...userInfo.address,
          school_district: selectedValue,
          school_district_code: getSchoolDistrictCode(schoolDistrictsData?.schoolDistrict, selectedValue)
        }
      });
    } else if (selectedDrop == "State") {
      setState(selectedValue);
      setUserInfo({...userInfo, address: {...userInfo.address, state: selectedValue}});
    } else {
      setAbleToEdit(true);
    }
    setSelectedValue("");
    setSelectedDrop("");
  };
  useEffect(() => {
    if (ableToEdit == true)
      clickedEvent.target.focus();
  }, [ableToEdit]);
  const setFocused = (event) => {
    if (!ableToEdit || clickedEvent.target != event.target) {
      event.preventDefault();
      event.target.blur();
      setClickedEvent(event);
      setWarningPopup(true);
    }
  };
  const setBlured = () => {
    setAbleToEdit(false);
  };
  useEffect(() => {
    if (!countyLoading && countyData?.getCounties) {
      setCounties(countyData.getCounties.map((v) => {
        return {label: v.county_name, value: Number(v.id)};
      }));
    }
  }, [countyData]);
  const {loading: schoolDistrictsDataLoading, data: schoolDistrictsData} = useQuery(getSchoolDistrictsByRegionId, {
    variables: {
      regionId: Number(me?.selectedRegionId)
    },
    skip: Number(me?.selectedRegionId) ? false : true,
    fetchPolicy: "network-only"
  });
  const [schoolDistricts, setSchoolDistricts] = useState([]);
  useEffect(() => {
    if (!schoolDistrictsDataLoading && schoolDistrictsData?.schoolDistrict.length > 0) {
      setSchoolDistricts(schoolDistrictsData?.schoolDistrict.map((d) => {
        return {label: d.school_district_name, value: d.school_district_name};
      }));
    }
  }, [schoolDistrictsDataLoading]);
  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email);
      setPreferredFirstName(userInfo.preferred_first_name || "");
      setPreferredLastName(userInfo.preferred_last_name || "");
      setLegalFirstName(userInfo.first_name || "");
      setLegalLastName(userInfo.last_name || "");
      setLegalMiddleName(userInfo.middle_name || "");
      setCity(userInfo.address.city || "");
      setState(userInfo.address.state);
      setStreet1(userInfo.address.street || "");
      setStreet2(userInfo.address.street2 || "");
      setZip(userInfo.address.zip || "");
      setCounty(userInfo.address.county_id || 0);
      setSchoolDistrict(userInfo.address.school_district || "");
    }
    setPhone(phoneInfo?.number || "");
    if (phoneInfo?.recieve_text) {
      setCanMessage(true);
    }
  }, [userInfo]);
  const closeReimbursementModal = () => setShowReimbursementModal(false);
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      marginTop: "24px"
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 5,
      width: "264px",
      height: "34px",
      borderRadius: 2
    },
    onClick: () => setShowReimbursementModal(true)
  }, "Reimbursements"), /* @__PURE__ */ React.createElement(Button, {
    onClick: toggleHomeroomResourceModal,
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 2,
      width: "264px",
      height: "34px",
      borderRadius: 2
    }
  }, "Homeroom Resources")), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 4,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Preferred First Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: preferedFirstName,
    onChange: (e) => {
      setPreferredFirstName(e.target.value);
      setUserInfo({...userInfo, ...{preferred_first_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Preferred Last Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: preferedLastName,
    onChange: (e) => {
      setPreferredLastName(e.target.value);
      setUserInfo({...userInfo, ...{preferred_last_name: e.target.value}});
    }
  }))), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 0,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Legal First Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: legalFirstName,
    onChange: (e) => {
      setLegalFirstName(e.target.value);
      setUserInfo({...userInfo, ...{first_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Legal Middle Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: legalMiddleName,
    onChange: (e) => {
      setLegalMiddleName(e.target.value);
      setUserInfo({...userInfo, ...{middle_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Legal Last Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: legalLastName,
    onChange: (e) => {
      setLegalLastName(e.target.value);
      setUserInfo({...userInfo, ...{last_name: e.target.value}});
    }
  }))), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 0,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Phone"), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "column"
    }
  }, /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: phone,
    onChange: (e) => {
      const formattedNumber = e.target.value.replace(/\D+/g, "").slice(0, 10);
      setPhone(formattedNumber);
      setPhoneInfo({...phoneInfo, ...{number: formattedNumber}});
    },
    InputProps: {
      inputComponent: PhoneInput
    }
  }), /* @__PURE__ */ React.createElement(FormControlLabel, {
    control: /* @__PURE__ */ React.createElement(Checkbox, {
      checked: canMessage,
      onChange: (e) => {
        setCanMessage(e.target.checked);
        setPhoneInfo({...phoneInfo, ...{ext: e.target.checked ? "1" : null}});
      },
      disabled: true
    }),
    label: /* @__PURE__ */ React.createElement(Paragraph, {
      sx: classes.checkBox
    }, MthTitle.TEXT_MESSAGE)
  }))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Email"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: email,
    onChange: (e) => {
      setEmail(e.target.value);
      setUserInfo({...userInfo, ...{email: e.target.value}});
    }
  }))), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 0,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Address Line 1"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: street1,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: (e) => {
      setStreet1(e.target.value);
      setUserInfo({...userInfo, address: {...userInfo.address, street: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Address Line 2"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: street2,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: (e) => {
      setStreet2(e.target.value);
      setUserInfo({...userInfo, address: {...userInfo.address, street2: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "City"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: city,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: (e) => {
      setCity(e.target.value);
      setUserInfo({...userInfo, address: {...userInfo.address, city: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "State"), /* @__PURE__ */ React.createElement(DropDown, {
    sx: classes.dropdown,
    dropDownItems: US_STATES,
    setParentValue: (val) => {
      setSelectedValue(String(val));
      setSelectedDrop("State");
      setWarningPopup(true);
    },
    alternate: true,
    size: "medium",
    defaultValue: state || ""
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Zip"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: zip,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: (e) => {
      setZip(e.target.value);
      setUserInfo({...userInfo, address: {...userInfo.address, zip: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "County"), /* @__PURE__ */ React.createElement(DropDown, {
    size: "medium",
    sx: classes.dropdown,
    dropDownItems: counties,
    defaultValue: county,
    placeholder: "Select County",
    auto: false,
    setParentValue: (e) => {
      setSelectedValue(String(e));
      setSelectedDrop("County");
      setWarningPopup(true);
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "School District"), /* @__PURE__ */ React.createElement(DropDown, {
    size: "medium",
    sx: classes.dropdown,
    dropDownItems: schoolDistricts,
    defaultValue: school_district,
    placeholder: "Select School District",
    auto: false,
    setParentValue: (e) => {
      setSelectedValue(String(e));
      setSelectedDrop("School District");
      setWarningPopup(true);
    }
  }))), epic275story5395 ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 0,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(FamilyNotesAndStudentNotes, {
    subtittleStyle: classes.note,
    textFieldStyle: classes.text,
    notes,
    setNotes,
    familyNotesTestId: testIDs.familyNotes,
    studentNotesTestId: testIDs.studentNotes,
    studentNotes,
    setStudentNotes,
    isFamilyNotesEditable: true
  })), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 0,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sx: {marginTop: "auto", textAlign: "right"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    color: MthColor.DISABLED,
    sx: {fontWeight: "700"}
  }, USER_ID, " ", userInfo?.uniqueId)))) : /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 0,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 7
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: TEXT_SIZE.LARGE,
    textAlign: "left",
    sx: classes.note
  }, "Notes"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    borderNone: true,
    value: notes || "",
    onChange: (e) => {
      setNotes(e.target.value);
    },
    multiline: true,
    rows: 8
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5,
    sx: {marginTop: "auto", textAlign: "right"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    color: MthColor.DISABLED,
    sx: {fontWeight: "700"}
  }, USER_ID, " ", userInfo?.uniqueId))), warningPopup && /* @__PURE__ */ React.createElement(CustomModal, {
    title: "Warning",
    description: "Updating the address will affect all children",
    onConfirm: setConfirmWarningPopup,
    onClose: setCancelWarningPopup,
    confirmStr: "Update"
  }), showHomeroomResourceModal && /* @__PURE__ */ React.createElement(HomeroomResourceModal, {
    toggleHomeroomResourceModal,
    showHomeromResourceModal: showHomeroomResourceModal,
    schoolYearIdSelected: selectedYearId,
    studentIds: familyStudentIds
  }), showReimbursementModal && /* @__PURE__ */ React.createElement(ReimbursementModal, {
    closeModal: closeReimbursementModal,
    isFromParent: true,
    personId: typeof userInfo === "object" && userInfo && "person_id" in userInfo ? Number(userInfo.person_id) : 0
  }));
};
