import React, {useContext, useEffect, useMemo, useState} from "../../../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../../../_snowpack/pkg/@apollo/client.js";
import {KeyboardArrowDown} from "../../../../../_snowpack/pkg/@mui/icons-material.js";
import {Grid, MenuItem, Select} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../../_snowpack/pkg/@mui/system.js";
import {useFlag} from "../../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {CustomConfirmModal} from "../../../../../components/CustomConfirmModal/CustomConfirmModal.js";
import {MthDatePicker} from "../../../../../components/MthDatePicker/MthDatePicker.js";
import {
  SPACE,
  KINDERGARTEN_LABEL,
  KINDERGARTEN_NUM_VALUE,
  PRE_KINDERGARTEN_LABEL,
  PRE_KINDERGARTEN_NUM_VALUE,
  EPIC_3665_BUG_4429,
  EMPTY_STRING,
  EPIC_2209_STORY_5188
} from "../../../../../core/constants/index.js";
import {DropDown} from "../../../../../core/components/shared/DropDown/DropDown.js";
import {Paragraph} from "../../../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../../../core/components/shared/Subtitle/Subtitle.js";
import {
  ActivateOption,
  ApplicationStatus,
  MthColor,
  StudentStatus,
  StudentStatusLabel,
  datePatterns
} from "../../../../../core/enums/index.js";
import {useDiplomaSeekingOptionsByStudentIdAndSchoolYearId} from "../../../../../core/hooks/index.js";
import {UserContext} from "../../../../../providers/UserContext/UserProvider.js";
import {diplomaQuestionDataBySchoolYearGql} from "../../../SiteManagement/services.js";
import {
  calcAge,
  getSoeByYear,
  getStatusText,
  gradeTextByStatus,
  showDate,
  formatDateByPattern,
  utcToTimezoned,
  getTimezone,
  isInEligibleStudentStatus,
  calculateGradeNumber
} from "../../../../../core/utils/index.js";
import {testIDs} from "./__tests__/StudentFilters.testIds.js";
import {ActiveModal} from "./ActiveModal/index.js";
import {BaseSpecialEds, BaseDiplomaSeekingOptions, BaseStatuses} from "./defaultValues.js";
import {selectStyles, useStyles} from "./styles.js";
import {WithdrawModal} from "./WithdrawModal/index.js";
export const StudentFilters = ({
  student,
  setStudentStatusData,
  originStudentStatus,
  studentStatusData,
  withdrawalStatus,
  specialEdOptions,
  setWithdrawalStatus,
  setIsChanged,
  schoolYears,
  currentSchoolYear,
  nextSchoolYear,
  schoolYearToShowProp
}) => {
  const {me} = useContext(UserContext);
  const timezone = getTimezone(me);
  const classes = useStyles;
  const selectClasses = selectStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [isIneligibleState, setIsIneligibleState] = useState(false);
  const [application, setApplication] = useState();
  const [originalStudentStatus, setOriginalStudentStatus] = useState(null);
  const [, setSpecialEd] = useState();
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [diplomaSeeking, setDiplomaSeeking] = useState();
  const [editingDOB, setEditingDOB] = useState(false);
  const [status, setStatus] = useState(BaseStatuses);
  const [specialEds, setSpecialEds] = useState(BaseSpecialEds);
  const DiplomaSeekingOptions = BaseDiplomaSeekingOptions;
  const epic3665bug4429 = useFlag(EPIC_3665_BUG_4429);
  const epic2209Story5188 = useFlag(EPIC_2209_STORY_5188);
  const [diplomaSeekOptionDefaultValue, setDiplomaSeekOptionDefaultValue] = useState();
  const {diplomaAnswer, diplomaOptions} = useDiplomaSeekingOptionsByStudentIdAndSchoolYearId(studentStatusData?.school_year_id || 0, studentStatusData?.student_id, !studentStatusData?.student_id || !studentStatusData?.school_year_id);
  const activeSchoolYear = schoolYears.find((item) => item.school_year_id === Number(currentSchoolYear?.school_year_id)) || schoolYears.find((item) => item.school_year_id === Number(nextSchoolYear?.school_year_id));
  const schoolYearToShowTemp = activeSchoolYear ? activeSchoolYear : currentSchoolYear;
  const schoolYearToShow = epic2209Story5188 ? schoolYearToShowProp : schoolYearToShowTemp;
  const schoolYearBegin = formatDateByPattern(new Date(schoolYearToShow?.date_begin ?? null), datePatterns.FULL_YEAR);
  const schoolYearEnd = formatDateByPattern(new Date(schoolYearToShow?.date_end ?? null), datePatterns.SHORT_YEAR);
  const {loading, data: diplomaQuestionData} = useQuery(diplomaQuestionDataBySchoolYearGql, {
    variables: {
      diplomaQuestionInput: {
        schoolYearId: studentStatusData?.school_year_id
      }
    },
    skip: !studentStatusData?.school_year_id,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && diplomaQuestionData && epic3665bug4429) {
      if (diplomaQuestionData?.getDiplomaQuestion) {
        const diplomaGrades = diplomaQuestionData?.getDiplomaQuestion?.grades;
        const grades = diplomaGrades?.split(",") ?? [];
        const currentGrade = student?.grade_levels?.at(-1);
        setDiplomaSeekOptionDefaultValue(grades.includes(currentGrade) ? "0" : void 0);
      }
    }
  }, [diplomaQuestionData, loading, student]);
  const handleChangeStudentStatus = (status2) => {
    if (status2 === StudentStatus.ACTIVE || status2 === StudentStatus.PENDING) {
      if (originStudentStatus === StudentStatus.WITHDRAWN) {
        setShowActiveModal(true);
      }
      setOriginalStudentStatus(studentStatusData.status);
      setStudentStatusData({...studentStatusData, ...{status: status2}});
    } else if (status2 === StudentStatus.WITHDRAWN) {
      if (originStudentStatus === StudentStatus.ACTIVE || originStudentStatus === StudentStatus.PENDING) {
        setShowWithdrawalModal(true);
      }
      setOriginalStudentStatus(studentStatusData.status);
      setStudentStatusData({...studentStatusData, ...{status: status2}});
    } else {
      setOriginalStudentStatus(status2);
      setStudentStatusData({...studentStatusData, ...{status: status2}});
    }
  };
  const handleChangeBirthDay = (value) => {
    if (value) {
      setStudentStatusData({...studentStatusData, ...{birth: value}});
    }
  };
  const handleSelectWithdrawOption = (withdrawOption) => {
    setIsChanged(true);
    setStudentStatusData({...studentStatusData, ...{withdrawOption}});
    setShowWithdrawalModal(false);
  };
  const handleSelectActiveOption = (activeOption) => {
    setIsChanged(true);
    setStudentStatusData({...studentStatusData, ...{activeOption}});
    setShowActiveModal(false);
  };
  const handleWithdrawCancel = () => {
    if (originalStudentStatus)
      setStudentStatusData({...studentStatusData, ...{status: originalStudentStatus}});
    setShowWithdrawalModal(false);
  };
  const handleActiveCancel = () => {
    if (originalStudentStatus)
      setStudentStatusData({...studentStatusData, ...{status: originalStudentStatus}});
    setShowActiveModal(false);
  };
  useEffect(() => {
    if (student) {
      setApplication(student?.applications?.find((item) => item.school_year_id === Number(currentSchoolYear?.school_year_id)) || student?.applications?.at(-1));
    }
    if (!epic2209Story5188) {
      const grades = schoolYearToShow?.grades?.replace(PRE_KINDERGARTEN_LABEL, PRE_KINDERGARTEN_NUM_VALUE.toString())?.replace(KINDERGARTEN_LABEL, KINDERGARTEN_NUM_VALUE.toString())?.split(",");
      const studentGradeLevel = student?.grade_levels?.at(-1);
      const currentGrade = calculateGradeNumber(studentGradeLevel?.grade_level, studentGradeLevel?.school_year?.date_begin, schoolYearToShow?.date_begin);
      if (grades)
        setIsIneligibleState(!grades?.includes(currentGrade.toString()));
    }
  }, [student, currentSchoolYear, schoolYearToShow]);
  const isIneligible2 = useMemo(() => {
    const studentGradeLevel = student?.grade_levels?.at(-1);
    return isInEligibleStudentStatus(schoolYearToShow?.grades, studentGradeLevel, schoolYearToShow?.date_begin);
  }, [student, schoolYearToShow]);
  const isIneligible = epic2209Story5188 ? isIneligible2 : isIneligibleState;
  useEffect(() => {
    if (specialEdOptions.length !== 0) {
      const specialEdDropdowns = [];
      specialEdOptions.map((item, index) => {
        specialEdDropdowns.push({
          label: item,
          value: index
        });
      });
      setSpecialEds(specialEdDropdowns);
    }
  }, [specialEdOptions]);
  useEffect(() => {
    if (studentStatusData?.date) {
      const updatedStatus = BaseStatuses.map((item) => {
        if (studentStatusData?.status === item.value || studentStatusData?.status === StudentStatus.REAPPLIED && item.value === StudentStatus.APPLIED) {
          return {
            ...item,
            label: `${studentStatusData?.status === StudentStatus.REAPPLIED ? "Applied (re-apply)" : item.label} ${formatDateByPattern(new Date(studentStatusData?.date), datePatterns.ZMONTH_ZDAY_4DYEAR)}`,
            value: studentStatusData.status === StudentStatus.REAPPLIED ? StudentStatus.REAPPLIED : item.value
          };
        }
        return item;
      });
      setStatus(updatedStatus);
    }
    if (studentStatusData.status === StudentStatus.WITHDRAWN && studentStatusData.withdrawOption && studentStatusData.withdrawOption > 0) {
      const updatedStatus = status.map((status2) => {
        if (status2.value === StudentStatus.WITHDRAWN) {
          return {...status2, label: `Withdrawn (${formatDateByPattern(new Date(), datePatterns.ZMONTH_ZDAY_4DYEAR)})`};
        }
        return status2;
      });
      setStatus(updatedStatus);
    }
    if (studentStatusData.status === StudentStatus.ACTIVE && studentStatusData.activeOption) {
      const updatedStatus = status.map((status2) => {
        if (status2.value === StudentStatus.ACTIVE) {
          return {...status2, label: `Active (${formatDateByPattern(new Date(), datePatterns.ZMONTH_ZDAY_4DYEAR)})`};
        }
        return status2;
      });
      setStatus(updatedStatus);
    }
    if ([StudentStatus.COMPLETED, StudentStatus.GRADUATED].includes(studentStatusData.status)) {
      setStatus([
        {
          label: `${studentStatusData.status === StudentStatus.COMPLETED ? StudentStatusLabel.COMPLETED : StudentStatusLabel.GRADUATED} (${formatDateByPattern(new Date(studentStatusData?.date), datePatterns.ZMONTH_ZDAY_4DYEAR)})`,
          value: studentStatusData.status
        }
      ]);
    }
  }, [studentStatusData]);
  const onRemoveWithdrawalRequest = async () => {
    setShowConfirmModal(true);
  };
  const getSelectClassName = (studentStatusData2, withdrawalStatus2, selectClasses2) => {
    const isWithdrawn = studentStatusData2?.status === StudentStatus.WITHDRAWN;
    const isRequested = withdrawalStatus2 === StudentStatusLabel.REQUESTED;
    if (isWithdrawn && isRequested) {
      return selectClasses2.yellowBackgroundSelect;
    } else if (isWithdrawn) {
      return selectClasses2.withdrawBackgroundSelect;
    } else if (isRequested) {
      return selectClasses2.yellowBackgroundSelect;
    } else {
      return selectClasses2.backgroundSelect;
    }
  };
  useEffect(() => {
    if (diplomaOptions && schoolYearToShow?.diploma_seeking) {
      setDiplomaSeeking(diplomaAnswer);
    }
  }, [diplomaOptions, diplomaAnswer]);
  const subtitleText = useMemo(() => {
    if (!isIneligible && application && schoolYearToShow) {
      if (application.school_year_id === schoolYearToShow?.school_year_id) {
        return `${schoolYearBegin} - ${schoolYearEnd} ${application.midyear_application ? "Mid-year Status" : "Status"}`;
      } else {
        return `${schoolYearBegin} - ${schoolYearEnd} Status`;
      }
    }
    return "Status";
  }, [isIneligible, application, schoolYearToShow]);
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      marginTop: "24px"
    }
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 4,
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    sx: {paddingRight: "16px"}
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", alignItems: "flex-start"}
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    sx: {marginRight: "16px", marginBottom: "5px"}
  }, subtitleText), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Select, {
    className: isIneligible ? selectClasses.backgroundSelect : getSelectClassName(studentStatusData, withdrawalStatus?.status, selectClasses),
    IconComponent: KeyboardArrowDown,
    inputProps: {
      classes: {
        icon: selectClasses.selectIcon
      }
    },
    value: isIneligible ? StudentStatus.INELIGIBLE : Number(studentStatusData.status),
    onChange: (e) => {
      if (!isIneligible) {
        handleChangeStudentStatus(e.target.value);
      }
    }
  }, isIneligible ? /* @__PURE__ */ React.createElement(MenuItem, {
    key: StudentStatus.INELIGIBLE,
    value: StudentStatus.INELIGIBLE,
    sx: {height: "35px"},
    style: {
      paddingTop: 0,
      paddingBottom: 0
    }
  }, StudentStatusLabel.INELIGIBLE) : status.map((item) => /* @__PURE__ */ React.createElement(MenuItem, {
    key: item.value,
    value: item.value,
    sx: {height: "35px"},
    style: {
      paddingTop: 0,
      paddingBottom: 0
    }
  }, item.label))), withdrawalStatus?.status === "Requested" && /* @__PURE__ */ React.createElement(Box, {
    onClick: () => onRemoveWithdrawalRequest()
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {color: MthColor.MTHBLUE, my: "5px", cursor: "pointer"},
    textAlign: "center"
  }, "Remove Withdraw Request")))), /* @__PURE__ */ React.createElement(Box, {
    onClick: () => setShowDetails(!showDetails)
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {textDecoration: "underline", color: MthColor.MTHBLUE, cursor: "pointer"}
  }, showDetails ? "Hide" : "View", " Details")), showWithdrawalModal && /* @__PURE__ */ React.createElement(WithdrawModal, {
    title: "Withdraw",
    description: "How would you like to proceed with this withdraw?",
    confirmStr: "Withdraw",
    cancelStr: "Cancel",
    onWithdraw: handleSelectWithdrawOption,
    onClose: () => handleWithdrawCancel()
  }), showActiveModal && /* @__PURE__ */ React.createElement(ActiveModal, {
    title: "Reinstate",
    description: "How would you like to proceed with reinstating this student?",
    confirmStr: "Reinstate",
    cancelStr: "Cancel",
    onActive: handleSelectActiveOption,
    onClose: () => handleActiveCancel()
  }), showConfirmModal && /* @__PURE__ */ React.createElement(CustomConfirmModal, {
    header: "Remove Request",
    content: "Are you sure you want to remove this Withdraw Request?",
    confirmBtnTitle: "Delete",
    handleConfirmModalChange: (isOk) => {
      if (isOk) {
        setWithdrawalStatus(void 0);
        setStudentStatusData({
          ...studentStatusData,
          ...{activeOption: ActivateOption.DELETE_WITHDRAWAL_FORM}
        });
      }
      setShowConfirmModal(false);
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 4
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", alignItems: "center"}
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    textAlign: "left",
    fontWeight: "700",
    sx: {marginRight: "30px", marginBottom: "5px"}
  }, "Special Ed"), /* @__PURE__ */ React.createElement(Select, {
    className: selectClasses.backgroundSelect,
    IconComponent: KeyboardArrowDown,
    inputProps: {
      classes: {
        icon: selectClasses.selectIcon
      }
    },
    value: Number(studentStatusData.special_ed ?? 0),
    onChange: (e) => {
      setSpecialEd(Number(e.target.value));
      setStudentStatusData({...studentStatusData, ...{special_ed: Number(e.target.value)}});
    }
  }, specialEds.map((item) => /* @__PURE__ */ React.createElement(MenuItem, {
    key: item.value,
    value: item.value
  }, item.label))))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 4
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", alignItems: "center"}
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    textAlign: "left",
    fontWeight: "700",
    sx: {marginRight: "30px", marginBottom: "5px"}
  }, "Diploma Seeking"), /* @__PURE__ */ React.createElement(DropDown, {
    sx: {
      padding: "0px 20px 4px 10px",
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      height: "29px",
      borderRadius: "8px",
      textAlign: "center",
      "& .MuiSelect-select": {
        color: MthColor.WHITE,
        fontWeight: 500,
        fontSize: "14px"
      },
      "& .MuiSvgIcon-root": {
        color: `${MthColor.WHITE} !important`
      }
    },
    testId: testIDs.diplomaSeekingDropdown,
    dropDownItems: DiplomaSeekingOptions,
    defaultValue: `${diplomaSeeking ?? (epic3665bug4429 && (diplomaSeekOptionDefaultValue ?? EMPTY_STRING))}`,
    borderNone: true,
    setParentValue: (val) => {
      setDiplomaSeeking(isNaN(Number(val)) ? null : Number(val));
      setStudentStatusData({
        ...studentStatusData,
        ...{diploma_seeking: isNaN(Number(val)) ? void 0 : Number(val)}
      });
    }
  }))), showDetails && /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.content
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.formRow
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: classes.formLabel,
    fontWeight: "500"
  }, "Application", /* @__PURE__ */ React.createElement(Box, {
    sx: classes.labelAfter
  })), /* @__PURE__ */ React.createElement(Subtitle, {
    sx: {...classes.formValue},
    fontWeight: "500"
  }, application?.status, SPACE, application?.status === ApplicationStatus.ACCEPTED ? formatDateByPattern(utcToTimezoned(application.date_accepted, timezone), datePatterns.ZMONTH_ZDAY_4DYEAR) : formatDateByPattern(application?.date_submitted ? utcToTimezoned(application.date_submitted, timezone) : new Date(), datePatterns.ZMONTH_ZDAY_4DYEAR))), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.formRow
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: classes.formLabel,
    fontWeight: "500"
  }, "Date of Birth", /* @__PURE__ */ React.createElement(Box, {
    sx: classes.labelAfter
  })), !editingDOB ? /* @__PURE__ */ React.createElement(Subtitle, {
    sx: {...classes.formValue, cursor: "pointer"},
    fontWeight: "500",
    onClick: () => setEditingDOB(true)
  }, showDate(studentStatusData?.birth), studentStatusData?.birth && ` (${calcAge(studentStatusData?.birth)})`) : /* @__PURE__ */ React.createElement(Box, {
    sx: {paddingX: "30px"}
  }, /* @__PURE__ */ React.createElement(MthDatePicker, {
    date: studentStatusData?.birth?.includes("T") ? studentStatusData?.birth?.split("T")[0] : studentStatusData?.birth,
    label: "Date Of Birth",
    handleChange: handleChangeBirthDay
  }))), schoolYears.map((schoolYearItem, idx) => /* @__PURE__ */ React.createElement(Box, {
    sx: classes.formRow,
    key: idx
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: classes.formLabel,
    fontWeight: "500"
  }, `${formatDateByPattern(new Date(schoolYearItem.date_begin), datePatterns.FULL_YEAR)}-${formatDateByPattern(new Date(schoolYearItem.date_end), datePatterns.SHORT_YEAR)} ${schoolYearItem.midyear_application ? "Mid-year" : ""}`, /* @__PURE__ */ React.createElement(Box, {
    sx: classes.labelAfter
  })), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.formRow
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: classes.formValue,
    fontWeight: "500"
  }, gradeTextByStatus(student, schoolYearItem.school_year_id), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.labelAfter
  }))), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.formRow
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: classes.formValue,
    fontWeight: "500"
  }, getStatusText(student, schoolYearItem.school_year_id), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.labelAfter
  }))), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.formRow
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: classes.formValue,
    fontWeight: "500"
  }, getSoeByYear(student, schoolYearItem.school_year_id), /* @__PURE__ */ React.createElement(Box, {
    sx: classes.labelAfter
  })))))))));
};
