import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import dayjs from "../../../_snowpack/pkg/dayjs.js";
import {sortBy} from "../../../_snowpack/pkg/lodash.js";
import {getActiveSchoolYearsByRegionId} from "../../../graphql/queries/school-year.js";
export const useActiveSchoolYearsByRegionId = (regionId) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const {loading, data, error} = useQuery(getActiveSchoolYearsByRegionId, {
    variables: {
      regionId
    },
    skip: !regionId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data?.getActiveSchoolYears) {
      const schoolYearsArray = [];
      data.getActiveSchoolYears.map((item) => {
        if (item.MainyearApplicatable) {
          schoolYearsArray.push({
            label: `${dayjs(item.date_begin).format("YYYY")}-${dayjs(item.date_end).format("YY")}`,
            value: item.school_year_id
          });
        }
        if (item.MidyearApplicatable) {
          schoolYearsArray.push({
            label: `${dayjs(item.date_begin).format("YYYY")}-${dayjs(item.date_end).format("YY")} Mid-year Program`,
            value: `${item.school_year_id}-mid`
          });
        }
      });
      setDropdownItems(sortBy(schoolYearsArray, "label"));
      setSchoolYears(data.getActiveSchoolYears);
    }
  }, [loading, data]);
  return {loading, schoolYears, dropdownItems, error};
};
