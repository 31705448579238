export const ENABLE_DISABLE_OPTIONS = [
  {
    label: "Enabled",
    value: "true"
  },
  {
    label: "Disabled",
    value: "false"
  }
];
