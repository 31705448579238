import dayjs from "../../_snowpack/pkg/dayjs.js";
import {CLIENT_ERROR_RESPONSES} from "../enums/index.js";
import {S3_UPLOAD_URL} from "../envs/s3-upload-url.js";
export const uploadFile = async (file, category, region, studentId) => {
  try {
    if (file) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("category", category);
      bodyFormData.append("region", region);
      bodyFormData.append("year", dayjs().format("YYYY"));
      if (studentId)
        bodyFormData.append("studentId", `${studentId}`);
      const response = await fetch(S3_UPLOAD_URL, {
        method: "POST",
        body: bodyFormData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWT")}`
        }
      });
      if ([CLIENT_ERROR_RESPONSES.CONFLICT, CLIENT_ERROR_RESPONSES.UNAUTHORIZED].includes(response.status)) {
        const result2 = await response.json();
        return {
          success: false,
          errors: [result2?.message],
          errorCodes: [response.status]
        };
      }
      const result = await response.json();
      return result;
    }
    return {
      success: false,
      errors: ["Invalid File"]
    };
  } catch (error) {
    return {
      success: false,
      errors: ["Invalid File"]
    };
  }
};
