import React from "../../../../_snowpack/pkg/react.js";
import ErrorOutlineIcon from "../../../../_snowpack/pkg/@mui/icons-material/ErrorOutline.js";
import {Box, Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import parse from "../../../../_snowpack/pkg/html-react-parser.js";
import {MthModal} from "../../../../components/MthModal/MthModal.js";
import {MthTitle} from "../../../enums/index.js";
export const AnnouncementPopupModal = ({announcementPopup, onClose}) => {
  return /* @__PURE__ */ React.createElement(MthModal, {
    onConfirm: onClose,
    onClose,
    open: true,
    width: 400,
    confirmStr: MthTitle.CLOSE,
    showBtnCancel: false
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, {
    textAlign: "center"
  }, /* @__PURE__ */ React.createElement(Typography, {
    fontSize: 20,
    fontWeight: 700
  }, announcementPopup?.title), /* @__PURE__ */ React.createElement(ErrorOutlineIcon, null)), /* @__PURE__ */ React.createElement(Typography, {
    fontSize: 14,
    fontWeight: 600
  }, parse(announcementPopup?.body))));
};
