import React, {useState} from "../../_snowpack/pkg/react.js";
import {Modal, Box} from "../../_snowpack/pkg/@mui/material.js";
import {MthColor, MthTitle, RoleLabels} from "../../core/enums/index.js";
import {CustomModal} from "../../screens/Admin/SiteManagement/EnrollmentSetting/components/CustomModal/CustomModals.js";
import {UserProfile} from "../../screens/Admin/UserProfile/UserProfile.js";
import {ProfileContext} from "./ProfileContext.js";
export const ProfileProvider = ({children}) => {
  const [store, setStore] = useState(false);
  const [open, setOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [data, setData] = useState();
  const [refetch, setRefetch] = useState(() => () => {
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const profileContext = React.useMemo(() => ({
    store,
    setStore,
    hideModal: () => {
    },
    showModal: (data2, refetch2 = () => () => {
    }) => {
      setData(data2);
      setRefetch(() => () => refetch2());
      setOpen(true);
    }
  }), [store]);
  const handelClose = (value) => {
    if (value) {
      setOpen(false);
      setStore(false);
      setIsChanged(false);
      refetch();
    } else if (isChanged) {
      setShowConfirmModal(true);
    } else {
      setOpen(false);
      setStore(false);
      setIsChanged(false);
      refetch();
    }
  };
  return /* @__PURE__ */ React.createElement(ProfileContext.Provider, {
    value: profileContext
  }, open && /* @__PURE__ */ React.createElement(Modal, {
    open: true,
    onClose: () => handelClose(false),
    "aria-labelledby": "modal-modal-title",
    "aria-describedby": "modal-modal-description"
  }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(UserProfile, {
    handleClose: handelClose,
    role: data?.role,
    student_id: data?.student_id,
    parent_id: data?.role === RoleLabels.PARENT ? data?.parent_id : data?.parent.parent_id,
    setIsChanged
  }))), showConfirmModal && /* @__PURE__ */ React.createElement(CustomModal, {
    title: MthTitle.UNSAVED_TITLE,
    description: MthTitle.UNSAVED_DESCRIPTION,
    cancelStr: "Cancel",
    confirmStr: "Yes",
    backgroundColor: MthColor.WHITE,
    onClose: () => {
      setShowConfirmModal(false);
    },
    onConfirm: () => {
      setShowConfirmModal(false);
      setIsChanged(false);
      setOpen(false);
      setStore(false);
    }
  }), children);
};
