import {_ as _extends} from "./extends-1379b0c5.js";
import {_ as _objectWithoutPropertiesLoose} from "./objectWithoutPropertiesLoose-fe69c3b9.js";
import {r as react} from "./index-c6c556cc.js";
import {u as useUtils, a as useDefaultDates, p as parsePickerInputValue, P as PickersToolbar, f as useDateValidation, d as usePickerState, M as MobileWrapper, e as PureDateInput, C as CalendarOrClockPicker} from "./PureDateInput-cf36b7d8.js";
import {u as useThemeProps} from "./useThemeProps-58507f80.js";
import {j as jsxRuntime} from "./jsx-runtime-c4f7c71f.js";
import {g as generateUtilityClasses} from "./generateUtilityClasses-f1d2e319.js";
import {s as styled} from "./styled-d39d6e04.js";
import {T as Typography} from "./Typography-89a03a72.js";
const isYearOnlyView = (views) => views.length === 1 && views[0] === "year";
const isYearAndMonthViews = (views) => views.length === 2 && views.indexOf("month") !== -1 && views.indexOf("year") !== -1;
const getFormatAndMaskByViews = (views, utils) => {
  if (isYearOnlyView(views)) {
    return {
      mask: "____",
      inputFormat: utils.formats.year
    };
  }
  if (isYearAndMonthViews(views)) {
    return {
      disableMaskedInput: true,
      inputFormat: utils.formats.monthAndYear
    };
  }
  return {
    mask: "__/__/____",
    inputFormat: utils.formats.keyboardDate
  };
};
function useDatePickerDefaultizedProps(props, name) {
  var _themeProps$views;
  const utils = useUtils();
  const defaultDates = useDefaultDates();
  const themeProps = useThemeProps({
    props,
    name
  });
  const views = (_themeProps$views = themeProps.views) != null ? _themeProps$views : ["year", "day"];
  return _extends({
    openTo: "day",
    minDate: defaultDates.minDate,
    maxDate: defaultDates.maxDate
  }, getFormatAndMaskByViews(views, utils), themeProps, {
    views
  });
}
const datePickerValueManager = {
  emptyValue: null,
  getTodayValue: (utils) => utils.date(),
  parseInput: parsePickerInputValue,
  areValuesEqual: (utils, a, b) => utils.isEqual(a, b)
};
const _excluded$1 = ["parsedValue", "isLandscape", "isMobileKeyboardViewOpen", "onChange", "toggleMobileKeyboardView", "toolbarFormat", "toolbarPlaceholder", "toolbarTitle", "views"];
const classes = generateUtilityClasses("PrivateDatePickerToolbar", ["penIcon"]);
const DatePickerToolbarRoot = styled(PickersToolbar)({
  [`& .${classes.penIcon}`]: {
    position: "relative",
    top: 4
  }
});
const DatePickerToolbarTitle = styled(Typography)(({
  ownerState
}) => _extends({}, ownerState.isLandscape && {
  margin: "auto 16px auto auto"
}));
const DatePickerToolbar = /* @__PURE__ */ react.forwardRef(function DatePickerToolbar2(props, ref) {
  const {
    parsedValue,
    isLandscape,
    isMobileKeyboardViewOpen,
    toggleMobileKeyboardView,
    toolbarFormat,
    toolbarPlaceholder = "\u2013\u2013",
    toolbarTitle = "Select date",
    views
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$1);
  const utils = useUtils();
  const dateText = react.useMemo(() => {
    if (!parsedValue) {
      return toolbarPlaceholder;
    }
    if (toolbarFormat) {
      return utils.formatByString(parsedValue, toolbarFormat);
    }
    if (isYearOnlyView(views)) {
      return utils.format(parsedValue, "year");
    }
    if (isYearAndMonthViews(views)) {
      return utils.format(parsedValue, "month");
    }
    return /en/.test(utils.getCurrentLocaleCode()) ? utils.format(parsedValue, "normalDateWithWeekday") : utils.format(parsedValue, "normalDate");
  }, [parsedValue, toolbarFormat, toolbarPlaceholder, utils, views]);
  const ownerState = props;
  return /* @__PURE__ */ jsxRuntime.jsx(DatePickerToolbarRoot, _extends({
    ref,
    toolbarTitle,
    isMobileKeyboardViewOpen,
    toggleMobileKeyboardView,
    isLandscape,
    penIconClassName: classes.penIcon,
    ownerState
  }, other, {
    children: /* @__PURE__ */ jsxRuntime.jsx(DatePickerToolbarTitle, {
      variant: "h4",
      align: isLandscape ? "left" : "center",
      ownerState,
      children: dateText
    })
  }));
});
const _excluded = ["ToolbarComponent", "value", "onChange"];
const MobileDatePicker = /* @__PURE__ */ react.forwardRef(function MobileDatePicker2(inProps, ref) {
  const props = useDatePickerDefaultizedProps(inProps, "MuiMobileDatePicker");
  const validationError = useDateValidation(props) !== null;
  const {
    pickerProps,
    inputProps,
    wrapperProps
  } = usePickerState(props, datePickerValueManager);
  const {
    ToolbarComponent = DatePickerToolbar
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded);
  const DateInputProps = _extends({}, inputProps, other, {
    ref,
    validationError
  });
  return /* @__PURE__ */ jsxRuntime.jsx(MobileWrapper, _extends({}, other, wrapperProps, {
    DateInputProps,
    PureDateInputComponent: PureDateInput,
    children: /* @__PURE__ */ jsxRuntime.jsx(CalendarOrClockPicker, _extends({}, pickerProps, {
      autoFocus: true,
      toolbarTitle: props.label || props.toolbarTitle,
      ToolbarComponent,
      DateInputProps
    }, other))
  }));
});
export {DatePickerToolbar as D, MobileDatePicker as M, datePickerValueManager as d, useDatePickerDefaultizedProps as u};
