import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getRegion} from "../../../graphql/queries/region.js";
export const useRegionByRegionId = (regionId) => {
  const [region, setRegion] = useState();
  const {loading, data, error} = useQuery(getRegion, {
    variables: {
      id: regionId
    },
    skip: !regionId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (data?.region) {
      setRegion(data?.region);
    }
  }, [loading, data]);
  return {loading, region, error};
};
