import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {GetNextSchoolYearByRegionId} from "../../components/announcements/services.js";
export const useNextSchoolYearByRegionId = (regionId) => {
  const {loading, data, error, refetch} = useQuery(GetNextSchoolYearByRegionId, {
    variables: {
      regionId
    },
    skip: !regionId,
    fetchPolicy: "cache-and-network"
  });
  return {loading, data: data?.nextSchoolYear, error, refetch};
};
