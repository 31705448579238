import {createTheme} from "../../../_snowpack/pkg/@mui/material/styles.js";
import {MthColor} from "../../enums/color.enum.js";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      dark: "#e6e6e6",
      contrastText: "#fff"
    },
    secondary: {
      main: MthColor.WHITE,
      dark: "#fff",
      contrastText: "#000"
    },
    warning: {
      main: MthColor.LIGHTGRAY,
      dark: MthColor.SYSTEM_08,
      contrastText: "#000"
    },
    error: {
      main: MthColor.RED,
      contrastText: "#fff"
    },
    divider: "#D7D6D5",
    background: {
      paper: "#fff",
      default: "#fafafa"
    }
  },
  typography: {
    fontFamily: "VisbyCF",
    htmlFontSize: 16,
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& .fw-700": {
            fontWeight: 700
          },
          "& .w-28": {
            width: "112px"
          },
          "& .w-31": {
            width: "124px"
          },
          "& .w-37": {
            width: "148px"
          },
          "& .w-75": {
            width: "300px"
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0
          },
          "& input[type=number]": {
            MozAppearance: "none",
            margin: 0
          },
          "& .bg-gradient": {
            background: MthColor.BUTTON_LINEAR_GRADIENT
          },
          "& .bg-gradient-dark": {
            background: MthColor.BUTTON_LINEAR_GRADIENT_DARK
          },
          "& .rounded-full": {
            borderRadius: "999px"
          }
        },
        a: {
          color: MthColor.VORTEX,
          textDecoration: "underline"
        },
        ".rdw-editor-toolbar a": {
          color: "inherit"
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: ({ownerState: {max}}) => ({
          ...[...Array(max)].reduce((result, _curr, index) => ({
            ...result,
            [`& > .MuiAvatar-root:nth-child(${index + 1})`]: {
              zIndex: (max || 100) - index
            }
          }), {})
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0 0 0 1px"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.MthFormField": {
            "& .MuiInputLabel-root:not(.Mui-error):not(.Mui-disabled)": {
              color: "#333333"
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset, &.Mui-focused fieldset": {
                borderWidth: "1px"
              },
              "&:not(.Mui-error):not(.Mui-disabled) fieldset, &.Mui-focused:not(.Mui-error):not(.Mui-disabled) fieldset": {
                borderColor: "#333333"
              }
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MthFormField": {
            "& .MuiInputLabel-root:not(.Mui-error):not(.Mui-disabled)": {
              color: "#333333"
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset, &.Mui-focused fieldset": {
                borderWidth: "1px"
              },
              "&:not(.Mui-error):not(.Mui-disabled) fieldset, &.Mui-focused:not(.Mui-error):not(.Mui-disabled) fieldset": {
                borderColor: "#333333"
              }
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "rgba(200, 200, 200, 0.6)"
          },
          "&:hover": {
            filter: "brightness(0.9)"
          }
        }
      }
    }
  }
});
