import {c as createCommonjsModule} from "./common/_commonjsHelpers-53bde9c7.js";
import {f as getApolloContext, c as useApolloClient, v as verifyDocumentType, D as DocumentType, h as equal, A as ApolloClient, m as mergeOptions, O as ObservableQuery, N as NetworkStatus, i as isApolloError, j as ApolloError, k as ApolloCache, I as InMemoryCache, M as MissingFieldError, l as defaultDataIdFromObject, n as makeVar, o as fromError, t as throwServerError, g as gql, r as resetCaches, p as disableFragmentWarnings, q as enableExperimentalFragmentVariables, s as disableExperimentalFragmentVariables, e as concat, w as execute, x as parseAndCheckHttpResponse, y as serializeFetchParameter, z as fallbackHttpConfig, B as defaultPrinter, C as selectHttpOptionsAndBody, E as selectHttpOptionsAndBodyInternal, F as checkFetcher, G as createSignalIfSupported, J as selectURI, K as createHttpLink, H as HttpLink, L as rewriteURIForGET, d as ApolloProvider, P as operationName, Q as parser, u as useQuery, a as useLazyQuery, b as useMutation} from "./common/useMutation-03f1e1f5.js";
import {b as invariant, O as Observable, A as ApolloLink, s as isReference, u as makeReference, H as setVerbosity} from "./common/ApolloLink-246f681c.js";
import {r as react} from "./common/index-c6c556cc.js";
import "./common/GraphQLError-08a9053e.js";
import "./common/symbols-815ce58a.js";
import "./common/process-2545f00a.js";
function toPromise(observable) {
  var completed = false;
  return new Promise(function(resolve, reject) {
    observable.subscribe({
      next: function(data) {
        if (completed) {
          __DEV__ && invariant.warn("Promise Wrapper does not support multiple results from Observable");
        } else {
          completed = true;
          resolve(data);
        }
      },
      error: reject
    });
  });
}
function fromPromise(promise) {
  return new Observable(function(observer) {
    promise.then(function(value) {
      observer.next(value);
      observer.complete();
    }).catch(observer.error.bind(observer));
  });
}
var empty = ApolloLink.empty;
var from = ApolloLink.from;
var split = ApolloLink.split;
var Cache;
(function(Cache2) {
})(Cache || (Cache = {}));
var ApolloConsumer = function(props) {
  var ApolloContext = getApolloContext();
  return react.createElement(ApolloContext.Consumer, null, function(context) {
    __DEV__ ? invariant(context && context.client, 'Could not find "client" in the context of ApolloConsumer. Wrap the root component in an <ApolloProvider>.') : invariant(context && context.client, 25);
    return props.children(context.client);
  });
};
function useSubscription(subscription, options) {
  var client2 = useApolloClient(options === null || options === void 0 ? void 0 : options.client);
  verifyDocumentType(subscription, DocumentType.Subscription);
  var _a = react.useState({
    loading: !(options === null || options === void 0 ? void 0 : options.skip),
    error: void 0,
    data: void 0,
    variables: options === null || options === void 0 ? void 0 : options.variables
  }), result = _a[0], setResult = _a[1];
  var _b = react.useState(function() {
    if (options === null || options === void 0 ? void 0 : options.skip) {
      return null;
    }
    return client2.subscribe({
      query: subscription,
      variables: options === null || options === void 0 ? void 0 : options.variables,
      fetchPolicy: options === null || options === void 0 ? void 0 : options.fetchPolicy,
      context: options === null || options === void 0 ? void 0 : options.context
    });
  }), observable = _b[0], setObservable = _b[1];
  var canResetObservableRef = react.useRef(false);
  react.useEffect(function() {
    return function() {
      canResetObservableRef.current = true;
    };
  }, []);
  var ref = react.useRef({client: client2, subscription, options});
  react.useEffect(function() {
    var _a2, _b2, _c, _d;
    var shouldResubscribe = options === null || options === void 0 ? void 0 : options.shouldResubscribe;
    if (typeof shouldResubscribe === "function") {
      shouldResubscribe = !!shouldResubscribe(options);
    }
    if (options === null || options === void 0 ? void 0 : options.skip) {
      if (!(options === null || options === void 0 ? void 0 : options.skip) !== !((_a2 = ref.current.options) === null || _a2 === void 0 ? void 0 : _a2.skip) || canResetObservableRef.current) {
        setResult({
          loading: false,
          data: void 0,
          error: void 0,
          variables: options === null || options === void 0 ? void 0 : options.variables
        });
        setObservable(null);
        canResetObservableRef.current = false;
      }
    } else if (shouldResubscribe !== false && (client2 !== ref.current.client || subscription !== ref.current.subscription || (options === null || options === void 0 ? void 0 : options.fetchPolicy) !== ((_b2 = ref.current.options) === null || _b2 === void 0 ? void 0 : _b2.fetchPolicy) || !(options === null || options === void 0 ? void 0 : options.skip) !== !((_c = ref.current.options) === null || _c === void 0 ? void 0 : _c.skip) || !equal(options === null || options === void 0 ? void 0 : options.variables, (_d = ref.current.options) === null || _d === void 0 ? void 0 : _d.variables)) || canResetObservableRef.current) {
      setResult({
        loading: true,
        data: void 0,
        error: void 0,
        variables: options === null || options === void 0 ? void 0 : options.variables
      });
      setObservable(client2.subscribe({
        query: subscription,
        variables: options === null || options === void 0 ? void 0 : options.variables,
        fetchPolicy: options === null || options === void 0 ? void 0 : options.fetchPolicy,
        context: options === null || options === void 0 ? void 0 : options.context
      }));
      canResetObservableRef.current = false;
    }
    Object.assign(ref.current, {client: client2, subscription, options});
  }, [client2, subscription, options, canResetObservableRef.current]);
  react.useEffect(function() {
    if (!observable) {
      return;
    }
    var subscription2 = observable.subscribe({
      next: function(fetchResult) {
        var _a2, _b2;
        var result2 = {
          loading: false,
          data: fetchResult.data,
          error: void 0,
          variables: options === null || options === void 0 ? void 0 : options.variables
        };
        setResult(result2);
        (_b2 = (_a2 = ref.current.options) === null || _a2 === void 0 ? void 0 : _a2.onSubscriptionData) === null || _b2 === void 0 ? void 0 : _b2.call(_a2, {
          client: client2,
          subscriptionData: result2
        });
      },
      error: function(error) {
        setResult({
          loading: false,
          data: void 0,
          error,
          variables: options === null || options === void 0 ? void 0 : options.variables
        });
      },
      complete: function() {
        var _a2, _b2;
        (_b2 = (_a2 = ref.current.options) === null || _a2 === void 0 ? void 0 : _a2.onSubscriptionComplete) === null || _b2 === void 0 ? void 0 : _b2.call(_a2);
      }
    });
    return function() {
      subscription2.unsubscribe();
    };
  }, [observable]);
  return result;
}
function useReactiveVar(rv) {
  var value = rv();
  var setValue = react.useState(value)[1];
  react.useEffect(function() {
    var probablySameValue = rv();
    if (value !== probablySameValue) {
      setValue(probablySameValue);
    } else {
      return rv.onNextChange(setValue);
    }
  }, [value]);
  return value;
}
var client = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  ApolloClient,
  mergeOptions,
  ObservableQuery,
  get NetworkStatus() {
    return NetworkStatus;
  },
  isApolloError,
  ApolloError,
  get Cache() {
    return Cache;
  },
  ApolloCache,
  InMemoryCache,
  MissingFieldError,
  defaultDataIdFromObject,
  makeVar,
  fromError,
  toPromise,
  fromPromise,
  throwServerError,
  Observable,
  isReference,
  makeReference,
  get gql() {
    return gql;
  },
  resetCaches,
  disableFragmentWarnings,
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
  empty,
  from,
  split,
  concat,
  execute,
  ApolloLink,
  parseAndCheckHttpResponse,
  serializeFetchParameter,
  fallbackHttpConfig,
  defaultPrinter,
  selectHttpOptionsAndBody,
  selectHttpOptionsAndBodyInternal,
  checkFetcher,
  createSignalIfSupported,
  selectURI,
  createHttpLink,
  HttpLink,
  rewriteURIForGET,
  setLogVerbosity: setVerbosity,
  ApolloProvider,
  ApolloConsumer,
  getApolloContext,
  resetApolloContext: getApolloContext,
  get DocumentType() {
    return DocumentType;
  },
  operationName,
  parser,
  useQuery,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useSubscription,
  useReactiveVar
});
var dist = createCommonjsModule(function(module, exports) {
  var r, t = client, e = react;
  !function(r2) {
    r2.REQUEST = "REQUEST", r2.ERROR = "ERROR", r2.SUCCESS = "SUCCESS", r2.CANCEL = "CANCEL";
  }(r || (r = {}));
  var n = r, o = function(r2) {
    function e2(t2) {
      r2.call(this), this.dispatcher = t2;
    }
    return r2 && (e2.__proto__ = r2), (e2.prototype = Object.create(r2 && r2.prototype)).constructor = e2, e2.prototype.request = function(r3, e3) {
      var o2 = this;
      this.dispatcher.dispatch({type: n.REQUEST, payload: {operation: r3}});
      var i2 = e3(r3);
      return new t.Observable(function(t2) {
        var e4 = true, u2 = i2.subscribe({next: function(i3) {
          e4 = false, o2.dispatcher.dispatch({type: n.SUCCESS, payload: {operation: r3, result: i3}}), t2.next(i3);
        }, error: function(i3) {
          e4 = false, o2.dispatcher.dispatch({type: n.ERROR, payload: {operation: r3, networkError: i3}}), t2.error(i3);
        }, complete: t2.complete.bind(t2)});
        return function() {
          e4 && o2.dispatcher.dispatch({type: n.CANCEL, payload: {operation: r3}}), u2 && u2.unsubscribe();
        };
      });
    }, e2;
  }(t.ApolloLink), i = function() {
    this.listeners = [];
  };
  function u(r2, t2, n2) {
    var o2 = e.useReducer(t2, n2), i2 = o2[0], u2 = o2[1];
    return e.useEffect(function() {
      var t3;
      function e2(r3) {
        t3 = requestAnimationFrame(function() {
          u2(r3);
        });
      }
      return r2.addListener(e2), function() {
        r2.removeListener(e2), cancelAnimationFrame(t3);
      };
    }, [r2]), i2;
  }
  i.prototype.addListener = function(r2) {
    this.listeners.push(r2);
  }, i.prototype.removeListener = function(r2) {
    this.listeners = this.listeners.filter(function(t2) {
      return t2 !== r2;
    });
  }, i.prototype.dispatch = function(r2) {
    this.listeners.forEach(function(t2) {
      t2(r2);
    });
  };
  var a = typeof window != "undefined" ? e.useLayoutEffect : e.useEffect;
  function s(r2, t2) {
    return r2.query.definitions.some(function(r3) {
      return r3.kind === "OperationDefinition" && r3.operation === t2;
    });
  }
  function c(r2) {
    return function(t2, e2) {
      if (t2 === void 0 && (t2 = 0), !s(e2.payload.operation, r2))
        return t2;
      switch (e2.type) {
        case n.REQUEST:
          return t2 + 1;
        case n.ERROR:
        case n.SUCCESS:
        case n.CANCEL:
          return Math.max(t2 - 1, 0);
      }
      return t2;
    };
  }
  function p(r2) {
    return function(t2, e2) {
      if (!s(e2.payload.operation, r2))
        return t2;
      switch (e2.type) {
        case n.REQUEST:
          return;
        case n.ERROR:
          var o2 = e2.payload;
          return {networkError: o2.networkError, operation: o2.operation};
        case n.SUCCESS:
          var i2 = e2.payload, u2 = i2.result;
          return u2 && u2.errors ? {graphQLErrors: u2.errors, response: u2, operation: i2.operation} : t2;
      }
      return t2;
    };
  }
  var f = c("query"), d = c("mutation"), l = p("query"), E = p("mutation"), y = {numPendingQueries: 0, numPendingMutations: 0, queryError: void 0, mutationError: void 0};
  function h(r2) {
    return r2.getContext().useApolloNetworkStatus !== false;
  }
  exports.ActionTypes = n, exports.createNetworkStatusNotifier = function() {
    var r2 = new i();
    return {link: new o(r2), useApolloNetworkStatus: function(t2) {
      return function(r3, t3) {
        t3 || (t3 = {});
        var n2, o2, i2 = (n2 = t3.shouldHandleOperation || h, o2 = e.useRef(function() {
          throw new Error("Function is called before it was assigned.");
        }), a(function() {
          o2.current = n2;
        }), e.useCallback(function() {
          for (var r4 = [], t4 = arguments.length; t4--; )
            r4[t4] = arguments[t4];
          return o2.current.apply(o2, r4);
        }, []));
        return u(r3, e.useMemo(function() {
          return function(r4, t4) {
            return i2(t4.payload.operation) ? function(r5, t5) {
              if (s(t5.payload.operation, "subscription"))
                return r5;
              var e2 = Object.assign({}, r5);
              return e2.numPendingQueries = f(e2.numPendingQueries, t5), e2.numPendingMutations = d(e2.numPendingMutations, t5), e2.queryError = l(e2.queryError, t5), e2.mutationError = E(e2.mutationError, t5), Object.keys(r5).some(function(t6) {
                return e2[t6] !== r5[t6];
              }) ? e2 : r5;
            }(r4, t4) : r4;
          };
        }, [i2]), y);
      }(r2, t2);
    }, useApolloNetworkStatusReducer: function(t2, e2) {
      return u(r2, t2, e2);
    }};
  };
});
var createNetworkStatusNotifier = dist.createNetworkStatusNotifier;
export {createNetworkStatusNotifier};
