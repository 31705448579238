import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {useHistory} from "../../_snowpack/pkg/react-router-dom.js";
import BackStackContext from "./BackStackContext.js";
export default function BackStackProvider(props) {
  const [paths, setPaths] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (history && !paths.length) {
      setPaths([history.location.pathname]);
    }
  }, [history, paths.length]);
  useEffect(() => {
    history?.listen((location, action) => {
      setPaths((paths2) => {
        switch (action) {
          case "POP":
            return paths2.slice(0, paths2.length - 1);
          case "PUSH":
            return [...paths2, location.pathname];
          case "REPLACE":
            return [...paths2.slice(0, paths2.length - 1), location.pathname];
          default:
            return paths2;
        }
      });
    });
  }, [history]);
  return /* @__PURE__ */ React.createElement(BackStackContext.Provider, {
    value: paths
  }, props.children);
}
