import {outlinedInputClasses} from "../../../../_snowpack/pkg/@mui/material.js";
import {MthColor} from "../../../../core/enums/index.js";
export const useStyles = {
  modalCard: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    paddingLeft: "28px",
    borderRadius: 2
  },
  close: {
    background: "black",
    borderRadius: 1,
    color: "white",
    cursor: "pointer"
  },
  errorOutline: {
    background: "#FAFAFA",
    borderRadius: 1,
    color: MthColor.BLACK,
    marginBottom: 12,
    height: 42,
    width: 42
  },
  content: {
    marginTop: "10px",
    paddingBottom: "20px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100% - 60px)",
    overflow: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      background: MthColor.WHITE
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  },
  submitButton: {
    position: "relative",
    top: "480px",
    marginLeft: "-50px",
    borderRadius: 10,
    width: "200px",
    marginBottom: 4
  },
  tableBorder: {
    borderTop: `1px solid ${MthColor.SYSTEM_12}`
  },
  textfield: {
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: MthColor.SYSTEM_07
    },
    marginY: 1,
    width: "100%"
  },
  deleteIcon: {
    cursor: "pointer",
    marginLeft: "3px",
    marginTop: "6px",
    "&:hover": {
      opacity: "0.9"
    }
  },
  editor: {
    border: `1px solid ${MthColor.MEDIUMGRAY}`,
    borderRadius: 1,
    marginBottom: "24px",
    "div.DraftEditor-editorContainer": {
      minHeight: "200px",
      maxHeight: "250px",
      overflow: "scroll",
      padding: "0 10px"
    }
  },
  toolBar: {
    borderBottom: `1px solid ${MthColor.MEDIUMGRAY}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 1
  },
  cancelButton: {
    borderRadius: 10,
    background: MthColor.SYSTEM_08,
    width: "200px",
    marginRight: 1
  },
  icon: {
    marginRight: 2,
    color: MthColor.SYSTEM_08,
    cursor: "pointer"
  },
  subject: {
    marginTop: 2
  },
  isActive: {
    color: "black"
  }
};
