import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getScheduleBuilder} from "../../../screens/Admin/Curriculum/services.js";
export const useScheduleBuilder = (regionId) => {
  const [scheduleBuilder, setScheduleBuilder] = useState();
  const {loading, data, error, refetch} = useQuery(getScheduleBuilder, {
    variables: {
      regionId
    },
    skip: !regionId,
    fetchPolicy: "cache-and-network"
  });
  useEffect(() => {
    if (data?.schoolyear_getcurrent?.ScheduleBuilder) {
      setScheduleBuilder(data.schoolyear_getcurrent.ScheduleBuilder);
    }
  }, [loading, data]);
  return {
    loading,
    scheduleBuilder,
    error,
    refetch
  };
};
