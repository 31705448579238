import axios from "../../../_snowpack/pkg/axios.js";
import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {SNOWPACK_PUBLIC_API_V3_URL} from "../../envs/index.js";
export const apiGet = async (apiName, controllerName, endpoint) => {
  try {
    const token = localStorage.getItem("JWT");
    if (isNil(token)) {
      throw "Token is empty";
    }
    const url = `${SNOWPACK_PUBLIC_API_V3_URL}/${apiName}/${controllerName}/${endpoint}`;
    return await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error("An error occured on GET request");
    console.error(`Api name: ${apiName} Controler name: ${controllerName}, endpoint: ${endpoint}`);
    console.error(error);
    throw error;
  }
};
export const apiPost = async (apiName, controllerName, endpoint, body) => {
  try {
    const token = localStorage.getItem("JWT");
    if (isNil(token)) {
      throw "Token is empty";
    }
    const url = `${SNOWPACK_PUBLIC_API_V3_URL}/${apiName}/${controllerName}/${endpoint}`;
    return await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error("An error occured on POST request");
    console.error(`Api name: ${apiName} Controler name: ${controllerName}, endpoint: ${endpoint}`);
    console.error(error);
    throw error;
  }
};
