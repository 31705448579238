import {c as createTheme} from "./createTheme-1192a460.js";
import {c as createBox} from "./createBox-0a59b43c.js";
import {C as ClassNameGenerator} from "./ClassNameGenerator-1fa9df9c.js";
const defaultTheme = createTheme();
const Box = createBox({
  defaultTheme,
  defaultClassName: "MuiBox-root",
  generateClassName: ClassNameGenerator.generate
});
var __pika_web_default_export_for_treeshaking__ = Box;
export {__pika_web_default_export_for_treeshaking__ as _};
