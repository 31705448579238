import {gql} from "../../../_snowpack/pkg/@apollo/client.js";
export const getEnrollmentPacketsDataTableQuery = gql`
  query EnrollmentPackets(
    $skip: Int
    $take: Int
    $search: String
    $timezoneOffsetStr: String
    $regionId: Int
    $sort: String
    $filters: [String!]
    $selectedYearId: Int!
  ) {
    packetsDataTable(
      skip: $skip
      take: $take
      search: $search
      timezoneOffsetStr: $timezoneOffsetStr
      region_id: $regionId
      sort: $sort
      filters: $filters
      selectedYearId: $selectedYearId
    ) {
      total
      results {
        packet_id
        date_submitted
        date_last_submitted
        deadline
        student_grade
        is_age_issue
        status
        studentFirstName
        studentLastName
        parentFirstName
        parentLastName
        reenrolled
        lastEmailDate
        midyearApplication
        schoolYearID
        updated_at
      }
    }
  }
`;
export const getEnrollmentPacketsQuery = gql`
  query EnrollmentPackets(
    $skip: Int
    $take: Int
    $search: String
    $timezoneOffsetStr: String
    $regionId: Int
    $sort: String
    $filters: [String!]
    $selectedYearId: Int!
  ) {
    packets(
      skip: $skip
      take: $take
      search: $search
      timezoneOffsetStr: $timezoneOffsetStr
      region_id: $regionId
      sort: $sort
      filters: $filters
      selectedYearId: $selectedYearId
    ) {
      total
      results {
        packet_id
        status
        exemption_form_date
        medical_exemption
        date_last_submitted
        date_submitted
        deadline
        admin_notes
        birth_place
        birth_country
        secondary_email
        secondary_phone
        signature_file_id
        signature_name
        race
        language
        photo_permission
        language_friends
        language_home
        language_home_child
        language_home_preferred
        dir_permission
        household_size
        household_income
        ferpa_agreement
        school_district
        last_school_address
        last_school
        reupload_files
        last_school_type
        worked_in_agriculture
        military
        hispanic
        secondary_contact_first
        secondary_contact_last
        is_age_issue
        missing_files
        special_ed
        packet_emails {
          subject
          created_at
          body
          from_email
        }
        meta
        student {
          status {
            status
          }
          reenrolled
          grade_level
          student_id
          special_ed
          grade_levels {
            grade_level
            school_year {
              date_begin
              date_end
            }
          }
          current_school_year_status {
            school_year_id
            midyear_application
          }
          applications {
            midyear_application
            school_year_id
            school_year {
              school_year_id
              schedule
              date_begin
              date_end
            }
          }
          parent {
            parent_id
            person {
              person_id
              preferred_first_name
              preferred_last_name
              first_name
              last_name
              middle_name
              email
              address {
                street
                street2
                city
                zip
                state
                county_id
                country_id
                school_district
              }
              user {
                user_id
                email
                userRegions {
                  regionDetail {
                    name
                  }
                }
              }
            }
            phone {
              number
            }
          }
          person {
            person_id
            preferred_first_name
            preferred_last_name
            first_name
            last_name
            middle_name
            email
            date_of_birth
            gender
            address {
              street
              street2
              city
              zip
              state
              county_id
              school_district
            }
          }
        }
        files {
          kind
          mth_file_id
        }
        reenroll
      }
    }
  }
`;
export const getSchoolYearSettingForPacketSubmit = gql`
  query Schoolyear($schoolYearId: ID!) {
    schoolyear(school_year_id: $schoolYearId) {
      schedule
    }
  }
`;
export const getEnrollmentPacketQuery = gql`
  query EnrollmentPacket($packetID: ID!) {
    packet(packet_id: $packetID) {
      packet_id
      status
      exemption_form_date
      medical_exemption
      date_last_submitted
      date_submitted
      date_accepted
      deadline
      admin_notes
      birth_place
      birth_country
      secondary_email
      secondary_phone
      signature_file_id
      signature_name
      race
      language
      photo_permission
      language_friends
      language_home
      language_home_child
      language_home_preferred
      dir_permission
      household_size
      household_income
      ferpa_agreement
      school_district
      last_school_address
      last_school
      reupload_files
      last_school_type
      worked_in_agriculture
      military
      hispanic
      secondary_contact_first
      secondary_contact_last
      is_age_issue
      missing_files
      special_ed
      packet_emails {
        subject
        created_at
        body
        from_email
      }
      meta
      student {
        status {
          status
          date_updated
        }
        reenrolled
        grade_level
        student_id
        special_ed
        grade_levels {
          grade_level
          school_year_id
          school_year {
            date_begin
            date_end
          }
        }
        current_school_year_status {
          school_year_id
          midyear_application
          date_begin
          date_end
        }
        applications {
          midyear_application
          school_year_id
          school_year {
            school_year_id
            schedule
            date_begin
            date_end
          }
        }
        parent {
          parent_id
          person {
            person_id
            preferred_first_name
            preferred_last_name
            first_name
            last_name
            middle_name
            email
            address {
              street
              street2
              city
              zip
              state
              county_id
              country_id
              school_district
            }
            user {
              user_id
              email
              userRegions {
                regionDetail {
                  name
                }
              }
            }
          }
          phone {
            number
            recieve_text
          }
        }
        person {
          person_id
          preferred_first_name
          preferred_last_name
          first_name
          last_name
          middle_name
          email
          date_of_birth
          gender
          address {
            street
            street2
            city
            zip
            state
            county_id
            school_district
          }
        }
      }
      files {
        kind
        mth_file_id
      }
      reenroll
      school_year_id
    }
  }
`;
export const getImmunizationSettings = gql`
  query ImmunizationSettings($where: FindImunizationSettingsInput) {
    immunizationSettings(where: $where) {
      results {
        id
        title
        date_created
        immunity_allowed
        consecutive_vaccine
      }
    }
  }
`;
export const getPacketFiles = gql`
  query PacketFiles($fileIds: String!) {
    packetFiles(file_ids: $fileIds) {
      results {
        file_id
        type
        name
        item1
        item2
        item3
        signedUrl
        is_new_upload_type
        year
        kind
      }
    }
  }
`;
export const deletePacketDocumentFileMutation = gql`
  mutation deletePacketDocumentFile($fileId: String!) {
    deletePacketDocumentFile(fileId: $fileId) {
      error
      message
    }
  }
`;
export const savePacketMutation = gql`
  mutation savePacket($enrollmentPacketInput: EnrollmentPacketInput!) {
    saveEnrollmentPacket(enrollmentPacketInput: $enrollmentPacketInput) {
      packet {
        admin_notes
        packet_id
        medical_exemption
        exemption_form_date
        status
        is_age_issue
        missing_files
        meta
        student_id
      }
    }
  }
`;
export const sendEmailMutation = gql`
  mutation SendEmail($emailInput: EmailInput!) {
    sendEmail(emailInput: $emailInput) {
      error
      message
    }
  }
`;
export const emailPacketMutation = gql`
  mutation EmailPacket($emailPacketInput: EmailPacketInput!) {
    emailPacket(emailPacketInput: $emailPacketInput) {
      packet_id
    }
  }
`;
export const deletePacketsMutation = gql`
  mutation DeletePackets($packetsActionInput: PacketsActionInput!) {
    deletePackets(packetsActionInput: $packetsActionInput) {
      packet_id
      status
    }
  }
`;
export const StudentImmunizationsQuery = gql`
  query StudentImmunizations($student_id: Int!, $school_year_id: Int!) {
    StudentImmunizations(student_id: $student_id, school_year_id: $school_year_id) {
      student_id
      value
      immunization_id
      school_year_id
      immunization {
        id
        title
        consecutive_vaccine
        consecutives
        immunity_allowed
        min_grade_level
        max_grade_level
        tooltip
        min_spacing_interval
        min_spacing_date
        max_spacing_interval
        max_spacing_date
        is_deleted
        school_year_id
      }
    }
  }
`;
export const updateCreateStudentImmunizationMutation = gql`
  mutation updateCreateStudentImmunization($input: [StudentImmunizationInput!]!) {
    updateCreateStudentImmunization(data: $input)
  }
`;
export const getSettingsQuery = gql`
  query getSettings($regionId: Int!) {
    immunizationEnabled(region_id: $regionId)
  }
`;
export const updateSettingsMutation = gql`
  mutation updateSettings($input: UpdateSettingsInput!) {
    updateImmunization(input: $input)
  }
`;
export const packetCountQuery = gql`
  query PacketCountByRegionId($regionId: ID, $schoolYearId: ID, $filters: [String]) {
    packetCountByRegionId(region_id: $regionId, school_year_id: $schoolYearId, filters: $filters) {
      error
      message
      results
    }
  }
`;
export const updateEnrollmentSchoolYearByIds = gql`
  mutation UpdateEnrollmentSchoolYearByIds($updateEnrollmentSchoolYearByIdsInput: UpdateSchoolYearPacketIdsInput!) {
    updateEnrollmentSchoolYearByIds(updateEnrollmentSchoolYearByIdsInput: $updateEnrollmentSchoolYearByIdsInput)
  }
`;
