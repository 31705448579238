import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {cloneDeep, groupBy, keyBy} from "../../../_snowpack/pkg/lodash.js";
import {COURSE_TYPE_ITEMS} from "../../constants/index.js";
import {CourseType} from "../../enums/index.js";
import {getStudentSchedulePeriodHistoriesQuery} from "../../../graphql/queries/schedule-period.js";
import {getIgnoreDiplomaStudentPeriodsQuery, getStudentProvidersQuery} from "../../../screens/Homeroom/Schedule/services.js";
import {makeProviderData} from "../useStudentSchedulePeriods/useStudentSchedulePeriods.js";
export const useStudentSchedulePeriodHistories = (student_id, school_year_id, isGradeFilter = true) => {
  const [scheduleDataHistory, setScheduleDataHistory] = useState([]);
  const {loading: loadingProviders, data: providersData} = useQuery(getStudentProvidersQuery, {
    variables: {schoolYearId: school_year_id},
    skip: !school_year_id,
    fetchPolicy: "network-only"
  });
  const {loading, data: periodsData} = useQuery(getIgnoreDiplomaStudentPeriodsQuery, {
    variables: {
      studentId: student_id,
      schoolYearId: school_year_id,
      isGradeFilter
    },
    skip: !student_id || !school_year_id,
    fetchPolicy: "network-only"
  });
  const {
    loading: studentSchedulePeriodHistoryLoading,
    data: studentSchedulePeriodHistoryData,
    refetch
  } = useQuery(getStudentSchedulePeriodHistoriesQuery, {
    variables: {
      schoolYearId: school_year_id,
      studentId: student_id
    },
    skip: !student_id || !school_year_id,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && periodsData?.studentPeriodsIgnoringDiploma && !loadingProviders && providersData.studentProviders) {
      const studentProviders = keyBy(providersData.studentProviders, "id");
      const {studentPeriodsIgnoringDiploma: studentPeriods} = periodsData;
      studentPeriods.map((period) => {
        period.Subjects?.map((subject) => {
          subject.Titles.concat(subject.AltTitles || []).map((title) => {
            title.Courses.concat(title.AltCourses).map((course) => course.Provider = studentProviders[course.provider_id]);
            title.Providers = makeProviderData(title.Courses, title.AltCourses);
            title.CourseTypes = COURSE_TYPE_ITEMS.filter((item) => item.value === CourseType.CUSTOM_BUILT && title.custom_built || item.value === CourseType.MTH_DIRECT && !!title.Providers?.length || item.value === CourseType.THIRD_PARTY_PROVIDER && title.third_party_provider);
          });
        });
      });
      const scheduleData = groupBy(studentPeriods, "period");
      const scheduleDataArray = [];
      const scheduleDataHistoryDataArray = [];
      for (const key in scheduleData) {
        scheduleDataArray.push({
          period: +key,
          Periods: scheduleData[key],
          filteredPeriods: scheduleData[key]
        });
      }
      if (!studentSchedulePeriodHistoryLoading && studentSchedulePeriodHistoryData?.schedulePeriodHistories) {
        const {schedulePeriodHistories} = studentSchedulePeriodHistoryData;
        schedulePeriodHistories.map((schedulePeriodHistory) => {
          if (scheduleDataHistoryDataArray.find((item) => item.scheduleHistoryId == schedulePeriodHistory.ScheduleHistoryId)) {
            scheduleDataHistoryDataArray.map((historyData) => {
              if (historyData.scheduleHistoryId == schedulePeriodHistory.ScheduleHistoryId) {
                historyData.schedulePeriodHistory?.push(schedulePeriodHistory);
              }
            });
          } else {
            scheduleDataHistoryDataArray.push({
              scheduleHistoryId: schedulePeriodHistory.ScheduleHistoryId,
              acceptedDate: schedulePeriodHistory.ScheduleHistory.date_accepted,
              scheduleData: cloneDeep(scheduleDataArray),
              schedulePeriodHistory: [schedulePeriodHistory],
              isExpand: false,
              isSecondSemester: schedulePeriodHistory.ScheduleHistory.is_second_semester
            });
          }
        });
        scheduleDataHistoryDataArray.map((historyData) => {
          historyData.scheduleData.map((item) => {
            const schedulePeriodHistory = historyData.schedulePeriodHistory?.find((x) => item.Periods.findIndex((period2) => period2.id === x.PeriodId) > -1);
            if (!schedulePeriodHistory)
              return;
            const period = item?.Periods?.find((periodItem) => periodItem?.id === schedulePeriodHistory?.PeriodId);
            if (period) {
              item.Period = period;
              item.schedulePeriodId = schedulePeriodHistory.schedule_period_history_id;
              item.schedulePeriodStatus = schedulePeriodHistory.status;
              if (schedulePeriodHistory.SubjectId)
                item.Subject = period.Subjects?.find((subject) => subject?.subject_id === schedulePeriodHistory.SubjectId);
              if (schedulePeriodHistory.TitleId)
                period.Subjects?.forEach((subject) => {
                  subject.Titles.concat(subject.AltTitles)?.map((title) => {
                    if (title.title_id === schedulePeriodHistory.TitleId)
                      item.Title = title;
                  });
                });
              if (schedulePeriodHistory.CourseId)
                period.Subjects?.forEach((subject) => {
                  subject.Titles.concat(subject.AltTitles)?.forEach((title) => {
                    title.Courses.concat(title.AltCourses)?.forEach((course) => {
                      if (course.id === schedulePeriodHistory.CourseId)
                        item.Course = course;
                    });
                  });
                });
              if (schedulePeriodHistory.course_type)
                item.CourseType = schedulePeriodHistory.course_type;
              if (schedulePeriodHistory.course_type === CourseType.CUSTOM_BUILT)
                item.CustomBuiltDescription = schedulePeriodHistory.custom_build_description;
              if (schedulePeriodHistory.course_type === CourseType.MTH_DIRECT && schedulePeriodHistory.osse_course_name)
                item.OnSiteSplitEnrollment = {
                  courseName: schedulePeriodHistory.osse_course_name,
                  districtSchool: schedulePeriodHistory.osse_district_school,
                  schoolDistrictName: schedulePeriodHistory.osse_school_district_name
                };
              if (schedulePeriodHistory.course_type === CourseType.THIRD_PARTY_PROVIDER)
                item.ThirdParty = {
                  providerName: schedulePeriodHistory.tp_provider_name,
                  courseName: schedulePeriodHistory.tp_course_name,
                  phoneNumber: schedulePeriodHistory.tp_phone_number,
                  specificCourseWebsite: schedulePeriodHistory.tp_specific_course_website,
                  additionalWebsite: schedulePeriodHistory.tp_additional_specific_course_website ? JSON.parse(schedulePeriodHistory.tp_additional_specific_course_website) : ""
                };
            }
          });
        });
      }
      setScheduleDataHistory(scheduleDataHistoryDataArray?.sort((a, b) => {
        return b.scheduleHistoryId - a.scheduleHistoryId;
      }));
    }
  }, [
    loading,
    periodsData,
    studentSchedulePeriodHistoryLoading,
    studentSchedulePeriodHistoryData,
    loadingProviders,
    providersData
  ]);
  return {
    scheduleDataHistory,
    setScheduleDataHistory,
    refetch
  };
};
