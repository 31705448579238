export const REIMBURSEMENT_TABLE_HEAD = {
  SUBMITTED: "Submitted",
  AMOUNT: "Amount",
  RB_STUDENT: "Student",
  GRADE: "Grade",
  RB_STATUS: "Status",
  PAID_ORDERED: "Paid/Ordered",
  CATEGORY: "Category",
  PERIOD: "Period",
  RB_TYPE: "Type",
  APPROVED_ORDERED_BY: "Approved By"
};
