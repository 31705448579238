import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {Box} from "../../_snowpack/pkg/@mui/material.js";
import {useFlag} from "../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {ContentState, EditorState, convertToRaw} from "../../_snowpack/pkg/draft-js.js";
import draftToHtml from "../../_snowpack/pkg/draftjs-to-html.js";
import htmlToDraft from "../../_snowpack/pkg/html-to-draftjs.js";
import {isFunction, isNil} from "../../_snowpack/pkg/lodash.js";
import Wysiwyg from "../../_snowpack/pkg/react-draft-wysiwyg.js";
import {
  ADMIN_OPTIONS,
  EMPTY_STRING,
  EPIC_1450_TASK_5422,
  EPIC_275_STORY_5395,
  openIframeRegex,
  openImgRegex,
  OPTIONS_TO_REMOVE
} from "../../core/constants/index.js";
import {MthColor, fontFamilyEnum} from "../../core/enums/index.js";
import {getOptions, handlePastedText} from "../../core/utils/index.js";
import {bulletEditorClasses} from "./styles.js";
const generateEditorState = (html) => {
  const htmlContent = openImgRegex.test(html) || openIframeRegex.test(html) ? `<p>${html}</p>` : html;
  const contentBlock = htmlToDraft(htmlContent || EMPTY_STRING);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  return EditorState.createWithContent(contentState);
};
const MthBulletEditor = ({
  testId,
  value,
  setValue,
  handleFocusLost,
  isEditedByExternal,
  error,
  height,
  maxHeight,
  isBlockEnd = true,
  wordBreak,
  readOnly = false,
  disableColorChangeReadOnly = false,
  whiteBackground = false,
  isOptionRemove = false,
  classStyle,
  hideToolbar = false
}) => {
  const [currentBlocks, setCurrentBlocks] = useState(0);
  const [editorState, setEditorState] = useState(generateEditorState(""));
  const [isEdited, setIsEdited] = useState(false);
  const epic275story5395 = useFlag(EPIC_275_STORY_5395);
  const epic_1450_task_5422 = useFlag(EPIC_1450_TASK_5422);
  const onContentStateChange = (state) => {
    try {
      if (currentBlocks !== 0 && currentBlocks < state?.blocks?.length && isBlockEnd) {
        const focusKey = editorState.getSelection().getFocusKey();
        if (focusKey) {
          const focusElement = document.querySelector(`div[data-offset-key="${focusKey}-0-0"]`);
          const wrapperElement = document.querySelector(".rdw-editor-main");
          const blockOffsetTop = focusElement?.offsetTop || 0;
          const blockHeight = focusElement?.clientHeight || 0;
          const scrollTop = wrapperElement?.scrollTop || 0;
          const wrapperHeight = wrapperElement?.clientHeight || 0;
          if (blockOffsetTop + blockHeight > scrollTop + wrapperHeight) {
            wrapperElement?.scroll({behavior: "smooth", top: blockOffsetTop + blockHeight - wrapperHeight});
          }
          const rect = focusElement.getBoundingClientRect();
          const wrapperRect = wrapperElement.getBoundingClientRect();
          if (rect.top + rect.height > window.innerHeight && wrapperRect.top + wrapperRect.height > window.innerHeight) {
            window.scrollTo(0, window.scrollY + Math.min(rect.top + rect.height, wrapperRect.top + wrapperRect.height) + 24 - window.innerHeight);
          } else {
            focusElement?.scrollIntoView({behavior: "smooth", block: "nearest"});
          }
        }
      }
      setCurrentBlocks(state?.blocks?.length);
      setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    } catch {
    }
  };
  const onEditorStateChange = (e) => {
    setIsEdited(true);
    setEditorState(e);
    setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  useEffect(() => {
    if (!isEdited)
      setEditorState(generateEditorState(value || ""));
  }, [value]);
  useEffect(() => {
    setEditorState(generateEditorState(value || ""));
  }, [isEditedByExternal]);
  let background;
  if (readOnly && epic_1450_task_5422) {
    background = {background: MthColor.LIGHTGRAY};
  } else if (whiteBackground) {
    background = {background: MthColor.WHITE};
  }
  return /* @__PURE__ */ React.createElement(Box, {
    "data-testid": testId,
    sx: {
      ...classStyle ? classStyle : null,
      ...bulletEditorClasses.editor,
      ...error && bulletEditorClasses.editorInvalid,
      "div.rdw-editor-main": {
        minHeight: height ? height : "300px",
        maxHeight: maxHeight ? maxHeight : "350px",
        overflow: "auto",
        wordBreak: wordBreak ? wordBreak : "break-word",
        px: "10px",
        ".public-DraftStyleDefault-block": {
          margin: 0
        },
        ...epic_1450_task_5422 ? null : {color: !disableColorChangeReadOnly && readOnly ? MthColor.DARKGRAY : "initial"},
        ...!isNil(background) ? background : null,
        ...readOnly && epic_1450_task_5422 ? {cursor: "not-allowed"} : null
      },
      marginY: "auto"
    }
  }, Wysiwyg && /* @__PURE__ */ React.createElement(Wysiwyg.Editor, {
    readOnly,
    onContentStateChange,
    placeholder: readOnly ? "" : "  Type here...",
    editorState,
    handlePastedText: (_, html, editorState2) => {
      return handlePastedText(html, setEditorState, editorState2);
    },
    onEditorStateChange: (e) => {
      onEditorStateChange(e);
    },
    onBlur: () => isFunction(handleFocusLost) && handleFocusLost(),
    toolbar: {
      options: getOptions(ADMIN_OPTIONS, OPTIONS_TO_REMOVE, isOptionRemove),
      fontFamily: {
        options: Object.values(fontFamilyEnum),
        className: void 0,
        component: void 0,
        dropdownClassName: void 0
      }
    },
    toolbarHidden: readOnly && epic_1450_task_5422 || hideToolbar && epic275story5395
  }));
};
export default MthBulletEditor;
