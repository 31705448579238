import {c as createCommonjsModule, b as commonjsGlobal} from "../../common/_commonjsHelpers-53bde9c7.js";
var timezone = createCommonjsModule(function(module, exports) {
  !function(t, e) {
    module.exports = e();
  }(commonjsGlobal, function() {
    var t = {year: 0, month: 1, day: 2, hour: 3, minute: 4, second: 5}, e = {};
    return function(n, i, o) {
      var r, a = function(t2, n2, i2) {
        i2 === void 0 && (i2 = {});
        var o2 = new Date(t2), r2 = function(t3, n3) {
          n3 === void 0 && (n3 = {});
          var i3 = n3.timeZoneName || "short", o3 = t3 + "|" + i3, r3 = e[o3];
          return r3 || (r3 = new Intl.DateTimeFormat("en-US", {hour12: false, timeZone: t3, year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", timeZoneName: i3}), e[o3] = r3), r3;
        }(n2, i2);
        return r2.formatToParts(o2);
      }, u = function(e2, n2) {
        for (var i2 = a(e2, n2), r2 = [], u2 = 0; u2 < i2.length; u2 += 1) {
          var f2 = i2[u2], s2 = f2.type, m = f2.value, c = t[s2];
          c >= 0 && (r2[c] = parseInt(m, 10));
        }
        var d = r2[3], l = d === 24 ? 0 : d, h = r2[0] + "-" + r2[1] + "-" + r2[2] + " " + l + ":" + r2[4] + ":" + r2[5] + ":000", v = +e2;
        return (o.utc(h).valueOf() - (v -= v % 1e3)) / 6e4;
      }, f = i.prototype;
      f.tz = function(t2, e2) {
        t2 === void 0 && (t2 = r);
        var n2, i2 = this.utcOffset(), a2 = this.toDate(), u2 = a2.toLocaleString("en-US", {timeZone: t2}), f2 = Math.round((a2 - new Date(u2)) / 1e3 / 60), s2 = 15 * -Math.round(a2.getTimezoneOffset() / 15) - f2;
        if (!Number(s2))
          n2 = this.utcOffset(0, e2);
        else if (n2 = o(u2, {locale: this.$L}).$set("millisecond", this.$ms).utcOffset(s2, true), e2) {
          var m = n2.utcOffset();
          n2 = n2.add(i2 - m, "minute");
        }
        return n2.$x.$timezone = t2, n2;
      }, f.offsetName = function(t2) {
        var e2 = this.$x.$timezone || o.tz.guess(), n2 = a(this.valueOf(), e2, {timeZoneName: t2}).find(function(t3) {
          return t3.type.toLowerCase() === "timezonename";
        });
        return n2 && n2.value;
      };
      var s = f.startOf;
      f.startOf = function(t2, e2) {
        if (!this.$x || !this.$x.$timezone)
          return s.call(this, t2, e2);
        var n2 = o(this.format("YYYY-MM-DD HH:mm:ss:SSS"), {locale: this.$L});
        return s.call(n2, t2, e2).tz(this.$x.$timezone, true);
      }, o.tz = function(t2, e2, n2) {
        var i2 = n2 && e2, a2 = n2 || e2 || r, f2 = u(+o(), a2);
        if (typeof t2 != "string")
          return o(t2).tz(a2);
        var s2 = function(t3, e3, n3) {
          var i3 = t3 - 60 * e3 * 1e3, o2 = u(i3, n3);
          if (e3 === o2)
            return [i3, e3];
          var r2 = u(i3 -= 60 * (o2 - e3) * 1e3, n3);
          return o2 === r2 ? [i3, o2] : [t3 - 60 * Math.min(o2, r2) * 1e3, Math.max(o2, r2)];
        }(o.utc(t2, i2).valueOf(), f2, a2), m = s2[0], c = s2[1], d = o(m).utcOffset(c);
        return d.$x.$timezone = a2, d;
      }, o.tz.guess = function() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      }, o.tz.setDefault = function(t2) {
        r = t2;
      };
    };
  });
});
export {timezone as default};
