export var QuestionTypeSlug;
(function(QuestionTypeSlug2) {
  QuestionTypeSlug2["PARENT_PREFERRED_FIRST_NAME"] = "parent_preferred_first_name";
  QuestionTypeSlug2["PARENT_PREFERRED_LAST_NAME"] = "parent_preferred_last_name";
  QuestionTypeSlug2["PARENT_FIRST_NAME"] = "parent_first_name";
  QuestionTypeSlug2["PARENT_LAST_NAME"] = "parent_last_name";
  QuestionTypeSlug2["PACKET_SECONDARY_CONTACT_FIRST"] = "packet_secondary_contact_first";
  QuestionTypeSlug2["PACKET_SECONDARY_CONTACT_LAST"] = "packet_secondary_contact_last";
  QuestionTypeSlug2["PARENT_PHONE_NUMBER"] = "parent_phone_number";
  QuestionTypeSlug2["PARENT_RECIEVE_TEXT"] = "parent_recieve_text";
  QuestionTypeSlug2["PARENT_EMAIL"] = "parent_email";
  QuestionTypeSlug2["STUDENT_FIRST_NAME"] = "student_first_name";
  QuestionTypeSlug2["STUDENT_LAST_NAME"] = "student_last_name";
  QuestionTypeSlug2["STUDENT_MIDDLE_NAME"] = "student_middle_name";
  QuestionTypeSlug2["STUDENT_PREFERRED_FIRST_NAME"] = "student_preferred_first_name";
  QuestionTypeSlug2["STUDENT_PREFERRED_LAST_NAME"] = "student_preferred_last_name";
  QuestionTypeSlug2["STUDENT_GRADE_LEVEL"] = "student_grade_level";
  QuestionTypeSlug2["ADDRESS_STREET"] = "address_street";
  QuestionTypeSlug2["ADDRESS_STREET2"] = "address_street2";
  QuestionTypeSlug2["ADDRESS_CITY"] = "address_city";
  QuestionTypeSlug2["ADDRESS_STATE"] = "address_state";
  QuestionTypeSlug2["ADDRESS_ZIP"] = "address_zip";
  QuestionTypeSlug2["STUDENT_DATE_OF_BIRTH"] = "student_date_of_birth";
  QuestionTypeSlug2["STUDENT_GENDER"] = "student_gender";
  QuestionTypeSlug2["ADDRESS_SCHOOL_DISTRICT"] = "address_school_district";
  QuestionTypeSlug2["ADDRESS_COUNTY_ID"] = "address_county_id";
  QuestionTypeSlug2["ADDRESS_COUNTRY_ID"] = "address_country_id";
  QuestionTypeSlug2["PARENT_EMAILCONFIRM"] = "parent_emailConfirm";
  QuestionTypeSlug2["STUDENT_EMAIL"] = "student_email";
  QuestionTypeSlug2["STUDENT_EMAILCONFIRM"] = "student_emailConfirm";
  QuestionTypeSlug2["PROGRAM_YEAR"] = "program_year";
  QuestionTypeSlug2["META_SPECIAL_EDUCATION"] = "meta_special_education";
  QuestionTypeSlug2["SCHOOL_OF_ENROLLMENT_PREFERENCE"] = "meta_soe_preference";
  QuestionTypeSlug2["STATE"] = "state";
  QuestionTypeSlug2["META_PARENT_LEGAL_NAME"] = "meta_parentlegalname";
  QuestionTypeSlug2["SECONDARY_EMAIL"] = "packet_secondary_email";
  QuestionTypeSlug2["STUDENT"] = "student";
})(QuestionTypeSlug || (QuestionTypeSlug = {}));
