export var HomeroomSettings;
(function(HomeroomSettings2) {
  HomeroomSettings2["DAY_TO_SUBMIT"] = "Max Days Allowed to Submit Early";
  HomeroomSettings2["DISPLAY_STUDENT"] = "Display Student Details";
  HomeroomSettings2["SCHEDULE_TO_SUBMIT"] = "Allow Students with an Updates Required Schedule to Submit";
  HomeroomSettings2["NOTIFY_WHEN_RESUBMIT"] = "Notify When a Resubmission is Required";
  HomeroomSettings2["NOTIFY_WHEN_GRADED"] = "Notify When Graded";
  HomeroomSettings2["MAX_OF_EXCUSED"] = "Max # of Excused Learning Logs Allowed";
  HomeroomSettings2["GRADING_SCALE"] = "Grading Scale";
  HomeroomSettings2["GRADES_SUBJECT"] = "Grades by Subject";
})(HomeroomSettings || (HomeroomSettings = {}));
export var HomeroomSettingsFields;
(function(HomeroomSettingsFields2) {
  HomeroomSettingsFields2["DAY_TO_SUBMIT"] = "days_to_submit_early";
  HomeroomSettingsFields2["GENDER"] = "gender";
  HomeroomSettingsFields2["SPECIAL_EDUCATION"] = "special_education";
  HomeroomSettingsFields2["DIPLOMA"] = "diploma";
  HomeroomSettingsFields2["SCHEDULE_TO_SUBMIT"] = "update_required_schedule_to_submit";
  HomeroomSettingsFields2["NOTIFY_WHEN_RESUBMIT"] = "notify_when_resubmit_required";
  HomeroomSettingsFields2["ALWAYS_UNLOCK"] = "always_unlock";
  HomeroomSettingsFields2["NOTIFY_WHEN_GRADED"] = "notify_when_graded";
  HomeroomSettingsFields2["MAX_OF_EXCUSED"] = "max_of_excused_learning_logs_allowed";
  HomeroomSettingsFields2["GRADING_SCALE"] = "grading_scale_percentage";
  HomeroomSettingsFields2["PASSING_AVERAGE"] = "passing_average";
  HomeroomSettingsFields2["ZERO_COUNT"] = "zero_count";
  HomeroomSettingsFields2["GRADES_SUBJECT"] = "grades_by_subject";
})(HomeroomSettingsFields || (HomeroomSettingsFields = {}));
export var homeroomSettingsGradeOptionLabel;
(function(homeroomSettingsGradeOptionLabel2) {
  homeroomSettingsGradeOptionLabel2["TRUE"] = "Enabled";
  homeroomSettingsGradeOptionLabel2["FALSE"] = "Disabled";
})(homeroomSettingsGradeOptionLabel || (homeroomSettingsGradeOptionLabel = {}));
export var homeroomSettingsGradeOption;
(function(homeroomSettingsGradeOption2) {
  homeroomSettingsGradeOption2["TRUE"] = "enabled";
  homeroomSettingsGradeOption2["FALSE"] = "disabled";
})(homeroomSettingsGradeOption || (homeroomSettingsGradeOption = {}));
export var homeroomSettingsOption;
(function(homeroomSettingsOption2) {
  homeroomSettingsOption2["TRUE"] = "yes";
  homeroomSettingsOption2["FALSE"] = "no";
})(homeroomSettingsOption || (homeroomSettingsOption = {}));
export var homeroomSettingsOptionLabel;
(function(homeroomSettingsOptionLabel2) {
  homeroomSettingsOptionLabel2["TRUE"] = "Yes";
  homeroomSettingsOptionLabel2["FALSE"] = "No";
})(homeroomSettingsOptionLabel || (homeroomSettingsOptionLabel = {}));
export var dropdownPlaceholder;
(function(dropdownPlaceholder2) {
  dropdownPlaceholder2["DEFAULT"] = "Select";
  dropdownPlaceholder2["NA"] = "N/A";
})(dropdownPlaceholder || (dropdownPlaceholder = {}));
