export var TokenErrorTexts;
(function(TokenErrorTexts2) {
  TokenErrorTexts2["ERROR"] = "Error";
  TokenErrorTexts2["FAILED_RESEND"] = "Failed to resend verification email.";
  TokenErrorTexts2["SUCCESS"] = "Success";
  TokenErrorTexts2["NEW_VERIFICATION_EMAIL_SENT"] = "A new email with the verification link has been sent. Please verifiy your email within 24 hours.";
  TokenErrorTexts2["DONE"] = "Done";
  TokenErrorTexts2["CLOSE"] = "Close";
  TokenErrorTexts2["INFOCENTER"] = "InfoCenter";
  TokenErrorTexts2["LINK_EXPIRED_PLEASE_CLICK"] = "This link has expired. Please click ";
  TokenErrorTexts2["HERE"] = "here";
  TokenErrorTexts2["TO_RESEND_LINK"] = " to resend the link";
})(TokenErrorTexts || (TokenErrorTexts = {}));
