import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getCoursesWithTitlesQuery} from "../../../screens/Admin/Curriculum/CourseCatalog/services.js";
export const useCoursesWithTitles = (providerId, isActive) => {
  const [coursesWithTitles, setCoursesWithTitles] = useState([]);
  const {loading, data, error, refetch} = useQuery(getCoursesWithTitlesQuery, {
    variables: {
      providerId,
      isActive
    },
    skip: !providerId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (data?.coursesWithTitles) {
      const {coursesWithTitles: coursesWithTitles2} = data;
      setCoursesWithTitles(coursesWithTitles2);
    }
  }, [loading, data]);
  return {
    loading,
    coursesWithTitles,
    error,
    refetch
  };
};
