import React from "../../../../_snowpack/pkg/react.js";
import {Button, CircularProgress, Grid} from "../../../../_snowpack/pkg/@mui/material.js";
import {useFormContext} from "../../../../_snowpack/pkg/react-hook-form.js";
import {
  MthColor,
  EnrollmentPacketSaveAction,
  PacketStatus,
  EnrollmentPacketFormProperty,
  ImmunizationValue
} from "../../../../core/enums/index.js";
import {checkImmmValueWithSpacing, isValidDate, isValidVaccInput} from "./helpers.js";
export const PacketSaveButtons = ({submitForm, loading}) => {
  const {watch, getValues, setValue, setError} = useFormContext();
  const status = watch(EnrollmentPacketFormProperty.STATUS);
  const [exemptionDate, enableExemptionDate] = watch(["exemptionDate", "enableExemptionDate"]);
  const onlySaveButton = ![PacketStatus.SUBMITTED, PacketStatus.RESUBMITTED].includes(status);
  const isValidExemptDate = !enableExemptionDate || isValidDate(exemptionDate);
  function onClick(action) {
    const values = getValues();
    setValue(EnrollmentPacketFormProperty.LAST_CLICKED_ACTION, action);
    if (enableExemptionDate && !isValidDate(values.exemptionDate)) {
      setError(EnrollmentPacketFormProperty.EXEMPTION_DATE, {type: "required", message: "Please enter a valid date"});
      return;
    }
    if (action === EnrollmentPacketSaveAction.MISSING_INFO) {
      setValue(EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL, true);
    } else if (action === EnrollmentPacketSaveAction.AGE_ISSUE) {
      setValue(EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL, true);
    } else {
      let isValid = true;
      for (const e of values.immunizations) {
        if (e?.immunization?.is_deleted)
          continue;
        if (!isValidVaccInput(e?.value, e?.immunization?.immunity_allowed === 1)) {
          isValid = false;
          break;
        }
        if (e.value === ImmunizationValue.EXEMPT && !isValidDate(values.exemptionDate)) {
          isValid = false;
          break;
        }
        if (!checkImmmValueWithSpacing(e, values.immunizations)) {
          isValid = false;
          break;
        }
      }
      if ([EnrollmentPacketSaveAction.ACCEPTED, EnrollmentPacketSaveAction.CONDITIONAL].includes(action)) {
        setValue(EnrollmentPacketFormProperty.PRE_SAVE_STATUS, action);
        if (isValid) {
          submitForm();
          setValue(EnrollmentPacketFormProperty.STATUS, action);
        } else
          setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, true);
      } else {
        if (isValid)
          submitForm();
        else
          setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, true);
      }
    }
  }
  const buttons = [
    {
      label: EnrollmentPacketSaveAction.SAVE,
      disabled: !isValidExemptDate,
      color: MthColor.BUTTON_LINEAR_GRADIENT,
      hoverColor: void 0
    },
    {
      label: EnrollmentPacketSaveAction.ACCEPTED,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.GREEN_GRADIENT,
      hoverColor: MthColor.GREEN
    },
    {
      label: EnrollmentPacketSaveAction.MISSING_INFO,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.RED_GRADIENT,
      hoverColor: MthColor.RED
    },
    {
      label: EnrollmentPacketSaveAction.AGE_ISSUE,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.YELLOW_GRADIENT,
      hoverColor: MthColor.YELLOW2
    },
    {
      label: EnrollmentPacketSaveAction.CONDITIONAL,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.BLACK_GRADIENT,
      hoverColor: MthColor.SYSTEM_02
    }
  ];
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, {
    sx: {
      "&.MuiGrid-root": {
        width: "100%"
      }
    },
    container: true
  }, /* @__PURE__ */ React.createElement(Grid, {
    container: true
  }, buttons.map((button, index) => /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    key: index
  }, /* @__PURE__ */ React.createElement(Button, {
    endIcon: loading && /* @__PURE__ */ React.createElement(CircularProgress, {
      size: 10
    }),
    disabled: button.disabled || loading,
    sx: {
      borderRadius: 2,
      textTransform: "none",
      height: 25,
      background: button.color,
      "&:hover": {
        background: button.hoverColor
      },
      color: "white",
      width: "105px",
      marginRight: "5px"
    },
    onClick: () => onClick(button.label)
  }, button.label))))));
};
