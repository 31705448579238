import React, {useContext, useEffect, useMemo, useRef, useState} from "../_snowpack/pkg/react.js";
import {useQuery} from "../_snowpack/pkg/@apollo/client.js";
import {datadogRum} from "../_snowpack/pkg/@datadog/browser-rum.js";
import {makeStyles} from "../_snowpack/pkg/@material-ui/core/styles.js";
import {Box} from "../_snowpack/pkg/@mui/material.js";
import {useFlag} from "../_snowpack/pkg/@unleash/proxy-client-react.js";
import axios from "../_snowpack/pkg/axios.js";
import {isNil, isObject} from "../_snowpack/pkg/lodash.js";
import {useHistory, useLocation} from "../_snowpack/pkg/react-router-dom.js";
import {useRecoilState} from "../_snowpack/pkg/recoil.js";
import {CustomConfirmModal} from "../components/CustomConfirmModal/CustomConfirmModal.js";
import {MthLoading} from "../components/MthLoading/MthLoading.js";
import {styles, transitionsText} from "../components/SideMenu/styles.js";
import {
  UNKNOWN,
  OPERATIONAL_MAINTENANCE_SCREEN,
  EPIC_4594_STORY_4663,
  EPIC_3681_STORY_5237,
  EMPTY_STRING
} from "../core/constants/index.js";
import {AnnouncementPopupModal} from "../core/components/announcementPopups/AnnouncementPopupModal/index.js";
import {AutoUpdateBanner} from "../core/components/shared/AutoUpdateBanner/AutoUpdateBanner.js";
import {LOCAL_STORAGE, MthRoute, RoleLevel, TEXT_SIZE} from "../core/enums/index.js";
import {SNOWPACK_PUBLIC_MAINTENANCE_PAGE_POLLING, SNOWPACK_PUBLIC_MAINTENANCE_PAGE_URL} from "../core/envs/index.js";
import {useAnnouncementsPopup} from "../core/hooks/useAnnouncementPopup/useAnnouncementPopup.js";
import {AuthContext} from "../providers/AuthProvider/AuthContext.js";
import {
  modalConfirmDialogBeforeLeave,
  regionSelectedDialogBeforeLeave,
  confirmDialogBeforeLeave,
  isLoggingOut
} from "../providers/Store/State.js";
import {lazyWithRetry} from "../core/utils/index.js";
import {Flexbox} from "../components/Flexbox/Flexbox.js";
import {TabContext, UserContext, userRegionState} from "../providers/UserContext/UserProvider.js";
import {LoadingScreen} from "../screens/LoadingScreen/LoadingScreen.js";
import {getMeQuery} from "./services.js";
const SideMenu = lazyWithRetry(() => import("../components/SideMenu/SideMenu.js"));
const AdminSideMenu = lazyWithRetry(() => import("../components/SideMenu/AdminSideMenu.js"));
const TeacherSideMenu = lazyWithRetry(() => import("../components/SideMenu/TeacherSideMenu.js"));
const StudentSideMenu = lazyWithRetry(() => import("../components/SideMenu/StudentSideMenu.js"));
const ObserverSideMenu = lazyWithRetry(() => import("../components/SideMenu/ObserverSideMenu.js"));
const AppBar = lazyWithRetry(() => import("../components/AppBar/AppBar.js"));
const AdminAppBar = lazyWithRetry(() => import("../components/AdminAppBar/AdminAppBar.js"));
const TeacherAppBar = lazyWithRetry(() => import("../components/TeacherAppBar/TeacherAppBar.js"));
const StudentAppBar = lazyWithRetry(() => import("../components/StudentAppBar/StudentAppBar.js"));
const MaintenancePage = lazyWithRetry(() => import("../components/MaintenancePage/MaintenancePage.js"));
const Routes = lazyWithRetry(() => import("../router/Routes.js"));
const AdminRoutes = lazyWithRetry(() => import("../router/AdminRoutes.js"));
const TeacherRoutes = lazyWithRetry(() => import("../router/TeacherRoutes.js"));
const StudentRoutes = lazyWithRetry(() => import("../router/StudentRoutes.js"));
const ObserverRoutes = lazyWithRetry(() => import("../router/ObserverRoutes.js"));
const UnauthenticatedRoutes = lazyWithRetry(() => import("../router/UnauthenticatedRoutes.js"));
const MasqueradeFooter = lazyWithRetry(() => import("../components/MasqueradeFooter/MasqueradeFooter.js"));
export const Root = () => {
  const epic3735Story5237 = useFlag(EPIC_3681_STORY_5237);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);
  const useStyles = makeStyles((theme) => ({
    content: {
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0"
      },
      marginLeft: isSideMenuOpen ? styles.sideMenu.sideMenuOpen.sizePx : styles.sideMenu.sideMenuClose.sizePx,
      transition: `margin-left width ${transitionsText}`
    }
  }));
  const classes = useStyles();
  const {me, setMe} = useContext(UserContext);
  const {setTab, setVisitedTabs} = useContext(TabContext);
  const {loading, data} = useQuery(getMeQuery, {skip: !!me});
  const [isSuper, setIsSuper] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [versionUpdateRequired, setVersionUpdateRequired] = useState(false);
  const operationalMaintenaceScreen = useFlag(OPERATIONAL_MAINTENANCE_SCREEN);
  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [nextLocation, setNextLocation] = useState();
  const unblockRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const epic4594Story4663 = useFlag(EPIC_4594_STORY_4663);
  const pullAnnouncements = useMemo(() => {
    if (isObject(location?.state) && "pullAnnouncements" in location?.state && location.state?.pullAnnouncements)
      return true;
    return false;
  }, [location]);
  const [_confirmDialogBeforeLeave, setConfirmDialogBeforLeave] = useRecoilState(confirmDialogBeforeLeave);
  const [_modalConfirmDialogBeforeLeave, setModalConfirmDialogBeforeLeave] = useRecoilState(modalConfirmDialogBeforeLeave);
  const [_regionSelectedDialogBeforeLeave, setRegionSelectedDialogBeforeLeave] = useRecoilState(regionSelectedDialogBeforeLeave);
  const [_isLoggingOut, setIsLoggingOut] = useRecoilState(isLoggingOut);
  const {signOut} = useContext(AuthContext);
  const [, setSelected] = useRecoilState(userRegionState);
  useEffect(() => {
    if (_confirmDialogBeforeLeave.activate) {
      const handleLocationChange = (newLocation) => {
        if (!_modalConfirmDialogBeforeLeave) {
          setModalConfirmDialogBeforeLeave(true);
          setNextLocation(newLocation);
          return false;
        }
        return true;
      };
      unblockRef.current = history.block((tx) => {
        if (!handleLocationChange(tx)) {
          return false;
        }
      });
      return () => {
        if (unblockRef.current) {
          unblockRef.current();
        }
      };
    }
  }, [history, nextLocation, _modalConfirmDialogBeforeLeave, _confirmDialogBeforeLeave]);
  const confirmLeavePage = () => {
    if (!isNil(_regionSelectedDialogBeforeLeave)) {
      setSelected(_regionSelectedDialogBeforeLeave);
      localStorage.setItem(LOCAL_STORAGE.SELECTED_REGION, JSON.stringify(_regionSelectedDialogBeforeLeave));
      setMe((prev) => {
        if (!prev) {
          return {
            selectedRegionId: _regionSelectedDialogBeforeLeave.region_id,
            masquerade: false
          };
        }
        return {
          ...prev,
          selectedRegionId: _regionSelectedDialogBeforeLeave.region_id
        };
      });
      setRegionSelectedDialogBeforeLeave(null);
    }
    if (_isLoggingOut) {
      setMe(null);
      signOut();
      const logoutDashboard = {...window.location, pathname: MthRoute.DASHBOARD};
      setNextLocation(logoutDashboard);
      setIsLoggingOut(false);
    }
    setConfirmDialogBeforLeave({activate: false, header: EMPTY_STRING, content: EMPTY_STRING});
    setModalConfirmDialogBeforeLeave(false);
    if (unblockRef.current) {
      unblockRef.current();
    }
    if (nextLocation?.pathname)
      history.push(nextLocation.pathname);
  };
  const cancelLeavePage = () => {
    setRegionSelectedDialogBeforeLeave(null);
    setIsLoggingOut(false);
    setModalConfirmDialogBeforeLeave(false);
    setNextLocation(void 0);
  };
  const renderConfirmDialogBeforeLeaveModal = () => {
    return /* @__PURE__ */ React.createElement(CustomConfirmModal, {
      header: _confirmDialogBeforeLeave.header,
      headerFontSize: TEXT_SIZE.EXTRA_LARGE,
      content: _confirmDialogBeforeLeave.content,
      cancelBtnTitle: _confirmDialogBeforeLeave.cancelBtnTitle,
      confirmBtnTitle: _confirmDialogBeforeLeave.confirmBtnTitle,
      handleConfirmModalChange: (isOk) => {
        if (isOk) {
          confirmLeavePage();
        } else {
          cancelLeavePage();
        }
      }
    });
  };
  const regionId = useMemo(() => Number(me?.selectedRegionId) || Number(me?.userRegion?.at(-1)?.region_id), [me?.selectedRegionId, me?.userRegion]);
  const announcements = useAnnouncementsPopup(pullAnnouncements, me?.level, regionId);
  useEffect(() => {
    setShowAnnouncements(announcements.length > 0);
  }, [announcements]);
  useEffect(() => {
    if (operationalMaintenaceScreen) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, SNOWPACK_PUBLIC_MAINTENANCE_PAGE_POLLING);
      return () => clearInterval(intervalId);
    }
  }, [operationalMaintenaceScreen]);
  async function fetchData() {
    const config = {
      url: SNOWPACK_PUBLIC_MAINTENANCE_PAGE_URL,
      method: "GET"
    };
    axios(config).then((response) => {
      setIsMaintenance(response.data.maintenanceStatus);
    });
  }
  const closeShowAnnouncements = () => {
    const state = {...history.location.state};
    delete state.pullAnnouncements;
    history.replace({...history.location, state});
    setShowAnnouncements(false);
  };
  useEffect(() => {
    if (!loading && me === null && data !== void 0) {
      setTab({
        currentTab: 0
      });
      const regions = [];
      data.me.userRegion.forEach((region) => {
        let i;
        for (i = 0; i < regions.length; i++) {
          if ((regions[i].regionDetail.name ?? "") > (region.regionDetail.name ?? ""))
            break;
        }
        regions.splice(i, 0, region);
      });
      setMe({
        ...data.me,
        userRegion: regions
      });
      setVisitedTabs([]);
      setIsSuper(Number(data.me?.level) === 1);
      if (localStorage.getItem("masquerade") === null) {
        datadogRum.setUser({
          id: data.me?.user_id,
          email: data.me?.email,
          name: `${data.me.first_name} ${data.me.last_name}`,
          role: data.me?.role?.name || UNKNOWN,
          region: regions.length > 0 ? regions.map((r) => r?.regionDetail?.name).filter((r) => !isNil(r)).join(",") : UNKNOWN
        });
      }
    }
  }, [loading, me, data]);
  const renderSideMenu = (me2) => {
    const sideMenuProps = {
      isVersionUpdateRequired: versionUpdateRequired,
      isSideMenuOpen,
      setIsSideMenuOpen
    };
    if (me2.level === RoleLevel.TEACHER) {
      return /* @__PURE__ */ React.createElement(TeacherSideMenu, {
        ...sideMenuProps
      });
    } else if (me2.level === RoleLevel.OBSERVER) {
      return /* @__PURE__ */ React.createElement(ObserverSideMenu, {
        ...sideMenuProps
      });
    } else if (me2.level === RoleLevel.STUDENT) {
      return /* @__PURE__ */ React.createElement(StudentSideMenu, {
        ...sideMenuProps
      });
    }
    return /* @__PURE__ */ React.createElement(SideMenu, {
      ...sideMenuProps
    });
  };
  const renderAppBar = (me2) => {
    if (me2?.level === RoleLevel.ADMIN) {
      return /* @__PURE__ */ React.createElement(AdminAppBar, null);
    } else if (me2?.level === RoleLevel.TEACHER) {
      return /* @__PURE__ */ React.createElement(TeacherAppBar, null);
    } else if (me2?.level === RoleLevel.STUDENT) {
      return /* @__PURE__ */ React.createElement(StudentAppBar, null);
    }
    return /* @__PURE__ */ React.createElement(AppBar, null);
  };
  const renderRoutes = (me2) => {
    if (me2.level === RoleLevel.TEACHER) {
      return /* @__PURE__ */ React.createElement(TeacherRoutes, null);
    } else if (me2.level === RoleLevel.OBSERVER) {
      return /* @__PURE__ */ React.createElement(ObserverRoutes, null);
    } else if (me2?.level === RoleLevel.STUDENT) {
      return /* @__PURE__ */ React.createElement(StudentRoutes, null);
    }
    return /* @__PURE__ */ React.createElement(Routes, null);
  };
  const autoUpdaterBannerProps = {
    versionUpdateRequired,
    setVersionUpdateRequired
  };
  if (isMaintenance) {
    return /* @__PURE__ */ React.createElement(React.Suspense, {
      fallback: /* @__PURE__ */ React.createElement(MthLoading, null)
    }, /* @__PURE__ */ React.createElement(MaintenancePage, null));
  } else if (loading && !me) {
    return /* @__PURE__ */ React.createElement(LoadingScreen, null);
  } else if (me !== null) {
    if (!isSuper) {
      return /* @__PURE__ */ React.createElement(React.Suspense, {
        fallback: /* @__PURE__ */ React.createElement(MthLoading, null)
      }, /* @__PURE__ */ React.createElement(AutoUpdateBanner, {
        ...autoUpdaterBannerProps
      }), /* @__PURE__ */ React.createElement(Box, {
        sx: {height: "100%", flex: 1},
        alignItems: "center"
      }, renderSideMenu(me), /* @__PURE__ */ React.createElement(Box, {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "space-between"
      }, /* @__PURE__ */ React.createElement("div", {
        className: classes.content
      }, renderAppBar(me), /* @__PURE__ */ React.createElement(Box, {
        sx: {marginTop: {xs: "65px", sm: 0}, marginBottom: {xs: "15px", sm: 0}}
      }, renderRoutes(me))), localStorage.getItem("masquerade") !== null && /* @__PURE__ */ React.createElement(MasqueradeFooter, {
        me
      }), announcements && showAnnouncements && epic4594Story4663 && /* @__PURE__ */ React.createElement(AnnouncementPopupModal, {
        onClose: closeShowAnnouncements,
        announcementPopup: announcements[0]
      }), _modalConfirmDialogBeforeLeave && renderConfirmDialogBeforeLeaveModal())));
    } else {
      return /* @__PURE__ */ React.createElement(React.Suspense, {
        fallback: /* @__PURE__ */ React.createElement(MthLoading, null)
      }, /* @__PURE__ */ React.createElement(AutoUpdateBanner, {
        ...autoUpdaterBannerProps
      }), /* @__PURE__ */ React.createElement(Flexbox, {
        flexDirection: "row"
      }, /* @__PURE__ */ React.createElement(AdminSideMenu, {
        isVersionUpdateRequired: versionUpdateRequired,
        isSideMenuOpen,
        setIsSideMenuOpen
      }), /* @__PURE__ */ React.createElement(Flexbox, {
        flexDirection: "column"
      }, /* @__PURE__ */ React.createElement("div", {
        style: {
          marginLeft: isSideMenuOpen ? styles.sideMenu.sideMenuOpen.sizePx : styles.sideMenu.sideMenuClose.sizePx,
          transition: `margin-left width ${transitionsText}`
        }
      }, /* @__PURE__ */ React.createElement(AdminAppBar, null), /* @__PURE__ */ React.createElement(AdminRoutes, null)), localStorage.getItem("masquerade") !== null && /* @__PURE__ */ React.createElement(MasqueradeFooter, {
        me
      }), announcements && showAnnouncements && epic4594Story4663 && /* @__PURE__ */ React.createElement(AnnouncementPopupModal, {
        onClose: closeShowAnnouncements,
        announcementPopup: announcements[0]
      }), _modalConfirmDialogBeforeLeave && renderConfirmDialogBeforeLeaveModal())));
    }
  } else {
    return /* @__PURE__ */ React.createElement(React.Suspense, {
      fallback: /* @__PURE__ */ React.createElement(MthLoading, null)
    }, /* @__PURE__ */ React.createElement(AutoUpdateBanner, {
      ...autoUpdaterBannerProps
    }), /* @__PURE__ */ React.createElement(UnauthenticatedRoutes, null));
  }
};
