import {useMemo} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getCurrentAnnouncementPopupByRegion} from "../../../graphql/queries/announcement-popups.js";
export const useAnnouncementsPopup = (shouldRequest = false, rol, regionId) => {
  const shouldSkip = !shouldRequest || !regionId || !rol;
  const {data} = useQuery(getCurrentAnnouncementPopupByRegion, {
    variables: {
      regionId,
      rol
    },
    skip: shouldSkip,
    fetchPolicy: "cache-first"
  });
  const annnouncementPopupData = useMemo(() => data?.getCurrentAnnouncementPopupByRegion || [], [data?.getCurrentAnnouncementPopupByRegion]);
  if (!shouldRequest) {
    return [];
  } else {
    return annnouncementPopupData;
  }
};
