import * as __SNOWPACK_ENV__ from "../_snowpack/env.js";
import {datadogLogs} from "../_snowpack/pkg/@datadog/browser-logs.js";
import {datadogRum} from "../_snowpack/pkg/@datadog/browser-rum.js";
import {STAGE, DATADOG_CONFIG} from "../core/constants/index.js";
import {SNOWPACK_PUBLIC_APP_VERSION} from "../core/envs/index.js";
const stage = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_STAGE;
if ([STAGE.STAGING, STAGE.DEMO, STAGE.PRODUCTION].includes(stage)) {
  datadogRum.init({
    applicationId: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_DATADOG_APPLICATION_ID || "",
    clientToken: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_DATADOG_CLIENT_TOKEN || "",
    site: DATADOG_CONFIG.SITE,
    service: DATADOG_CONFIG.SERVICE,
    env: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_ENVIRONMENT || "",
    version: SNOWPACK_PUBLIC_APP_VERSION.toLowerCase(),
    sessionSampleRate: 100,
    trackUserInteractions: true,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      /^https:\/\/(?:.*api.*\.)?mytechhigh\.com\/.*/,
      /^https:\/\/(?:.*api.*\.)?infocenter\.tech\/.*/
    ],
    beforeSend: (event, context) => {
      if (event.type === "resource" && event.resource.type === "fetch") {
        event.context = {
          ...event.context,
          requestBody: context.requestInit?.body
        };
      }
    }
  });
  datadogRum.startSessionReplayRecording();
  datadogLogs.init({
    clientToken: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: DATADOG_CONFIG.SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  });
}
