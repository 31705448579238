export var WarningMessagesTitles;
(function(WarningMessagesTitles2) {
  WarningMessagesTitles2["WARNING"] = "Warning";
  WarningMessagesTitles2["ERROR"] = "Error";
  WarningMessagesTitles2["ASSIGN"] = "Assign";
  WarningMessagesTitles2["TRANSFER"] = "Transfer";
  WarningMessagesTitles2["UPLOAD_ERROR"] = "Upload Error";
  WarningMessagesTitles2["SCHOOL_OF_ENROLLMENT"] = "School of Enrollment";
})(WarningMessagesTitles || (WarningMessagesTitles = {}));
export var WarningMessagesSubtitles;
(function(WarningMessagesSubtitles2) {
  WarningMessagesSubtitles2["SELECT_APPLICATION"] = "Please select applications";
  WarningMessagesSubtitles2["NO_STUDENT_SELECTED"] = "No student(s) selected";
  WarningMessagesSubtitles2["SELECTED_STUDENTS_DELETION"] = "About to delete selected applications.";
  WarningMessagesSubtitles2["REQUESTS_EXCEEDED"] = "This student has used the maximum number of requests.";
  WarningMessagesSubtitles2["STUDENT_ALREADY_ASSIGNED"] = "This student(s) has already been assigned to a Homeroom.";
  WarningMessagesSubtitles2["PLEASE_TRANSFER"] = "Please transfer the student instead.";
  WarningMessagesSubtitles2["STUDENT_NOT_ASSIGNED"] = "At least one student was not assigned.";
  WarningMessagesSubtitles2["SIBLING_IN_MULTIPLE_HOMEROOMS"] = "Siblings are in multiple homerooms or siblings have no homeroom assignment";
  WarningMessagesSubtitles2["PAST_DUE_DATE"] = "This Homeroom has Learning Logs past their due date.";
  WarningMessagesSubtitles2["PROCEED"] = "How would you like to proceed?";
  WarningMessagesSubtitles2["NOT_TRANSFERED"] = "This student(s) has not been assigned to a Homeroom.";
  WarningMessagesSubtitles2["PLEASE_ASSIGN"] = "Please assign the student instead.";
  WarningMessagesSubtitles2["NO_HOMEROOM"] = "No Homeroom selected";
  WarningMessagesSubtitles2["NO_RECEIPTS"] = "At least one receipt is required";
  WarningMessagesSubtitles2["LL_TRANSFER"] = "All Weekly Learning Log history and grades will be";
  WarningMessagesSubtitles2["LL_TRANSFER_CONTD"] = "transferred.";
  WarningMessagesSubtitles2["UPLOAD_ERROR"] = "An error occurred during the upload process. Please try again.";
  WarningMessagesSubtitles2["SAVING_ERROR"] = "An error occurred during the saving process. Please try again.";
  WarningMessagesSubtitles2["POPUP_OVERLAP"] = "Unable to save. The selected dates overlap with existing announcements. Please choose different start and/or end dates to ensure only one announcement is displayed at a time.";
  WarningMessagesSubtitles2["SOE_TRANSFER"] = "How would you like to proceed with the SOE change?";
  WarningMessagesSubtitles2["DOWNLOAD_LEARNING_LOG"] = "Failed to download learning log file";
  WarningMessagesSubtitles2["UNOFFICIAL_TRANSCRIPT"] = "Failed to download unofficial transcript";
  WarningMessagesSubtitles2["DOWNLOAD_OPT_OUT_FORM"] = "Failed to download opt-out form";
  WarningMessagesSubtitles2["SUBMIT_LEARNING_LOG"] = "An error occurred while submitting the learning log";
})(WarningMessagesSubtitles || (WarningMessagesSubtitles = {}));
export var WarningMessagesButtons;
(function(WarningMessagesButtons2) {
  WarningMessagesButtons2["CLOSE"] = "Close";
  WarningMessagesButtons2["OK"] = "OK";
  WarningMessagesButtons2["ASSIGN"] = "Assign";
  WarningMessagesButtons2["CANCEL"] = "Cancel";
  WarningMessagesButtons2["TRANSFER"] = "Transfer";
})(WarningMessagesButtons || (WarningMessagesButtons = {}));
