import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {GetSchoolsOfEnrollment} from "../../../screens/Admin/SiteManagement/SchoolPartner/services.js";
import {soeUppStatus} from "../../enums/school-enrollment-status.enum.js";
export const useSchoolPartnerListByRegionIdAndSchoolYearId = (regionId, school_year_id = 0) => {
  const [schoolofEnrollments, setSchoolofEnrollments] = useState([]);
  const {
    loading,
    data: schoolOfEnrollments,
    error
  } = useQuery(GetSchoolsOfEnrollment, {
    variables: {
      schoolPartnerArgs: {
        region_id: regionId,
        sort: school_year_id ? {column: "name", direction: "ASC"} : null,
        school_year_id
      }
    },
    skip: !regionId || !school_year_id,
    fetchPolicy: "network-only"
  });
  const [schoolOfEnrollmentList, setSchoolOfEnrollmentList] = useState([]);
  useEffect(() => {
    if (schoolOfEnrollments) {
      const filteredEnrollments = schoolOfEnrollments.getSchoolsOfEnrollmentByRegion.filter((enrollment) => {
        const schoolEnrollmentYear = enrollment.schoolEnrollmentYear[0];
        return schoolEnrollmentYear.active;
      });
      setSchoolOfEnrollmentList([
        {
          label: soeUppStatus.UNASSIGNED,
          value: soeUppStatus.UNASSIGNED
        },
        ...filteredEnrollments.map((enrollment) => ({
          label: enrollment.abbreviation,
          value: `${enrollment.id}`
        }))
      ]);
      setSchoolofEnrollments([
        soeUppStatus.UNASSIGNED,
        ...filteredEnrollments.map((enrollment) => `${enrollment.id}`)
      ]);
    }
  }, [schoolOfEnrollments]);
  return {
    loading,
    selectedSchoolofEnrollments: schoolofEnrollments,
    setSelectedSchoolofEnrollments: setSchoolofEnrollments,
    schoolOfEnrollmentList,
    error
  };
};
