import * as __SNOWPACK_ENV__ from "../../../../_snowpack/env.js";
import React, {useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import {DeleteForeverOutlined} from "../../../../_snowpack/pkg/@mui/icons-material.js";
import {Box, IconButton, Tooltip} from "../../../../_snowpack/pkg/@mui/material.js";
import {isNil} from "../../../../_snowpack/pkg/lodash.js";
import {MthTable} from "../../../../components/MthTable/index.js";
import {
  MthColor,
  MthRoute,
  MthTitle,
  ReimbursementRequestStatus,
  ReimbursementRequestTypeAbr
} from "../../../../core/enums/index.js";
import {useStyles} from "./styles.js";
import {REIMBURSEMENT_TABLE_HEAD} from "./types.js";
export const ReimbursementTable = ({
  data,
  handleSort,
  handleSelection,
  reimbursementToDelete,
  handleSelectItem,
  selectedItems,
  showPeriod,
  editDirectDeduction
}) => {
  const [items, setItems] = useState([]);
  const openReimbursement = (reimbursement_id) => {
    window.open(`${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_WEB_URL}${MthRoute.REIMBURSEMENTS_REQUESTS}?id=${reimbursement_id}`, "_blank", "status=yes");
  };
  useEffect(() => {
    if (data) {
      setItems(data.map((item) => ({
        key: `col-${item.amount}-${Math.random()}`,
        isSelected: selectedItems?.find((fitem) => fitem.reimbursement_request_id === item.reimbursement_request_id) !== void 0,
        columns: {
          submitted: item.submitted,
          amount: item.amount,
          rb_student: item.student,
          grade: item.grade,
          rb_status: item.status,
          paid_ordered: item.paid_ordered,
          category: item.category,
          period: item.period,
          rb_type: item.rb_type,
          approved_ordered_by: item.approved_ordered_by
        },
        rawData: item
      })));
    }
  }, [data]);
  let tHeads = Object.entries(REIMBURSEMENT_TABLE_HEAD).map(([key, value]) => {
    const head = {
      key: key.toLowerCase(),
      label: value,
      sortable: value !== REIMBURSEMENT_TABLE_HEAD.PERIOD
    };
    if (value === REIMBURSEMENT_TABLE_HEAD.RB_STUDENT) {
      return {
        ...head,
        formatter: (item) => {
          return /* @__PURE__ */ React.createElement(Box, {
            sx: {cursor: "pointer", color: MthColor.VORTEX, textDecoration: "underline"},
            onClick: () => {
              if (item.rawData.rb_type !== ReimbursementRequestTypeAbr.DIRECT_DEDUCTION)
                openReimbursement(item.rawData.reimbursement_request_id);
              else
                editDirectDeduction(item.rawData);
            }
          }, item.rawData.student);
        }
      };
    }
    return head;
  });
  if (!showPeriod)
    tHeads = tHeads.filter((item) => item.label !== REIMBURSEMENT_TABLE_HEAD.PERIOD);
  const fields = [
    ...tHeads,
    {
      key: "action",
      formatter: (item) => {
        return /* @__PURE__ */ React.createElement(Box, {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end"
        }, /* @__PURE__ */ React.createElement(Tooltip, {
          title: MthTitle.DELETE
        }, /* @__PURE__ */ React.createElement(IconButton, {
          sx: useStyles.actionButton,
          disabled: !isNil(item.rawData.status) && item.rawData.status === ReimbursementRequestStatus.APPROVED || item.rawData.status === ReimbursementRequestStatus.PAID,
          onClick: () => reimbursementToDelete(item.rawData.reimbursement_request_id)
        }, /* @__PURE__ */ React.createElement(DeleteForeverOutlined, null))));
      }
    }
  ];
  return /* @__PURE__ */ React.createElement(MthTable, {
    items,
    fields,
    selectable: true,
    oddBg: false,
    isTableCellBorder: false,
    sx: useStyles.tableStyle,
    onSortChange: handleSort,
    labelSize: 12,
    onSelectionChange: handleSelection,
    showSelectAll: true,
    onSelectionHandle: handleSelectItem
  });
};
