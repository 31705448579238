import {gql} from "../../_snowpack/pkg/@apollo/client.js";
export const getRegion = gql`
  query Region($id: ID!) {
    region(id: $id) {
      id
      name
      timezone
      resource_confirm_details
      resource_instruction
    }
  }
`;
export const getAllRegion = gql`
  query Regions {
    regions {
      id
      name
      timezone
      is_available
    }
  }
`;
