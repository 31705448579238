import * as __SNOWPACK_ENV__ from "../../../_snowpack/env.js";
const MiB_TO_B = 1024 * 1024;
const MiB_SIZE_LIMIT = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_MB_SIZE_LIMIT || 25;
export const dataUrlToFile = async (dataUrl, fileName) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, {type: "image/png"});
};
export const validateFile = (file, validationMessage, validationTypes, fileSizeLimit) => {
  const sizeLimit = fileSizeLimit || MiB_SIZE_LIMIT;
  const maxSize = sizeLimit * MiB_TO_B;
  if (file.size > maxSize) {
    return {
      status: false,
      message: `This file exceeds maximum allowed size of ${sizeLimit} MB`
    };
  }
  if (validationTypes.indexOf(file.type) === -1) {
    return {
      status: false,
      message: validationMessage
    };
  }
  return {
    status: true
  };
};
