import {StudentStatus} from "../../enums/student-status.enum.js";
export const getAvailableStudents = (students, currentYearIds, nextYearIds) => {
  const currentYearStudents = students.filter((student) => student.status.some((yearStatus) => [currentYearIds[0], nextYearIds[0]].includes(yearStatus.school_year_id)));
  const availableStudents = currentYearStudents.filter((student) => student.status.length > 0 && !student.status.some((studentYearStatus) => currentYearIds.includes(studentYearStatus.school_year_id) && [
    StudentStatus.WITHDRAWN,
    StudentStatus.DELETED,
    StudentStatus.INACTIVE,
    StudentStatus.COMPLETED,
    StudentStatus.GRADUATED
  ].includes(studentYearStatus.status)));
  return availableStudents;
};
export const getNextYearAvailableStudents = (students, currentYearIds, nextYearIds) => {
  const currentYearStudents = students.filter((student) => student.status.some((yearStatus) => [currentYearIds[0], nextYearIds[0]].includes(yearStatus.school_year_id)));
  const nextYearAvailableStudents = currentYearStudents.filter((student) => student.status.length > 0 && student.status.some((studentYearStatus) => nextYearIds.includes(studentYearStatus.school_year_id)));
  return nextYearAvailableStudents;
};
