import React, {useState} from "../../../../../../_snowpack/pkg/react.js";
import InfoIcon from "../../../../../../_snowpack/pkg/@mui/icons-material/InfoOutlined.js";
import {Box, Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Typography} from "../../../../../../_snowpack/pkg/@mui/material.js";
import {MthColor, WithdrawalOption} from "../../../../../../core/enums/index.js";
export const WITHDRAWAL_OPTIONS = [
  {value: WithdrawalOption.NOTIFY_PARENT_OF_WITHDRAW, label: "Notify Parent of Withdrawal"},
  {value: WithdrawalOption.NO_FORM_NO_EMAIL, label: "No Form/No Email"},
  {value: WithdrawalOption.UNDECLARED_FORM_EMAIL, label: "Undeclared Form/Email"},
  {value: WithdrawalOption.UNDECLARED_FORM_NO_EMAIL, label: "Undeclared Form/No Email"}
];
export const WithdrawModal = ({
  title,
  description,
  subDescription,
  onClose,
  onWithdraw,
  confirmStr = "Confirm",
  cancelStr = "Cancel"
}) => {
  const [withdrawOption, setWithdrawOption] = useState(WithdrawalOption.NOTIFY_PARENT_OF_WITHDRAW);
  const handleChange = (e) => {
    setWithdrawOption(e.target.value);
  };
  const handleWithdraw = () => {
    onWithdraw(withdrawOption);
  };
  return /* @__PURE__ */ React.createElement(Modal, {
    open: true,
    "aria-labelledby": "child-modal-title",
    disableAutoFocus: true,
    "aria-describedby": "child-modal-description"
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "550px",
      height: "auto",
      bgcolor: MthColor.SYSTEM_09,
      borderRadius: 2,
      display: "flex",
      justifyContent: "center",
      p: 4
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {textAlign: "center"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    fontWeight: "bold"
  }, title), /* @__PURE__ */ React.createElement(InfoIcon, {
    sx: {fontSize: 50, margin: "20px 0px"}
  }), /* @__PURE__ */ React.createElement(Typography, null, description), subDescription && /* @__PURE__ */ React.createElement(Typography, null, subDescription), /* @__PURE__ */ React.createElement(Box, {
    sx: {marginTop: "30px"}
  }, /* @__PURE__ */ React.createElement(FormControl, null, /* @__PURE__ */ React.createElement(RadioGroup, {
    "aria-labelledby": "demo-controlled-radio-buttons-group",
    name: "controlled-radio-buttons-group",
    value: withdrawOption,
    onChange: handleChange
  }, WITHDRAWAL_OPTIONS.map((option, index) => /* @__PURE__ */ React.createElement(FormControlLabel, {
    key: index,
    value: option.value,
    control: /* @__PURE__ */ React.createElement(Radio, null),
    label: option.label
  }))))), /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", justifyContent: "space-between", marginTop: "30px", gap: "20px"}
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {width: "160px", height: "36px", background: MthColor.SYSTEM_08, borderRadius: "50px"},
    onClick: onClose
  }, cancelStr), /* @__PURE__ */ React.createElement(Button, {
    sx: {
      width: "160px",
      height: "36px",
      background: "#43484F",
      borderRadius: "50px",
      color: "white"
    },
    onClick: handleWithdraw
  }, confirmStr)))));
};
