export var EnrollmentPacketSaveAction;
(function(EnrollmentPacketSaveAction2) {
  EnrollmentPacketSaveAction2["SAVE"] = "Save";
  EnrollmentPacketSaveAction2["ACCEPTED"] = "Accepted";
  EnrollmentPacketSaveAction2["MISSING_INFO"] = "Missing Info";
  EnrollmentPacketSaveAction2["AGE_ISSUE"] = "Age Issue";
  EnrollmentPacketSaveAction2["CONDITIONAL"] = "Conditional";
  EnrollmentPacketSaveAction2["STARTED"] = "Started";
  EnrollmentPacketSaveAction2["NOT_STARTED"] = "Not Started";
})(EnrollmentPacketSaveAction || (EnrollmentPacketSaveAction = {}));
