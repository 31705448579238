export var FeedBackSettings;
(function(FeedBackSettings2) {
  FeedBackSettings2["TITLE"] = "Feedback";
  FeedBackSettings2["VIEW_ALL"] = "View All";
  FeedBackSettings2[FeedBackSettings2["INITIAL_CARACTER"] = 0] = "INITIAL_CARACTER";
  FeedBackSettings2[FeedBackSettings2["LIMIT"] = 3] = "LIMIT";
  FeedBackSettings2[FeedBackSettings2["SKIP"] = 0] = "SKIP";
  FeedBackSettings2["SORT"] = "due_date|DESC";
  FeedBackSettings2[FeedBackSettings2["CHARACTERS_TO_SHOW"] = 400] = "CHARACTERS_TO_SHOW";
  FeedBackSettings2[FeedBackSettings2["CHARACTERS_TO_SHOW_VIEW_ALL"] = 125] = "CHARACTERS_TO_SHOW_VIEW_ALL";
  FeedBackSettings2[FeedBackSettings2["INITIAL_LOAD_MORE"] = 5] = "INITIAL_LOAD_MORE";
  FeedBackSettings2[FeedBackSettings2["DEFAULT_GRADE"] = 100] = "DEFAULT_GRADE";
})(FeedBackSettings || (FeedBackSettings = {}));
