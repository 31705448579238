export var QuestionOptionLabel;
(function(QuestionOptionLabel2) {
  QuestionOptionLabel2["MALE"] = "Male";
  QuestionOptionLabel2["FEMALE"] = "Female";
  QuestionOptionLabel2["CHECKBOX"] = "Checkbox";
  QuestionOptionLabel2["AGREEMENT"] = "Agreement";
  QuestionOptionLabel2["MULTIPLE_CHOICES"] = "Multiple Choices";
  QuestionOptionLabel2["CALENDAR"] = "Calendar";
  QuestionOptionLabel2["INFORMATION"] = "Information";
  QuestionOptionLabel2["SPECIAL_EDUCATION"] = "Special Education";
  QuestionOptionLabel2["UNITED_STATES"] = "United States";
  QuestionOptionLabel2["NO_PREFERENCE"] = "No Preference";
  QuestionOptionLabel2["SPECIAL_EDUCATION_ABBREV"] = "SPED";
})(QuestionOptionLabel || (QuestionOptionLabel = {}));
