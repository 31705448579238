import {gql} from "../../_snowpack/pkg/@apollo/client.js";
export const getResourceRequestsQuery = gql`
  query ResourceRequests(
    $schoolYearId: Int!
    $skip: Int
    $take: Int
    $region: Int
    $filter: ResourceRequestFilters
    $sort: String
    $search: String
    $studentIds: [Int!]!
  ) {
    resourceRequests(
      schoolYearId: $schoolYearId
      skip: $skip
      take: $take
      region: $region
      filter: $filter
      sort: $sort
      search: $search
      studentIds: $studentIds
    ) {
      total
      totalPrice
      results {
        id
        student_id
        resource_id
        resource_level_id
        status
        username
        password
        created_at
        updated_at
        Student {
          student_id
          username_first_last
          username_last_first
          username_last_first_year
          username_last_firstinitial
          username_last_first_mth
          username_last_first_birth
          username_first_last_domain
          username_student_email
          username_parent_email
          person {
            first_name
            last_name
            email
            date_of_birth
            uniqueId
          }
          parent {
            person {
              first_name
              last_name
              email
            }
          }
          applications {
            midyear_application
            school_year_id
          }
          status {
            school_year_id
            status
          }
          grade_levels {
            school_year_id
            grade_level
          }
        }
        Resource {
          subtitle
          price
          std_username_format
          std_user_name
          std_password
          title
          ResourceLevels {
            resource_level_id
            name
            limit
          }
        }
        ResourceLevel {
          name
        }
        ResourceRequestEmails {
          id
          resource_request_id
          email_record_id
          created_at
        }
      }
    }
  }
`;
export const getEmailRecordsByIds = gql`
  query GetEmailRecordsByIds($ids: [Int!]!) {
    getEmailRecordsByIds(ids: $ids) {
      subject
      body
      from_email
      created_at
    }
  }
`;
export const downloadResourceRequestsReport = gql`
  query Query(
    $schoolYearId: Int!
    $filter: ResourceRequestFilters
    $search: String
    $skip: Int
    $sort: String
    $take: Int
  ) {
    downloadResourceRequestsReport(
      schoolYearId: $schoolYearId
      filter: $filter
      search: $search
      skip: $skip
      sort: $sort
      take: $take
    ) {
      cost
      parent_email
      parent_first_name
      parent_last_name
      password
      resource_level
      resource_request_id
      returning
      status
      student_dob
      student_email
      student_first_name
      student_grade
      student_id
      uniqueId
      student_last_name
      submitted
      username
      vendor
      year_status
    }
  }
`;
