import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {
  GRADES_FULL,
  HYPHEN,
  KINDERGARTEN_LABEL,
  KINDERGARTEN_NUM_VALUE,
  PRE_KINDERGARTEN_LABEL,
  PRE_KINDERGARTEN_NUM_VALUE
} from "../../constants/index.js";
import {GRADES} from "../../constants/index.js";
import {GRADE_LEVEL_LABEL, MthTitle} from "../../enums/index.js";
import {gradeToNumber, gradeToAbbreviation} from "../grade-text/grade-text.util.js";
import {toOrdinalSuffix} from "../string/string.util.js";
export const renderGrades = (gradesStr) => {
  if (!gradesStr)
    return "";
  const decodedGrades = gradesStr.replace(KINDERGARTEN_NUM_VALUE.toString(), GRADE_LEVEL_LABEL.KINDERGARTEN).replace(PRE_KINDERGARTEN_NUM_VALUE.toString(), PRE_KINDERGARTEN_LABEL).split(",").filter((item) => item !== "all");
  const grades = GRADES.filter((item) => decodedGrades.includes(`${item}`)).map((item) => gradeToNumber(item));
  return formatGradeRanges(grades);
};
export const formatGradeRanges = (grades) => {
  const result = [];
  let start = null;
  let end = null;
  const normalizedGrades = grades.map((item) => item <= 0 ? item + 1 : item);
  for (let i = 0; i < normalizedGrades.length; i++) {
    if (start === null && end === null) {
      start = normalizedGrades[i];
      end = normalizedGrades[i];
    } else if (normalizedGrades[i] - end === 1) {
      end = normalizedGrades[i];
    } else {
      result.push(formatRange(start, end));
      start = normalizedGrades[i];
      end = normalizedGrades[i];
    }
  }
  if (start !== null && end !== null) {
    result.push(formatRange(start, end));
  }
  return result.join(",");
};
export const formatRange = (start, end) => {
  const decodedStart = start <= 0 ? start - 1 : start;
  const decodedEnd = end <= 0 ? end - 1 : end;
  if (decodedStart === decodedEnd) {
    return gradeToAbbreviation(decodedStart);
  } else {
    return gradeToAbbreviation(decodedStart) + "-" + gradeToAbbreviation(decodedEnd);
  }
};
export const calculateGradeNumber = (grade, initialYear, endYear) => {
  const OFFSET = 1;
  if (!grade || !initialYear || !endYear)
    return 0;
  const initialYearNumber = Number(initialYear.split(HYPHEN)?.[0]);
  const endYearNumber = Number(endYear.split(HYPHEN)?.[0]);
  const steps = endYearNumber - initialYearNumber;
  const gradeNumber = gradeToNumber(grade);
  if (steps < 0)
    return gradeNumber;
  const index = gradeNumber === PRE_KINDERGARTEN_NUM_VALUE ? 0 : gradeNumber === KINDERGARTEN_NUM_VALUE ? 1 : gradeNumber + OFFSET;
  const nextItem = GRADES[index + steps];
  if (isNil(nextItem))
    return 0;
  return gradeToNumber(nextItem);
};
export const isInEligibleStudentStatus = (schoolYearGrades, studentGradeLevel, schoolYearDateBegin) => {
  const grades = schoolYearGrades?.replace(PRE_KINDERGARTEN_LABEL, PRE_KINDERGARTEN_NUM_VALUE.toString())?.replace(KINDERGARTEN_LABEL, KINDERGARTEN_NUM_VALUE.toString())?.split(",");
  const currentGrade = calculateGradeNumber(studentGradeLevel?.grade_level, studentGradeLevel?.school_year?.date_begin, schoolYearDateBegin);
  if (grades)
    return !grades?.includes(currentGrade.toString());
  else
    return false;
};
export const gradesToDropDownItems = (grades, enableFullGrades = false) => {
  const dropDownItems = [];
  const gradesArray = enableFullGrades ? GRADES_FULL : GRADES;
  gradesArray.forEach((grade) => {
    if (grades?.includes(String(grade))) {
      if (typeof grade !== "string") {
        dropDownItems.push({
          label: `${toOrdinalSuffix(grade)} ${MthTitle.GRADE}`,
          value: grade.toString()
        });
      }
      if (typeof grade === "string") {
        dropDownItems.push({
          label: grade,
          value: grade
        });
      }
    }
  });
  return dropDownItems;
};
