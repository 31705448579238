import React from "../../../../../../_snowpack/pkg/react.js";
import {Box, Button} from "../../../../../../_snowpack/pkg/@mui/material.js";
import {saveCancelClasses} from "./styles.js";
const SaveCancelComponent = ({
  isSubmitted,
  handleCancel,
  handleSave,
  sxCancelBtn = {},
  sxSaveBtn = {}
}) => {
  return /* @__PURE__ */ React.createElement(Box, {
    sx: saveCancelClasses.align
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {...saveCancelClasses.cancelBtn, ...sxCancelBtn},
    onClick: () => handleCancel()
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button, {
    sx: {...saveCancelClasses.saveBtn, ...sxSaveBtn},
    type: "submit",
    disabled: isSubmitted,
    onClick: handleSave
  }, "Save"));
};
export default SaveCancelComponent;
