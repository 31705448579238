import {c as createCommonjsModule} from "./_commonjsHelpers-53bde9c7.js";
import {c as createSvgIcon$1} from "./createSvgIcon-e773c0f9.js";
import {C as ClassNameGenerator} from "./ClassNameGenerator-1fa9df9c.js";
import {s as setRef, u as useForkRef} from "./useForkRef-8d56fe3c.js";
import {c as capitalize} from "./createTheme-723fa293.js";
import {c as createChainedFunction} from "./createChainedFunction-b32cfe42.js";
import {d as debounce} from "./debounce-735db077.js";
import {i as isMuiElement} from "./isMuiElement-dcb6bc25.js";
import {o as ownerDocument} from "./ownerDocument-176afcdd.js";
import {o as ownerWindow} from "./ownerWindow-2abea87b.js";
import {u as useEnhancedEffect} from "./useEnhancedEffect-f0068896.js";
import {u as useId} from "./useId-60647959.js";
import {u as useControlled} from "./useControlled-697dd2b3.js";
import {u as useEventCallback} from "./useEventCallback-fd4680bd.js";
import {u as useIsFocusVisible} from "./useIsFocusVisible-974ba34c.js";
function deprecatedPropType(validator, reason) {
  {
    return () => null;
  }
}
function requirePropFactory(componentNameInError, Component) {
  {
    return () => null;
  }
}
function unsupportedProp(props, propName, componentName, location, propFullName) {
  {
    return null;
  }
}
const unstable_ClassNameGenerator = {
  configure: (generator) => {
    console.warn(["MUI: `ClassNameGenerator` import from `@mui/material/utils` is outdated and might cause unexpected issues.", "", "You should use `import { unstable_ClassNameGenerator } from '@mui/material/className'` instead", "", "The detail of the issue: https://github.com/mui/material-ui/issues/30011#issuecomment-1024993401", "", "The updated documentation: https://mui.com/guides/classname-generator/"].join("\n"));
    ClassNameGenerator.configure(generator);
  }
};
var utils = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  unstable_ClassNameGenerator,
  capitalize,
  createChainedFunction,
  createSvgIcon: createSvgIcon$1,
  debounce,
  deprecatedPropType,
  isMuiElement,
  ownerDocument,
  ownerWindow,
  requirePropFactory,
  setRef,
  unstable_useEnhancedEffect: useEnhancedEffect,
  unstable_useId: useId,
  unsupportedProp,
  useControlled,
  useEventCallback,
  useForkRef,
  useIsFocusVisible
});
var interopRequireDefault = createCommonjsModule(function(module) {
  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
  module.exports = _interopRequireDefault, module.exports.__esModule = true, module.exports["default"] = module.exports;
});
var createSvgIcon = createCommonjsModule(function(module, exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
      return utils.createSvgIcon;
    }
  });
});
var require$$0 = createSvgIcon;
export {interopRequireDefault as i, require$$0 as r};
