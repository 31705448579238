import React from "../../../../../_snowpack/pkg/react.js";
import {Grid} from "../../../../../_snowpack/pkg/@mui/material.js";
import {Title} from "../../../../../core/components/shared/Title/Title.js";
import {MthColor} from "../../../../../core/enums/index.js";
import {Info} from "./Info.js";
import {SignatureComp} from "./Signature.js";
export const EnrollmentPacketInfo = () => {
  return /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    columnSpacing: 5,
    maxWidth: "100%"
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Title, {
    color: MthColor.SYSTEM_01,
    size: "small",
    fontWeight: "700"
  }, "Packet Info")), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12
  }, /* @__PURE__ */ React.createElement(Info, null)), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    md: 6,
    xs: 12
  }, /* @__PURE__ */ React.createElement(SignatureComp, null)));
};
