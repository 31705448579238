import React from "../../_snowpack/pkg/react.js";
export const PhoneInput = React.forwardRef((props, ref) => {
  const {value, ...otherProps} = props;
  let formattedValue = value ? value : "";
  const rawValue = formattedValue.replace(/\D+/g, "").slice(0, 10);
  if (rawValue.length > 6) {
    formattedValue = rawValue.replace(/(\d{3})(\d{3})(\d{1,4})/, "$1-$2-$3");
  } else if (rawValue.length > 3) {
    formattedValue = rawValue.replace(/(\d{3})(\d{1,3})/, "$1-$2");
  } else {
    formattedValue = rawValue;
  }
  return /* @__PURE__ */ React.createElement("input", {
    ref,
    value: formattedValue,
    ...otherProps
  });
});
