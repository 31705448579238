import React from "../../_snowpack/pkg/react.js";
import {Close} from "../../_snowpack/pkg/@mui/icons-material.js";
import {Box, Button, CircularProgress, Modal, Typography} from "../../_snowpack/pkg/@mui/material.js";
import {mthButtonClasses} from "../../styles/button.style.js";
import {useStyles} from "./styles.js";
export const MthModal = ({
  open,
  title,
  center = false,
  onClose,
  onConfirm,
  confirmStr = "Confirm",
  cancelStr = "Cancel",
  children,
  noCloseOnBackdrop = false,
  width,
  confirmBtnType = "roundSmallDark",
  cancelBtnType = "roundSmallGray",
  showBtnClose = false,
  showBtnConfirm = true,
  showBtnCancel = true,
  isCustomBtn = false,
  customStr = "",
  customBtnType = "primary",
  onCustom,
  loading = false
}) => {
  const onBackdropClick = () => {
    if (!noCloseOnBackdrop)
      onClose();
  };
  return /* @__PURE__ */ React.createElement(Modal, {
    open,
    "aria-labelledby": "child-modal-title",
    disableAutoFocus: true,
    "aria-describedby": "child-modal-description",
    onBackdropClick
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      width: width ? "calc(100% - 20px)" : "auto",
      maxWidth: width ? `${width}px` : "calc(100% - 20px)",
      ...useStyles.modal,
      px: {xs: 2, sm: 4},
      pt: {xs: 2, sm: 4}
    }
  }, showBtnClose && /* @__PURE__ */ React.createElement(Button, {
    className: "modal-btn-close",
    onClick: onClose
  }, /* @__PURE__ */ React.createElement(Close, {
    color: "inherit"
  })), /* @__PURE__ */ React.createElement(Box, {
    sx: {textAlign: center ? "center" : "left"}
  }, !!title && /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    fontWeight: "bold"
  }, title), loading && /* @__PURE__ */ React.createElement(Box, {
    sx: {textAlign: "center", width: "400px", padding: 5}
  }, /* @__PURE__ */ React.createElement(CircularProgress, {
    sx: {"svg circle": {stroke: "url(#mth_loading_gradient)"}}
  })), !loading && /* @__PURE__ */ React.createElement(React.Fragment, null, children, (showBtnCancel || showBtnConfirm) && /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", justifyContent: "center", marginTop: "30px", gap: "40px"}
  }, showBtnCancel && /* @__PURE__ */ React.createElement(Button, {
    sx: {...useStyles.button, ...mthButtonClasses[cancelBtnType]},
    onClick: onClose
  }, cancelStr), showBtnConfirm && /* @__PURE__ */ React.createElement(Button, {
    sx: {...useStyles.button, ...mthButtonClasses[confirmBtnType]},
    onClick: onConfirm
  }, confirmStr)), isCustomBtn && /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", justifyContent: "center", marginTop: "60px"}
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: mthButtonClasses[customBtnType],
    onClick: onCustom,
    type: "button"
  }, customStr))))));
};
