import {
  isAfter,
  isBefore,
  format,
  addDays as addDays_,
  subDays,
  isValid,
  addYears as addYears_,
  isDate,
  compareAsc,
  compareDesc,
  parse,
  endOfDay as endOfDay_,
  startOfDay as startOfDay_,
  isEqual
} from "../../../_snowpack/pkg/date-fns.js";
import {utcToZonedTime, zonedTimeToUtc as _zonedTimeToUtc} from "../../../_snowpack/pkg/date-fns-tz.js";
import dayjs from "../../../_snowpack/pkg/dayjs.js";
import timezone from "../../../_snowpack/pkg/dayjs/plugin/timezone.js";
import utc from "../../../_snowpack/pkg/dayjs/plugin/utc.js";
import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {EMPTY_STRING, STRING_TYPE, localZonedIsoDate} from "../../constants/index.js";
import {MthTitle, Order} from "../../enums/index.js";
import {datePatterns, dateSettings} from "../../enums/index.js";
dayjs.extend(utc);
dayjs.extend(timezone);
export const nowBetweenUtcInterval = (begin, end) => {
  try {
    return afterNow(end) && beforeNow(begin);
  } catch (error) {
    return false;
  }
};
export const nowBetweenUtcIntervalFromString = (begin, end) => {
  if (isNil(begin) || isNil(end)) {
    return false;
  }
  try {
    return afterNow(new Date(end)) && beforeNow(new Date(begin));
  } catch (error) {
    return false;
  }
};
export const beforeNow = (date) => {
  return isBefore(date, new Date());
};
export const afterNow = (date) => {
  return isAfter(date, new Date());
};
export const addTimeToDate = (date, hours, minutes, seconds) => {
  return new Date(date.setHours(date.getHours() + hours, date.getMinutes() + minutes, seconds));
};
export const isStartOfDay = (date) => {
  return isEqual(date, startOfDay(date));
};
export const isEndOfDay = (date) => {
  return isEqual(date, new Date(endOfDay(date).setMilliseconds(0)));
};
export const toUTCString = (date, timezone2) => {
  const utcDate = zonedTimeToUtc(new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()), timezone2);
  return utcDate.toISOString();
};
export const utcToTimezoned = (date, timezone2) => {
  return utcToZonedTime(date, timezone2);
};
export const formatUtcToTzByPattern = (utcDate, timezone2, pattern) => {
  try {
    const date = utcToTimezoned(utcDate, timezone2);
    return format(date, pattern);
  } catch (error) {
    console.error("An error occurred while converting UTC date to formatted timezone");
    console.error(error);
    return "";
  }
};
export const startOfDay = (date) => {
  return startOfDay_(date);
};
export const formatDateByPattern = (date, pattern) => {
  return format(date, pattern);
};
export const formatDateUtc = (date) => {
  try {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return null;
    }
    return utcToZonedTime(date, dateSettings.UTC);
  } catch {
    return null;
  }
};
export const formatDateByPatternUtc = (date, pattern) => {
  const utcDate = utcToZonedTime(date, dateSettings.UTC);
  return format(utcDate, pattern);
};
export const addDays = (date, amount) => {
  return addDays_(date, amount);
};
export const addYears = (date, amountYears) => {
  return addYears_(date, amountYears);
};
export const substractDays = (date, amount) => {
  return subDays(date, amount);
};
export const concatDateAndTime = (date, hour) => {
  const date_ = new Date(date);
  const hourSplit = hour.split(":");
  date_.setHours(Number(hourSplit[0]), Number(hourSplit[1]));
  return date_;
};
export const utcDateFromString = (date) => {
  return utcToZonedTime(date, dateSettings.UTC);
};
export const utcDateFromDate = (date) => {
  return utcToZonedTime(date, dateSettings.UTC);
};
export const endOfDay = (date) => {
  return endOfDay_(date);
};
export const nowBetweenInterval = (begin, end, timezone2) => {
  try {
    const begin_ = startOfDay(utcDateFromString(begin));
    const end_ = endOfDay(utcDateFromString(end));
    const now = utcToTimezoned(new Date(), timezone2);
    return now >= begin_ && now <= end_;
  } catch (error) {
    return false;
  }
};
export const dateBetweenInterval = (date, begin, end) => {
  try {
    const begin_ = startOfDay(utcDateFromString(begin));
    const end_ = endOfDay(utcDateFromString(end));
    const date_ = utcDateFromString(date);
    return date_ >= begin_ && date_ <= end_;
  } catch (error) {
    console.error("An error occurred while checking date interval:");
    console.error(error);
    return false;
  }
};
export const onlyDateISOString = (date) => {
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${date.getFullYear()}-${month}-${day}T00:00:00.000Z`;
};
export const isValidDate = (value) => {
  if (!Boolean(value)) {
    return false;
  }
  try {
    const formats = Object.values(datePatterns);
    if (isValid(value)) {
      return true;
    }
    if (typeof value === STRING_TYPE && value.match(localZonedIsoDate)) {
      return true;
    }
    for (const format2 of formats) {
      const parsedDate = parse(value, format2, new Date());
      if (isValid(parsedDate)) {
        return true;
      }
    }
  } catch (e) {
    console.error(e);
  }
  return false;
};
export const isTypeDate = (value) => {
  return isDate(value);
};
export const compare = (date1, date2, order = Order.ASC) => {
  return order === Order.ASC ? compareAsc(date1, date2) : compareDesc(date1, date2);
};
export const getFirstDayAndLastDayOfMonth = (date = new Date()) => {
  const CALENDAR_CELL_COUNT = 42;
  const CALENDAR_CELL_START = 1;
  const CALENDAR_END = 36;
  const DAY_OF_WEEK_SUNDAY = 0;
  const DAYS_IN_WEEK = 7;
  const calendarDays = [];
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const weekdayOfFirstDay = firstDayOfMonth.getDay();
  for (let cell = CALENDAR_CELL_START; cell <= CALENDAR_CELL_COUNT; cell++) {
    if (cell === CALENDAR_CELL_START && weekdayOfFirstDay === DAY_OF_WEEK_SUNDAY) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - (DAYS_IN_WEEK - 1));
    } else if (cell === 1) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (cell - weekdayOfFirstDay));
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }
    if (cell === DAYS_IN_WEEK && firstDayOfMonth.getMonth() !== date.getMonth()) {
      calendarDays.splice(0);
      continue;
    }
    if (cell === CALENDAR_END && firstDayOfMonth.getMonth() !== date.getMonth()) {
      break;
    }
    calendarDays.push(new Date(firstDayOfMonth));
  }
  return {firstDay: calendarDays?.at(0), lastDay: calendarDays?.at(-1)};
};
export const calcAge = (birth) => {
  if (birth) {
    return dayjs().tz("UTC").diff(birth, "years");
  }
  return 0;
};
export const getTimezoneOffsetStr = (offsetMin) => {
  const h = Math.floor(Math.abs(offsetMin) / 60);
  const m = Math.abs(offsetMin) % 60;
  return `${offsetMin > 0 ? "-" : "+"}${h}:${m}`;
};
export const showDate = (date, format2 = "MM/DD/YYYY") => {
  if (date) {
    return dayjs(date).tz("UTC").format(format2);
  }
  return "";
};
export const formatTwoDigits = (num) => {
  return num.toString().padStart(2, "0");
};
export const formatTime = (dateString) => {
  if (!dateString)
    return "";
  const date = new Date(dateString);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };
  return date.toLocaleString("en-US", options);
};
export const formatMonthDays = (dateString) => {
  if (!dateString)
    return "";
  const date = new Date(dateString);
  const options = {
    month: "long",
    day: "numeric"
  };
  return date.toLocaleString("en-US", options);
};
export const formatSchoolYear = (date_begin, date_end, timezone2) => {
  const begin = formatDateByPattern(utcToTimezoned(new Date(date_begin), timezone2), datePatterns.FULL_YEAR);
  const end = formatDateByPattern(utcToTimezoned(new Date(date_end), timezone2), datePatterns.SHORT_YEAR);
  return `${begin}-${end}`;
};
export const formatSchoolYearWithAddedYears = (dateBegin, dateEnd, midYear, timezone2, amount) => {
  if (!dateBegin || !dateEnd) {
    return EMPTY_STRING;
  }
  const nextBeginYear = utcToTimezoned(addYears(new Date(dateBegin), amount), timezone2);
  const nextEndYear = utcToTimezoned(addYears(new Date(dateEnd), amount), timezone2);
  if (midYear) {
    return `${formatDateByPattern(nextBeginYear, datePatterns.FULL_YEAR)}-${formatDateByPattern(nextEndYear, datePatterns.SHORT_YEAR)} ${MthTitle.MID_YEAR}`;
  }
  return `${formatDateByPattern(nextBeginYear, datePatterns.FULL_YEAR)}-${formatDateByPattern(nextEndYear, datePatterns.SHORT_YEAR)}`;
};
export const validateDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return {valid: false, message: "Both start date and end date are required."};
  }
  const currentDate = startOfDay(new Date());
  if (isBefore(startDate, currentDate)) {
    return {valid: false, message: "Start date cannot be in the past."};
  }
  if (isBefore(endDate, currentDate)) {
    return {valid: false, message: "End date cannot be in the past."};
  }
  if (isBefore(endDate, startDate)) {
    return {valid: false, message: "End date must be after start date."};
  }
  return {valid: true, message: "Date range is valid."};
};
export const zonedTimeToUtc = (date, tz) => {
  return _zonedTimeToUtc(date, tz);
};
export function splitFormatDate(date) {
  if (date) {
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear().toString().slice(-2);
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = formattedDate.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  }
  return "";
}
export function formatDateForInput(date) {
  if (date) {
    return dayjs(new Date(date)).utc().format("YYYY-MM-DD");
  }
  return "";
}
