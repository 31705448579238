import {useMemo} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {isEmpty, isNil} from "../../../_snowpack/pkg/lodash.js";
import {useDebounceCallback} from "../../../_snowpack/pkg/usehooks-ts.js";
import {isEmail} from "../../constants/index.js";
import {emailTakenQuery} from "../../../graphql/queries/email-taken.js";
export const useCheckEmailTaken = (waitTime = 2e3, fetchPolicy = "network-only", isStundentInUse = void 0) => {
  const [checkEmail, {loading, data, error}] = useLazyQuery(emailTakenQuery, {
    fetchPolicy
  });
  const checkEmailDebounced = useDebounceCallback(async (email, index, validateSecondaryEmails = false, excludePersonIdSecondaryEmails) => {
    if (!isNil(email) && isEmail.test(email)) {
      if (isStundentInUse && !isNil(index)) {
        await checkEmail({
          variables: {
            emailTakenInput: {
              email,
              validateSecondaryEmails,
              excludePersonIdSecondaryEmails
            }
          }
        }).then((res) => {
          const emailInUse = res.data.emailTaken && !isEmpty(email);
          isStundentInUse(emailInUse, index);
        });
        return;
      }
      checkEmail({
        variables: {
          emailTakenInput: {
            email,
            validateSecondaryEmails,
            excludePersonIdSecondaryEmails
          }
        }
      });
    }
  }, waitTime);
  const result = useMemo(() => !loading && !isNil(data) && data.emailTaken, [data, loading]);
  return [checkEmailDebounced, {loading, result, error: !isNil(error)}];
};
