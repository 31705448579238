export var LearningLogQuestionActions;
(function(LearningLogQuestionActions2) {
  LearningLogQuestionActions2[LearningLogQuestionActions2["CONTINUE"] = 1] = "CONTINUE";
  LearningLogQuestionActions2[LearningLogQuestionActions2["SHOW_CONDITIONAL_QUESTION"] = 2] = "SHOW_CONDITIONAL_QUESTION";
})(LearningLogQuestionActions || (LearningLogQuestionActions = {}));
export var LearningLogGradeDisplay;
(function(LearningLogGradeDisplay2) {
  LearningLogGradeDisplay2["EXCUSED"] = "EX";
  LearningLogGradeDisplay2["NANDA"] = "N/A";
})(LearningLogGradeDisplay || (LearningLogGradeDisplay = {}));
