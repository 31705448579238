import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getPeriods} from "../../../screens/Admin/Curriculum/services.js";
import {gradeShortText} from "../../utils/index.js";
export const usePeriods = (schoolYearId, archived, keyword, preSelectedIds) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [checkBoxItems, setCheckBoxItems] = useState([]);
  const [periods, setPeriods] = useState([]);
  const {loading, data, error, refetch} = useQuery(getPeriods, {
    variables: {school_year_id: +schoolYearId, archived, keyword},
    skip: !schoolYearId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (data?.periods) {
      const {periods: periods2} = data;
      setDropdownItems((periods2 || []).map((item) => ({
        label: `Period ${item.period} - ${item.category}`,
        value: item.id.toString()
      })));
      setCheckBoxItems((periods2 || []).filter((item) => !item.archived || preSelectedIds?.includes(item.id)).map((item) => ({
        label: `Period ${item.period} - ${item.category} (${item.min_grade === item.max_grade ? gradeShortText(item.min_grade) : gradeShortText(item.min_grade) + "-" + gradeShortText(item.max_grade)})`,
        value: item.id.toString(),
        disabled: item.archived
      })));
      setPeriods(periods2 || []);
    }
  }, [loading, data]);
  return {
    loading,
    periods,
    dropdownItems,
    checkBoxItems,
    error,
    refetch
  };
};
