export var WithdrawalStatus;
(function(WithdrawalStatus2) {
  WithdrawalStatus2["NOTIFIED"] = "Notified";
  WithdrawalStatus2["WITHDRAWN"] = "Withdrawn";
  WithdrawalStatus2["REQUESTED"] = "Requested";
})(WithdrawalStatus || (WithdrawalStatus = {}));
export var MidYear;
(function(MidYear2) {
  MidYear2["MID"] = "mid";
  MidYear2["NONMID"] = "non-mid";
})(MidYear || (MidYear = {}));
