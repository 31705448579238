export var ToDoCategory;
(function(ToDoCategory2) {
  ToDoCategory2["SUBMIT_ENROLLMENT_PACKET"] = "SUBMIT_ENROLLMENT_PACKET";
  ToDoCategory2["RESUBMIT_ENROLLMENT_PACKET"] = "RESUBMIT_ENROLLMENT_PACKET";
  ToDoCategory2["SUBMIT_SCHEDULE"] = "SUBMIT_SCHEDULE";
  ToDoCategory2["RESUBMIT_SCHEDULE"] = "RESUBMIT_SCHEDULE";
  ToDoCategory2["RESUBMIT_REIMBURSEMENT"] = "RESUBMIT_REIMBURSEMENT";
  ToDoCategory2["RESUBMIT_DIRECT_ORDER"] = "RESUBMIT_DIRECT_ORDER";
  ToDoCategory2["TESTING_PREFERNCE"] = "TESTING_PREFERNCE";
  ToDoCategory2["MISSING_LEARNING_LOG"] = "MISSING_LEARNING_LOG";
  ToDoCategory2["RESUBMIT_LEARNING_LOG"] = "RESUBMIT_LEARNING_LOG";
  ToDoCategory2["INTENT_TO_RE_ENROLL"] = "INTENT_TO_RE_ENROLL";
  ToDoCategory2["SUBMIT_INTENT_TO_RE_ENROLL"] = "SUBMIT_INTENT_TO_RE_ENROLL";
  ToDoCategory2["REQUEST_HOMEROOM_RESOURCES"] = "REQUEST_HOMEROOM_RESOURCES";
  ToDoCategory2["SUBMIT_WITHDRAW"] = "SUBMIT_WITHDRAW";
  ToDoCategory2["SUBMIT_SECOND_SEMESTER_SCHEDULE"] = "SUBMIT_SECOND_SEMESTER_SCHEDULE";
  ToDoCategory2["RESUBMIT_SECOND_SEMESTER_SCHEDULE"] = "RESUBMIT_SECOND_SEMESTER_SCHEDULE";
})(ToDoCategory || (ToDoCategory = {}));
