export var datePatterns;
(function(datePatterns2) {
  datePatterns2["UNIX_TIMESTAMP"] = "T";
  datePatterns2["HOUR_MINUTE"] = "HH:mm";
  datePatterns2["ZMONTH_ZDAY_4DYEAR"] = "MM/dd/yyyy";
  datePatterns2["MONTH_NAME_ORDINAL_DAY_4DYEAR"] = "MMMM do, yyyy";
  datePatterns2["MONTH_NAME_DAY_4DYEAR"] = "MMMM d, yyyy";
  datePatterns2["ABRV_MONTH_NAME_ORDINAL_DAY_4DYEAR"] = "MMM do, yyyy";
  datePatterns2["ZMONTH_ZDAY_2DYEAR"] = "MM/dd/yy";
  datePatterns2["MONTH_DAY_4DYEAR"] = "M/d/yyyy";
  datePatterns2["FULL_YEAR"] = "yyyy";
  datePatterns2["SHORT_YEAR"] = "yy";
  datePatterns2["MONTH"] = "L";
  datePatterns2["DAY"] = "d";
  datePatterns2["DAY_OF_WEEK"] = "c";
  datePatterns2["YEAR_ZMONTH_ZDAY"] = "yyyy-MM-dd";
  datePatterns2["MONTH_NAME_DAY"] = "MMMM dd";
  datePatterns2["TIME_12_HOURS"] = "hh:mm a";
  datePatterns2["EEE"] = "EEE";
  datePatterns2["EEEE"] = "EEEE";
  datePatterns2["MM_DOT_DD"] = "MM.dd";
  datePatterns2["MONTH_NAME_4DYEAR"] = "MMMM yyyy";
  datePatterns2["FULL_DAY_NAME"] = "cccc";
  datePatterns2["MYSQL"] = "yyyy-MM-dd HH:mm:ss";
  datePatterns2["SHORT_MONTH_YEAR"] = "MMM yyyy";
  datePatterns2["SHORT_MONTH_DAY"] = "MMM dd";
  datePatterns2["USMONTH_DAY_YEAR"] = "MM-dd-yyyy";
})(datePatterns || (datePatterns = {}));
