import {extractAllNumFromStr} from "../string/string.util.js";
export const convertWidth = (str, total) => {
  let returnVal = 0;
  if (typeof str === "string") {
    if (str.includes("calc")) {
      const nums = extractAllNumFromStr(str);
      returnVal = nums[0] * total / 100 - nums[1];
    } else if (str.includes("%")) {
      returnVal = Number(str.replace("%", "")) * total / 100;
    } else if (str.includes("px")) {
      returnVal = Number(str.replace("px", ""));
    }
  }
  return returnVal;
};
