import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getProvidersQuery} from "../../../screens/Admin/Curriculum/CourseCatalog/services.js";
export const useProviders = (schoolYearId, searchField = "", isActive = true, simplified = false, includeCourses = true) => {
  const [dropdownItems, setDropdownItems] = useState([]);
  const [checkBoxItems, setCheckBoxItems] = useState([]);
  const [providers, setProviders] = useState([]);
  const [courseWithResourceCheckBoxItems, setCourseWithResourceCheckBoxItems] = useState([]);
  const {loading, data, error, refetch} = useQuery(getProvidersQuery, {
    variables: {
      findProvidersInput: {schoolYearId, searchField, isActive, simplified, includeCourses}
    },
    skip: !schoolYearId,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (data?.providers) {
      const {providers: providers2} = data;
      setDropdownItems(providers2.map((item) => ({
        value: item.id,
        label: item.name
      })));
      setCheckBoxItems(providers2.map((item) => ({
        value: item.id.toString(),
        label: item.name
      })));
      const courses = [];
      providers2.map((provider) => {
        provider.Courses?.map((course) => {
          if (course.resource_id) {
            courses.push({
              value: course.id.toString(),
              label: `${provider.name} - ${course.name}`
            });
          }
        });
      });
      setCourseWithResourceCheckBoxItems(courses);
      setProviders(providers2);
    }
  }, [loading, data]);
  return {
    loading,
    providers,
    dropdownItems,
    checkBoxItems,
    courseWithResourceCheckBoxItems,
    error,
    refetch
  };
};
