export var DisplayOnUsirOptions;
(function(DisplayOnUsirOptions2) {
  DisplayOnUsirOptions2["FIRST_DTP_DTAP_DT_TD_TDAP"] = "1st DTP, DTaP, DT, Td, Tdap";
  DisplayOnUsirOptions2["SECOND_DTP_DTAP_DT_TD_TDAP"] = "2nd DTP, DTaP, DT, Td, Tdap";
  DisplayOnUsirOptions2["THIRD_DTP_DTAP_DT_TD_TDAP"] = "3rd DTP, DTaP, DT, Td, Tdap";
  DisplayOnUsirOptions2["FOURTH_DTP_DTAP_DT_TD_TDAP"] = "4th DTP, DTaP, DT, Td, Tdap";
  DisplayOnUsirOptions2["FIFTH_DTP_DTAP_DT_TD_TDAP"] = "5th DTP, DTaP, DT, Td, Tdap";
  DisplayOnUsirOptions2["FIRST_HAEMOPHILUS_INFLUENZA_TYPE_B_HIB"] = "1st Haemophilus influenza type b (Hib)";
  DisplayOnUsirOptions2["SECOND_HAEMOPHILUS_INFLUENZA_TYPE_B_HIB"] = "2nd Haemophilus influenza type b (Hib)";
  DisplayOnUsirOptions2["THIRD_HAEMOPHILUS_INFLUENZA_TYPE_B_HIB"] = "3rd Haemophilus influenza type b (Hib)";
  DisplayOnUsirOptions2["FORUTH_HAEMOPHILUS_INFLUENZA_TYPE_B_HIB"] = "4th Haemophilus influenza type b (Hib)";
  DisplayOnUsirOptions2["FIFTH_HAEMOPHILUS_INFLUENZA_TYPE_B_HIB"] = "5th Haemophilus influenza type b (Hib)";
  DisplayOnUsirOptions2["FIRST_HEPATITIS_A_HAV"] = "1st Hepatitis A (HAV)";
  DisplayOnUsirOptions2["SECOND_HEPATITIS_A_HAV"] = "2nd Hepatitis A (HAV)";
  DisplayOnUsirOptions2["FIRST_HEPATITIS_B_HBV"] = "1st Hepatitis B (HBV)";
  DisplayOnUsirOptions2["SECOND_HEPATITIS_B_HBV"] = "2nd Hepatitis B (HBV)";
  DisplayOnUsirOptions2["THIRD_HEPATITIS_B_HBV"] = "3rd Hepatitis B (HBV)";
  DisplayOnUsirOptions2["FIRST_POLIO_IPV_OR_OPV"] = "1st Polio (IPV or OPV)";
  DisplayOnUsirOptions2["SECOND_POLIO_IPV_OR_OPV"] = "2nd Polio (IPV or OPV)";
  DisplayOnUsirOptions2["THIRD_POLIO_IPV_OR_OPV"] = "3rd Polio (IPV or OPV)";
  DisplayOnUsirOptions2["FOURTH_POLIO_IPV_OR_OPV"] = "4th Polio (IPV or OPV)";
  DisplayOnUsirOptions2["FIRST_PNEUMOCOCCAL"] = "1st Pneumococcal";
  DisplayOnUsirOptions2["SECOND_PNEUMOCOCCAL"] = "2nd Pneumococcal";
  DisplayOnUsirOptions2["THIRD_PNEUMOCOCCAL"] = "3rd Pneumococcal";
  DisplayOnUsirOptions2["FOURTH_PNEUMOCOCCAL"] = "4th Pneumococcal";
  DisplayOnUsirOptions2["FIRST_MEASLES_MUMPS_AND_RUBELLA_MMR"] = "1st Measles, Mumps, and Rubella (MMR)";
  DisplayOnUsirOptions2["SECOND_MEASLES_MUMPS_AND_RUBELLA_MMR"] = "2nd Measles, Mumps, and Rubella (MMR)";
  DisplayOnUsirOptions2["FIRST_MENINGOCOCCAL"] = "1st Meningococcal";
  DisplayOnUsirOptions2["FIRST_VARICELLA_CHICKENPOX"] = "1st Varicella (Chickenpox)*";
  DisplayOnUsirOptions2["SECOND_VARICELLA_CHICKENPOX"] = "2nd Varicella (Chickenpox)*";
  DisplayOnUsirOptions2["TDAP"] = "Tdap";
})(DisplayOnUsirOptions || (DisplayOnUsirOptions = {}));
