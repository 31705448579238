import {_ as _extends} from "./extends-1379b0c5.js";
function resolveProps(defaultProps, props) {
  const output = _extends({}, props);
  Object.keys(defaultProps).forEach((propName) => {
    if (output[propName] === void 0) {
      output[propName] = defaultProps[propName];
    }
  });
  return output;
}
function getThemeProps(params) {
  const {
    theme,
    name,
    props
  } = params;
  if (!theme || !theme.components || !theme.components[name] || !theme.components[name].defaultProps) {
    return props;
  }
  return resolveProps(theme.components[name].defaultProps, props);
}
export {getThemeProps as g, resolveProps as r};
