import {ReportTitle, RequestName} from "../enums/index.js";
export const COLUMN_LABEL = {
  [RequestName.STUDENT_LEGAL]: ReportTitle.STUDENT_LEGAL,
  [RequestName.GRADE]: ReportTitle.GRADE,
  [RequestName.SOE]: ReportTitle.SOE,
  [RequestName.ASSESSMENT]: ReportTitle.ASSESSMENT,
  [RequestName.OPT_IN]: ReportTitle.OPT_IN,
  [RequestName.OPT_OUT]: ReportTitle.OPT_OUT,
  [RequestName.DATE]: ReportTitle.DATE,
  [RequestName.PARENT]: ReportTitle.PARENT,
  [RequestName.PHONE]: ReportTitle.PHONE,
  [RequestName.EMAIL]: ReportTitle.EMAIL,
  [RequestName.UNIQUE_ID]: ReportTitle.UNIQUE_ID
};
