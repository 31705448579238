import axios from "../../_snowpack/pkg/axios.js";
import {isNil} from "../../_snowpack/pkg/lodash.js";
import {SNOWPACK_PUBLIC_API_V3_URL} from "../envs/index.js";
export const generateSoeTransferPdf = async (schoolYearId, studentId) => {
  try {
    const token = localStorage.getItem("JWT");
    if (isNil(token)) {
      throw new Error("Token is empty");
    }
    const url = `${SNOWPACK_PUBLIC_API_V3_URL}/file-service/soe-transfer/generate-student-form/scholyear/${schoolYearId}/studentId/${studentId}`;
    return await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error("An error occured obtaining signed URL");
    console.error(error);
    throw error;
  }
};
