import {r as react} from "./index-c6c556cc.js";
import {j as jsxRuntime} from "./jsx-runtime-c4f7c71f.js";
const MuiPickersAdapterContext = /* @__PURE__ */ react.createContext(null);
function LocalizationProvider(props) {
  const {
    children,
    dateAdapter: Utils,
    dateFormats,
    dateLibInstance,
    locale
  } = props;
  const utils = react.useMemo(() => new Utils({
    locale,
    formats: dateFormats,
    instance: dateLibInstance
  }), [Utils, locale, dateFormats, dateLibInstance]);
  const defaultDates = react.useMemo(() => {
    return {
      minDate: utils.date("1900-01-01T00:00:00.000"),
      maxDate: utils.date("2099-12-31T00:00:00.000")
    };
  }, [utils]);
  const contextValue = react.useMemo(() => {
    return {
      utils,
      defaultDates
    };
  }, [defaultDates, utils]);
  return /* @__PURE__ */ jsxRuntime.jsx(MuiPickersAdapterContext.Provider, {
    value: contextValue,
    children
  });
}
export {LocalizationProvider as L, MuiPickersAdapterContext as M};
