export var CartEventType;
(function(CartEventType2) {
  CartEventType2["ADD"] = "add";
  CartEventType2["ALLOW_REQUEST"] = "allowRequest";
  CartEventType2["DISALLOW_REQUEST"] = "disallowRequest";
  CartEventType2["ARCHIVE"] = "archive";
  CartEventType2["DELETE"] = "delete";
  CartEventType2["DUPLICATE"] = "duplicate";
  CartEventType2["EDIT"] = "edit";
  CartEventType2["RESTORE"] = "restore";
  CartEventType2["HIDE"] = "hide";
  CartEventType2["UN_HIDE"] = "unHide";
  CartEventType2["ADD_CART"] = "addCart";
  CartEventType2["REMOVE_CART"] = "removeCart";
  CartEventType2["CLICK"] = "click";
  CartEventType2["DETAILS"] = "details";
  CartEventType2["UNARCHIVE"] = "unarchive";
})(CartEventType || (CartEventType = {}));
