export var ReimbursementTransactionStatus;
(function(ReimbursementTransactionStatus2) {
  ReimbursementTransactionStatus2["CREATED"] = "Created";
  ReimbursementTransactionStatus2["SCHEDULED"] = "Scheduled";
  ReimbursementTransactionStatus2["PENDING_ACCOUNT_ACTIVATION"] = "Pending Account Activation";
  ReimbursementTransactionStatus2["PENDING_TAX_VERIFICATION"] = "Pending Tax Verification";
  ReimbursementTransactionStatus2["PENDING_TRANSFER_METHOD_ACTION"] = "Pending Transfer Method Action";
  ReimbursementTransactionStatus2["PENDING_TRANSACTION_VERIFICATION"] = "Pending Transaction Verification";
  ReimbursementTransactionStatus2["IN_PROGRESS"] = "In Progress";
  ReimbursementTransactionStatus2["UNCLAIMED"] = "Unclaimed";
  ReimbursementTransactionStatus2["COMPLETED"] = "Completed";
  ReimbursementTransactionStatus2["CANCELLED"] = "Cancelled";
  ReimbursementTransactionStatus2["FAILED"] = "Failed";
  ReimbursementTransactionStatus2["RECALLED"] = "Recalled";
  ReimbursementTransactionStatus2["RETURNED"] = "Returned";
  ReimbursementTransactionStatus2["EXPIRED"] = "Expired";
})(ReimbursementTransactionStatus || (ReimbursementTransactionStatus = {}));
