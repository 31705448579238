import {StudentStatus} from "../../enums/student-status.enum.js";
export const haveActiveStudents = (me, schoolyear) => {
  if (!me || !me.students || me.students.length < 1)
    return false;
  const activeStudents = me.students.find((student) => {
    const aux_student = student.status.find((studentStatus) => studentStatus.school_year_id === schoolyear && studentStatus.status === StudentStatus.ACTIVE);
    if (aux_student)
      return true;
    return false;
  });
  if (activeStudents)
    return true;
  return false;
};
