import React from "../../../../_snowpack/pkg/react.js";
import {Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import {TEXT_SIZE} from "../../../enums/index.js";
export const Subtitle = ({
  testId,
  textAlign,
  children,
  size,
  color,
  fontWeight,
  sx,
  onClick,
  className,
  lineHeight
}) => {
  const fontSize = () => size === TEXT_SIZE.EXTRA_LARGE ? 25 : size === TEXT_SIZE.LARGE ? 20 : size === TEXT_SIZE.MEDIUM ? 18 : 16;
  return /* @__PURE__ */ React.createElement(Typography, {
    "data-testid": testId,
    textAlign,
    sx,
    fontWeight,
    color,
    fontSize,
    onClick,
    className,
    lineHeight
  }, children);
};
