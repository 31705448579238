export var MthFilterSlug;
(function(MthFilterSlug2) {
  MthFilterSlug2["PROGRAM_YEAR"] = "program-year";
  MthFilterSlug2["FOR_PROGRAM_YEAR_PREPEND"] = "for-program-year-";
  MthFilterSlug2["MID_YEAR_APPEND"] = "-midyear";
  MthFilterSlug2["STATUS"] = "status";
  MthFilterSlug2["GRADE_LEVEL"] = "grade-level";
  MthFilterSlug2["SOE"] = "soe";
  MthFilterSlug2["SPECIAL_ED"] = "special-ed";
})(MthFilterSlug || (MthFilterSlug = {}));
