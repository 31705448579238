export var ReimbursementRequestType;
(function(ReimbursementRequestType2) {
  ReimbursementRequestType2["DIRECT_ORDER"] = "DIRECT_ORDER";
  ReimbursementRequestType2["REIMBURSEMENT"] = "REIMBURSEMENT";
  ReimbursementRequestType2["DIRECT_DEDUCTION"] = "DIRECT_DEDUCTION";
})(ReimbursementRequestType || (ReimbursementRequestType = {}));
export var ReimbursementType;
(function(ReimbursementType2) {
  ReimbursementType2[ReimbursementType2["REIMBURSEMENT"] = 0] = "REIMBURSEMENT";
  ReimbursementType2[ReimbursementType2["DIRECT_ORDER"] = 1] = "DIRECT_ORDER";
  ReimbursementType2[ReimbursementType2["DIRECT_DEDUCTION"] = 2] = "DIRECT_DEDUCTION";
})(ReimbursementType || (ReimbursementType = {}));
export var ReimbursementTypeLabel;
(function(ReimbursementTypeLabel2) {
  ReimbursementTypeLabel2["REIMBURSEMENT"] = "Reimbursement";
  ReimbursementTypeLabel2["DIRECT_ORDER"] = "Direct Order";
  ReimbursementTypeLabel2["DIRECT_DEDUCTION"] = "Direct Deduction";
})(ReimbursementTypeLabel || (ReimbursementTypeLabel = {}));
export var ReimbursementRequestTypeAbr;
(function(ReimbursementRequestTypeAbr2) {
  ReimbursementRequestTypeAbr2["DIRECT_ORDER"] = "DO";
  ReimbursementRequestTypeAbr2["REIMBURSEMENT"] = "RB";
  ReimbursementRequestTypeAbr2["DIRECT_DEDUCTION"] = "DD";
})(ReimbursementRequestTypeAbr || (ReimbursementRequestTypeAbr = {}));
export var ReimbursementCustomBuiltType;
(function(ReimbursementCustomBuiltType2) {
  ReimbursementCustomBuiltType2["SINGLE_PERIOD"] = "SINGLE";
  ReimbursementCustomBuiltType2["MERGED_PERIOD"] = "MERGED";
})(ReimbursementCustomBuiltType || (ReimbursementCustomBuiltType = {}));
export var ReceiptApplicable;
(function(ReceiptApplicable2) {
  ReceiptApplicable2[ReceiptApplicable2["APPLICABLE"] = 1] = "APPLICABLE";
  ReceiptApplicable2[ReceiptApplicable2["NOT_APPLICABLE"] = 0] = "NOT_APPLICABLE";
})(ReceiptApplicable || (ReceiptApplicable = {}));
