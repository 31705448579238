import React from "../../../_snowpack/pkg/react.js";
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Checkbox, Radio} from "../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../_snowpack/pkg/@mui/system.js";
import {map} from "../../../_snowpack/pkg/lodash.js";
import {MthColor} from "../../../core/enums/index.js";
import {Paragraph} from "../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../core/components/shared/Subtitle/Subtitle.js";
export const StandardResponses = ({
  options,
  type,
  standardResponses,
  setStandardResponses
}) => {
  const SelectionComponent = type === "missingInfo" ? Checkbox : Radio;
  const checkResponse = (title, evt) => {
    const responses = [];
    if (SelectionComponent === Checkbox) {
      if (evt.target.checked) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].title === title)
            responses.push(options[i]);
          else {
            for (let j = 0; j < standardResponses.length; j++) {
              if (standardResponses[j].title === options[i].title)
                responses.push(standardResponses[j]);
            }
          }
        }
      } else {
        for (let i = 0; i < standardResponses.length; i++) {
          if (standardResponses[i].title !== title)
            responses.push(standardResponses[i]);
        }
      }
    } else if (SelectionComponent === Radio) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].title == title && evt.target.checked)
          responses.push(options[i]);
      }
    }
    setStandardResponses(responses);
  };
  const checkGroupResponse = (group, title, evt) => {
    const responses = [];
    if (evt.target.checked) {
      for (let i = 0; i < options.length; i++) {
        for (let j = 0; j < options[i].responses.length; j++) {
          if (options[i].responses[j].title == title)
            responses.push(options[i].responses[j]);
          else {
            for (let k = 0; k < standardResponses.length; k++) {
              if (standardResponses[k].title == options[i].responses[j].title)
                responses.push(standardResponses[k]);
            }
          }
        }
      }
    } else {
      for (let i = 0; i < standardResponses.length; i++) {
        if (standardResponses[i].title != title)
          responses.push(standardResponses[i]);
      }
    }
    setStandardResponses(responses);
  };
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "column"
    }
  }, /* @__PURE__ */ React.createElement(FormControl, {
    component: "fieldset"
  }, /* @__PURE__ */ React.createElement(FormLabel, {
    component: "legend"
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    size: "large",
    fontWeight: "700",
    color: MthColor.BLACK
  }, "Standard Responses")), /* @__PURE__ */ React.createElement(RadioGroup, {
    "aria-label": "gender",
    name: "radio-buttons-group"
  }, type !== "missingInfo" && map(options, (option, index) => !!option.title && /* @__PURE__ */ React.createElement(FormControlLabel, {
    key: index,
    value: option.title,
    control: /* @__PURE__ */ React.createElement(SelectionComponent, {
      onChange: (e) => {
        checkResponse(option.title, e);
      }
    }),
    label: /* @__PURE__ */ React.createElement(Paragraph, {
      size: "large"
    }, option.title),
    style: {fontSize: "12px"}
  })), type === "missingInfo" && map(options, (option) => map(option.responses, (response, index) => !!response.title && /* @__PURE__ */ React.createElement(FormControlLabel, {
    key: index,
    value: response.title,
    control: /* @__PURE__ */ React.createElement(SelectionComponent, {
      onChange: (e) => {
        checkGroupResponse(option.title, response.title, e);
      }
    }),
    label: /* @__PURE__ */ React.createElement(Paragraph, {
      size: "large"
    }, response.title),
    style: {fontSize: "12px"}
  }))))));
};
