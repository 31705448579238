export const isPhoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
export const isEmail = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
export const isNumber = /^[0-9\b]+$/;
export const getNumberBetweenBrackets = /\[(\d+)\]/;
export const base64Rex = /data:image\/[a-z]*;base64,[^"]+/g;
export const urlRex = RegExp("^(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");
export const urlPrefixRex = RegExp("^(https?://)");
export const isPrice = /^\d+(\.\d{0,2})?$/;
export const isDecimal = /^\d+(\.\d*)?$/;
export const emailAndNameRegex = /^(?:[a-zA-Z\s]+<[a-zA-Z0-9!#\$%&'\*\+/=\?\^_`{\|}~\.-]+@[a-zA-Z\.-]+\.[a-zA-Z]{2,}>|[a-zA-Z0-9!#\$%&'\*\+/=\?\^_`{\|}~\.-]+@[a-zA-Z\.-]+\.[a-zA-Z]{2,})\s*$/;
export const isValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
export const isExtraMergeHTMLTags = /<p[^>]+?>|<p>|<\/p>/g;
export const isDupNewLines = /(\r?\n){2,}/g;
export const paragraphRegex = /<p[^>]*>(.*?)<\/p>/gs;
export const emptyParagraphRegex = /<p><\/p>/g;
export const tagRegex = /<\/?[^>]+(>|$)/g;
export const spaceRegex = /\n/g;
export const spanRegex = /<\/span><span[^>]+>/g;
export const openSpanRegex = /<span[^>]*>/g;
export const openImgRegex = /^\s*<img\b/i;
export const openIframeRegex = /^\s*<iframe\b/i;
export const closeSpanRegex = /<\/span>/g;
export const styleRegex = /style\s*=\s*["'][^"']*?background-color\s*:\s*rgba\(.*?\)\s*;\s*?["']/gi;
export const htmlTag = /(<([^>]+)>)/gi;
export const htmlSpace = /&nbsp;/g;
export const point = /\./g;
export const nameAndEmailRegex = /(.*)<(.*)>/gi;
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
export const emptyArrayRegex = /\[\]/g;
export const emptyObjectRegex = /\{\}/g;
export const lastComma = /,([^,]*)$/;
export const periodInsertRegex = /\[PERIOD\]/g;
export const nonAlphaNumericTrim = /(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9]+$)/gi;
export const nonAlphaNumeric = /[^a-zA-Z0-9]+/gi;
export const newlineReplaceRegex = /(?:\r\n|\r|\n)/g;
export const orderConfirmationRegex = /(.*confirmation:)(.*)/gi;
export const aHrefSpanTag = /(<a[^>]*>)(<span[^>]*style="[^"]*)color: rgba\(0,0,0,0.87\)([^"]*"[^>]*>)/g;
export const localZonedIsoDate = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}\.\d{3})Z/g;
export const linkDetection = /((http|https):\/\/(([a-z0-9\-]+\.)+([a-z]{2}|biz|com|edu|gov|info|jobs|mil|name|net|org|local))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)/gi;
