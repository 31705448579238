export var StudentStatus;
(function(StudentStatus2) {
  StudentStatus2[StudentStatus2["INACTIVE"] = 4] = "INACTIVE";
  StudentStatus2[StudentStatus2["PENDING"] = 0] = "PENDING";
  StudentStatus2[StudentStatus2["ACTIVE"] = 1] = "ACTIVE";
  StudentStatus2[StudentStatus2["WITHDRAWN"] = 2] = "WITHDRAWN";
  StudentStatus2[StudentStatus2["GRADUATED"] = 3] = "GRADUATED";
  StudentStatus2[StudentStatus2["APPLIED"] = 5] = "APPLIED";
  StudentStatus2[StudentStatus2["ACCEPTED"] = 6] = "ACCEPTED";
  StudentStatus2[StudentStatus2["REAPPLIED"] = 7] = "REAPPLIED";
  StudentStatus2[StudentStatus2["DELETED"] = 8] = "DELETED";
  StudentStatus2[StudentStatus2["INELIGIBLE"] = 9] = "INELIGIBLE";
  StudentStatus2[StudentStatus2["COMPLETED"] = 10] = "COMPLETED";
  StudentStatus2[StudentStatus2["MID_YEAR"] = 50] = "MID_YEAR";
  StudentStatus2[StudentStatus2["NO_STATUS"] = -1] = "NO_STATUS";
})(StudentStatus || (StudentStatus = {}));
export var StudentStatusLabel;
(function(StudentStatusLabel2) {
  StudentStatusLabel2["PENDING"] = "Pending";
  StudentStatusLabel2["ACTIVE"] = "Active";
  StudentStatusLabel2["WITHDRAWN"] = "Withdrawn";
  StudentStatusLabel2["GRADUATED"] = "Graduated";
  StudentStatusLabel2["INACTIVE"] = "Inactive";
  StudentStatusLabel2["APPLIED"] = "Applied";
  StudentStatusLabel2["ACCEPTED"] = "Accepted";
  StudentStatusLabel2["REAPPLIED"] = "Reapplied";
  StudentStatusLabel2["DELETED"] = "Deleted";
  StudentStatusLabel2["CONDITIONAL"] = "Conditional";
  StudentStatusLabel2["REQUESTED"] = "Requested";
  StudentStatusLabel2["INELIGIBLE"] = "Ineligible";
  StudentStatusLabel2["COMPLETED"] = "Completed";
})(StudentStatusLabel || (StudentStatusLabel = {}));
