import React, {useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../../../_snowpack/pkg/@apollo/client.js";
import {Box, Typography} from "../../../../../_snowpack/pkg/@mui/material.js";
import {useFormContext} from "../../../../../_snowpack/pkg/react-hook-form.js";
import {MdArrowDropUp, MdArrowDropDown} from "../../../../../_snowpack/pkg/react-icons/md.js";
import {EMPTY_STRING} from "../../../../../core/constants/index.js";
import {StudentImmunizationsQuery} from "../../services.js";
import {getValidGrade} from "../helpers.js";
import {VaccinesInfoHeader} from "./Header.js";
import ImmunizationItem from "./ImmunizationItem.js";
export const EnrollmentPacketVaccineView = ({packet}) => {
  const {setValue} = useFormContext();
  const student = packet?.student;
  const {watch} = useFormContext();
  const immunizations = watch("immunizations");
  const schoolYearId = Number(packet.school_year_id);
  const {loading: immLoading, data: immData} = useQuery(StudentImmunizationsQuery, {
    variables: {
      student_id: Number(student?.student_id || 0),
      school_year_id: schoolYearId
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });
  useEffect(() => {
    if (!immLoading && immData?.StudentImmunizations && !immunizations.length && immData?.StudentImmunizations.length) {
      const levels = student?.grade_levels;
      const grade = getValidGrade(levels?.length ? levels[levels?.length - 1]?.grade_level : EMPTY_STRING);
      setValue("immunizations", immData?.StudentImmunizations.map((v) => {
        if (v.value)
          return v;
        const max_grade = getValidGrade(v?.immunization?.max_grade_level || "");
        const min_grade = getValidGrade(v?.immunization?.min_grade_level || "");
        const isNA = grade < min_grade || grade > max_grade;
        return {
          ...v,
          value: isNA ? "NA" : ""
        };
      }));
    }
  }, [immData, immLoading, immunizations]);
  return /* @__PURE__ */ React.createElement(VaccineView, null);
};
const VaccineView = () => {
  const [showImmunizations, setShowImmunizations] = useState(true);
  const {watch} = useFormContext();
  const immunizations = watch("immunizations");
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex"}
  }, showImmunizations ? /* @__PURE__ */ React.createElement(VaccinesInfoHeader, null) : /* @__PURE__ */ React.createElement(Typography, {
    sx: {
      width: "25rem",
      textAlign: "end",
      fontWeight: "bold",
      fontSize: "large",
      paddingRight: "10px"
    }
  }, "Vaccines"), /* @__PURE__ */ React.createElement(Box, {
    onClick: () => setShowImmunizations(!showImmunizations)
  }, showImmunizations ? /* @__PURE__ */ React.createElement(MdArrowDropUp, {
    size: "70",
    height: "30px"
  }) : /* @__PURE__ */ React.createElement(MdArrowDropDown, {
    size: "70",
    height: "30px"
  }))), showImmunizations && /* @__PURE__ */ React.createElement(Box, {
    display: "flex"
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {width: "250px"}
  }, immunizations && immunizations.slice(0, Math.ceil(immunizations.length / 2)).map((it) => /* @__PURE__ */ React.createElement(ImmunizationItem, {
    key: it.immunization_id,
    item: it
  }))), /* @__PURE__ */ React.createElement(Box, {
    sx: {width: "250px"}
  }, immunizations && immunizations.slice(Math.ceil(immunizations.length / 2), immunizations.length).map((it) => /* @__PURE__ */ React.createElement(ImmunizationItem, {
    key: it.immunization_id,
    item: it
  })))));
};
