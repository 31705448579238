import * as __SNOWPACK_ENV__ from "../../../_snowpack/env.js";
import {isNil} from "../../../_snowpack/pkg/lodash.js";
import {EMPTY_STRING, urlPrefixRex} from "../../constants/index.js";
export const convertExternalUrl = (url) => {
  if (!url)
    return "";
  if (urlPrefixRex.test(url))
    return url;
  return `//${url}`;
};
export const buildContentUrl = (path) => {
  if (isNil(path))
    return EMPTY_STRING;
  if (path.startsWith("http"))
    return path;
  if (path.trim() !== EMPTY_STRING) {
    return __SNOWPACK_ENV__.SNOWPACK_PUBLIC_BASE_S3_IMAGE_URL + path;
  } else {
    return EMPTY_STRING;
  }
};
