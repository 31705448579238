export var MthRoute;
(function(MthRoute2) {
  MthRoute2["ADMIN_APPLICATIONS"] = "/enrollment/applications";
  MthRoute2["ANNOUNCEMENTS"] = "/communication/announcements";
  MthRoute2["APPLICATIONS"] = "/applications";
  MthRoute2["CALENDAR"] = "/calendar";
  MthRoute2["COMMUNICATION"] = "/communication";
  MthRoute2["GRADEBOOK"] = "/gradebook";
  MthRoute2["CONFIRM_EMAIL"] = "/confirm";
  MthRoute2["DASHBOARD"] = "/";
  MthRoute2["EMAIL_RECORDS"] = "/communication/email-records";
  MthRoute2["ANNOUNCEMENT_POPUPS"] = "/communication/announcement-popups";
  MthRoute2["EMAIL_VERIFICATION"] = "/email-verification";
  MthRoute2["EMAILTEMPLATES"] = "/email-templates";
  MthRoute2["ENROLLMENT"] = "/enrollment";
  MthRoute2["ENROLLMENT_PACKETS"] = "/enrollment/enrollment-packets";
  MthRoute2["RESOURCE_REQUESTS"] = "/enrollment/resource-requests";
  MthRoute2["FORGOT_PASSWORD"] = "/forgot-password";
  MthRoute2["HOMEROOM"] = "/homeroom";
  MthRoute2["PARENT_LINK"] = "/parent-link";
  MthRoute2["RECORDS"] = "/records";
  MthRoute2["REIMBURSEMENTS"] = "/reimbursements";
  MthRoute2["REIMBURSEMENTS_REQUESTS"] = "/reimbursements/requests";
  MthRoute2["REIMBURSEMENTS_SETTINGS"] = "/reimbursements/settings";
  MthRoute2["REIMBURSEMENTS_REIMBURSEMENT_FORM"] = "/reimbursements/reimbursement_form";
  MthRoute2["REIMBURSEMENTS_DIRECT_ORDER_FORM"] = "/reimbursements/direct_order_form";
  MthRoute2["REIMBURSEMENTS_REIMBURSEMENT_VIEW"] = "/reimbursements/reimbursement_view";
  MthRoute2["REIMBURSEMENTS_DIRECT_ORDER_VIEW"] = "/reimbursements/direct_order_view";
  MthRoute2["CURRICULUM"] = "/curriculum";
  MthRoute2["CURRICULUM_COURSE_CATALOG"] = "/curriculum/course-catalog";
  MthRoute2["CURRICULUM_COURSE_CATALOG_SETTINGS"] = "/curriculum/course-catalog/settings";
  MthRoute2["CURRICULUM_COURSE_CATALOG_PERIODS"] = "/curriculum/course-catalog/periods";
  MthRoute2["CURRICULUM_COURSE_CATALOG_SUBJECTS"] = "/curriculum/course-catalog/subjects";
  MthRoute2["CURRICULUM_COURSE_CATALOG_PROVIDERS"] = "/curriculum/course-catalog/providers";
  MthRoute2["CURRICULUM_COURSE_CATALOG_STATE_CODES"] = "/curriculum/course-catalog/state-codes";
  MthRoute2["CURRICULUM_HOMEROOM_RESOURCES"] = "/curriculum/homeroom-resources";
  MthRoute2["HOMEROOM_LEARNING_LOGS"] = "/homeroom/learning-log";
  MthRoute2["LEARNING_LOG"] = "/learning-log";
  MthRoute2["GRADE_LL"] = "/grade";
  MthRoute2["HOMEROOM_ASSIGNMENTS"] = "/homeroom/assignments";
  MthRoute2["HOMEROOM_CHECKLIST"] = "/homeroom/checklist";
  MthRoute2["HOMEROOM_GRADEBOOK"] = "/homeroom/gradebook";
  MthRoute2["HOMEROOM_SETTINGS"] = "/homeroom/settings";
  MthRoute2["REPORTS"] = "/reports";
  MthRoute2["SCHOOL_PARTNER_REPORTS"] = "/reports/school-partner";
  MthRoute2["MASTER_REPORTS"] = "/reports/master";
  MthRoute2["APPLICATIONS_REPORTS"] = "/reports/applications";
  MthRoute2["SCHEDULE_REPORTS"] = "/reports/schedule";
  MthRoute2["RESET_PASSWORD"] = "/reset-password";
  MthRoute2["SETTINGS"] = "/settings";
  MthRoute2["SITE_MANAGEMENT"] = "/site-management";
  MthRoute2["SCHOOL_ENROLLMENT"] = "/enrollment/school-enrollment";
  MthRoute2["SUBMIT_WITHDRAWAL"] = "/withdrawal";
  MthRoute2["SUBMIT_SCHEDULE"] = "/schedule";
  MthRoute2["VIEW"] = "/view";
  MthRoute2["USERS"] = "/users";
  MthRoute2["WITHDRAWAL"] = "/enrollment/withdrawal";
  MthRoute2["TESTING_PREFERENCE"] = "/enrollment/testing-preference";
  MthRoute2["TESTING_PREFERENCE_PATH"] = "/site-management/enrollment/testing-preference";
  MthRoute2["ENROLLMENT_SCHEDULE"] = "/enrollment/enrollment-schedule";
  MthRoute2["ENROLLMENT_SCHEDULE_NOT_SUBMITTED"] = "/enrollment/enrollment-not-submitted-schedule";
})(MthRoute || (MthRoute = {}));
