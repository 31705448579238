import React from "../../../../../../_snowpack/pkg/react.js";
import {makeStyles} from "../../../../../../_snowpack/pkg/@material-ui/core/styles.js";
import InfoIcon from "../../../../../../_snowpack/pkg/@mui/icons-material/InfoOutlined.js";
import {Box, Button, Modal, Typography} from "../../../../../../_snowpack/pkg/@mui/material.js";
import {MthColor} from "../../../../../../core/enums/index.js";
const useClasses = makeStyles((theme) => ({
  modalCard: {
    width: "441px",
    [theme.breakpoints.down("xs")]: {
      width: "97% !important"
    }
  }
}));
export const CustomModal = ({
  title,
  description,
  subDescription,
  onClose,
  onConfirm,
  confirmStr = "Confirm",
  cancelStr = "Cancel",
  backgroundColor = MthColor.SYSTEM_09
}) => {
  const classes = useClasses();
  return /* @__PURE__ */ React.createElement(Modal, {
    open: true,
    "aria-labelledby": "child-modal-title",
    disableAutoFocus: true,
    "aria-describedby": "child-modal-description"
  }, /* @__PURE__ */ React.createElement(Box, {
    className: classes.modalCard,
    sx: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: subDescription ? "550px" : "500px",
      height: "auto",
      bgcolor: backgroundColor,
      borderRadius: 2,
      display: "flex",
      justifyContent: "center",
      p: 4
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {textAlign: "center"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    variant: "h5",
    fontWeight: "bold"
  }, title), /* @__PURE__ */ React.createElement(InfoIcon, {
    sx: {fontSize: 50, margin: "20px 0px"}
  }), /* @__PURE__ */ React.createElement(Typography, null, description), subDescription && /* @__PURE__ */ React.createElement(Typography, null, subDescription), /* @__PURE__ */ React.createElement(Box, {
    sx: {display: "flex", justifyContent: "space-between", marginTop: "30px", gap: "20px"}
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {width: "160px", height: "36px", background: MthColor.SYSTEM_08, borderRadius: "50px"},
    onClick: onClose
  }, cancelStr), /* @__PURE__ */ React.createElement(Button, {
    sx: {
      width: "160px",
      height: "36px",
      background: MthColor.BUTTON_LINEAR_GRADIENT_DARK,
      borderRadius: "50px",
      color: "white"
    },
    onClick: onConfirm
  }, confirmStr)))));
};
