import React from "../../../../_snowpack/pkg/react.js";
import {Typography} from "../../../../_snowpack/pkg/@mui/material.js";
export const Paragraph = ({
  children,
  size,
  color,
  fontWeight,
  fontFamily,
  textAlign,
  sx,
  onClick,
  testId,
  lineHeight
}) => {
  const fontSize = () => size === "large" ? 14 : size === "medium" ? 12 : 10;
  return /* @__PURE__ */ React.createElement(Typography, {
    fontWeight,
    color,
    fontSize,
    fontFamily,
    textAlign,
    sx,
    onClick,
    "data-testid": testId,
    lineHeight
  }, children);
};
