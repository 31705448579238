import {c as createCommonjsModule, b as commonjsGlobal} from "../common/_commonjsHelpers-53bde9c7.js";
import {r as rng, s as stringify, v as validate, a as v4} from "../common/v4-4b52e436.js";
import {r as react} from "../common/index-c6c556cc.js";
function E() {
}
E.prototype = {
  on: function(name, callback, ctx) {
    var e = this.e || (this.e = {});
    (e[name] || (e[name] = [])).push({
      fn: callback,
      ctx
    });
    return this;
  },
  once: function(name, callback, ctx) {
    var self = this;
    function listener() {
      self.off(name, listener);
      callback.apply(ctx, arguments);
    }
    listener._ = callback;
    return this.on(name, listener, ctx);
  },
  emit: function(name) {
    var data = [].slice.call(arguments, 1);
    var evtArr = ((this.e || (this.e = {}))[name] || []).slice();
    var i = 0;
    var len = evtArr.length;
    for (i; i < len; i++) {
      evtArr[i].fn.apply(evtArr[i].ctx, data);
    }
    return this;
  },
  off: function(name, callback) {
    var e = this.e || (this.e = {});
    var evts = e[name];
    var liveEvents = [];
    if (evts && callback) {
      for (var i = 0, len = evts.length; i < len; i++) {
        if (evts[i].fn !== callback && evts[i].fn._ !== callback)
          liveEvents.push(evts[i]);
      }
    }
    liveEvents.length ? e[name] = liveEvents : delete e[name];
    return this;
  }
};
var tinyEmitter = E;
var TinyEmitter = E;
tinyEmitter.TinyEmitter = TinyEmitter;
var util = createCommonjsModule(function(module, exports) {
  Object.defineProperty(exports, "__esModule", {value: true});
  exports.urlWithContextAsQuery = exports.notNullOrUndefined = void 0;
  var notNullOrUndefined = function(_a) {
    var value = _a[1];
    return value !== void 0 && value !== null;
  };
  exports.notNullOrUndefined = notNullOrUndefined;
  var urlWithContextAsQuery = function(url, context) {
    var urlWithQuery = new URL(url.toString());
    Object.entries(context).filter(exports.notNullOrUndefined).forEach(function(_a) {
      var contextKey = _a[0], contextValue = _a[1];
      if (contextKey === "properties" && contextValue) {
        Object.entries(contextValue).filter(exports.notNullOrUndefined).forEach(function(_a2) {
          var propertyKey = _a2[0], propertyValue = _a2[1];
          return urlWithQuery.searchParams.append("properties[".concat(propertyKey, "]"), propertyValue);
        });
      } else {
        urlWithQuery.searchParams.append(contextKey, contextValue);
      }
    });
    return urlWithQuery;
  };
  exports.urlWithContextAsQuery = urlWithContextAsQuery;
});
var metrics = createCommonjsModule(function(module, exports) {
  var __assign = commonjsGlobal && commonjsGlobal.__assign || function() {
    __assign = Object.assign || function(t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s)
          if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __awaiter = commonjsGlobal && commonjsGlobal.__awaiter || function(thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function(resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function(resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = commonjsGlobal && commonjsGlobal.__generator || function(thisArg, body) {
    var _ = {label: 0, sent: function() {
      if (t[0] & 1)
        throw t[1];
      return t[1];
    }, trys: [], ops: []}, f2, y, t, g;
    return g = {next: verb(0), throw: verb(1), return: verb(2)}, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
      return this;
    }), g;
    function verb(n) {
      return function(v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f2)
        throw new TypeError("Generator is already executing.");
      while (_)
        try {
          if (f2 = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
            return t;
          if (y = 0, t)
            op = [op[0] & 2, t.value];
          switch (op[0]) {
            case 0:
            case 1:
              t = op;
              break;
            case 4:
              _.label++;
              return {value: op[1], done: false};
            case 5:
              _.label++;
              y = op[1];
              op = [0];
              continue;
            case 7:
              op = _.ops.pop();
              _.trys.pop();
              continue;
            default:
              if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                _ = 0;
                continue;
              }
              if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                _.label = op[1];
                break;
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1];
                t = op;
                break;
              }
              if (t && _.label < t[2]) {
                _.label = t[2];
                _.ops.push(op);
                break;
              }
              if (t[2])
                _.ops.pop();
              _.trys.pop();
              continue;
          }
          op = body.call(thisArg, _);
        } catch (e) {
          op = [6, e];
          y = 0;
        } finally {
          f2 = t = 0;
        }
      if (op[0] & 5)
        throw op[1];
      return {value: op[0] ? op[1] : void 0, done: true};
    }
  };
  Object.defineProperty(exports, "__esModule", {value: true});
  var doNothing = function() {
  };
  var Metrics = function() {
    function Metrics2(_a) {
      var onError = _a.onError, onSent = _a.onSent, appName = _a.appName, metricsInterval = _a.metricsInterval, _b = _a.disableMetrics, disableMetrics = _b === void 0 ? false : _b, url = _a.url, clientKey = _a.clientKey, fetch2 = _a.fetch, headerName = _a.headerName, _c = _a.customHeaders, customHeaders = _c === void 0 ? {} : _c;
      this.onError = onError;
      this.onSent = onSent || doNothing;
      this.disabled = disableMetrics;
      this.metricsInterval = metricsInterval * 1e3;
      this.appName = appName;
      this.url = url instanceof URL ? url : new URL(url);
      this.clientKey = clientKey;
      this.bucket = this.createEmptyBucket();
      this.fetch = fetch2;
      this.headerName = headerName;
      this.customHeaders = customHeaders;
    }
    Metrics2.prototype.start = function() {
      var _this = this;
      if (this.disabled) {
        return false;
      }
      if (typeof this.metricsInterval === "number" && this.metricsInterval > 0) {
        setTimeout(function() {
          _this.startTimer();
          _this.sendMetrics();
        }, 2e3);
      }
    };
    Metrics2.prototype.stop = function() {
      if (this.timer) {
        clearTimeout(this.timer);
        delete this.timer;
      }
    };
    Metrics2.prototype.createEmptyBucket = function() {
      return {
        start: new Date(),
        stop: null,
        toggles: {}
      };
    };
    Metrics2.prototype.getHeaders = function() {
      var _a;
      var headers = (_a = {}, _a[this.headerName] = this.clientKey, _a.Accept = "application/json", _a["Content-Type"] = "application/json", _a);
      Object.entries(this.customHeaders).filter(util.notNullOrUndefined).forEach(function(_a2) {
        var name = _a2[0], value = _a2[1];
        return headers[name] = value;
      });
      return headers;
    };
    Metrics2.prototype.sendMetrics = function() {
      return __awaiter(this, void 0, void 0, function() {
        var url, payload, e_1;
        return __generator(this, function(_a) {
          switch (_a.label) {
            case 0:
              url = "".concat(this.url, "/client/metrics");
              payload = this.getPayload();
              if (this.bucketIsEmpty(payload)) {
                return [2];
              }
              _a.label = 1;
            case 1:
              _a.trys.push([1, 3, , 4]);
              return [4, this.fetch(url, {
                cache: "no-cache",
                method: "POST",
                headers: this.getHeaders(),
                body: JSON.stringify(payload)
              })];
            case 2:
              _a.sent();
              this.onSent(payload);
              return [3, 4];
            case 3:
              e_1 = _a.sent();
              console.error("Unleash: unable to send feature metrics", e_1);
              this.onError(e_1);
              return [3, 4];
            case 4:
              return [2];
          }
        });
      });
    };
    Metrics2.prototype.count = function(name, enabled) {
      if (this.disabled || !this.bucket) {
        return false;
      }
      this.assertBucket(name);
      this.bucket.toggles[name][enabled ? "yes" : "no"]++;
      return true;
    };
    Metrics2.prototype.countVariant = function(name, variant) {
      if (this.disabled || !this.bucket) {
        return false;
      }
      this.assertBucket(name);
      if (this.bucket.toggles[name].variants[variant]) {
        this.bucket.toggles[name].variants[variant] += 1;
      } else {
        this.bucket.toggles[name].variants[variant] = 1;
      }
      return true;
    };
    Metrics2.prototype.assertBucket = function(name) {
      if (this.disabled || !this.bucket) {
        return false;
      }
      if (!this.bucket.toggles[name]) {
        this.bucket.toggles[name] = {
          yes: 0,
          no: 0,
          variants: {}
        };
      }
    };
    Metrics2.prototype.startTimer = function() {
      var _this = this;
      this.timer = setInterval(function() {
        _this.sendMetrics();
      }, this.metricsInterval);
    };
    Metrics2.prototype.bucketIsEmpty = function(payload) {
      return Object.keys(payload.bucket.toggles).length === 0;
    };
    Metrics2.prototype.getPayload = function() {
      var bucket = __assign(__assign({}, this.bucket), {stop: new Date()});
      this.bucket = this.createEmptyBucket();
      return {
        bucket,
        appName: this.appName,
        instanceId: "browser"
      };
    };
    return Metrics2;
  }();
  exports.default = Metrics;
});
var storageProviderInmemory = createCommonjsModule(function(module, exports) {
  var __awaiter = commonjsGlobal && commonjsGlobal.__awaiter || function(thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function(resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function(resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = commonjsGlobal && commonjsGlobal.__generator || function(thisArg, body) {
    var _ = {label: 0, sent: function() {
      if (t[0] & 1)
        throw t[1];
      return t[1];
    }, trys: [], ops: []}, f2, y, t, g;
    return g = {next: verb(0), throw: verb(1), return: verb(2)}, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
      return this;
    }), g;
    function verb(n) {
      return function(v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f2)
        throw new TypeError("Generator is already executing.");
      while (_)
        try {
          if (f2 = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
            return t;
          if (y = 0, t)
            op = [op[0] & 2, t.value];
          switch (op[0]) {
            case 0:
            case 1:
              t = op;
              break;
            case 4:
              _.label++;
              return {value: op[1], done: false};
            case 5:
              _.label++;
              y = op[1];
              op = [0];
              continue;
            case 7:
              op = _.ops.pop();
              _.trys.pop();
              continue;
            default:
              if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                _ = 0;
                continue;
              }
              if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                _.label = op[1];
                break;
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1];
                t = op;
                break;
              }
              if (t && _.label < t[2]) {
                _.label = t[2];
                _.ops.push(op);
                break;
              }
              if (t[2])
                _.ops.pop();
              _.trys.pop();
              continue;
          }
          op = body.call(thisArg, _);
        } catch (e) {
          op = [6, e];
          y = 0;
        } finally {
          f2 = t = 0;
        }
      if (op[0] & 5)
        throw op[1];
      return {value: op[0] ? op[1] : void 0, done: true};
    }
  };
  Object.defineProperty(exports, "__esModule", {value: true});
  var InMemoryStorageProvider = function() {
    function InMemoryStorageProvider2() {
      this.store = new Map();
    }
    InMemoryStorageProvider2.prototype.save = function(name, data) {
      return __awaiter(this, void 0, void 0, function() {
        return __generator(this, function(_a) {
          this.store.set(name, data);
          return [2];
        });
      });
    };
    InMemoryStorageProvider2.prototype.get = function(name) {
      return __awaiter(this, void 0, void 0, function() {
        return __generator(this, function(_a) {
          return [2, this.store.get(name)];
        });
      });
    };
    return InMemoryStorageProvider2;
  }();
  exports.default = InMemoryStorageProvider;
});
var storageProviderLocal = createCommonjsModule(function(module, exports) {
  var __awaiter = commonjsGlobal && commonjsGlobal.__awaiter || function(thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function(resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function(resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = commonjsGlobal && commonjsGlobal.__generator || function(thisArg, body) {
    var _ = {label: 0, sent: function() {
      if (t[0] & 1)
        throw t[1];
      return t[1];
    }, trys: [], ops: []}, f2, y, t, g;
    return g = {next: verb(0), throw: verb(1), return: verb(2)}, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
      return this;
    }), g;
    function verb(n) {
      return function(v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f2)
        throw new TypeError("Generator is already executing.");
      while (_)
        try {
          if (f2 = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
            return t;
          if (y = 0, t)
            op = [op[0] & 2, t.value];
          switch (op[0]) {
            case 0:
            case 1:
              t = op;
              break;
            case 4:
              _.label++;
              return {value: op[1], done: false};
            case 5:
              _.label++;
              y = op[1];
              op = [0];
              continue;
            case 7:
              op = _.ops.pop();
              _.trys.pop();
              continue;
            default:
              if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                _ = 0;
                continue;
              }
              if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                _.label = op[1];
                break;
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1];
                t = op;
                break;
              }
              if (t && _.label < t[2]) {
                _.label = t[2];
                _.ops.push(op);
                break;
              }
              if (t[2])
                _.ops.pop();
              _.trys.pop();
              continue;
          }
          op = body.call(thisArg, _);
        } catch (e) {
          op = [6, e];
          y = 0;
        } finally {
          f2 = t = 0;
        }
      if (op[0] & 5)
        throw op[1];
      return {value: op[0] ? op[1] : void 0, done: true};
    }
  };
  Object.defineProperty(exports, "__esModule", {value: true});
  var LocalStorageProvider = function() {
    function LocalStorageProvider2() {
      this.prefix = "unleash:repository";
    }
    LocalStorageProvider2.prototype.save = function(name, data) {
      return __awaiter(this, void 0, void 0, function() {
        var repo, key;
        return __generator(this, function(_a) {
          repo = JSON.stringify(data);
          key = "".concat(this.prefix, ":").concat(name);
          try {
            window.localStorage.setItem(key, repo);
          } catch (ex) {
            console.error(ex);
          }
          return [2];
        });
      });
    };
    LocalStorageProvider2.prototype.get = function(name) {
      try {
        var key = "".concat(this.prefix, ":").concat(name);
        var data = window.localStorage.getItem(key);
        return data ? JSON.parse(data) : void 0;
      } catch (e) {
        console.error(e);
      }
    };
    return LocalStorageProvider2;
  }();
  exports.default = LocalStorageProvider;
});
var _nodeId;
var _clockseq;
var _lastMSecs = 0;
var _lastNSecs = 0;
function v1(options, buf, offset) {
  var i = buf && offset || 0;
  var b = buf || new Array(16);
  options = options || {};
  var node = options.node || _nodeId;
  var clockseq = options.clockseq !== void 0 ? options.clockseq : _clockseq;
  if (node == null || clockseq == null) {
    var seedBytes = options.random || (options.rng || rng)();
    if (node == null) {
      node = _nodeId = [seedBytes[0] | 1, seedBytes[1], seedBytes[2], seedBytes[3], seedBytes[4], seedBytes[5]];
    }
    if (clockseq == null) {
      clockseq = _clockseq = (seedBytes[6] << 8 | seedBytes[7]) & 16383;
    }
  }
  var msecs = options.msecs !== void 0 ? options.msecs : Date.now();
  var nsecs = options.nsecs !== void 0 ? options.nsecs : _lastNSecs + 1;
  var dt = msecs - _lastMSecs + (nsecs - _lastNSecs) / 1e4;
  if (dt < 0 && options.clockseq === void 0) {
    clockseq = clockseq + 1 & 16383;
  }
  if ((dt < 0 || msecs > _lastMSecs) && options.nsecs === void 0) {
    nsecs = 0;
  }
  if (nsecs >= 1e4) {
    throw new Error("uuid.v1(): Can't create more than 10M uuids/sec");
  }
  _lastMSecs = msecs;
  _lastNSecs = nsecs;
  _clockseq = clockseq;
  msecs += 122192928e5;
  var tl = ((msecs & 268435455) * 1e4 + nsecs) % 4294967296;
  b[i++] = tl >>> 24 & 255;
  b[i++] = tl >>> 16 & 255;
  b[i++] = tl >>> 8 & 255;
  b[i++] = tl & 255;
  var tmh = msecs / 4294967296 * 1e4 & 268435455;
  b[i++] = tmh >>> 8 & 255;
  b[i++] = tmh & 255;
  b[i++] = tmh >>> 24 & 15 | 16;
  b[i++] = tmh >>> 16 & 255;
  b[i++] = clockseq >>> 8 | 128;
  b[i++] = clockseq & 255;
  for (var n = 0; n < 6; ++n) {
    b[i + n] = node[n];
  }
  return buf || stringify(b);
}
function parse(uuid) {
  if (!validate(uuid)) {
    throw TypeError("Invalid UUID");
  }
  var v;
  var arr = new Uint8Array(16);
  arr[0] = (v = parseInt(uuid.slice(0, 8), 16)) >>> 24;
  arr[1] = v >>> 16 & 255;
  arr[2] = v >>> 8 & 255;
  arr[3] = v & 255;
  arr[4] = (v = parseInt(uuid.slice(9, 13), 16)) >>> 8;
  arr[5] = v & 255;
  arr[6] = (v = parseInt(uuid.slice(14, 18), 16)) >>> 8;
  arr[7] = v & 255;
  arr[8] = (v = parseInt(uuid.slice(19, 23), 16)) >>> 8;
  arr[9] = v & 255;
  arr[10] = (v = parseInt(uuid.slice(24, 36), 16)) / 1099511627776 & 255;
  arr[11] = v / 4294967296 & 255;
  arr[12] = v >>> 24 & 255;
  arr[13] = v >>> 16 & 255;
  arr[14] = v >>> 8 & 255;
  arr[15] = v & 255;
  return arr;
}
function stringToBytes(str) {
  str = unescape(encodeURIComponent(str));
  var bytes = [];
  for (var i = 0; i < str.length; ++i) {
    bytes.push(str.charCodeAt(i));
  }
  return bytes;
}
var DNS = "6ba7b810-9dad-11d1-80b4-00c04fd430c8";
var URL$1 = "6ba7b811-9dad-11d1-80b4-00c04fd430c8";
function v35(name, version2, hashfunc) {
  function generateUUID(value, namespace, buf, offset) {
    if (typeof value === "string") {
      value = stringToBytes(value);
    }
    if (typeof namespace === "string") {
      namespace = parse(namespace);
    }
    if (namespace.length !== 16) {
      throw TypeError("Namespace must be array-like (16 iterable integer values, 0-255)");
    }
    var bytes = new Uint8Array(16 + value.length);
    bytes.set(namespace);
    bytes.set(value, namespace.length);
    bytes = hashfunc(bytes);
    bytes[6] = bytes[6] & 15 | version2;
    bytes[8] = bytes[8] & 63 | 128;
    if (buf) {
      offset = offset || 0;
      for (var i = 0; i < 16; ++i) {
        buf[offset + i] = bytes[i];
      }
      return buf;
    }
    return stringify(bytes);
  }
  try {
    generateUUID.name = name;
  } catch (err) {
  }
  generateUUID.DNS = DNS;
  generateUUID.URL = URL$1;
  return generateUUID;
}
function md5(bytes) {
  if (typeof bytes === "string") {
    var msg = unescape(encodeURIComponent(bytes));
    bytes = new Uint8Array(msg.length);
    for (var i = 0; i < msg.length; ++i) {
      bytes[i] = msg.charCodeAt(i);
    }
  }
  return md5ToHexEncodedArray(wordsToMd5(bytesToWords(bytes), bytes.length * 8));
}
function md5ToHexEncodedArray(input) {
  var output = [];
  var length32 = input.length * 32;
  var hexTab = "0123456789abcdef";
  for (var i = 0; i < length32; i += 8) {
    var x = input[i >> 5] >>> i % 32 & 255;
    var hex = parseInt(hexTab.charAt(x >>> 4 & 15) + hexTab.charAt(x & 15), 16);
    output.push(hex);
  }
  return output;
}
function getOutputLength(inputLength8) {
  return (inputLength8 + 64 >>> 9 << 4) + 14 + 1;
}
function wordsToMd5(x, len) {
  x[len >> 5] |= 128 << len % 32;
  x[getOutputLength(len) - 1] = len;
  var a = 1732584193;
  var b = -271733879;
  var c = -1732584194;
  var d = 271733878;
  for (var i = 0; i < x.length; i += 16) {
    var olda = a;
    var oldb = b;
    var oldc = c;
    var oldd = d;
    a = md5ff(a, b, c, d, x[i], 7, -680876936);
    d = md5ff(d, a, b, c, x[i + 1], 12, -389564586);
    c = md5ff(c, d, a, b, x[i + 2], 17, 606105819);
    b = md5ff(b, c, d, a, x[i + 3], 22, -1044525330);
    a = md5ff(a, b, c, d, x[i + 4], 7, -176418897);
    d = md5ff(d, a, b, c, x[i + 5], 12, 1200080426);
    c = md5ff(c, d, a, b, x[i + 6], 17, -1473231341);
    b = md5ff(b, c, d, a, x[i + 7], 22, -45705983);
    a = md5ff(a, b, c, d, x[i + 8], 7, 1770035416);
    d = md5ff(d, a, b, c, x[i + 9], 12, -1958414417);
    c = md5ff(c, d, a, b, x[i + 10], 17, -42063);
    b = md5ff(b, c, d, a, x[i + 11], 22, -1990404162);
    a = md5ff(a, b, c, d, x[i + 12], 7, 1804603682);
    d = md5ff(d, a, b, c, x[i + 13], 12, -40341101);
    c = md5ff(c, d, a, b, x[i + 14], 17, -1502002290);
    b = md5ff(b, c, d, a, x[i + 15], 22, 1236535329);
    a = md5gg(a, b, c, d, x[i + 1], 5, -165796510);
    d = md5gg(d, a, b, c, x[i + 6], 9, -1069501632);
    c = md5gg(c, d, a, b, x[i + 11], 14, 643717713);
    b = md5gg(b, c, d, a, x[i], 20, -373897302);
    a = md5gg(a, b, c, d, x[i + 5], 5, -701558691);
    d = md5gg(d, a, b, c, x[i + 10], 9, 38016083);
    c = md5gg(c, d, a, b, x[i + 15], 14, -660478335);
    b = md5gg(b, c, d, a, x[i + 4], 20, -405537848);
    a = md5gg(a, b, c, d, x[i + 9], 5, 568446438);
    d = md5gg(d, a, b, c, x[i + 14], 9, -1019803690);
    c = md5gg(c, d, a, b, x[i + 3], 14, -187363961);
    b = md5gg(b, c, d, a, x[i + 8], 20, 1163531501);
    a = md5gg(a, b, c, d, x[i + 13], 5, -1444681467);
    d = md5gg(d, a, b, c, x[i + 2], 9, -51403784);
    c = md5gg(c, d, a, b, x[i + 7], 14, 1735328473);
    b = md5gg(b, c, d, a, x[i + 12], 20, -1926607734);
    a = md5hh(a, b, c, d, x[i + 5], 4, -378558);
    d = md5hh(d, a, b, c, x[i + 8], 11, -2022574463);
    c = md5hh(c, d, a, b, x[i + 11], 16, 1839030562);
    b = md5hh(b, c, d, a, x[i + 14], 23, -35309556);
    a = md5hh(a, b, c, d, x[i + 1], 4, -1530992060);
    d = md5hh(d, a, b, c, x[i + 4], 11, 1272893353);
    c = md5hh(c, d, a, b, x[i + 7], 16, -155497632);
    b = md5hh(b, c, d, a, x[i + 10], 23, -1094730640);
    a = md5hh(a, b, c, d, x[i + 13], 4, 681279174);
    d = md5hh(d, a, b, c, x[i], 11, -358537222);
    c = md5hh(c, d, a, b, x[i + 3], 16, -722521979);
    b = md5hh(b, c, d, a, x[i + 6], 23, 76029189);
    a = md5hh(a, b, c, d, x[i + 9], 4, -640364487);
    d = md5hh(d, a, b, c, x[i + 12], 11, -421815835);
    c = md5hh(c, d, a, b, x[i + 15], 16, 530742520);
    b = md5hh(b, c, d, a, x[i + 2], 23, -995338651);
    a = md5ii(a, b, c, d, x[i], 6, -198630844);
    d = md5ii(d, a, b, c, x[i + 7], 10, 1126891415);
    c = md5ii(c, d, a, b, x[i + 14], 15, -1416354905);
    b = md5ii(b, c, d, a, x[i + 5], 21, -57434055);
    a = md5ii(a, b, c, d, x[i + 12], 6, 1700485571);
    d = md5ii(d, a, b, c, x[i + 3], 10, -1894986606);
    c = md5ii(c, d, a, b, x[i + 10], 15, -1051523);
    b = md5ii(b, c, d, a, x[i + 1], 21, -2054922799);
    a = md5ii(a, b, c, d, x[i + 8], 6, 1873313359);
    d = md5ii(d, a, b, c, x[i + 15], 10, -30611744);
    c = md5ii(c, d, a, b, x[i + 6], 15, -1560198380);
    b = md5ii(b, c, d, a, x[i + 13], 21, 1309151649);
    a = md5ii(a, b, c, d, x[i + 4], 6, -145523070);
    d = md5ii(d, a, b, c, x[i + 11], 10, -1120210379);
    c = md5ii(c, d, a, b, x[i + 2], 15, 718787259);
    b = md5ii(b, c, d, a, x[i + 9], 21, -343485551);
    a = safeAdd(a, olda);
    b = safeAdd(b, oldb);
    c = safeAdd(c, oldc);
    d = safeAdd(d, oldd);
  }
  return [a, b, c, d];
}
function bytesToWords(input) {
  if (input.length === 0) {
    return [];
  }
  var length8 = input.length * 8;
  var output = new Uint32Array(getOutputLength(length8));
  for (var i = 0; i < length8; i += 8) {
    output[i >> 5] |= (input[i / 8] & 255) << i % 32;
  }
  return output;
}
function safeAdd(x, y) {
  var lsw = (x & 65535) + (y & 65535);
  var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
  return msw << 16 | lsw & 65535;
}
function bitRotateLeft(num, cnt) {
  return num << cnt | num >>> 32 - cnt;
}
function md5cmn(q, a, b, x, s, t) {
  return safeAdd(bitRotateLeft(safeAdd(safeAdd(a, q), safeAdd(x, t)), s), b);
}
function md5ff(a, b, c, d, x, s, t) {
  return md5cmn(b & c | ~b & d, a, b, x, s, t);
}
function md5gg(a, b, c, d, x, s, t) {
  return md5cmn(b & d | c & ~d, a, b, x, s, t);
}
function md5hh(a, b, c, d, x, s, t) {
  return md5cmn(b ^ c ^ d, a, b, x, s, t);
}
function md5ii(a, b, c, d, x, s, t) {
  return md5cmn(c ^ (b | ~d), a, b, x, s, t);
}
var v3 = v35("v3", 48, md5);
var v3$1 = v3;
function f(s, x, y, z) {
  switch (s) {
    case 0:
      return x & y ^ ~x & z;
    case 1:
      return x ^ y ^ z;
    case 2:
      return x & y ^ x & z ^ y & z;
    case 3:
      return x ^ y ^ z;
  }
}
function ROTL(x, n) {
  return x << n | x >>> 32 - n;
}
function sha1(bytes) {
  var K = [1518500249, 1859775393, 2400959708, 3395469782];
  var H = [1732584193, 4023233417, 2562383102, 271733878, 3285377520];
  if (typeof bytes === "string") {
    var msg = unescape(encodeURIComponent(bytes));
    bytes = [];
    for (var i = 0; i < msg.length; ++i) {
      bytes.push(msg.charCodeAt(i));
    }
  } else if (!Array.isArray(bytes)) {
    bytes = Array.prototype.slice.call(bytes);
  }
  bytes.push(128);
  var l = bytes.length / 4 + 2;
  var N = Math.ceil(l / 16);
  var M = new Array(N);
  for (var _i = 0; _i < N; ++_i) {
    var arr = new Uint32Array(16);
    for (var j = 0; j < 16; ++j) {
      arr[j] = bytes[_i * 64 + j * 4] << 24 | bytes[_i * 64 + j * 4 + 1] << 16 | bytes[_i * 64 + j * 4 + 2] << 8 | bytes[_i * 64 + j * 4 + 3];
    }
    M[_i] = arr;
  }
  M[N - 1][14] = (bytes.length - 1) * 8 / Math.pow(2, 32);
  M[N - 1][14] = Math.floor(M[N - 1][14]);
  M[N - 1][15] = (bytes.length - 1) * 8 & 4294967295;
  for (var _i2 = 0; _i2 < N; ++_i2) {
    var W = new Uint32Array(80);
    for (var t = 0; t < 16; ++t) {
      W[t] = M[_i2][t];
    }
    for (var _t = 16; _t < 80; ++_t) {
      W[_t] = ROTL(W[_t - 3] ^ W[_t - 8] ^ W[_t - 14] ^ W[_t - 16], 1);
    }
    var a = H[0];
    var b = H[1];
    var c = H[2];
    var d = H[3];
    var e = H[4];
    for (var _t2 = 0; _t2 < 80; ++_t2) {
      var s = Math.floor(_t2 / 20);
      var T = ROTL(a, 5) + f(s, b, c, d) + e + K[s] + W[_t2] >>> 0;
      e = d;
      d = c;
      c = ROTL(b, 30) >>> 0;
      b = a;
      a = T;
    }
    H[0] = H[0] + a >>> 0;
    H[1] = H[1] + b >>> 0;
    H[2] = H[2] + c >>> 0;
    H[3] = H[3] + d >>> 0;
    H[4] = H[4] + e >>> 0;
  }
  return [H[0] >> 24 & 255, H[0] >> 16 & 255, H[0] >> 8 & 255, H[0] & 255, H[1] >> 24 & 255, H[1] >> 16 & 255, H[1] >> 8 & 255, H[1] & 255, H[2] >> 24 & 255, H[2] >> 16 & 255, H[2] >> 8 & 255, H[2] & 255, H[3] >> 24 & 255, H[3] >> 16 & 255, H[3] >> 8 & 255, H[3] & 255, H[4] >> 24 & 255, H[4] >> 16 & 255, H[4] >> 8 & 255, H[4] & 255];
}
var v5 = v35("v5", 80, sha1);
var v5$1 = v5;
var nil = "00000000-0000-0000-0000-000000000000";
function version(uuid) {
  if (!validate(uuid)) {
    throw TypeError("Invalid UUID");
  }
  return parseInt(uuid.substr(14, 1), 16);
}
var esmBrowser = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  v1,
  v3: v3$1,
  v4,
  v5: v5$1,
  NIL: nil,
  version,
  validate,
  stringify,
  parse
});
var eventsHandler = createCommonjsModule(function(module, exports) {
  var __assign = commonjsGlobal && commonjsGlobal.__assign || function() {
    __assign = Object.assign || function(t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s)
          if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  Object.defineProperty(exports, "__esModule", {value: true});
  var EventsHandler = function() {
    function EventsHandler2() {
    }
    EventsHandler2.prototype.generateEventId = function() {
      return (0, esmBrowser.v4)();
    };
    EventsHandler2.prototype.createImpressionEvent = function(context, enabled, featureName, eventType, impressionData, variant) {
      var baseEvent = this.createBaseEvent(context, enabled, featureName, eventType, impressionData);
      if (variant) {
        return __assign(__assign({}, baseEvent), {variant});
      }
      return baseEvent;
    };
    EventsHandler2.prototype.createBaseEvent = function(context, enabled, featureName, eventType, impressionData) {
      return {
        eventType,
        eventId: this.generateEventId(),
        context,
        enabled,
        featureName,
        impressionData
      };
    };
    return EventsHandler2;
  }();
  exports.default = EventsHandler;
});
var build = createCommonjsModule(function(module, exports) {
  var __extends = commonjsGlobal && commonjsGlobal.__extends || function() {
    var extendStatics = function(d, b) {
      extendStatics = Object.setPrototypeOf || {__proto__: []} instanceof Array && function(d2, b2) {
        d2.__proto__ = b2;
      } || function(d2, b2) {
        for (var p in b2)
          if (Object.prototype.hasOwnProperty.call(b2, p))
            d2[p] = b2[p];
      };
      return extendStatics(d, b);
    };
    return function(d, b) {
      if (typeof b !== "function" && b !== null)
        throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
  }();
  var __assign = commonjsGlobal && commonjsGlobal.__assign || function() {
    __assign = Object.assign || function(t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s)
          if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
      }
      return t;
    };
    return __assign.apply(this, arguments);
  };
  var __awaiter = commonjsGlobal && commonjsGlobal.__awaiter || function(thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P ? value : new P(function(resolve) {
        resolve(value);
      });
    }
    return new (P || (P = Promise))(function(resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
  var __generator = commonjsGlobal && commonjsGlobal.__generator || function(thisArg, body) {
    var _ = {label: 0, sent: function() {
      if (t[0] & 1)
        throw t[1];
      return t[1];
    }, trys: [], ops: []}, f2, y, t, g;
    return g = {next: verb(0), throw: verb(1), return: verb(2)}, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
      return this;
    }), g;
    function verb(n) {
      return function(v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f2)
        throw new TypeError("Generator is already executing.");
      while (_)
        try {
          if (f2 = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
            return t;
          if (y = 0, t)
            op = [op[0] & 2, t.value];
          switch (op[0]) {
            case 0:
            case 1:
              t = op;
              break;
            case 4:
              _.label++;
              return {value: op[1], done: false};
            case 5:
              _.label++;
              y = op[1];
              op = [0];
              continue;
            case 7:
              op = _.ops.pop();
              _.trys.pop();
              continue;
            default:
              if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                _ = 0;
                continue;
              }
              if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                _.label = op[1];
                break;
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1];
                t = op;
                break;
              }
              if (t && _.label < t[2]) {
                _.label = t[2];
                _.ops.push(op);
                break;
              }
              if (t[2])
                _.ops.pop();
              _.trys.pop();
              continue;
          }
          op = body.call(thisArg, _);
        } catch (e) {
          op = [6, e];
          y = 0;
        } finally {
          f2 = t = 0;
        }
      if (op[0] & 5)
        throw op[1];
      return {value: op[0] ? op[1] : void 0, done: true};
    }
  };
  var __spreadArray = commonjsGlobal && commonjsGlobal.__spreadArray || function(to, from, pack) {
    if (pack || arguments.length === 2)
      for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
          if (!ar)
            ar = Array.prototype.slice.call(from, 0, i);
          ar[i] = from[i];
        }
      }
    return to.concat(ar || Array.prototype.slice.call(from));
  };
  Object.defineProperty(exports, "__esModule", {value: true});
  exports.InMemoryStorageProvider = exports.LocalStorageProvider = exports.UnleashClient = exports.resolveFetch = exports.EVENTS = void 0;
  exports.InMemoryStorageProvider = storageProviderInmemory.default;
  exports.LocalStorageProvider = storageProviderLocal.default;
  var DEFINED_FIELDS = ["userId", "sessionId", "remoteAddress"];
  exports.EVENTS = {
    INIT: "initialized",
    ERROR: "error",
    READY: "ready",
    UPDATE: "update",
    IMPRESSION: "impression",
    SENT: "sent"
  };
  var IMPRESSION_EVENTS = {
    IS_ENABLED: "isEnabled",
    GET_VARIANT: "getVariant"
  };
  var defaultVariant = {name: "disabled", enabled: false};
  var storeKey = "repo";
  var resolveFetch = function() {
    try {
      if (typeof window !== "undefined" && "fetch" in window) {
        return fetch.bind(window);
      } else if ("fetch" in globalThis) {
        return fetch.bind(globalThis);
      }
    } catch (e) {
      console.error('Unleash failed to resolve "fetch"', e);
    }
    return void 0;
  };
  exports.resolveFetch = resolveFetch;
  var UnleashClient = function(_super) {
    __extends(UnleashClient2, _super);
    function UnleashClient2(_a) {
      var storageProvider = _a.storageProvider, url = _a.url, clientKey = _a.clientKey, _b = _a.disableRefresh, disableRefresh = _b === void 0 ? false : _b, _c = _a.refreshInterval, refreshInterval = _c === void 0 ? 30 : _c, _d = _a.metricsInterval, metricsInterval = _d === void 0 ? 30 : _d, _e = _a.disableMetrics, disableMetrics = _e === void 0 ? false : _e, appName = _a.appName, _f = _a.environment, environment = _f === void 0 ? "default" : _f, context = _a.context, _g = _a.fetch, fetch2 = _g === void 0 ? (0, exports.resolveFetch)() : _g, bootstrap = _a.bootstrap, _h = _a.bootstrapOverride, bootstrapOverride = _h === void 0 ? true : _h, _j = _a.headerName, headerName = _j === void 0 ? "Authorization" : _j, _k = _a.customHeaders, customHeaders = _k === void 0 ? {} : _k, _l = _a.impressionDataAll, impressionDataAll = _l === void 0 ? false : _l, _m = _a.usePOSTrequests, usePOSTrequests = _m === void 0 ? false : _m;
      var _this = _super.call(this) || this;
      _this.toggles = [];
      _this.etag = "";
      _this.readyEventEmitted = false;
      _this.usePOSTrequests = false;
      _this.started = false;
      if (!url) {
        throw new Error("url is required");
      }
      if (!clientKey) {
        throw new Error("clientKey is required");
      }
      if (!appName) {
        throw new Error("appName is required.");
      }
      _this.eventsHandler = new eventsHandler.default();
      _this.impressionDataAll = impressionDataAll;
      _this.toggles = bootstrap && bootstrap.length > 0 ? bootstrap : [];
      _this.url = url instanceof URL ? url : new URL(url);
      _this.clientKey = clientKey;
      _this.headerName = headerName;
      _this.customHeaders = customHeaders;
      _this.storage = storageProvider || (typeof window !== "undefined" ? new storageProviderLocal.default() : new storageProviderInmemory.default());
      _this.refreshInterval = disableRefresh ? 0 : refreshInterval * 1e3;
      _this.context = __assign({appName, environment}, context);
      _this.usePOSTrequests = usePOSTrequests;
      _this.ready = new Promise(function(resolve) {
        _this.init().then(resolve).catch(function(error) {
          console.error(error);
          _this.emit(exports.EVENTS.ERROR, error);
          resolve();
        });
      });
      if (!fetch2) {
        console.error('Unleash: You must either provide your own "fetch" implementation or run in an environment where "fetch" is available.');
      }
      _this.fetch = fetch2;
      _this.bootstrap = bootstrap && bootstrap.length > 0 ? bootstrap : void 0;
      _this.bootstrapOverride = bootstrapOverride;
      _this.metrics = new metrics.default({
        onError: _this.emit.bind(_this, exports.EVENTS.ERROR),
        onSent: _this.emit.bind(_this, exports.EVENTS.SENT),
        appName,
        metricsInterval,
        disableMetrics,
        url: _this.url,
        clientKey,
        fetch: fetch2,
        headerName,
        customHeaders
      });
      return _this;
    }
    UnleashClient2.prototype.getAllToggles = function() {
      return __spreadArray([], this.toggles, true);
    };
    UnleashClient2.prototype.isEnabled = function(toggleName) {
      var _a;
      var toggle = this.toggles.find(function(t) {
        return t.name === toggleName;
      });
      var enabled = toggle ? toggle.enabled : false;
      this.metrics.count(toggleName, enabled);
      if ((toggle === null || toggle === void 0 ? void 0 : toggle.impressionData) || this.impressionDataAll) {
        var event_1 = this.eventsHandler.createImpressionEvent(this.context, enabled, toggleName, IMPRESSION_EVENTS.IS_ENABLED, (_a = toggle === null || toggle === void 0 ? void 0 : toggle.impressionData) !== null && _a !== void 0 ? _a : void 0);
        this.emit(exports.EVENTS.IMPRESSION, event_1);
      }
      return enabled;
    };
    UnleashClient2.prototype.getVariant = function(toggleName) {
      var _a;
      var toggle = this.toggles.find(function(t) {
        return t.name === toggleName;
      });
      var enabled = (toggle === null || toggle === void 0 ? void 0 : toggle.enabled) || false;
      var variant = toggle ? toggle.variant : defaultVariant;
      if (variant.name) {
        this.metrics.countVariant(toggleName, variant.name);
      }
      this.metrics.count(toggleName, enabled);
      if ((toggle === null || toggle === void 0 ? void 0 : toggle.impressionData) || this.impressionDataAll) {
        var event_2 = this.eventsHandler.createImpressionEvent(this.context, enabled, toggleName, IMPRESSION_EVENTS.GET_VARIANT, (_a = toggle === null || toggle === void 0 ? void 0 : toggle.impressionData) !== null && _a !== void 0 ? _a : void 0, variant.name);
        this.emit(exports.EVENTS.IMPRESSION, event_2);
      }
      return variant;
    };
    UnleashClient2.prototype.updateContext = function(context) {
      return __awaiter(this, void 0, void 0, function() {
        var staticContext;
        var _this = this;
        return __generator(this, function(_a) {
          switch (_a.label) {
            case 0:
              if (context.appName || context.environment) {
                console.warn("appName and environment are static. They can't be updated with updateContext.");
              }
              staticContext = {
                environment: this.context.environment,
                appName: this.context.appName,
                sessionId: this.context.sessionId
              };
              this.context = __assign(__assign({}, staticContext), context);
              if (!(this.timerRef || this.readyEventEmitted))
                return [3, 2];
              return [4, this.fetchToggles()];
            case 1:
              _a.sent();
              return [3, 4];
            case 2:
              if (!this.started)
                return [3, 4];
              return [4, new Promise(function(resolve) {
                var listener = function() {
                  _this.fetchToggles().then(function() {
                    _this.off(exports.EVENTS.READY, listener);
                    resolve();
                  });
                };
                _this.once(exports.EVENTS.READY, listener);
              })];
            case 3:
              _a.sent();
              _a.label = 4;
            case 4:
              return [2];
          }
        });
      });
    };
    UnleashClient2.prototype.getContext = function() {
      return __assign({}, this.context);
    };
    UnleashClient2.prototype.setContextField = function(field, value) {
      var _a, _b;
      if (DEFINED_FIELDS.includes(field)) {
        this.context = __assign(__assign({}, this.context), (_a = {}, _a[field] = value, _a));
      } else {
        var properties = __assign(__assign({}, this.context.properties), (_b = {}, _b[field] = value, _b));
        this.context = __assign(__assign({}, this.context), {properties});
      }
      if (this.timerRef) {
        this.fetchToggles();
      }
    };
    UnleashClient2.prototype.init = function() {
      return __awaiter(this, void 0, void 0, function() {
        var sessionId, _a;
        return __generator(this, function(_b) {
          switch (_b.label) {
            case 0:
              return [4, this.resolveSessionId()];
            case 1:
              sessionId = _b.sent();
              this.context = __assign({sessionId}, this.context);
              _a = this;
              return [4, this.storage.get(storeKey)];
            case 2:
              _a.toggles = _b.sent() || [];
              if (!(this.bootstrap && (this.bootstrapOverride || this.toggles.length === 0)))
                return [3, 4];
              return [4, this.storage.save(storeKey, this.bootstrap)];
            case 3:
              _b.sent();
              this.toggles = this.bootstrap;
              this.emit(exports.EVENTS.READY);
              _b.label = 4;
            case 4:
              this.emit(exports.EVENTS.INIT);
              return [2];
          }
        });
      });
    };
    UnleashClient2.prototype.start = function() {
      return __awaiter(this, void 0, void 0, function() {
        var interval;
        var _this = this;
        return __generator(this, function(_a) {
          switch (_a.label) {
            case 0:
              this.started = true;
              if (this.timerRef) {
                console.error("Unleash SDK has already started, if you want to restart the SDK you should call client.stop() before starting again.");
                return [2];
              }
              return [4, this.ready];
            case 1:
              _a.sent();
              this.metrics.start();
              interval = this.refreshInterval;
              return [4, this.fetchToggles()];
            case 2:
              _a.sent();
              if (interval > 0) {
                this.timerRef = setInterval(function() {
                  return _this.fetchToggles();
                }, interval);
              }
              return [2];
          }
        });
      });
    };
    UnleashClient2.prototype.stop = function() {
      if (this.timerRef) {
        clearInterval(this.timerRef);
        this.timerRef = void 0;
      }
      this.metrics.stop();
    };
    UnleashClient2.prototype.resolveSessionId = function() {
      return __awaiter(this, void 0, void 0, function() {
        var sessionId;
        return __generator(this, function(_a) {
          switch (_a.label) {
            case 0:
              if (!this.context.sessionId)
                return [3, 1];
              return [2, this.context.sessionId];
            case 1:
              return [4, this.storage.get("sessionId")];
            case 2:
              sessionId = _a.sent();
              if (!!sessionId)
                return [3, 4];
              sessionId = Math.floor(Math.random() * 1e9);
              return [4, this.storage.save("sessionId", sessionId)];
            case 3:
              _a.sent();
              _a.label = 4;
            case 4:
              return [2, sessionId];
          }
        });
      });
    };
    UnleashClient2.prototype.getHeaders = function() {
      var _a;
      var headers = (_a = {}, _a[this.headerName] = this.clientKey, _a.Accept = "application/json", _a["Content-Type"] = "application/json", _a["If-None-Match"] = this.etag, _a);
      Object.entries(this.customHeaders).filter(util.notNullOrUndefined).forEach(function(_a2) {
        var name = _a2[0], value = _a2[1];
        return headers[name] = value;
      });
      return headers;
    };
    UnleashClient2.prototype.storeToggles = function(toggles) {
      return __awaiter(this, void 0, void 0, function() {
        return __generator(this, function(_a) {
          switch (_a.label) {
            case 0:
              this.toggles = toggles;
              this.emit(exports.EVENTS.UPDATE);
              return [4, this.storage.save(storeKey, toggles)];
            case 1:
              _a.sent();
              return [2];
          }
        });
      });
    };
    UnleashClient2.prototype.fetchToggles = function() {
      return __awaiter(this, void 0, void 0, function() {
        var isPOST, url, method, body, response, data, e_1;
        return __generator(this, function(_a) {
          switch (_a.label) {
            case 0:
              if (!this.fetch)
                return [3, 8];
              _a.label = 1;
            case 1:
              _a.trys.push([1, 7, , 8]);
              isPOST = this.usePOSTrequests;
              url = isPOST ? this.url : (0, util.urlWithContextAsQuery)(this.url, this.context);
              method = isPOST ? "POST" : "GET";
              body = isPOST ? JSON.stringify({context: this.context}) : void 0;
              return [4, this.fetch(url.toString(), {
                method,
                cache: "no-cache",
                headers: this.getHeaders(),
                body
              })];
            case 2:
              response = _a.sent();
              if (!(response.ok && response.status !== 304))
                return [3, 5];
              this.etag = response.headers.get("ETag") || "";
              return [4, response.json()];
            case 3:
              data = _a.sent();
              return [4, this.storeToggles(data.toggles)];
            case 4:
              _a.sent();
              if (!this.bootstrap && !this.readyEventEmitted) {
                this.emit(exports.EVENTS.READY);
                this.readyEventEmitted = true;
              }
              return [3, 6];
            case 5:
              if (!response.ok && response.status !== 304) {
                console.error("Unleash: Fetching feature toggles did not have an ok response");
                this.emit(exports.EVENTS.ERROR, {
                  type: "HttpError",
                  code: response.status
                });
              }
              _a.label = 6;
            case 6:
              return [3, 8];
            case 7:
              e_1 = _a.sent();
              console.error("Unleash: unable to fetch feature toggles", e_1);
              this.emit(exports.EVENTS.ERROR, e_1);
              return [3, 8];
            case 8:
              return [2];
          }
        });
      });
    };
    return UnleashClient2;
  }(tinyEmitter.TinyEmitter);
  exports.UnleashClient = UnleashClient;
});
var index_browser = createCommonjsModule(function(module) {
  (() => {
    var e = {n: (n2) => {
      var t2 = n2 && n2.__esModule ? () => n2.default : () => n2;
      return e.d(t2, {a: t2}), t2;
    }, d: (n2, t2) => {
      for (var r2 in t2)
        e.o(t2, r2) && !e.o(n2, r2) && Object.defineProperty(n2, r2, {enumerable: true, get: t2[r2]});
    }, o: (e2, n2) => Object.prototype.hasOwnProperty.call(e2, n2), r: (e2) => {
      typeof Symbol != "undefined" && Symbol.toStringTag && Object.defineProperty(e2, Symbol.toStringTag, {value: "Module"}), Object.defineProperty(e2, "__esModule", {value: true});
    }}, n = {};
    e.r(n), e.d(n, {FlagContext: () => o, FlagProvider: () => u, InMemoryStorageProvider: () => t.InMemoryStorageProvider, LocalStorageProvider: () => t.LocalStorageProvider, UnleashClient: () => t.UnleashClient, default: () => d, useFlag: () => a, useFlags: () => i, useFlagsStatus: () => c, useUnleashClient: () => f2, useUnleashContext: () => s, useVariant: () => l});
    const t = build, r = react, o = e.n(r)().createContext(null);
    const u = function(e2) {
      var n2 = e2.config, u2 = e2.children, a2 = e2.unleashClient, i2 = e2.startClient, c2 = i2 === void 0 || i2, l2 = r.useRef(a2), s2 = r.useState(false), f3 = s2[0], d2 = s2[1], g = r.useState(null), v = g[0], y = g[1];
      n2 || a2 || console.warn("You must provide either a config or an unleash client to the flag provider. If you are initializing the client in useEffect, you can avoid this warning by\n      checking if the client exists before rendering."), l2.current || (l2.current = new t.UnleashClient(n2)), l2.current.on("ready", function() {
        d2(true);
      }), l2.current.on("error", function(e3) {
        y(e3);
      }), r.useEffect(function() {
        return (c2 || !a2) && (l2.current.stop(), l2.current.start()), function() {
          l2.current && l2.current.stop();
        };
      }, []);
      var p = function(e3) {
        return n3 = void 0, t2 = void 0, o2 = function() {
          return function(e4, n4) {
            var t3, r3, o3, u3, a3 = {label: 0, sent: function() {
              if (1 & o3[0])
                throw o3[1];
              return o3[1];
            }, trys: [], ops: []};
            return u3 = {next: i3(0), throw: i3(1), return: i3(2)}, typeof Symbol == "function" && (u3[Symbol.iterator] = function() {
              return this;
            }), u3;
            function i3(u4) {
              return function(i4) {
                return function(u5) {
                  if (t3)
                    throw new TypeError("Generator is already executing.");
                  for (; a3; )
                    try {
                      if (t3 = 1, r3 && (o3 = 2 & u5[0] ? r3.return : u5[0] ? r3.throw || ((o3 = r3.return) && o3.call(r3), 0) : r3.next) && !(o3 = o3.call(r3, u5[1])).done)
                        return o3;
                      switch (r3 = 0, o3 && (u5 = [2 & u5[0], o3.value]), u5[0]) {
                        case 0:
                        case 1:
                          o3 = u5;
                          break;
                        case 4:
                          return a3.label++, {value: u5[1], done: false};
                        case 5:
                          a3.label++, r3 = u5[1], u5 = [0];
                          continue;
                        case 7:
                          u5 = a3.ops.pop(), a3.trys.pop();
                          continue;
                        default:
                          if (!((o3 = (o3 = a3.trys).length > 0 && o3[o3.length - 1]) || u5[0] !== 6 && u5[0] !== 2)) {
                            a3 = 0;
                            continue;
                          }
                          if (u5[0] === 3 && (!o3 || u5[1] > o3[0] && u5[1] < o3[3])) {
                            a3.label = u5[1];
                            break;
                          }
                          if (u5[0] === 6 && a3.label < o3[1]) {
                            a3.label = o3[1], o3 = u5;
                            break;
                          }
                          if (o3 && a3.label < o3[2]) {
                            a3.label = o3[2], a3.ops.push(u5);
                            break;
                          }
                          o3[2] && a3.ops.pop(), a3.trys.pop();
                          continue;
                      }
                      u5 = n4.call(e4, a3);
                    } catch (e5) {
                      u5 = [6, e5], r3 = 0;
                    } finally {
                      t3 = o3 = 0;
                    }
                  if (5 & u5[0])
                    throw u5[1];
                  return {value: u5[0] ? u5[1] : void 0, done: true};
                }([u4, i4]);
              };
            }
          }(this, function(n4) {
            switch (n4.label) {
              case 0:
                return [4, l2.current.updateContext(e3)];
              case 1:
                return n4.sent(), [2];
            }
          });
        }, new ((r2 = void 0) || (r2 = Promise))(function(e4, u3) {
          function a3(e5) {
            try {
              c3(o2.next(e5));
            } catch (e6) {
              u3(e6);
            }
          }
          function i3(e5) {
            try {
              c3(o2.throw(e5));
            } catch (e6) {
              u3(e6);
            }
          }
          function c3(n4) {
            var t3;
            n4.done ? e4(n4.value) : (t3 = n4.value, t3 instanceof r2 ? t3 : new r2(function(e5) {
              e5(t3);
            })).then(a3, i3);
          }
          c3((o2 = o2.apply(n3, t2 || [])).next());
        });
        var n3, t2, r2, o2;
      }, h = function(e3) {
        return l2.current.isEnabled(e3);
      }, b = function(e3) {
        return l2.current.getVariant(e3);
      }, x = function(e3, n3, t2) {
        return l2.current.on(e3, n3, t2);
      }, C = r.useMemo(function() {
        return {on: x, updateContext: p, isEnabled: h, getVariant: b, client: l2.current, flagsReady: f3, flagsError: v, setFlagsReady: d2, setFlagsError: y};
      }, [f3, v]);
      return r.createElement(o.Provider, {value: C}, u2);
    }, a = function(e2) {
      var n2 = (0, r.useContext)(o), t2 = n2.isEnabled, u2 = n2.client, a2 = (0, r.useState)(!!t2(e2)), i2 = a2[0], c2 = a2[1], l2 = (0, r.useRef)();
      return l2.current = i2, (0, r.useEffect)(function() {
        u2 && (u2.on("update", function() {
          var n3 = t2(e2);
          n3 !== l2.current && (l2.current = n3, c2(!!n3));
        }), u2.on("ready", function() {
          var n3 = t2(e2);
          c2(n3);
        }));
      }, [u2]), i2;
    }, i = function() {
      return (0, r.useContext)(o).client.getAllToggles();
    }, c = function() {
      var e2 = (0, r.useContext)(o);
      return {flagsReady: e2.flagsReady, flagsError: e2.flagsError};
    }, l = function(e2) {
      var n2 = (0, r.useContext)(o), t2 = n2.getVariant, u2 = n2.client, a2 = (0, r.useState)(t2(e2)), i2 = a2[0], c2 = a2[1], l2 = (0, r.useRef)();
      return l2.current = i2, (0, r.useEffect)(function() {
        u2 && (u2.on("update", function() {
          var n3 = t2(e2);
          l2.current.name === n3.name && l2.current.enabled === n3.enabled || (c2(n3), l2.current = n3);
        }), u2.on("ready", function() {
          var n3 = t2(e2);
          c2(n3);
        }));
      }, [u2]), i2 || {};
    }, s = function() {
      return (0, r.useContext)(o).updateContext;
    }, f2 = function() {
      return (0, r.useContext)(o).client;
    }, d = u;
    module.exports = n;
  })();
});
var FlagProvider = index_browser.FlagProvider;
var useFlag = index_browser.useFlag;
export {FlagProvider, useFlag};
