import {useEffect, useMemo, useState} from "../../../_snowpack/pkg/react.js";
import {useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {useFlag} from "../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {cloneDeep, groupBy, keyBy} from "../../../_snowpack/pkg/lodash.js";
import {COURSE_TYPE_ITEMS, EPIC_2966_BUG_5383} from "../../constants/index.js";
import {CourseType, DiplomaSeekingPath, ScheduleStatus} from "../../enums/index.js";
import {diplomaAnswerGql} from "../../../graphql/queries/diploma.js";
import {getStudentSchedulePeriodsQuery} from "../../../graphql/queries/schedule-period.js";
import {SEMESTER_TYPE} from "../../../screens/Admin/Curriculum/types.js";
import {
  getIgnoreDiplomaStudentPeriodsQuery,
  getStudentPeriodsQuery,
  getStudentProvidersQuery
} from "../../../screens/Homeroom/Schedule/services.js";
export const makeProviderData = (courses, altCourses) => {
  const providers = [];
  const providersData = groupBy(courses, "provider_id");
  for (const key in providersData) {
    providers.push({
      ...providersData[key]?.[0].Provider,
      Courses: providersData[key]
    });
  }
  const altProvidersData = groupBy(altCourses, "provider_id");
  for (const key in altProvidersData) {
    const index = providers.findIndex((item) => item.id === +key);
    if (index > -1) {
      providers[index].AltCourses = altProvidersData[key];
    } else {
      providers.push({
        ...altProvidersData[key]?.[0].Provider,
        Courses: [],
        AltCourses: altProvidersData[key]
      });
    }
  }
  return providers;
};
export const attachSelectedItems = (item, schedulePeriod) => {
  const period = item?.Periods?.find((periodItem) => periodItem?.id === schedulePeriod?.PeriodId);
  if (period && schedulePeriod) {
    item.Period = period;
    item.schedulePeriodId = schedulePeriod.schedule_period_id;
    item.scheduleResubmitDate = schedulePeriod.Schedule.date_resubmit;
    item.originalSchedulePeriodStatus = schedulePeriod.status;
    item.schedulePeriodStatus = schedulePeriod.status;
    if (schedulePeriod.SubjectId)
      item.Subject = period.Subjects?.find((subject) => subject?.subject_id === schedulePeriod.SubjectId);
    if (schedulePeriod.TitleId)
      period.Subjects?.forEach((subject) => {
        subject.Titles.concat(subject.AltTitles)?.map((title) => {
          if (title.title_id === schedulePeriod.TitleId)
            item.Title = title;
        });
      });
    if (schedulePeriod.CourseId)
      (item.Title?.Courses || []).concat(item.Title?.AltCourses || [])?.forEach((course) => {
        if (course.id === schedulePeriod.CourseId)
          item.Course = course;
      });
    if (schedulePeriod.course_type)
      item.CourseType = schedulePeriod.course_type;
    if (schedulePeriod.course_type === CourseType.CUSTOM_BUILT)
      item.CustomBuiltDescription = schedulePeriod.custom_build_description;
    if (schedulePeriod.course_type === CourseType.MTH_DIRECT && schedulePeriod.osse_course_name)
      item.OnSiteSplitEnrollment = {
        courseName: schedulePeriod.osse_course_name,
        districtSchool: schedulePeriod.osse_district_school,
        schoolDistrictName: schedulePeriod.osse_school_district_name
      };
    if (schedulePeriod.course_type === CourseType.THIRD_PARTY_PROVIDER)
      item.ThirdParty = {
        providerName: schedulePeriod.tp_provider_name,
        courseName: schedulePeriod.tp_course_name,
        phoneNumber: schedulePeriod.tp_phone_number,
        specificCourseWebsite: schedulePeriod.tp_specific_course_website,
        additionalWebsite: schedulePeriod.tp_additional_specific_course_website ? JSON.parse(schedulePeriod.tp_additional_specific_course_website) : ""
      };
  }
  return item;
};
export const filterSemesterOnlyTitles = (subject, scheduleData) => {
  if (!subject)
    return void 0;
  subject.Titles = subject.Titles.filter((title) => title.always_unlock || title.title_id === scheduleData.FirstSemesterSchedule?.Title?.title_id);
  subject.AltTitles = subject.AltTitles.filter((title) => title.always_unlock || title.title_id === scheduleData.FirstSemesterSchedule?.Title?.title_id);
  return subject;
};
export const filterSemesterOnly = (item) => {
  item.Subject = filterSemesterOnlyTitles(item.Subject, item);
  item?.Period?.Subjects?.map((subject) => filterSemesterOnlyTitles(subject, item));
  item?.Periods?.map((period) => {
    period.Subjects?.map((subject) => filterSemesterOnlyTitles(subject, item));
  });
  item?.filteredPeriods?.map((period) => {
    period.Subjects?.map((subject) => filterSemesterOnlyTitles(subject, item));
  });
  return item;
};
export const makeScheduleData = (studentPeriods, studentProvidersData, schedulePeriods, showSecondSemester = false, ignoreDiplomaStudentPeriods = [], diplomaAnswer, isAdmin = false, showAllPeriods = false, hideUncheckedSubjects = false) => {
  if (hideUncheckedSubjects) {
    const isEditable = schedulePeriods.some((period) => period.Schedule && (isAdmin || period.Schedule.status === ScheduleStatus.DRAFT || period.Schedule.status === ScheduleStatus.NOT_SUBMITTED || period.Schedule.status === ScheduleStatus.UPDATES_REQUIRED));
    if (schedulePeriods.length === 0 || isEditable) {
      studentPeriods.forEach((item) => {
        if (item.Subjects && Array.isArray(item.Subjects)) {
          item.Subjects = item.Subjects.filter((subject) => subject.allow_request !== false);
        }
      });
    }
  }
  const modifiedPeriods = [];
  const studentProviders = keyBy(studentProvidersData, "id");
  studentPeriods.map((period) => {
    period.Subjects?.map((subject) => {
      const secondSchedulePeriods = schedulePeriods.filter((item) => item.Schedule.is_second_semester);
      const showParentPeriods = !isAdmin && secondSchedulePeriods.length > 0 && secondSchedulePeriods[0].Schedule.status !== ScheduleStatus.UPDATES_REQUIRED;
      const showAdminPeriods = isAdmin && secondSchedulePeriods.length > 0 && showAllPeriods;
      if (showAdminPeriods || showParentPeriods) {
        const ignoreDiplomaSubject = ignoreDiplomaStudentPeriods?.find((element) => element.id === period.id)?.Subjects?.find((element) => element.subject_id === subject.subject_id);
        const firstSchedulePeriods = schedulePeriods.filter((item) => !item.Schedule.is_second_semester);
        const allIgnoreDiplomaTitles = ignoreDiplomaSubject?.Titles.concat(ignoreDiplomaSubject?.AltTitles || []);
        const allTitles = subject.Titles.concat(subject.AltTitles || []);
        const isTitleIdFound = firstSchedulePeriods.some((schedule) => {
          return allTitles.some((title) => schedule.TitleId === title.title_id);
        });
        if (ignoreDiplomaStudentPeriods.length > 0 && !isTitleIdFound && allIgnoreDiplomaTitles?.length !== allTitles.length) {
          const foundPeriod = ignoreDiplomaStudentPeriods.find((item) => item.id === period.id);
          const foundSubject = foundPeriod?.Subjects?.find((item) => item.subject_id === subject.subject_id);
          if (foundSubject) {
            modifiedPeriods.push(period.id);
            subject.Titles = foundSubject.Titles;
            subject.AltTitles = foundSubject.AltTitles;
          }
        }
      }
      subject.Titles.concat(subject.AltTitles || []).map((title) => {
        title.Courses.concat(title.AltCourses).map((course) => course.Provider = studentProviders[course.provider_id]);
        title.Providers = makeProviderData(title.Courses, title.AltCourses);
        title.CourseTypes = COURSE_TYPE_ITEMS.filter((item) => item.value === CourseType.CUSTOM_BUILT && title.custom_built || item.value === CourseType.MTH_DIRECT && (!!title.Providers?.length || title.split_enrollment === true) || item.value === CourseType.THIRD_PARTY_PROVIDER && title.third_party_provider);
      });
    });
  });
  const scheduleData = groupBy(studentPeriods, "period");
  const scheduleDataArray = [];
  let firstScheduleDataArray = [];
  let secondScheduleDataArray = [];
  for (const key in scheduleData) {
    scheduleDataArray.push({
      period: +key,
      Periods: scheduleData[key],
      filteredPeriods: scheduleData[key]
    });
  }
  let hasSecondSemesterSchedule = false;
  let hasUnlockedPeriods = false;
  let studentScheduleId = 0;
  let studentScheduleStatus = ScheduleStatus.DRAFT;
  let firstSemesterScheduleId = 0;
  let secondSemesterScheduleId = 0;
  if (schedulePeriods) {
    const firstSchedulePeriods = schedulePeriods.filter((item) => !item.Schedule.is_second_semester);
    firstScheduleDataArray = cloneDeep(scheduleDataArray);
    firstScheduleDataArray.map((item) => {
      const schedulePeriod = firstSchedulePeriods.find((x) => item.Periods.findIndex((period) => period.id === x.PeriodId) > -1);
      return attachSelectedItems(item, schedulePeriod);
    });
    const secondSchedulePeriods = schedulePeriods.filter((item) => item.Schedule.is_second_semester);
    if (secondSchedulePeriods?.length) {
      hasSecondSemesterSchedule = secondSchedulePeriods[0]?.Schedule?.status !== ScheduleStatus.DRAFT;
      secondScheduleDataArray = cloneDeep(scheduleDataArray);
      secondScheduleDataArray.map((item) => {
        let periodNumber = 1;
        const schedulePeriod = secondSchedulePeriods.find((x) => {
          if (!x.Period && periodNumber === item.period) {
            item.schedulePeriodId = x.schedule_period_id;
            item.originalSchedulePeriodStatus = x.status;
            item.schedulePeriodStatus = x.status;
          }
          periodNumber++;
          const foundPeriod = item.Periods.find((period) => {
            return period.id === x.PeriodId;
          });
          return foundPeriod !== void 0;
        });
        return attachSelectedItems(item, schedulePeriod);
      });
      if (modifiedPeriods.length > 0) {
        for (const secondScheduleData of secondScheduleDataArray) {
          if (secondScheduleData.Period?.id && modifiedPeriods.includes(secondScheduleData.Period.id)) {
            if (secondScheduleData.Title?.diploma_seeking_path === DiplomaSeekingPath.DIPLOMA_SEEKING && diplomaAnswer !== 1 || secondScheduleData.Title?.diploma_seeking_path === DiplomaSeekingPath.NON_DIPLOMA_SEEKING && diplomaAnswer !== 0)
              delete secondScheduleData.Title;
          }
        }
      }
    } else {
      hasSecondSemesterSchedule = false;
      secondScheduleDataArray = cloneDeep(firstScheduleDataArray);
      secondScheduleDataArray.map((item) => delete item.schedulePeriodId);
    }
    secondScheduleDataArray.map((item) => {
      item.FirstSemesterSchedule = firstScheduleDataArray.find((x) => x.period === item.period);
      return filterSemesterOnly(item);
    });
    const regularScheduleData = firstScheduleDataArray.length ? firstScheduleDataArray : scheduleDataArray;
    if ((showSecondSemester || hasSecondSemesterSchedule) && regularScheduleData?.filter((item) => item?.Period?.semester !== SEMESTER_TYPE.NONE || item.Title?.always_unlock || item.Course?.always_unlock).length) {
      hasUnlockedPeriods = true;
      studentScheduleId = secondSchedulePeriods?.length ? secondSchedulePeriods[0]?.ScheduleId : 0;
      studentScheduleStatus = secondSchedulePeriods?.length ? secondSchedulePeriods[0]?.Schedule?.status : ScheduleStatus.DRAFT;
    } else {
      hasUnlockedPeriods = regularScheduleData?.filter((item) => item?.Period?.semester !== SEMESTER_TYPE.NONE || item.Title?.always_unlock || item.Course?.always_unlock).length ? true : false;
      studentScheduleId = secondSchedulePeriods?.length ? secondSchedulePeriods[0]?.ScheduleId : showSecondSemester ? 0 : firstSchedulePeriods[0]?.ScheduleId;
      studentScheduleStatus = secondSchedulePeriods?.length ? secondSchedulePeriods[0]?.Schedule?.status : firstSchedulePeriods[0]?.Schedule?.status;
    }
    firstSemesterScheduleId = firstSchedulePeriods[0]?.ScheduleId;
    secondSemesterScheduleId = secondSchedulePeriods[0]?.ScheduleId;
  }
  return {
    firstScheduleData: firstScheduleDataArray.length ? firstScheduleDataArray : scheduleDataArray,
    secondScheduleData: secondScheduleDataArray.length ? secondScheduleDataArray : scheduleDataArray,
    hasSecondSemesterSchedule,
    hasUnlockedPeriods,
    studentScheduleId,
    studentScheduleStatus,
    firstSemesterScheduleId,
    secondSemesterScheduleId
  };
};
export const useStudentSchedulePeriods = (student_id, school_year_id, showSecondSemester = false, isGradeFilter = true, isAdmin = false, showAllPeriods = false, hideUncheckedSubjects = false) => {
  const [scheduleData, setScheduleData] = useState([]);
  const [secondScheduleData, setSecondScheduleData] = useState([]);
  const [studentScheduleId, setStudentScheduleId] = useState(0);
  const [studentScheduleStatus, setStudentScheduleStatus] = useState(ScheduleStatus.DRAFT);
  const [hasSecondSemesterSchedule, setHasSecondSemesterSchedule] = useState(false);
  const [hasUnlockedPeriods, setHasUnlockedPeriods] = useState(false);
  const [firstSemesterScheduleId, setFirstSemesterScheduleId] = useState(0);
  const [secondSemesterScheduleId, setSecondSemesterScheduleId] = useState(0);
  const epic2966bug5383 = useFlag(EPIC_2966_BUG_5383);
  const {loading: loadingProviders, data: providersData} = useQuery(getStudentProvidersQuery, {
    variables: {schoolYearId: school_year_id},
    skip: !school_year_id,
    fetchPolicy: "network-only"
  });
  const transformPeriodsData = (periodsData2) => {
    return periodsData2?.map((period) => {
      const updatedPeriod = {...period};
      updatedPeriod.Subjects = updatedPeriod.Subjects?.map((subject) => {
        const updatedSubject = {...subject};
        updatedSubject.Titles = updatedSubject.Titles?.map((title) => {
          const updatedTitle = {...title};
          updatedTitle.AltCourses = updatedTitle.Courses;
          return updatedTitle;
        });
        updatedSubject.AltTitles = updatedSubject.Titles;
        return updatedSubject;
      });
      return updatedPeriod;
    });
  };
  const {loading, data: periodsData} = useQuery(getStudentPeriodsQuery, {
    variables: {
      studentId: student_id,
      schoolYearId: school_year_id,
      isGradeFilter
    },
    skip: !student_id || !school_year_id,
    fetchPolicy: "network-only"
  });
  const {loading: ignoreDiplomaLoading, data: ignoreDiplomaPeriodsData} = useQuery(getIgnoreDiplomaStudentPeriodsQuery, {
    variables: {
      studentId: student_id,
      schoolYearId: school_year_id,
      isGradeFilter
    },
    skip: !student_id || !school_year_id,
    fetchPolicy: "network-only"
  });
  const {
    loading: studentSchedulePeriodsLoading,
    data: studentSchedulePeriodsData,
    refetch
  } = useQuery(getStudentSchedulePeriodsQuery, {
    variables: {
      schoolYearId: school_year_id,
      studentId: student_id
    },
    skip: !student_id || !school_year_id,
    fetchPolicy: "network-only"
  });
  const {loading: diplomaAnswerLoading, data: diplomaAnswerData} = useQuery(diplomaAnswerGql, {
    variables: {
      diplomaAnswerInput: {
        schoolYearId: school_year_id,
        studentId: student_id
      }
    },
    skip: !student_id || !school_year_id,
    fetchPolicy: "cache-and-network"
  });
  const transformedPeriodsData = useMemo(() => {
    if (!loading && periodsData?.studentPeriods && !isGradeFilter && epic2966bug5383) {
      return transformPeriodsData(periodsData.studentPeriods);
    }
    return periodsData?.studentPeriods;
  }, [loading, periodsData, epic2966bug5383]);
  const transformedPeriodsDiplomaData = useMemo(() => {
    if (!ignoreDiplomaLoading && ignoreDiplomaPeriodsData?.studentPeriodsIgnoringDiploma && !isGradeFilter && epic2966bug5383) {
      return transformPeriodsData(ignoreDiplomaPeriodsData.studentPeriodsIgnoringDiploma);
    }
    return ignoreDiplomaPeriodsData?.studentPeriodsIgnoringDiploma;
  }, [ignoreDiplomaLoading, ignoreDiplomaPeriodsData, epic2966bug5383]);
  useEffect(() => {
    if (transformedPeriodsData && !loadingProviders && providersData.studentProviders && !studentSchedulePeriodsLoading && transformedPeriodsDiplomaData) {
      const result = makeScheduleData(transformedPeriodsData, providersData.studentProviders, studentSchedulePeriodsData?.schedulePeriods, showSecondSemester, transformedPeriodsDiplomaData, diplomaAnswerData?.getDiplomaAnswer?.answer ?? -1, isAdmin, showAllPeriods, hideUncheckedSubjects);
      setScheduleData(result.firstScheduleData);
      setSecondScheduleData(result.secondScheduleData);
      setHasSecondSemesterSchedule(result.hasSecondSemesterSchedule);
      setHasUnlockedPeriods(result.hasUnlockedPeriods);
      setStudentScheduleId(result.studentScheduleId);
      setStudentScheduleStatus(result.studentScheduleStatus);
      setFirstSemesterScheduleId(result.firstSemesterScheduleId);
      setSecondSemesterScheduleId(result.secondSemesterScheduleId);
    }
  }, [
    transformedPeriodsData,
    studentSchedulePeriodsLoading,
    studentSchedulePeriodsData,
    loadingProviders,
    providersData,
    transformedPeriodsDiplomaData,
    diplomaAnswerLoading
  ]);
  return {
    scheduleData,
    hasSecondSemester: hasSecondSemesterSchedule,
    setScheduleData,
    secondScheduleData,
    setSecondScheduleData,
    studentScheduleId,
    setStudentScheduleId,
    studentScheduleStatus,
    hasUnlockedPeriods,
    firstSemesterScheduleId,
    secondSemesterScheduleId,
    refetch
  };
};
