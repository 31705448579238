import {isNil} from "../../../_snowpack/pkg/lodash.js";
export const handleDownload = async ({
  params,
  apiFunction,
  responseExtractor,
  setError,
  loadingState,
  setLoading,
  errorMessage
}) => {
  if (loadingState)
    return;
  setLoading(true);
  try {
    const response = await apiFunction(params);
    const signedUrl = responseExtractor(response);
    if (isNil(signedUrl)) {
      setError(true);
      console.error(errorMessage);
      return;
    }
    const link = document.createElement("a");
    link.href = signedUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(`An error occurred: ${errorMessage}`);
    console.error(error);
    setError(true);
  } finally {
    setLoading(false);
  }
};
