export var EnrollmentPacketFormProperty;
(function(EnrollmentPacketFormProperty2) {
  EnrollmentPacketFormProperty2["LAST_CLICKED_ACTION"] = "last_clicked_action";
  EnrollmentPacketFormProperty2["EXEMPTION_DATE"] = "exemptionDate";
  EnrollmentPacketFormProperty2["SHOW_MISSING_INFO_MODAL"] = "showMissingInfoModal";
  EnrollmentPacketFormProperty2["SHOW_AGE_ISSUE_MODAL"] = "showAgeIssueModal";
  EnrollmentPacketFormProperty2["PRE_SAVE_STATUS"] = "preSaveStatus";
  EnrollmentPacketFormProperty2["STATUS"] = "status";
  EnrollmentPacketFormProperty2["SHOW_SAVE_WARN_MODAL"] = "showSaveWarnModal";
  EnrollmentPacketFormProperty2["SAVE_ALERT"] = "saveAlert";
  EnrollmentPacketFormProperty2["SAVE_ERROR"] = "saveError";
  EnrollmentPacketFormProperty2["NOTES"] = "notes";
  EnrollmentPacketFormProperty2["MISSING_INFO_ALERT"] = "missingInfoAlert";
  EnrollmentPacketFormProperty2["AGE_ISSUE"] = "age_issue";
  EnrollmentPacketFormProperty2["MISSING_FILES"] = "missing_files";
  EnrollmentPacketFormProperty2["SHOW_VALIDATION_ERRORS"] = "showValidationErrors";
})(EnrollmentPacketFormProperty || (EnrollmentPacketFormProperty = {}));
