import {makeStyles} from "../../../../../_snowpack/pkg/@material-ui/styles.js";
import {MthColor} from "../../../../../core/enums/index.js";
export const selectStyles = makeStyles({
  backgroundSelect: {
    fontSize: "12px",
    borderRadius: "8px!important",
    minWidth: "135px",
    height: "29px",
    textAlign: "center",
    background: MthColor.BUTTON_LINEAR_GRADIENT,
    color: `${MthColor.LIGHTGRAY} !important`,
    "&:before": {
      borderColor: MthColor.BUTTON_LINEAR_GRADIENT
    },
    "&:after": {
      borderColor: MthColor.BUTTON_LINEAR_GRADIENT
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: "500"
    }
  },
  withdrawBackgroundSelect: {
    fontSize: "12px",
    borderRadius: "8px!important",
    minWidth: "135px",
    height: "29px",
    textAlign: "center",
    background: MthColor.RED_GRADIENT,
    color: `${MthColor.LIGHTGRAY} !important`,
    "&:before": {
      borderColor: MthColor.RED_GRADIENT
    },
    "&:after": {
      borderColor: MthColor.RED_GRADIENT
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: "500"
    }
  },
  yellowBackgroundSelect: {
    fontSize: "12px",
    borderRadius: "8px!important",
    minWidth: "135px",
    height: "29px",
    textAlign: "center",
    background: MthColor.YELLOW_GRADIENT,
    color: `${MthColor.LIGHTGRAY} !important`,
    "&:before": {
      borderColor: MthColor.YELLOW_GRADIENT
    },
    "&:after": {
      borderColor: MthColor.YELLOW_GRADIENT
    },
    "& > div": {
      paddingTop: 0,
      paddingBottom: 0
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: "500"
    }
  },
  selectIcon: {
    fill: `${MthColor.LIGHTGRAY} !important`,
    color: `${MthColor.LIGHTGRAY} !important`
  },
  selectRoot: {
    color: `${MthColor.LIGHTGRAY} !important`
  }
});
export const useStyles = {
  modalCard: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    backgroundColor: MthColor.SYSTEM_09,
    boxShadow: 24,
    padding: "16px 32px",
    borderRadius: 2
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "200px"
  },
  close: {
    background: "black",
    borderRadius: 1,
    color: "white",
    cursor: "pointer"
  },
  errorOutline: {
    background: MthColor.BG_MAIN,
    borderRadius: 1,
    color: MthColor.BLACK,
    marginBottom: 12,
    height: 42,
    width: 42
  },
  content: {
    padding: "10px 0"
  },
  submitButton: {
    borderRadius: "8px",
    width: "90px"
  },
  formRow: {
    display: "flex",
    alignItems: "center",
    paddingY: "10px",
    background: MthColor.BG_MAIN,
    "&:nth-child(even)": {
      background: MthColor.WHITE,
      borderRadius: "8px"
    }
  },
  formLabel: {
    width: "155px",
    textAlign: "center",
    position: "relative",
    color: MthColor.BLACK
  },
  formValue: {
    padding: "0 30px",
    color: MthColor.LIGHTPURPLE,
    position: "relative"
  },
  labelAfter: {
    width: 0,
    height: "23px",
    borderRight: `1px solid ${MthColor.BLACK}`,
    position: "absolute",
    top: 0,
    right: 0
  },
  modalEmailCard: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 441,
    backgroundColor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  },
  emailRowHead: {
    display: "flex",
    alignItems: "center",
    mb: 3
  },
  emailRow: {
    display: "flex",
    alignItems: "center",
    mb: 2
  },
  emailLabel: {
    width: "150px",
    display: "flex",
    alignItems: "center"
  },
  ok: {
    borderRadius: 10,
    width: "9px",
    height: "19px",
    marginTop: 4
  }
};
