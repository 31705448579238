export var QuestionTypeLabel;
(function(QuestionTypeLabel2) {
  QuestionTypeLabel2["DROP_DOWN"] = "Drop Down";
  QuestionTypeLabel2["TEXT_FIELD"] = "Text Field";
  QuestionTypeLabel2["CHECKBOX"] = "Checkbox";
  QuestionTypeLabel2["AGREEMENT"] = "Agreement";
  QuestionTypeLabel2["MULTIPLE_CHOICES"] = "Multiple Choices";
  QuestionTypeLabel2["CALENDAR"] = "Calendar";
  QuestionTypeLabel2["INFORMATION"] = "Information";
  QuestionTypeLabel2["SPECIAL_EDUCATION"] = "Special Education";
  QuestionTypeLabel2["PROGRAM_STATE"] = "Program State";
  QuestionTypeLabel2["PROGRAM_YEAR"] = "Program Year";
})(QuestionTypeLabel || (QuestionTypeLabel = {}));
