import React from "../../../../_snowpack/pkg/react.js";
import {Box, CircularProgress} from "../../../../_snowpack/pkg/@mui/material.js";
import {MthModal} from "../../../../components/MthModal/MthModal.js";
import {containerBoxStyles, progressCircleContainerStyles, progressCircleStyles} from "./MthLoadingWrapper.styles.js";
export const MthLoadingWrapper = ({
  children,
  loading,
  minHeight = "150px",
  modalMode = false,
  modalTitle,
  hideChildrenWhenLoading = false
}) => {
  const shouldShowChildren = !(hideChildrenWhenLoading && loading) || !hideChildrenWhenLoading;
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {...containerBoxStyles, minHeight}
  }, shouldShowChildren && children, !modalMode && loading && /* @__PURE__ */ React.createElement(Box, {
    sx: progressCircleContainerStyles
  }, /* @__PURE__ */ React.createElement(CircularProgress, {
    sx: progressCircleStyles
  })), modalMode && loading && /* @__PURE__ */ React.createElement(MthModal, {
    title: modalTitle,
    open: true,
    onClose: () => {
    },
    onConfirm: () => {
    },
    loading
  }));
};
