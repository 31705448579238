import {MthTitle, ReduceFunds} from "../enums/index.js";
export const REDUCE_FUNDS_ITEMS = [
  {label: "None", value: ReduceFunds.NONE},
  {label: MthTitle.SUPPLEMENTAL_LEARNING_FUNDS, value: ReduceFunds.SUPPLEMENTAL},
  {label: MthTitle.TECHNOLOGY_ALLOWANCE, value: ReduceFunds.TECHNOLOGY}
];
export const ADMIN_REDUCE_FUNDS_ITEMS = [
  {label: "Disabled", value: ReduceFunds.NONE},
  {label: MthTitle.SUPPLEMENTAL_LEARNING_FUNDS, value: ReduceFunds.SUPPLEMENTAL},
  {label: MthTitle.TECHNOLOGY_ALLOWANCE, value: ReduceFunds.TECHNOLOGY}
];
