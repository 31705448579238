import {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useLazyQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import {getStudentDetail} from "../../../screens/Admin/UserProfile/services.js";
export const useStudentInfo = (student_id) => {
  const [studentInfo, setStudentInfo] = useState();
  const [getStudentInfo, {loading: studentInfoLoading, data: studentInfoData, refetch}] = useLazyQuery(getStudentDetail, {
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (student_id) {
      getStudentInfo({
        variables: {
          student_id
        }
      });
    }
  }, [student_id]);
  useEffect(() => {
    if (!studentInfoLoading && studentInfoData) {
      setStudentInfo(studentInfoData?.student);
    }
  }, [studentInfoLoading, studentInfoData]);
  return {
    studentInfo,
    refetch
  };
};
