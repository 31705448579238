import {forOwn, groupBy, values} from "../../../_snowpack/pkg/lodash.js";
import {ToDoCategory} from "../../../components/ToDoList/components/ToDoListItem/types.js";
import {checkEnrollPacketStatus, findAcceptedDateApplicationByPacket} from "../index.js";
export const filterAndMergeTodos = (parent_todos, schoolYears, meStudents, timezone) => {
  let newTodoList = [];
  forOwn(parent_todos, (item, key) => {
    if (key !== "__typename") {
      if (item.category === ToDoCategory.MISSING_LEARNING_LOG || item.category === ToDoCategory.RESUBMIT_LEARNING_LOG) {
        const groupedItems = [{...item, students: item.students}];
        newTodoList = newTodoList.concat(groupedItems);
      } else if (item.category === ToDoCategory.SUBMIT_ENROLLMENT_PACKET) {
        const splitItems = values(groupBy(item.students.filter((student) => checkEnrollPacketStatus(schoolYears, student)), (student) => findAcceptedDateApplicationByPacket(Number(student.student_id), meStudents, timezone))).reduce((list, students) => list.concat([{...item, students}]), []);
        newTodoList = newTodoList.concat(splitItems);
      } else if (item.category === ToDoCategory.SUBMIT_WITHDRAW) {
        const splitItems = item.students.reduce((list, student) => list.concat([{...item, students: [student]}]), []);
        newTodoList = newTodoList.concat(splitItems);
      } else {
        const splitItems = item.students.reduce((list, student) => list.concat([{...item, students: [student]}]), []);
        newTodoList = newTodoList.concat(splitItems);
      }
    }
  });
  return newTodoList;
};
