import {gql} from "../../_snowpack/pkg/@apollo/client.js";
export const saveReimbursementRequestMutation = gql`
  mutation CreateOrUpdateReimbursementRequest($requestInput: CreateOrUpdateReimbursementRequestInputs!) {
    createOrUpdateReimbursementRequest(requestInput: $requestInput) {
      reimbursement_request_id
    }
  }
`;
export const createOrUpdateReimbursementRequestOnRequestForm = gql`
  mutation CreateOrUpdateReimbursementRequestOnRequestForm($requestInput: CreateOrUpdateReimbursementRequestInputs!) {
    createOrUpdateReimbursementRequestOnRequestForm(requestInput: $requestInput) {
      reimbursement_request_id
      isExceeded
    }
  }
`;
export const deleteReimbursementQuestionMutation = gql`
  mutation DeleteReimbursementRequest($remimbursementRequestId: Float!) {
    deleteReimbursementRequest(remimbursement_request_id: $remimbursementRequestId)
  }
`;
export const deleteReimbursementRequestsMutation = gql`
  mutation DeleteReimbursementRequests($reimbursementRequestsActionInput: ReimbursementRequestsActionInput!) {
    deleteReimbursementRequests(reimbursementRequestsActionInput: $reimbursementRequestsActionInput)
  }
`;
export const sendReimbursementRequestEmailToParentsMutation = gql`
  mutation SendReimbursementRequestEmailToParents($EmailReimbursementRequestInput: EmailReimbursementRequestInput!) {
    sendReimbursementRequestEmailToParents(EmailReimbursementRequestInput: $EmailReimbursementRequestInput) {
      id
      reimbursement_request_id
      email_record_id
      template_name
      subject
      body
      from_email
      created_at
    }
  }
`;
